import React, { Component } from 'react'

export default class blockForWidget extends Component {
    render() {
        const { data, label } = this.props;

        return (
            <div className="blockForWidget">
                {
                    label &&
                    <div className="row">
                        <div className="col-7 labelTitle">
                            {label}
                        </div>
                    </div>
                }
                {
                    data.map((el, k) => {
                        return (
                            Object.keys(el).map(key => {
                                return (
                                    <div key={k} className="row">
                                        <div className="col-7 labelKey">
                                            {key}
                                        </div>
                                        <div className={label ? "col-4 labelValue withLabel" : "col-4 labelValue"}>
                                            {el[key]}
                                        </div>
                                    </div>
                                )
                            })
                        )
                    })
                }
            </div>
        )
    }
}