import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { isEmpty, isNull } from 'lodash';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { FormGroup, FormControlLabel, Switch, FormControl } from '@material-ui/core';
import { sanificationArray, sanificationValueBE } from '../actions/promoFunctions';
import Alert from '@material-ui/lab/Alert';

import './style.scss';

import { countries } from '../countries';

import MultiSelect from 'react-select';
import makeAnimated from 'react-select/animated';

/**
 * Attivazione modale per aggiungere / modificare promo * 
 */
export default class modalPromo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idPromo: this.props.promoTemp._id || '',
            description: this.props.promoTemp.description || '',
            discount: (this.props.promoTemp.discount * 100) || '',
            discountValue: this.props.promoTemp.discountValue || '',
            priceValue: this.props.promoTemp.priceValue || '',
            webUsers: this.props.promoTemp.webUser || '',
            toBuy: 1,
            startAt: this.props.promoTemp.startAt || null,
            endAt: this.props.promoTemp.endAt || null,
            productNames: sanificationValueBE(this.props.promoTemp.productNames, 'products'),
            qty: 1,
            countries: sanificationValueBE(this.props.promoTemp.countries, 'countries') || null,
            startItems: this.props.promoTemp.startItems,
            totalUses: this.props.promoTemp.totalUses || '',
            isReseller: this.props.promoTemp.isReseller || false,
            isRotten: this.props.promoTemp.isRotten || false,
            groups: !isNull(this.props.promoTemp.groups) ? this.props.promoTemp.groups : [],
            /**
             * Variabile utilizzata per la solo editazione della promo.
             * Quando apro una modale, viene copiato lo stesso valore a groups e groupsTemp.
             * Prima di mandare la PUT per la modifica della promo, controllo se questi due valori non coincidono,
             * allora effettua determinate sanificazioni.
             */
            groupsTemp: !isNull(this.props.promoTemp.groups) ? this.props.promoTemp.groups : [],
            /**
             * Variabile Passata da userInDetails per capire se sto attivando la promo da li.
             * Se è true mi sto gestendo la promo da userInDetails, se è false la sto gestendo dalla sezione Promo/Coupon
             */
            isNewPromoUserInDetail: this.props.isNewPromoUserInDetail || false,
            isEditPromoUserInDetail: this.props.isEditPromoUserInDetail || false,

            /**
             * Variabile utilizzata in userInDetail per precompilare la modale di creazione Promo con l'email dell'utente associato.
             */
            userId: this.props.userId,

            idPromoNull: false,
            descriptionNull: false,
            discountNull: false,
            startItemsNull: false,
            startAtNull: false,
            endAtNull: false,
            productNameNull: false,
            qtyNull: false,
            isResellerNull: false,

            unlimitedUse: this.props.promoTemp.startItems === null ? true : false,
            unlimitedDate: this.props.promoTemp.endAt === null ? true : false,

            activeReseller: this.props.promoTemp.isReseller || false,

            disableDate: false,
            disableUse: false,

            titleModal: this.props.titleModal,

            /**
            * Array rispettivamente di countries e webUsers da mandare al BE durante la creazine di una Promo/Coupon.
            */
            countriesFromListArray: null,
            emailFromListArray: null,

            productsFromListArray: null,

            /**
             * Se nel titolo è presente la seguente dicitura, allora attiva elementi per una nuova modale promozione.
             * Se la dicitura è diversa, setta la variabile a false.
             */
            isNewPromo: this.props.titleModal === 'Crea Promo',
            editPromo: this.props.titleModal === 'Modifica Promo',
        }
    }

    componentDidMount() {
        if (!this.state.isNewPromoUserInDetail && !this.state.isEditPromoUserInDetail) {
            this.props.listDevice();
            this.props.getGroupList();
        }

        this.props.productList();
    }

    /**
     * onChange utilizzata nella multiSelect quando seleziono i dati.
     * Quando seleziono i dati da mandare al BE le trasformo da oggetti {label: xx, value: xx}
     * a un array di value (valore accettato dal BE).
    */
    setDataFromOptionsList = (options, name) => {
        let arrayTemp = [];

        switch (name) {
            case 'webUser':
                if (!isNull(options) || !isEmpty(options)) {
                    // eslint-disable-next-line
                    options.map(el => {
                        arrayTemp.push(el.value);
                    });
                }

                this.setState({
                    emailFromListArray: arrayTemp,
                    webUsers: ''
                });

                break;

            case 'products':
                if (!isNull(options) || !isEmpty(options)) {
                    // eslint-disable-next-line
                    options.map(el => {
                        arrayTemp.push(el.value);
                    });
                }

                this.setState({
                    productsFromListArray: arrayTemp,
                    productNames: ''
                });

                break;

            case 'countries':
                if (!isNull(options) || !isEmpty(options)) {
                    // eslint-disable-next-line
                    options.map(el => {
                        arrayTemp.push(el.value);
                    });
                }

                this.setState({
                    countriesFromListArray: arrayTemp,
                    countries: null
                });

                break;

            case 'group':
                this.setState({ groups: options });

                break;

            default:
                this.setState({
                    emailFromListArray: null,
                    productsFromListArray: null,
                    countriesFromListArray: null,
                    countries: null,
                    webUsers: '',
                    productNames: ''
                });
                break;
        }
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });

        switch (event.target.name) {
            case 'description': this.setState({ descriptionNull: false }); break;
            case 'discount': this.setState({ discountNull: false }); break;
            case 'startItems': this.setState({ startItemsNull: false }); break;
            case 'productNames': this.setState({ productNameNull: false }); break;
            case 'activeReseller': this.setState({ isResellerNull: false }); break;
            default: break;
        }
    }

    setPromoTime = (newDate, name) => {
        switch (name) {
            case 'startAt':
                this.setState({
                    startAt: moment(newDate).toISOString(),
                    startAtNull: false
                });
                break;

            case 'endAt':
                this.setState({
                    endAt: moment(newDate).toISOString(),
                    endAtNull: false
                });
                break;

            default: break;
        }
    }

    /**
     * Setta lo stato del numero di usi dell'app in modo che sia illimitato
     */
    setUnlimitedUse = () => {
        this.setState({ unlimitedUse: !this.state.unlimitedUse })
        setTimeout(() => {
            if (this.state.unlimitedUse) {
                this.setState({
                    startItems: null,
                    disableUse: true
                });
            } else {
                this.setState({
                    startItems: '',
                    disableUse: false
                });
            }
        }, 300);
    }

    /**
     * Setta lo stato della data di fine in modo che la promo non scada mai
     */
    setUnlimitedDate = () => {
        this.setState({ unlimitedDate: !this.state.unlimitedDate });
        setTimeout(() => {
            if (this.state.unlimitedDate) {
                this.setState({
                    endAt: null,
                    disableDate: true
                });
            } else {
                this.setState({ disableDate: false });
            }
        }, 300);
    }

    setReseller = () => {
        this.setState({ activeReseller: !this.state.activeReseller });
        setTimeout(() => {
            if (this.state.activeReseller) {
                this.setState({
                    isReseller: true
                });
            } else {
                this.setState({ isReseller: false });
            }
        }, 300);
    }

    /**
     * Funzione per non chiamare getListDevice ad ogni carattere digitato nella select per i webUsers.
     * Viene chiamata getDeviceList dal quinto carattere digitato fino al dodicesimo. 
     * Quando si cancella la chiamata non viene effettuata. (fatto così per ridurre il numero di chiamate per ogni digitazione). 
    */
    updateListUsers = (event) => {
        if (event !== '') {
            if (!isNull(this.state.webUsers)) {
                this.setState({ webUsers: event });
                if (event.length > 5 && event.length < 12) {
                    this.props.listDevice(event);
                }

            } else {
                this.setState({ webUsers: '' });
            }
        }
    }

    confirm = () => {
        const { canPostPromoApi, canEditPromoApi, infoNotify } = this.props;
        if (this.state.idPromo === '' && !this.state.isNewPromo) {
            this.setState({ idPromoNull: true });
            this.props.errorNotify('ID della promo obbligatoria');
        } else if ((isEmpty(this.state.productsFromListArray) || isNull(this.state.productsFromListArray)) && (isEmpty(this.state.productNames) || isNull(this.state.productNames))) {
            this.setState({ productNameNull: true });
            this.props.errorNotify('Prodotto al quale associare la promo obbligatorio');
        } else if (this.state.description === '') {
            this.setState({ descriptionNull: true });
            this.props.errorNotify('Descrizione della promo obbligatoria');
        } else if (
            !this.state.isReseller &&
            (isNull(this.state.webUsers) || isEmpty(this.state.webUsers)) &&
            (isNull(this.state.emailFromListArray) || isEmpty(this.state.emailFromListArray)) &&
            (isNull(this.state.countries) || isEmpty(this.state.countries)) &&
            (isNull(this.state.countriesFromListArray) || isEmpty(this.state.countriesFromListArray)) &&
            (isNull(this.state.groups) || isEmpty(this.state.groups)) &&
            (!this.state.isNewPromoUserInDetail)
        ) {
            this.setState({ isResellerNull: true });
            this.props.errorNotify('Inserire Web User o Paese o Reseller o Gruppo');
        } else if (this.state.startItems === '') {
            this.setState({ startItemsNull: true });
            this.props.errorNotify('Numero di volte di utilizzo della promo obbligatoria');
        } else if ((this.state.discount === '' || this.state.discount === null) && (this.state.discountValue === '' || this.state.discountValue === null) && (this.state.priceValue === '' || this.state.priceValue === null)) {
            this.setState({ discountNull: true });
            this.props.errorNotify('Sconto della promo obbligatoria');
        } else if (this.state.discount > 100) {
            this.setState({ discountNull: true });
            this.props.warningNotify('La percentuale della promo non puo superare i 100!');
        } else if (this.state.qty > 1000) {
            this.setState({ qtyNull: true });
            this.props.errorNotify('Il Coupon non può superare le 100 unità!');
        } else if (this.state.qty < 1) {
            this.setState({ qtyNull: true });
            this.props.errorNotify('Il Coupon non può avere una quantità minore di 1!');
        } else if (isNull(this.state.startAt)) {
            this.setState({ startAtNull: true });
            this.props.errorNotify('Data di inizio della promo obbligatoria');
        } else if (isNull(this.state.endAt) && !this.state.unlimitedDate) {
            this.setState({ endAtNull: true });
            this.props.errorNotify('Data di fine della promo obbligatoria');
        } else {
            // CREAZIONE PROMO
            if (this.state.isNewPromo) {
                let data = {
                    webUsers: this.state.isNewPromoUserInDetail ? sanificationValueBE(this.state.userId, 'userInDetail') : this.state.emailFromListArray,
                    description: this.state.description,
                    startAt: this.state.startAt,
                    endAt: this.state.endAt,
                    productNames: this.state.productsFromListArray,
                    discount: this.state.discount === '' ? null : (this.state.discount * 0.01).toFixed(2),
                    discountValue: this.state.discountValue === '' ? null : parseFloat(this.state.discountValue).toFixed(2),
                    priceValue: this.state.priceValue === '' ? null : parseFloat(this.state.priceValue).toFixed(2),
                    toBuy: 1,
                    countries: this.state.countriesFromListArray,
                    startItems: this.state.startItems,
                    totalUses: this.state.totalUses,
                    isReseller: this.state.isReseller,
                    groups: sanificationArray(this.state.groups, 'userGroup', this.state.groupsTemp)
                };

                if (canPostPromoApi) {
                    this.props.clickNew(data, false);
                } else {
                    infoNotify('Non hai i permessi per creare una promo!');
                }
            }
            // EDIT PROMO
            else if (this.state.editPromo) {
                let data = {
                    id: this.state.idPromo,
                    webUser: this.state.webUsers === '' ? null : this.state.webUsers,
                    description: this.state.description,
                    startAt: this.state.startAt,
                    endAt: this.state.endAt,
                    productNames: (this.state.productNames === null || this.state.productNames === '') ? this.state.productsFromListArray : sanificationArray(this.state.productNames, 'products'),
                    discount: this.state.discount === '' ? null : (this.state.discount * 0.01).toFixed(2),
                    discountValue: this.state.discountValue === '' ? null : parseFloat(this.state.discountValue).toFixed(2),
                    priceValue: this.state.priceValue === '' ? null : parseFloat(this.state.priceValue).toFixed(2),
                    toBuy: 1,
                    countries: (this.state.countries === null || this.state.countries === '') ? this.state.countriesFromListArray : sanificationArray(this.state.countries, 'countries'),
                    startItems: this.state.startItems,
                    totalUses: this.state.totalUses,
                    isReseller: this.state.isReseller,
                    groups: sanificationArray(this.state.groups, 'userGroup', this.state.groupsTemp)
                };

                if (canEditPromoApi) {
                    this.props.clickEdit(data, false);

                } else {
                    infoNotify('Non hai i permessi per modificare una promo!');
                }
            }
            this.props.toggle();
        }
    }

    render() {
        const { stateModal, toggle, titleModal, preferences, group, promo } = this.props;
        const animatedComponents = makeAnimated();

        return (
            <Fragment>
                <Modal isOpen={stateModal} className="modalPlayer">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        <Form>
                            {
                                // questo messaggio appare solo quando una PROMO è scaduta per notificare all'utente che modificandola verrà riattivata con il numero di utilizzi inseriti
                                this.state.isRotten &&
                                <Form.Group as={Row}>
                                    <Col sm="12">
                                        <Alert severity="error">Attenzione! Modificando questa PROMO sarà riattivata con gli utilizzi selezionati</Alert>
                                    </Col>
                                </Form.Group>
                            }

                            {
                                !this.state.isNewPromo &&
                                <Form.Group as={Row}>
                                    <Form.Label column sm="3" className="text">
                                        ID Promo
                                    </Form.Label>
                                    <Col sm="8">
                                        <Form.Control
                                            type="text"
                                            value={this.state.idPromo}
                                            isInvalid={this.state.idPromoNull}
                                            readOnly
                                        />
                                    </Col>
                                </Form.Group>
                            }

                            <Form.Group as={Row}>
                                <Form.Label column sm="3" className="text">
                                    Seleziona il prodotto
                                </Form.Label>

                                <Col sm="8">
                                    <FormControl>
                                        <MultiSelect
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            options={sanificationValueBE(preferences.allProducts, 'productList')}
                                            isClearable
                                            isSearchable
                                            value={(isNull(this.state.productNames) || isEmpty(this.state.productNames)) ? undefined : this.state.productNames}
                                            name="productNames"
                                            onChange={options => this.setDataFromOptionsList(options, 'products')}
                                        />
                                    </FormControl>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label column sm="3" className="text">
                                    Descrizione
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control
                                        type="text"
                                        name="description"
                                        value={this.state.description}
                                        onChange={this.setChange}
                                        isInvalid={this.state.descriptionNull}
                                    />
                                </Col>
                            </Form.Group>
                            {
                                // Questa Multi select viene utilizzata in differenti casi:
                                // 
                                // - Creazione di una PROMO dalla sezione Promo/Coupon
                                // - Modifica di una PROMO dalla sezione Promo/Coupon
                                // - Creazione di una PROMO da UserInDetails
                                // - Modifica di una PROMO da UserInDetails
                                //
                                // NOTA: il controllo ternario con undefined è importante perchè value non deve essere sempre passato al componente,
                                //       nello specifico quando sto creando una nuova PROMO (sia in userInDetail sia in Promo/Coupon).
                                //       
                                // NOTA: isMulti viene disabilitato quando sto creando una promo da userInDetail perchè in quel caso l'utente è uno solo

                                !this.state.isNewPromoUserInDetail && !this.state.isEditPromoUserInDetail &&
                                <Form.Group as={Row}>
                                    <Form.Label column sm="3" className="text">
                                        Web User
                                    </Form.Label>
                                    <Col sm="8">
                                        <MultiSelect
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti={!this.state.isNewPromoUserInDetail}
                                            options={promo.listEmails}
                                            isClearable
                                            isSearchable
                                            name="webUsers"
                                            value={this.state.editPromo ? sanificationValueBE(this.state.webUsers, 'webUsers') : undefined}
                                            onChange={options => this.setDataFromOptionsList(options, 'webUser')}
                                            onInputChange={event => this.updateListUsers(event)}
                                            isDisabled={(!isNull(this.state.countriesFromListArray) && !isEmpty(this.state.countriesFromListArray)) || (this.state.editPromo || this.state.isNewPromoUserInDetail || this.state.isEditPromoUserInDetail)}
                                        />
                                    </Col>
                                </Form.Group>
                            }

                            <Form.Group as={Row}>
                                <Form.Label column sm="3" className="text">
                                    Quanti utilizzi?
                                </Form.Label>
                                <Col sm="2" >
                                    <Form.Control
                                        type="text"
                                        name="startItems"
                                        value={this.state.startItems}
                                        onChange={this.setChange}
                                        isInvalid={this.state.startItemsNull}
                                        disabled={this.state.disableUse || this.state.unlimitedUse || (!this.state.isRotten && !this.state.isNewPromo)} // il controllo con isRotten è fatto per evitare la modifica degli usi a dispozione prima della scadenza della PROMO
                                    />
                                </Col>
                                <Col sm="2" className="col-4">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.unlimitedUse}
                                                    onChange={this.setUnlimitedUse}
                                                    value="unlimitedUse"
                                                    color="primary"
                                                    disabled={!this.state.isRotten && !this.state.isNewPromo} // il controllo con isRotten è fatto per evitare la modifica degli usi a dispozione prima della scadenza della PROMO
                                                />
                                            }
                                            label="Illimitato"
                                            labelPlacement="bottom"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="2" className="col-4">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.activeReseller}
                                                    onChange={this.setReseller}
                                                    value="activeReseller"
                                                    color="primary"
                                                />
                                            }
                                            label="Reseller"
                                            disabled={this.state.editPromo}
                                            labelPlacement="bottom"
                                        />
                                    </FormGroup>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label column sm="3" className="text">
                                    Sconto (%)
                                </Form.Label>
                                <Col sm="2">
                                    <Form.Control
                                        type="number"
                                        name="discount"
                                        min="0"
                                        max="100"
                                        value={this.state.discount}
                                        onChange={this.setChange}
                                        isInvalid={this.state.discountNull}
                                        disabled={this.state.discountValue !== '' || this.state.priceValue !== ''}
                                    />
                                </Col>

                                <Form.Label column sm="3" className="text">
                                    Sconto (int)
                                </Form.Label>
                                <Col sm="2">
                                    <Form.Control
                                        type="number"
                                        name="discountValue"
                                        min="0"
                                        value={this.state.discountValue}
                                        onChange={this.setChange}
                                        isInvalid={this.state.discountNull}
                                        disabled={this.state.discount !== '' || this.state.priceValue !== ''}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label column sm="3" className="text">
                                    Prezzo Fisso
                                </Form.Label>
                                <Col sm="2">
                                    <Form.Control
                                        type="number"
                                        name="priceValue"
                                        min="0"
                                        value={this.state.priceValue}
                                        onChange={this.setChange}
                                        isInvalid={this.state.discountNull}
                                        disabled={this.state.discount !== '' || this.state.discountValue !== ''}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} style={{ marginTop: '2rem' }}>
                                <Form.Label column sm="3" className="text">
                                    Quantità
                                </Form.Label>
                                <Col sm="2">
                                    <Form.Control
                                        type="number"
                                        name="qty"
                                        min="0"
                                        max="100"
                                        value={this.state.qty}
                                        onChange={this.setChange}
                                        isInvalid={this.state.qtyNull}
                                        disabled={this.state.isNewPromoUserInDetail}
                                        readOnly={!this.state.isNewPromo}
                                    />
                                </Col>

                                <Form.Label column sm="3" className="text">
                                    Assegna Gruppo
                                </Form.Label>
                                <Col sm="3">
                                    <MultiSelect
                                        defaultValue={
                                            this.state.editPromo ?
                                                sanificationValueBE(this.state.groups, 'userGroupEdit') : undefined
                                        }
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        options={sanificationValueBE(group.listGroup, 'userGroup')}
                                        name="group"
                                        onChange={options => this.setDataFromOptionsList(options, 'group')}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label column sm="3" className="text">
                                    Periodo Promo
                                </Form.Label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                    <Col sm="3" className="col-5">
                                        <KeyboardDatePicker
                                            id="dateStartAtPromo"
                                            variant="inline"
                                            label="Inizio Promo"
                                            value={this.state.startAt || null}
                                            onChange={newDate => this.setPromoTime(newDate, 'startAt')}
                                            format="dd/MM/yyyy"
                                        />
                                    </Col>

                                    <Col sm="3" className="col-5">
                                        <KeyboardDatePicker
                                            id="dateEndAtPromo"
                                            variant="inline"
                                            label="Fine Promo"
                                            value={this.state.endAt || null}
                                            onChange={newDate => this.setPromoTime(newDate, 'endAt')}
                                            disabled={this.state.disableDate || this.state.unlimitedDate}
                                            // se la data di inizio è presente, disabilita tutte le date da quella data (compresa)
                                            minDate={this.state.startAt ? moment(this.state.startAt).add(1, 'days')._d : false}
                                            minDateMessage="Attenzione! Data precedente a quella di inizio"
                                            format="dd/MM/yyyy"
                                        />
                                    </Col>
                                </MuiPickersUtilsProvider>
                                <Col sm="2" className="col-2">
                                    <FormGroup>
                                        <FormControlLabel
                                            className="positionSwitch"
                                            control={
                                                <Switch
                                                    checked={this.state.unlimitedDate}
                                                    onChange={this.setUnlimitedDate}
                                                    value="unlimitedDate"
                                                    color="primary"
                                                />
                                            }
                                            label="Illimitato"
                                            labelPlacement="bottom"
                                        />
                                    </FormGroup>
                                </Col>
                            </Form.Group>
                            {
                                !this.state.isNewPromoUserInDetail && !this.state.isEditPromoUserInDetail &&
                                <Form.Group as={Row}>
                                    <Form.Label column sm="3" className="text">
                                        Seleziona Paese
                                    </Form.Label>

                                    <Col sm="8">
                                        <FormControl>
                                            <MultiSelect
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                isMulti
                                                name="countries"
                                                defaultValue={
                                                    this.state.editPromo ? this.state.countries : undefined
                                                }
                                                options={countries}
                                                onChange={options => this.setDataFromOptionsList(options, 'countries')}
                                                isDisabled={!isNull(this.state.emailFromListArray) && !isEmpty(this.state.emailFromListArray)}
                                            />
                                        </FormControl>
                                    </Col>
                                </Form.Group>
                            }
                        </Form>

                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.confirm}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}