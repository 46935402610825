// import { isEmpty, isNil } from 'lodash';
import { preferencesActionType } from '../actions';

const initialState = {
    isLoading: false,
    versionSite: '',

    listProductPlayer: [],
    listProductVpnPass: [],
    listProductBox: [],
    listProductBoxHw: [],
    listProductCoupon: [],
    allProducts: [],

    // lista dei country in cui è possibile acquistare la VPN Box
    countryPurchasableList: []

}

export default function preferences(state = initialState, action) {
    switch (action.type) {
        case preferencesActionType.SET_VERSION_SITE:
            return {
                ...state,
                versionSite: action.versionSite
            }
        case `${preferencesActionType.SEND_EDIT_PREFERENCES}_PENDING`:
        case `${preferencesActionType.REMOVE_PREFERENCES}_PENDING`:
        case `${preferencesActionType.GET_PRODUCT_LIST}_PENDING`:
        case `${preferencesActionType.GET_VERSION_SITE}_PENDING`:
        case `${preferencesActionType.GET_COUNTRY_LIST}_PENDING`:
            return {
                ...state,
                isLoading: true
            }
        case `${preferencesActionType.REMOVE_PREFERENCES}_FULFILLED`:
        case `${preferencesActionType.SEND_EDIT_PREFERENCES}_FULFILLED`:
        case `${preferencesActionType.GET_VERSION_SITE}_FULFILLED`:
            return {
                ...state,
                isLoading: false
            }
        case `${preferencesActionType.GET_COUNTRY_LIST}_FULFILLED`:
            return {
                ...state,
                isLoading: false,
                countryPurchasableList: action.payload.countryPurchasableList
            }
        case `${preferencesActionType.GET_PRODUCT_LIST}_FULFILLED`:
            return {
                ...state,
                isLoading: false,
                listProductPlayer: action.payload.listProductPlayer,
                listProductVpnPass: action.payload.listProductVpnPass,
                listProductBox: action.payload.listProductBox,
                listProductBoxHw: action.payload.listProductBoxHw,
                listProductCoupon: action.payload.listProductCoupon,
                allProducts: action.payload.allProducts
            }
        default:
            return state;
    }
}