import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MainTemplate from '../mainLayout';
import Dashboard from '../home/containerHome';
import Users from '../users/containerUsers';
import UserInDetail from '../userInDetail/containerUserInDetail';
import Players from '../players/containerPlayers';
import Licences from '../players/containerLicences';
import Playlists from '../players/containerPlaylists';
import Payments from '../payments/containerPayments';
import Promo from '../promo/containerPromo';
import Worker from '../worker/containerWorker';
import Role from '../role/containerRole';
import Bundle from '../bundle/containerBundle';
import ChangeLog from '../changeLog/containerChangeLog';

import VpnMachine from '../vpn/containerVpnMachine';
import VpnPass from '../vpn/containerVpnPass';
import VpnPeer from '../vpn/containerVpnPeer';
import VpnBox from '../vpn/containerVpnBox';

import VpnBoxStock from '../stock/containerVpnBoxStock';

import PreferencesWL from '../preferences/containerPreferencesWhiteList';
import PreferencesIC from '../preferences/containerPreferencesIvaCountry';
import PreferencesPR from '../preferences/containerPreferencesPricing';

import Groups from '../preferences/containerPreferencesGroups';
import ErrorStatusCode from '../errors/containerErrors';
import SideBar from '../common/sidebar';
import Header from '../common/header';
import '../mainLayout/index.scss';
import { setAuthorizationToken } from '../utility';
import { userActions, notifyActions, preferencesActions } from '../store/actions';

class RouterComponent extends Component {
    constructor(props) {
        super(props);
        if (localStorage.token) {
            setAuthorizationToken(localStorage.token);
            try {
                this.props.refreshToken(localStorage.token);
            } catch (e) {
                localStorage.clear();
            }
        }

        this.state = {
            expandSidebarDesktop: true,
            expandSidebarMobile: false,

            activeSidebar: false
        }
        this.toggleExpandSidebarDesktop = this.toggleExpandSidebarDesktop.bind(this);
        this.props.getVersionSite();
    }

    toggleExpandSidebarDesktop = () => {
        this.setState({ expandSidebarDesktop: !this.state.expandSidebarDesktop });
    }

    toggleExpandSidebarMobile = open => {
        this.setState({ expandSidebarMobile: open });
    }

    /**
     * Controlla se è in modalita dashboard.
     * Se è in modalita dashboard restituisce true.
     */
    inDashboard = () => {
        return ((this.state.activeSidebar && this.state.expandSidebarDesktop) || (this.state.activeSidebar && !this.state.expandSidebarDesktop));
    }

    render() {
        return (
            <BrowserRouter>
                <Route render={({ location, history }) => (
                    <div className="main">

                        {
                            (this.props.user.isAuthenticated && this.props.user.user.email !== undefined) &&
                            <SideBar
                                user={this.props.user}
                                role={this.props.role}
                                logout={this.props.logoutRequest}
                                history={history}

                                expandSidebarDesktop={this.toggleExpandSidebarDesktop}
                                valueExpandSidebarDesktop={this.state.expandSidebarDesktop}
                                expandSidebarMobile={this.toggleExpandSidebarMobile}
                                valueExpandSidebarMobile={this.state.expandSidebarMobile}

                                location={location}
                                versionSite={this.props.preferences.versionSite}
                            />
                        }

                        {/* Se la sidebar è attiva, allora attiva (in base se è aperta o chiusa), una determinata classe css */}
                        {/* Se è presente la schermata di login, attiva la classe all'header per espansione massima */}
                        <div
                            className={
                                (!this.props.user.isAuthenticated && this.props.user.user.email === undefined) ? "contentMain onlyLogin" :
                                    this.state.expandSidebarDesktop ? "contentMain expand" : "contentMain"
                            }
                        >

                            <Header
                                expandSidebarMobile={this.toggleExpandSidebarMobile}
                                versionSite={this.props.preferences.versionSite}
                                flush={this.props.flush}
                                history={history}
                                role={this.props.role}
                                user={this.props.user}
                            />

                            <MainTemplate>
                                <Switch>
                                    <Route path="(/|/login|/register|/dashboard)" component={Dashboard} />
                                    <Route path="/users" component={Users} />
                                    <Route path="/userInDetail/:userId" component={UserInDetail} />
                                    <Route path="/player/devices" component={Players} />
                                    <Route path="(/player/licences|/player/licences/:userId)" component={Licences} />
                                    <Route path="/player/playlists" component={Playlists} />
                                    <Route path="/payments" component={Payments} />
                                    <Route path="(/promo/createdPromo|/promo/createdCoupon|/promo/buyCoupon|/promo/expiredPromo|/promo/expiredCoupon)" component={Promo} />
                                    <Route path="/worker" component={Worker} />
                                    <Route path="/role" component={Role} />
                                    <Route path="/bundle" component={Bundle} />
                                    <Route path="/changeLog" component={ChangeLog} />
                                    <Route path="/vpn/machine" component={VpnMachine} />
                                    <Route path="(/vpn/peer|/vpn/peer/:peerId)" component={VpnPeer} />
                                    <Route path="(/vpn/pass|/vpn/pass/:passId)" component={VpnPass} />
                                    <Route path="/vpn/box" component={VpnBox} />
                                    <Route path="/stock/vpnBox" component={VpnBoxStock} />
                                    <Route path="/preferences/whitelist" component={PreferencesWL} />
                                    <Route path="/preferences/ivacountry" component={PreferencesIC} />
                                    <Route path="/preferences/pricing" component={PreferencesPR} />
                                    <Route path="/preferences/groups" component={Groups} />
                                    {/* <Route path="/preferences/partner" component={PreferencesPA} /> */}
                                    <Route render={(props) => <ErrorStatusCode {...props} statusCode='404' errorMsg="Error.404" errorDesc="Error.404.desc" />} />
                                </Switch>
                                <ToastContainer />
                            </MainTemplate>
                        </div>
                    </div>
                )}
                />
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        user: state.user,
        preferences: state.preferences,
        role: state.role
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        refreshToken: token => {
            dispatch(userActions.refreshToken(token));
        },
        getVersionSite: () => {
            dispatch(preferencesActions.getVersionSite());
        },
        logoutRequest: (history) => {
            dispatch(userActions.logoutRequest(history));
            dispatch(notifyActions.infoAction('Logout effettuato con successo!'));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouterComponent);