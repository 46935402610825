import React, { Component } from 'react';
import { NativeSelect, FormControl } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { isEmpty, isNull } from 'lodash';

import { months } from '../../../common/actions/months';
import { returnDays } from '../../../common/actions/returnDays';
import Loading from '../../../common/loading/loadingInBlock';

export default class VpnDays extends Component {
    sanitizeVpnId = (item) => {
        let arraySize = 31;
        let prova = [];

        if (!isNull(item) && !isEmpty(item)) {
            for (let i = 0; i < arraySize; i++) {
                prova[i] = 0;
            }

            // eslint-disable-next-line
            item.map(el => {
                for (let [key, value] of Object.entries(el)) {
                    prova.splice(returnDays(key) - 1, 1, value);
                }
            });
        }
        return prova;
    }

    render() {
        const { vpnDays, onChange, vpnOneDayValue, vpnThreeDaysValue, vpnSixDaysValue, vpnTwelveDaysValue, vpnTwentyfourDaysValue, vpnThreeDaysHwValue, vpnSixDaysHwValue, vpnTwelveDaysHwValue, vpnTwentyfourDaysHwValue, loadingGraph13, vpnBoxChange } = this.props;

        const optionsVpnDaysLicence = {
            responsive: true,
            layout: {
                padding: {
                    left: 15,
                    right: 25,
                    top: 10
                }
            },

            legend: {
                display: true,
                position: 'top',
                align: 'end'
            },

            plugins: {
                title: {
                    display: true,
                    text: !vpnBoxChange ? 'Vpn Pass al Giorno' : 'Vpn Box al Giorno',
                    font: {
                        size: 20
                    }
                }
            },
            
            scales: {
                y: {
                    ticks: {
                        beginAtZero: true
                    },
                    stacked: true
                },
                x: {
                    stacked: true
                }
            }
        };

        const dataVpnDaysLicence = {
            labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
            datasets: [
                {
                    label: "Vpn 1 Month",
                    backgroundColor: 'rgba(42,110,255, 0.5)',
                    data: this.sanitizeVpnId(vpnOneDayValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "Vpn 3 Months",
                    backgroundColor: 'rgba(88,255,112,0.5)',
                    data: this.sanitizeVpnId(vpnThreeDaysValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "Vpn 6 Months",
                    backgroundColor: 'white',
                    data: this.sanitizeVpnId(vpnSixDaysValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "Vpn 12 Months",
                    backgroundColor: 'rgba(255,166,59,0.5)',
                    data: this.sanitizeVpnId(vpnTwelveDaysValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "Vpn 24 Months",
                    backgroundColor: 'rgba(106, 120, 205,0.5)',
                    data: this.sanitizeVpnId(vpnTwentyfourDaysValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                }
            ],
        };

        const dataVpnDaysHwLicence = {
            labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
            datasets: [
                {
                    label: "Vpn Box 3 Months",
                    backgroundColor: 'rgba(88,255,112,0.5)',
                    data: this.sanitizeVpnId(vpnThreeDaysHwValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "Vpn Box 6 Months",
                    backgroundColor: 'white',
                    data: this.sanitizeVpnId(vpnSixDaysHwValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "Vpn Box 12 Months",
                    backgroundColor: 'rgba(255,166,59,0.5)',
                    data: this.sanitizeVpnId(vpnTwelveDaysHwValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "Vpn Box 24 Months",
                    backgroundColor: 'rgba(106, 120, 205,0.5)',
                    data: this.sanitizeVpnId(vpnTwentyfourDaysHwValue),
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                }
            ],
        };

        return (
            <div className="containerChart">
                <div className="homeChart">
                    <FormControl className="licenceMonth">
                        <NativeSelect
                            name="vpnBoxChange"
                            value={vpnBoxChange}
                            onChange={onChange}
                        >
                            <option className="colorMonth" value={false}>VPN Pass</option>
                            <option className="colorMonth" value={true}>VPN Box</option>
                        </NativeSelect>
                    </FormControl>

                    <FormControl className="vpnChange">
                        <NativeSelect
                            name="vpnDays"
                            value={vpnDays}
                            onChange={onChange}
                        >
                            {
                                months.map((item, k) => {
                                    return (
                                        <option className="colorMonth" key={k} value={item.value}>{item.label}</option>
                                    );
                                })
                            }
                        </NativeSelect>
                    </FormControl>

                    {
                        loadingGraph13 ? <Loading /> :
                            <Bar options={optionsVpnDaysLicence} data={!vpnBoxChange ? dataVpnDaysLicence : dataVpnDaysHwLicence} />
                    }
                </div>
            </div>
        )
    }
}