/**
 * Ritorn tutti i giorni del mese in base alla label ricevuta.
 * 
 * @param {*} giorno 
 * @returns 
 */
export var returnDays = function (giorno) {
    switch (giorno) {
        case 'aa': return 1;
        case 'ab': return 2;
        case 'ac': return 3;
        case 'ad': return 4;
        case 'ae': return 5;
        case 'af': return 6;
        case 'ag': return 7;
        case 'ah': return 8;
        case 'ai': return 9;
        case 'aj': return 10;
        case 'ak': return 11;
        case 'al': return 12;
        case 'am': return 13;
        case 'an': return 14;
        case 'ao': return 15;
        case 'ap': return 16;
        case 'aq': return 17;
        case 'ar': return 18;
        case 'as': return 19;
        case 'at': return 20;
        case 'au': return 21;
        case 'av': return 22;
        case 'aw': return 23;
        case 'ax': return 24;
        case 'ay': return 25;
        case 'az': return 26;
        case 'ba': return 27;
        case 'bb': return 28;
        case 'bc': return 29;
        case 'bd': return 30;
        case 'be': return 31;
        default: return;
    }
}