import { connect } from 'react-redux'
import { workerActions, notifyActions } from '../store/actions';
import pageWorker from './pages';

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user,
        worker: state.worker,
        role: state.role,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addActiveJob: (data) => {
            dispatch(workerActions.addActiveJob(data))
        },
        removeActiveJob: (data) => {
            dispatch(workerActions.removeActiveJob(data))
        },
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(pageWorker);