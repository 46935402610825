import axios from 'axios';
import { playerActionType, notifyActions } from './';
import {
  API_SEND_NEW_PLAYLIST,
  API_SEND_MODIFY_PLAYLIST,
  API_REMOVE_PLAYLIST,
  API_PLAYER,
  API_PLAYER_REBOOT,
  // API_ADD_PASS_TO_PLAYER,
  API_PLAYER_SEND_MESSAGE,
  API_GET_PLAYER_LIST_URL,
  API_GET_PLAY_LIST_URL,
  API_PLAYER_PLAYLIST,
  API_PLAYER_LICENCE,
  API_POST_PLAY_LIST_URL,
  API_PLAYER_NEW_LICENCE
} from '../../config';

export const playerActions = {
  setIdPlayerForDetail,
  sendNewPlaylist,
  sendModifyPlaylist,
  removePlaylist,
  sendEditPlayer,
  removePlayer,
  refreshPlayer,
  sendMessagePlayer,
  addPassToPlayer,
  getPlayerList,
  getAllPlayList,
  getLicenceList,
  changeDns,
  createLicences
};

/**
 * Setta nel payload un id Player che è stato selezionato per visualizzarlo nel dettaglio
 * 
 * @param {String} idPlayerForDetail 
 */
function setIdPlayerForDetail(idPlayerForDetail) {
  return {
    type: playerActionType.SET_ID_PLAYER_FOR_DETAIL,
    idPlayerForDetail
  }
}

/**
 * Aggiunge una playlist al player.
 * 
 * @param {Object} data contiene title, url e deviceId al quale aggiungere la playlist
 */
function sendNewPlaylist(data) {
  return (dispatch) => {
    dispatch({
      type: playerActionType.SEND_NEW_PLAYLIST,
      async payload() {
        try {
          await axios.post(`${API_SEND_NEW_PLAYLIST}`, data);
          dispatch(notifyActions.successAction('Playlist aggiunta correttamente! 😉'));
        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: dispatch(notifyActions.errorAction('Errore nella compilazione dei campi!')); break;
              case 404: dispatch(notifyActions.errorAction('Player non trovato!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_SEND_NEW_PLAYLIST} - POST`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Modifica una playlist al player.
 * 
 * @param {Object} data contiene title, url e key della playlist, poi il deviceId del player
 */
function sendModifyPlaylist(data) {
  return (dispatch) => {
    dispatch({
      type: playerActionType.SEND_MODIFY_PLAYLIST,
      async payload() {
        try {
          let params = {
            title: data.title,
            url: data.url,
            deviceId: data.deviceId
          }
          await axios.put(`${API_SEND_MODIFY_PLAYLIST}/${data.playListId}`, params);
          dispatch(notifyActions.successAction('Playlist aggiornata correttamente! 😉'));
        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: dispatch(notifyActions.errorAction('Errore nella compilazione dei campi!')); break;
              case 404: dispatch(notifyActions.errorAction('Player non trovato!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_SEND_MODIFY_PLAYLIST} - PUT`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Rimuove una playlist dal player.
 * 
 * @param {Object} data contiene key della playlist e id del player
 */
function removePlaylist(data) {
  return (dispatch) => {
    dispatch({
      type: playerActionType.REMOVE_PLAYLIST,
      async payload() {
        try {
          await axios.delete(`${API_REMOVE_PLAYLIST}`, { data });
          dispatch(notifyActions.successAction('Playlist rimossa correttamente! 😉'));
        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('Player non trovato!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_REMOVE_PLAYLIST} - DELETE`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Modifica un player a un determinato utente.
 * 
 * @param {Object} data contiene licence (object), id (idPlayer) , description, model, version, platform, user
 * @param {String} action se presente, attiva un determinato feedback 
 *                (puo essere: 
 *                    deactiveLicence per licenza disattivata
 *                    oppure nulla per player modificato)
 */
function sendEditPlayer(data, action) {
  return (dispatch) => {
    dispatch({
      type: playerActionType.SEND_EDIT_PLAYER,
      async payload() {
        try {

          await axios.put(`${API_PLAYER}`, data);

          if (action === 'activeLicence') {
            dispatch(notifyActions.successAction('Licenza su ' + data.id + ' Attivata! 😉'));
          } else if (action === 'deactiveLicence') {
            dispatch(notifyActions.successAction('Licenza ' + data.id + ' Disattivata! 😉'));
          } else {
            dispatch(notifyActions.successAction('Player modificato correttamente! 😉'));
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: dispatch(notifyActions.errorAction('Dati non validi! Controlla la correttezza dei campi inseriti')); break;
              case 404: dispatch(notifyActions.errorAction('Il Player non esiste')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_PLAYER} - PUT`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Rimuove un player
 * 
 * @param id del player
 */
function removePlayer(playerId) {
  return (dispatch) => {
    dispatch({
      type: playerActionType.REMOVE_PLAYER,
      async payload() {
        try {
          await axios.delete(`${API_PLAYER}/${playerId}`);
          dispatch(notifyActions.successAction('Player rimosso correttamente! 😉'));

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('Impossibile eliminare, Player non trovato! 🙁')); break;
              default: dispatch(notifyActions.errorAction('Errore' + result.response.status + ' nella rimozione del Player! 😶')); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Riavvio il player.
 * 
 * @param {Object} data contiene i dati del device, a noi servirà solamente l'id
 */
function refreshPlayer(data) {
  return (dispatch) => {
    dispatch({
      type: playerActionType.REFRESH_PLAYER,
      async payload() {
        try {
          let params = {
            deviceId: data._id
          }
          await axios.post(`${API_PLAYER_REBOOT}`, params);
          dispatch(notifyActions.successAction('Player riavviato correttamente! 😉'));
        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('Player non trovato!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_PLAYER_REBOOT} - POST`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Manda messaggio al player.
 * 
 * @param {Object} data contiene id del device e il testo del messaggio
 */
function sendMessagePlayer(data) {
  return (dispatch) => {
    dispatch({
      type: playerActionType.SEND_MESSAGE_PLAYER,
      async payload() {
        try {
          let params = {
            deviceId: data.deviceId,
            message: data.message
          }
          await axios.post(`${API_PLAYER_SEND_MESSAGE}`, params);
          dispatch(notifyActions.successAction('Messaggio inviato correttamente! 😉'));
        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('Player non trovato!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_PLAYER_SEND_MESSAGE} - POST`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Associa una Pass al Player player.
 * 
 * @param {Object} data contiene id del device e key pass
 */
function addPassToPlayer(data) {
  return (dispatch) => {
    dispatch({
      type: playerActionType.ADD_PASS_TO_PLAYER,
      async payload() {
        try {
          console.log(data)
          // await axios.post(`${API_ADD_PASS_TO_PLAYER}`, data);
          // dispatch(notifyActions.successAction('Player riavviato correttamente! 😉'));
        } catch (result) {
          // if (result && result.response && result.response.status) {
          //   switch (result.response.status) {
          //     case 404: dispatch(notifyActions.errorAction('Player non trovato!')); break;
          //     default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_PLAYER_REBOOT} - POST`)); break;
          //   }
          // }
        }
      }
    })
  }
}

/**
 * Crea nuove licenze.
 * 
 * @param {Object} data contiene i filtri da passare alla chiamata per la creazione licenze
 */
function createLicences(data, filters) {
  return (dispatch) => {
    dispatch({
      type: playerActionType.CREATE_NEW_LICENCE,
      async payload() {
        try {
          await axios.post(`${API_PLAYER_NEW_LICENCE}`, data, { params: filters });
          dispatch(notifyActions.successAction('Licenze create correttamente! 😉'));
          dispatch(getPlayerList(false, filters));
        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: dispatch(notifyActions.errorAction('Target Dns non deve contenere l\'intestazione! "http://"')); break;
              case 404: dispatch(notifyActions.errorAction('Il dispositivo non trovato')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_PLAYER_NEW_LICENCE} - POST`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Cambia il dns alle playlists.
 * 
 * @param {Object} data contiene i filtri da passare alla chiamata per il cambio dns
 */
function changeDns(data) {
  let params = {
    'elementsByPage': 25,
    'page': 1,
    'sortField': "",
    'filters[0][Source]': data.source === null ? "" : data.source,
    'filters[1][Title]': data.title === null ? "" : data.title,
    'filters[2][DeviceId]': data.deviceID === null ? "" : data.deviceId,
    'filters[3][CreatedAtStart]': data.createdAtStart === null ? "" : data.createdAtStart,
    'filters[4][CreatedAtEnd]': data.createdAtEnd === null ? "" : data.createdAtEnd
  }

  return (dispatch) => {
    dispatch({
      type: playerActionType.CHANGE_DNS_TO_PLAYLIST,
      async payload() {
        try {
          await axios.post(`${API_POST_PLAY_LIST_URL}`, data);
          dispatch(notifyActions.successAction('Dns Aggiornati correttamente! 😉'));
          dispatch(getAllPlayList(params));
        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: dispatch(notifyActions.errorAction('Target Dns non deve contenere l\'intestazione! "http://"')); break;
              case 404: dispatch(notifyActions.errorAction('Il dispositivo non trovato')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_POST_PLAY_LIST_URL} - POST`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Restituisce la lista di tutti i player.
 * 
 * @param {Boolean} downloadAllPlaylists se a true scarica le playlist in un csv
 * @param {Object} data i filtri da passare alla chiamata
 */
function getPlayerList(downloadAllPlaylists, data) {
  return (dispatch) => {
    dispatch({
      type: playerActionType.GET_PLAYER,
      async payload() {
        try {
          if (downloadAllPlaylists) {
            // se deve scaricare le playlists in uno zip
            await axios
              .request({
                url: API_PLAYER_PLAYLIST,
                method: 'GET',
                params: data,
                responseType: 'blob',
              })
              .then(({ data }) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'Playlists.zip'); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
              });

            notifyActions.infoAction('Attendi qualche secondo e le playlists verranno scaricate in un file zip! 🙂');
          }

          let response = await axios.get(`${API_GET_PLAYER_LIST_URL}`, { params: data });

          return {
            listPlayer: response.data.value.value,
            totalPlayers: response.data.info.total,
            totalPages: response.data.info.totalPages,
            hasNext: response.data.info.hasNext,
            isComplete: response.data.info.isComplete,
            paramsGetPlayerListTemp: data
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: dispatch(notifyActions.errorAction('Inserisci un numero valido!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_GET_PLAYER_LIST_URL} - GET`)); break;
            }
          }

          return {
            listPlayer: [],
            totalPages: '',
            totalPlayers: '',
            paramsGetPlayerListTemp: {},
            hasNext: null,
            isComplete: null
          }
        }
      }
    })
  }
}

/**
 * Restituisce la lista di tutti le playlist.
 * 
 * @param {Object} data i filtri da passare alla chiamata
 */
function getAllPlayList(data) {
  return (dispatch) => {
    dispatch({
      type: playerActionType.GET_PLAYLIST,
      async payload() {
        try {
          let response = await axios.get(`${API_GET_PLAY_LIST_URL}`, { params: data });

          return {
            listPlaylist: response.data.value.value,
            totalPlaylist: response.data.info.total,
            totalPagesPlaylist: response.data.info.totalPages,
            currentPagePlaylist: response.data.info.currentPage,
            elementForPagePlaylist: response.data.info.elementForPage,
            paramsGetPlayListTemp: data
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_GET_PLAY_LIST_URL} - GET`)); break;
            }
          }

          return {
            listPlaylist: [],
            totalPlaylist: '',
            totalPagesPlaylist: '',
            currentPagePlaylist: '',
            elementForPagePlaylist: '',
            paramsGetPlayListTemp: {}
          }
        }
      }
    })
  }
}

/**
 * Restituisce la lista delle licenze
 */
function getLicenceList(data) {
  return (dispatch) => {
    dispatch({
      type: playerActionType.GET_PLAYER_LICENCE,
      async payload() {
        try {
          let response = await axios.get(`${API_PLAYER_LICENCE}`, { params: data });

          return {
            listLicences: response.data.value.value,
            totalLicences: response.data.info.total,
            totalPagesLicences: response.data.info.totalPages,
            currentPageLicences: response.data.info.currentPage,
            elementForPageLicences: response.data.info.elementForPage,
            paramsGetLicenceListTemp: data
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_PLAYER_LICENCE}} - GET`)); break;
            }
          }

          return {
            listLicences: [],
            totalLicences: '',
            totalPagesLicences: '',
            currentPageLicences: '',
            elementForPageLicences: '',
            paramsGetLicenceListTemp: {}
          }
        }
      }
    })
  }
}