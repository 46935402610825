import React, { Component } from 'react';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { controlRole } from '../../../common/actions/controlRole';
import './style.scss';

export default class actionMenuPromo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            setOpen: false,
        };
    }

    toggle = () => {
        this.setState({ setOpen: !this.state.setOpen });
    }

    /**
     * Invia all'azione del click, il tipo di azione e i dati del player
     */
    actionClick = (action) => {
        this.props.actionClick(action, this.props.promoTemp._id, this.props.promoTemp);
    }

    render() {
        const { role } = this.props;
        return (
            <ButtonDropdown isOpen={this.state.setOpen} toggle={this.toggle}>
                <DropdownToggle caret>
                    <i className="fas fa-ellipsis-v"></i>
                </DropdownToggle>

                <DropdownMenu>
                    {
                        !this.props.promoTemp.isCoupon &&
                        controlRole(role.promo, "api/Promo", "PUT") &&
                        <DropdownItem onClick={() => { this.actionClick('editPromo'); }}>
                            <div className="row">
                                <div className="col-3 icon editPlayer">
                                    <i className="far fa-edit"></i>
                                </div>
                                <div className="col-9">
                                    Modifica Promo
                                </div>
                            </div>
                        </DropdownItem>
                    }

                    {
                        this.props.promoTemp.isCoupon &&
                        controlRole(role.promo, "api/Promo/Coupon", "PUT") &&
                        <DropdownItem onClick={() => { this.actionClick('editCoupon'); }}>
                            <div className="row">
                                <div className="col-3 icon editPlayer">
                                    <i className="far fa-edit"></i>
                                </div>
                                <div className="col-9">
                                    Modifica Coupon
                                </div>
                            </div>
                        </DropdownItem>
                    }

                    {
                        controlRole(role.promo, "api/Promo/DeleteMany", "DELETE") &&
                        <DropdownItem onClick={() => { this.actionClick('deletePromo'); }}>
                            <div className="row">
                                <div className="col-3 icon deletePlayer">
                                    <i className="far fa-trash-alt"></i>
                                </div>
                                <div className="col-9">
                                    Elimina Promo
                                </div>
                            </div>
                        </DropdownItem>
                    }
                </DropdownMenu>
            </ButtonDropdown>
        )
    }
}