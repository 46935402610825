import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { isNil } from 'lodash';

import Date from '../../../common/date';
import ExpanableLicence from './tabExpanableLicence';
import Loading from '../../../common/loading';
import CSVExport from '../../../common/csvExport';
import SelectorCopy from '../../../common/selectorCopy';

class tabLicences extends Component {

    componentDidMount() {
        this.getLicenceList();
    }

    /**
     * La funzione prende in input l'id dell'utente selezionato (input preso in automatico dallo stato), 
     * e verifica i player associati ad esso
     * 
     */
    getLicenceList = () => {
        let params = {
            'elementsByPage': '1000',
            'filters[0][User]': this.props.userId,
        };

        setTimeout(() => {
            this.props.getLicenceList(params);
        }, 500);
    }

    render() {
        const { player } = this.props;
        const columns = [
            {
                name: 'Codice Attivazione',
                selector: row => <SelectorCopy item={row.key} infoNotify={this.props.infoNotify} />
            },
            {
                name: 'ID Utente',
                selector: row =>
                    row.user === null ? 'Utente non registrato'
                        :
                        <SelectorCopy item={row.user} infoNotify={this.props.infoNotify} />,
                minWidth: '280px'
            },
            {
                name: 'Device ID',
                selector: row =>
                    row.playerId === '' ? 'Player Non Associato'
                        :
                        <SelectorCopy item={row.playerId} infoNotify={this.props.infoNotify} />
            },
            {
                name: 'Platform',
                selector: row => (row.platform === 'sectv-tizen' || row.platform === 'tv-webos') ? "Samsung/LG" : "Android"
            },
            {
                name: 'Creato il',
                selector: row => <Date format="L" date={row.createdAt} />
            }
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                {this.props.player.isLoading && <Loading />}
                {
                    !this.props.player.isLoading &&
                    <Fragment>
                        <div className="row title">
                            <div className="col-6">
                                <Badge variant="warning">
                                    Numero Device: {player.totalLicences}
                                </Badge>
                            </div>

                            {
                                !isNil(player.listLicences) &&
                                <div className="col-6 text-right">
                                    <CSVExport
                                        data={player.listLicences}
                                        filename="list-licences.csv"
                                        label="Scarica la lista delle licenze selezionate"
                                    />
                                </div>
                            }
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <hr />
                                <DataTable
                                    columns={columns}
                                    data={player.listLicences}
                                    customStyles={customStyles}
                                    noHeader
                                    striped
                                    highlightOnHover
                                    pointerOnHover
                                    clearSelectedRows
                                    expandableRows={this.props.player.isLoading ? false : true}
                                    expandOnRowClicked
                                    expandableRowsComponent={ExpanableLicence}
                                    expandableRowsComponentProps={{
                                        "history": this.props.history,
                                    }}
                                />
                            </div>
                        </div>
                    </Fragment>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        player: state.player
    };
};

export default connect(mapStateToProps)(tabLicences);