import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Row, Col } from 'react-bootstrap';
import 'date-fns';
import './style.scss';

/**
 * Attivazione modale per aggiungere / modificare player:
 * 
 *      "clickEditPlayer" : attivo la callback per modificare il player
 *      "titleModal" : il titolo della modale quando si apre
 *      "contentModal" : il contenuto che deve essere mostrato nella modale
 *      "toggle" : boolean che apre o chiude la modale
 *      "stateModal" : booleano che indica lo stato della modale (aperta o chiusa)
 *      "licenceKey" : il codice attivazione della licenza del player
 *      "idPlayer" : id del player
 *      "description" : descrizione del player
 *      "userId" : id dell'utente
 *      "vpnPass" : pass vpn associata al player
 *      "newPlayerWithoutUser" : se presente, indica che si sta creando un nuovo player senza utente associato
 */
export default class modalPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            licenceKey: '',
            idPlayer: '',
            description: '',
            userId: '',
            vpnPass: '',

            hasIdPlayer: false,
            hasDescription: false,
            idPlayerNull: false,
            descriptionNull: false,
            licenceKeyNull: false,
            vpnPassNull: false
        }

        this.setIdPlayer = this.setIdPlayer.bind(this);
        this.setChange = this.setChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            licenceKey: this.props.licence,
            idPlayer: this.props.idPlayer,
            description: this.props.description,
            userId: this.props.userId,
            vpnPass: this.props.vpnPass
        });
    }

    setIdPlayer = (event) => {
        this.setState({ idPlayer: event.target.value, idPlayerNull: false });
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        switch (event.target.name) {
            case 'licenceKey': this.setState({ licenceKeyNull: false }); break;
            case 'description': this.setState({ descriptionNull: false }); break;
            default: break;
        }
    }

    confirm = () => {
        if (this.state.idPlayer === '') {
            this.setState({ idPlayerNull: true });
            this.props.errorNotify('ID del player obbligatorio');
        } else if (this.state.description === '') {
            this.setState({ descriptionNull: true });
            this.props.errorNotify('Inserisci una descrizione');
        } else if (this.state.description.length < 3 || this.state.description.length > 255) {
            this.setState({ descriptionNull: true });
            this.props.errorNotify('Scegli una descrizione compresa tra 3 e 255 caratteri');
        } else if (this.state.licenceKey === '') {
            this.setState({ licenceKeyNull: true });
            this.props.errorNotify('Inserisci una licenza');
        } else if (this.state.licenceKey.length !== 16) {
            this.setState({ licenceKeyNull: true });
            this.props.errorNotify('La licenza deve avere una lunghezza di 16 caratteri');
        } else if (this.state.vpnPass.length !== 16 && this.state.vpnPass.length !== 0) {
            this.setState({ vpnPassNull: true });
            this.props.errorNotify('La Vpn Pass deve avere una lunghezza di 16 caratteri');
        } else {
            let data = {};
            if (this.props.titleModal === 'Modifica Player') {
                data = {
                    id: this.state.idPlayer,
                    description: this.state.description,
                    user: this.state.userId,
                    licenceKey: this.state.licenceKey,
                    vpnPass: this.state.vpnPass
                };
                this.props.clickEditPlayer(data);
            }
            this.props.toggle();
        }
    }

    render() {
        const { stateModal, toggle, titleModal } = this.props;

        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalPlayer">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Form.Group as={Row} controlId="formPlaintextTitle">
                                <Form.Label column sm="3" className="text-right">
                                    ID Player
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control
                                        type="text"
                                        value={this.state.idPlayer}
                                        onChange={this.setIdPlayer}
                                        isInvalid={this.state.idPlayerNull}
                                        readOnly={titleModal === 'Modifica Player'}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label column sm="3" className="text-right">
                                    Licence Key
                                </Form.Label>

                                <Col sm="8">
                                    <Form.Control
                                        type="number"
                                        name="licenceKey"
                                        maxLength="16"
                                        value={this.state.licenceKey}
                                        onChange={this.setChange}
                                        isInvalid={this.state.licenceKeyNull}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextUrl">
                                <Form.Label column sm="3" className="text-right">
                                    Descrizione
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control
                                        type="text"
                                        name="description"
                                        value={this.state.description}
                                        onChange={this.setChange}
                                        isInvalid={this.state.descriptionNull}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextUrl">
                                <Form.Label column sm="3" className="text-right">
                                    ID Utente
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control
                                        type="text"
                                        name="userId"
                                        value={this.state.userId}
                                        readOnly
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextUrl">
                                <Form.Label column sm="3" className="text-right">
                                    VPN Pass
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control
                                        type="text"
                                        name="vpnPass"
                                        maxLength="16"
                                        value={this.state.vpnPass}
                                        onChange={this.setChange}
                                    />
                                </Col>
                            </Form.Group>
                        </Form>
                    </ModalBody>

                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.confirm}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}