import { combineReducers } from 'redux';
import user from './user';
import player from './player';
import notify from './notify';
import payment from './payment';
import preferences from './preferences';
import promo from './promo';
import worker from './worker';
import vpn from './vpn';
import role from './role';
import group from './group';
import bundle from './bundle';
import stock from './stock';

export default combineReducers({
    user,
    player,
    notify,
    payment,
    preferences,
    promo,
    vpn,
    worker,
    role,
    group,
    bundle,
    stock
});