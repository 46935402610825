import React, { Component, Fragment } from 'react';
import './style.scss';
import MyTooltip from '../tooltip';

/**
 * Copia stringa negli appunti.
 * 
 * - item, (required) stringa da copiare
 * - noLabel, se inserito, fa uscire solo l'icona (senza stringa affianco)
 * - infoNotify, (required) per poter far uscire la notifica
 * 
 * 
 * Ad esempio copia un id device, o un id pagamento, ecc...
 */
export default class index extends Component {
    copyItem = (str) => {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.props.infoNotify('Testo copiato negli appunti: ' + str);
    }

    render() {
        const { item, noLabel, isReseller, payments } = this.props;
        return (
            <Fragment>
                <MyTooltip
                    title="Copia"
                    content={<i className="fas fa-copy" onClick={() => this.copyItem(item)}></i> }
                    position="top"
                />
                {!noLabel && item}
                
                {   
                    isReseller && payments < 40 &&
                    <i className="fas fa-crown colorCrown"></i>
                }
                {   
                    isReseller && payments > 40 &&
                    <i className="fas fa-crown colorCrownTop"></i>
                }
            </Fragment>
        )
    }
}