/**
 * Genera un alpha numerico random di 10 caratteri
 */
export var randomString = function () {
    let tempID = Math.random().toString(36).slice(-2);
    tempID += Math.random().toString(36).slice(-2).toUpperCase();
    tempID += Math.random().toString(36).slice(-2);
    tempID += Math.random().toString(36).slice(-2).toUpperCase();
    tempID += Math.random().toString(36).slice(-2);
    return  tempID;
}