import React, { Component, Fragment } from 'react';
import './style.scss';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Row, Col } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { Alert } from '@material-ui/lab';

export default class modalChangeDns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            targetDns: ''
        }
    }

    setChange = (event) => {
        this.setState({ targetDns: event.target.value });
    }

    sendData = () => {
        let dataDns = {
            title: this.props.dataDns.title === "" ? null : this.props.dataDns.title,
            source: this.props.dataDns.source === "" ? null : this.props.dataDns.source,
            createdAtStart: this.props.dataDns.createdAtStart,
            createdAtEnd: this.props.dataDns.createdAtEnd,
            deviceId: this.props.dataDns.deviceId === "" ? null : this.props.dataDns.deviceId,
            targetDns: this.state.targetDns,
        }

        if (!isEmpty(this.state.targetDns)) {
            this.props.sendData(dataDns);
            this.props.toggle();

        } else {
            this.props.errorNotify('TargetDns obbligatorio');
        }
    }

    render() {
        const { stateModal, toggle, titleModal } = this.props;
        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        <Alert severity="info" style={{ marginBottom: '2rem' }}>Inserire il nuovo dominio con questo formato: <b>dominio.com</b> <br />
                            <b>Nota:</b> omettendo l'intestazione <b>http://</b></Alert>
                        {
                            <Form.Group as={Row} controlId="formPlaintextTitle">
                                <Form.Label column sm="3" className="text-right">
                                    Target Dns
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control
                                        type="text"
                                        value={this.state.targetDns}
                                        onChange={this.setChange}
                                    />
                                </Col>
                            </Form.Group>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.sendData}>Cambia Dns</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}