import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { isEmpty, isNull } from 'lodash';

import './style.scss';
import FormFilter from '../components/formFilters';
import Loading from '../../common/loading';
import ActionMenu from '../components/actionMenu';
import ExpanableComponent from '../components/expanableComponent';
import IconLicence from '../../common/iconLicence';
import ModalPromo from '../../common/modal/modalPromo';
import ModalCouponGenerate from '../../common/modal/modalCouponGenerate';
import ModalCouponBuy from '../../common/modal/modalCouponBuy';
import Modal from '../../common/modal';
import SelectorCopy from '../../common/selectorCopy';
import CSVExport from '../../common/csvExport';
import { controlRole } from '../../common/actions/controlRole';
import MyTooltip from '../../common/tooltip';
import { returnNationality } from '../../common/actions/returnNationality';

export default class Promo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Tenere in considerazione la terminologia prima di visitare la seguente pagina:
            //
            // PROMO: sconto associato ad un utente specifico usando la sua email come riferimento
            // COUPON: codice sconto che viene fornito all'utente da inserire sul sito in fase d'acquisto

            // variabili che fanno riferimento a modalPromo per creare/modificare una PROMO
            hasClickNewPromo: false,
            hasClickEditPromo: false,

            // variabili che fanno riferimento a modalCoupon per creare/modificare un COUPON
            // NOTA: assignedCoupon viene utilizzata solo come riferimento grafico per capire se un COUPON è stata già fornito ad un utente oppure se è stato creato ma non è stato ancora fornito a nessuno
            hasClickNewCouponGenerate: false,
            hasClickNewCouponBuy: false,
            hasClickEditCoupon: false,
            hasClickAssignedCoupon: false,


            // variabili che vengono utilizzate sia per le PROMO che per i COUPON, nello specifico per l'eliminazione
            hasClickDeletePromo: false,
            hasClickDeleteAllPromo: false,

            // variabili usate per qualsiasi modale in questa sezione
            titleModal: '',
            contentModal: '',
            modal: false,

            // promoTemp:      oggetto che contiene i dati ricevuti dal backend sia per una PROMO sia per un COUPON
            //
            // webUser:        campo che identifica, tramite l'inserimento dell'email, a quale utente è associata una PROMO
            //                 NOTA: se viene utilizzato sulla crezione di un COUPON l'utente specificato si ritroverà il COUPON nella lista dei suoi coupon utilizzabili (se è un reseller sul sito potrà visualizzare la lista, altrimenti no)
            //          
            // toBuy:          se è null identifica un COUPON, mentre se assume un valore identifica una PROMO
            // id:             identificativo di una PROMO o COUPON
            // startAt:        data di inizio della PROMO o COUPON creato
            // endAt:          data di fine della PROMO o COUPON creato
            //                 NOTA: se è null non ha data di scadenza -> la PROMO o il COUPON sono infiniti
            //
            // productNames:   tutti i prodotti ai quali può essere associata una PROMO o un COUPON
            // discount:       la percentuale di sconto ottenuta con l'utilizzo di una PROMO o un COUPON
            // discountValue:  lo sconto da sottrarre al prezzo di un prodotto con l'utilizzo di una PROMO o un COUPON
            // priceValue:     il prezzo a cui pagare un prodotto con l'utilizzo di una PROMO o un COUPON
            // description:    descrizione di una PROMO o un COUPON
            // code:           codice da inserire sul sito per l'utilizzo del COUPON
            //                 NOTA: il campo viene riempito anche per le PROMO con una stringa ma comunque non ha nessun valore rilevante
            //
            // countries:      paesi in cui una determinata PROMO o COUPON sono utilizzabili
            // startItems:     specifica quante volte può essere utilizzata una PROMO o un COUPON (questa variabile non viene decrementata)
            // maxItems:       specifica quante volte può essere ancora utilizzata una PROMO o un COUPON, dopo che il numero arriva a 0 la PROMO/COUPON non risulta più valida (ad ogni utilizzo di PROMO/COUPON questa variabile viene decrementata)
            // totalUses:      specifica quante volte è stata utilizzata una PROMO o un COUPON
            //
            // NOTA:           le variabili startItems, maxItems e totalUses lavorano a stretto contatto. startItems e maxItems al momento della creazione della PROMO/COUPON vengono settate allo stesso valore.
            //                 La differenza sta nel fatto che ad ogni utilizzo di PROMO/COUPON maxItems viene decrementato mentre startItems rimane invariato per tenere traccia di quanti utilizzi iniziali sono stati dati alla PROMO/COUPON.
            //                 Quando maxItems arriva a 0 la PROMO/COUPON risulta scaduta. totalUses invece conta il numero di utilizzi della PROMO/COUPON quindi ad ogni utilizzo viene incrementata
            //
            // isRotten:       variabile che se è a true indica che la PROMO o il COUPON non è più valida
            // isReseller:     variabile che se è a true indaca che la PROMO o il COUPON sono validi solo per i reseller
            // isCoupon:       variabile che se è a true indica che mi riferisco a un COUPON, se a false mi riferisco ad una PROMO
            // groups:         gruppo a cui è associata la PROMO. La PROMO è valida per tutti gli utenti appartenenti al gruppo. Non valido per i COUPON
            // assignedCoupon: viene utilizzata solo come riferimento grafico per capire se un COUPON è stata già fornito ad un utente oppure se è stato creato ma non è stato ancora fornito a nessuno
            // prizes: array di prodotti gratuiti da fornire insieme al COUPON utilizzato
            promoTemp: {
                webUser: null,
                toBuy: '',
                id: '',
                startAt: '',
                endAt: '',
                productNames: '',
                discount: '',
                discountValue: '',
                priceValue: '',
                description: '',
                code: '',
                countries: null,
                startItems: '',
                maxItems: 0,
                totalUses: '',
                isRotten: false,
                isReseller: false,
                isCoupon: null,
                groups: '',
                assignedCoupon: false,
                prizes: null,
            },

            // array per popolare i prodotti gratuiti da fornire durante l'acquisto con COUPON
            arrayProduct: [],

            // informazioni che dovranno andare nel CSV da esportare
            headersCSV: [],
            dataCSV: [],

            // variabili per selezionare più di una PROMO o COUPON da eliminare
            selectedRows: [],
            selectedCount: 0,
            clearSelectedRows: false,

            isLoading: false,
            loadingForRole: true,
            getPromoSelectedRows: []
        }
        this.setLoading = this.setLoading.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare le promo, viene rispedito alla home
        if (!isEmpty(this.props.role.promo)) {
            if (!controlRole(this.props.role.promo, "api/Promo/GetPromos", "GET")) {
                this.props.history.push('/');
                this.props.infoNotify('Non hai nessun permesso per visualizzare le promo!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                }
            }
        }

        this.props.getPromoList(this.props.promo.paramsPromoTemp, true);
        this.props.getProductList();
    }

    componentDidUpdate(prevProps) {
        // se l'utente non ha nessun permesso per visualizzare le promo, viene rispedito alla home
        if (!isEmpty(this.props.role.promo)) {
            if (!controlRole(this.props.role.promo, "api/Promo/GetPromos", "GET")) {
                this.props.history.push('/');
                this.props.infoNotify('Non hai nessun permesso per visualizzare le promo!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                }

                if (this.props.history.location !== prevProps.location) {
                    this.props.getPromoList(this.props.promo.paramsPromoTemp, true);
                }
            }
        }
    }

    /**
    * Popola il csv da esportare con le informazioni ricevute
    */
    popolaCSV = () => {
        let dataTemp = [];
        const headersTemp = [
            { label: "Nome Prodotto", key: "productNamesCSV" },
            { label: "ID Promo", key: "idCSV" },
            { label: "Codice", key: "codeCSV" },
            { label: "Descrizione", key: "descriptionCSV" },
            { label: "Data Inizio", key: "startAtCSV" },
            { label: "Data Fine", key: "endAtCSV" },
            { label: "Percentuale Sconto", key: "discountCSV" },
            { label: "Utente Associato", key: "webUserCSV" },
            { label: "Acquisti Minimi", key: "toBuyCSV" },
            { label: "Volte Utilizzato", key: "totalUsesCSV" },
            { label: "Generati", key: "startItemsCSV" },
            { label: "Paesi Associati", key: "countriesCSV" },
            { label: "Usati in questi Paesi", key: "promoByCountryCSV" },
        ];

        // eslint-disable-next-line
        this.state.getPromoSelectedRows.map(el => {
            dataTemp.push(
                {
                    productNamesCSV: el.productNames.map((item) => { return (item) }),
                    idCSV: el._id,
                    codeCSV: el.code,
                    descriptionCSV: el.description,
                    startAtCSV: el.startAt,
                    endAtCSV: el.endAt === null ? "Illimitata" : el.endAt,
                    discountCSV: el.discount * 100 + "%",
                    webUserCSV: (isNull(el.webUser) || isEmpty(el.webUser)) ? "Nessun Web User Associato" : el.webUser,
                    toBuyCSV: el.toBuy,
                    totalUsesCSV: el.totalUses,
                    startItemsCSV: el.startItems,
                    countriesCSV: (isNull(el.countries) || isEmpty(el.countries)) ? "Nessun Paese" : el.countries.map((item) => { return (item) }),
                    promoByCountryCSV: Object.keys(el.promoByCountry).map((item) => { return (isNull(el.promoByCountry[item].country) ? "-" : el.promoByCountry[item].count === 0 ? "" : returnNationality(el.promoByCountry[item].country) + ":" + el.promoByCountry[item].count) }),
                }
            )
        });

        this.setState({
            headersCSV: headersTemp,
            dataCSV: dataTemp
        });
    }

    setLoading = (value) => {
        this.setState({ isLoading: value });
    }

    toggleModal = () => {
        this.setState(prevState => ({ modal: !prevState.modal }));
        if (this.state.modal) {
            this.setState({
                hasClickNewPromo: false,
                hasClickEditPromo: false,
                hasClickNewCouponGenerate: false,
                hasClickNewCouponBuy: false,
                hasClickEditCoupon: false,
                hasClickDeletePromo: false,
                hasClickAssignedCoupon: false,
                hasClickDeleteAllPromo: false,
            });
        }

        this.reset();
    }

    /**
     * Al click del menu opzioni, controlla quale opzione sia stata selezionata 
     * e di conseguenza esegue l'azione per quella riga.
     * 
     * @param action è il tipo di azione selezionato
     * @param dataPromo è l'oggetto contente le informazioni della promo selezionata dell'utente per cui è richiesta quell'azione
     */
    actionClick = (action, dataPromo) => {
        switch (action) {
            case 'editPromo': this.activeEditPromo(dataPromo); break;
            case 'assignedCoupon': this.activeAssignedCoupon(dataPromo); break;
            case 'deletePromo': this.activeDeletePromo(dataPromo); break;
            default: break;
        }
    }

    /**
     * Attiva la modale per inserire una nuova PROMO
     */
    activeNewPromo = () => {
        this.setState({
            hasClickNewPromo: true,
            modal: true
        });
    }

    /**
     * Attiva la modale per inserire un nuovo COUPON generato
     */
    activeNewCouponGenerate = () => {
        this.setState({
            hasClickNewCouponGenerate: true,
            modal: true,
            arrayProduct: [{
                name: '',
                qty: 0
            }]
        });
    }

    /**
     * Attiva la modale per inserire un nuovo COUPON venduto
     */
    activeNewCouponBuy = () => {
        this.setState({
            hasClickNewCouponBuy: true,
            modal: true
        });
    }

    /**
     * Attiva la modale per la modifica del COUPON se isCoupon è true
     * altrimenti si attiva la modale per la modifica della PROMO
     */
    activeEditPromo = (dataPromo) => {
        this.setState({
            promoTemp: dataPromo,
            arrayProduct: []
        });

        setTimeout(() => {
            if (dataPromo.isCoupon) {

                if (!isNull(dataPromo.prizes)) {
                    // eslint-disable-next-line
                    dataPromo.prizes.map(el => {
                        this.state.arrayProduct.push(el);
                    })
                }

                this.setState({
                    hasClickEditCoupon: true,
                    modal: true,
                });
            } else {
                this.setState({
                    hasClickEditPromo: true,
                    modal: true,
                });
            }
        }, 300);
    }

    /**
     * Attiva la modale per assegnare un coupon
     */
    activeAssignedCoupon = (dataPromo) => {
        let promoTemp = {
            webUser: dataPromo.webUser,
            id: dataPromo._id,
            startAt: dataPromo.startAt,
            endAt: dataPromo.endAt,
            productNames: dataPromo.productNames,
            discount: dataPromo.discount,
            discountValue: dataPromo.discountValue,
            priceValue: dataPromo.priceValue,
            description: dataPromo.description,
            code: dataPromo.code,
            countries: dataPromo.countries,
            startItems: dataPromo.startItems,
            totalUses: dataPromo.totalUses,
            assignedCoupon: dataPromo.assignedCoupon ? false : true,
            prizes: dataPromo.prizes
        };

        this.setState({
            titleModal: dataPromo.assignedCoupon ? 'Libera Coupon' : 'Assegna Coupon',
            contentModal: dataPromo.assignedCoupon ? 'Vuoi impostare questo Coupon come "Non Assegnato" ?' : 'Vuoi impostare questo Coupon come "Assegnato" ?',
            hasClickAssignedCoupon: true,
            modal: true,
            promoTemp: promoTemp
        });
    }

    /**
     * Attiva la modale per eliminare le promo selezionate
     */
    activeDeleteAllPromo = (dataPromo) => {
        this.setState({
            titleModal: "Elimina Coupon/Promo selezionati",
            contentModal: "Sei sicuro di voler eliminare i Coupon/Promo selezionati ?",
            hasClickDeleteAllPromo: true,
            modal: true,
            promoTemp: dataPromo,
        });
    }

    /**
     * Attiva la modale per eliminare la promo
     */
    activeDeletePromo = (dataPromo) => {
        this.setState({
            titleModal: dataPromo.isCoupon ? 'Elimina Coupon' : 'Elimina Promo',
            contentModal: dataPromo.isCoupon ? 'Sei sicuro di voler cancellare questo coupon ?' : 'Sei sicuro di voler cancellare questa promozione ?',
            hasClickDeletePromo: true,
            modal: true,
            promoTemp: dataPromo
        });
    }

    /**
     * Chiama il BE, se entro nell'if chiamo la delete su tutte le PROMO o COUPON che ho selezionato e refresha la lista delle PROMO/COUPON, 
     * se entro nell'else cancella la PROMO/COUPON singola e refresha la lista delle PROMO/COUPON
     */
    deletePromo = () => {
        let arrayIdPromo = [];
        if (this.state.hasClickDeleteAllPromo) {
            // eslint-disable-next-line
            this.state.selectedRows.map(el => {
                arrayIdPromo.push(el)
            });
        } else {
            arrayIdPromo.push(this.state.promoTemp._id);
        }

        this.props.deletePromo(arrayIdPromo);
        this.toggleModal();

        this.setState({
            clearSelectedRows: false
        });
    }

    /**
     * Chiama il BE e modifica una promo/coupon
     * 
     * @param {Object} data sono i dati per la creazione della promo
     * * @param {Boolean} isCoupon è variabile che mi dice se sto modificando un coupon o una promo
     * 
     */
    sendEditPromo = (data, isCoupon) => {
        this.props.sendEditPromo(data, isCoupon);

        this.toggleModal();
    }

    /**
    * Prendo tutti gli id delle PROMO/COUPON che seleziono con la checkbox e li passo a selectedRows che servirà per eliminare più PROMO/COUPON
    */
    onChangeSelectRow = (data) => {
        let arrayTemp = [];
        let arrayTempSelected = data.selectedRows;
        // eslint-disable-next-line
        arrayTempSelected.map(el => {
            arrayTemp.push(el._id);
        });

        this.setState({
            selectedCount: data.selectedCount,
            selectedRows: arrayTemp,
        });

        setTimeout(() => {
            this.exelForRows();
            this.popolaCSV();
        }, 300);
    }

    /**
    * Scarica l'exel della riga selezionata
    */
    exelForRows = () => {
        let promoSelectedRows = [];

        // eslint-disable-next-line
        this.state.selectedRows.map(el => {
            // eslint-disable-next-line
            this.props.promo.promoList.map(elem => {
                if (el === elem._id) {
                    promoSelectedRows.push(elem);
                }
            });
        });

        this.setState({
            getPromoSelectedRows: promoSelectedRows
        });
    }

    // resetta i valori
    reset = () => {
        this.setState({
            promoTemp: {
                webUser: null,
                id: '',
                startAt: '',
                endAt: '',
                productNames: '',
                discount: '',
                discountValue: '',
                priceValue: '',
                description: '',
                code: '',
                countries: null,
                startItems: '',
                maxItems: 0,
                totalUses: '',
                isRotten: false,
                isReseller: false,
                isCoupon: null,
                groups: '',
                assignedCoupon: false,
                prizes: null
            },
            selectedRows: [],
            selectedCount: 0,
            clearSelectedRows: true
        });
    }

    render() {
        const promoSection = this.props.history.location.pathname === '/promo/createdPromo';
        const couponGenerate = this.props.history.location.pathname === '/promo/createdCoupon';
        const couponBuy = this.props.history.location.pathname === '/promo/buyCoupon';

        const columns = [
            {
                name: 'Creator',
                selector: row => isNull(row.backOfficeUserCreator) ? "-" :
                    <MyTooltip
                        title={row.backOfficeUserCreator}
                        position="top"
                        content={<i className="fas fa-user-circle" style={{ fontSize: '18px', color: '#1b76d3' }}></i>}
                    />,
                maxWidth: '80px',
                minWidth: '80px',
                center: true
            },
            {
                name: 'Assigned',
                selector: row => row.assignedCoupon ? <i className="fas fa-user-check" style={{ color: 'cadetblue', fontSize: '1rem' }}></i> : "-",
                maxWidth: '100px',
                minWidth: '100px',
                center: true
            },
            {
                name: 'Type',
                selector: row => row.isCoupon ? <i className="fas fa-gift iconCoupon" /> && row.isRotten ? <i className="fas fa-gift iconCouponExpired" /> : <i className="fas fa-gift iconCoupon" /> : <i className="fas fa-user-tag iconPromo" /> && row.isRotten ? <i className="fas fa-user-tag iconPromoExpired" /> : <i className="fas fa-user-tag iconPromo" />,
                maxWidth: '70px',
                minWidth: '70px',
                center: true
            },
            {
                name: 'Codice',
                selector: row => row.isCoupon ? <SelectorCopy item={row.code} infoNotify={this.props.infoNotify} /> : "-"
            },
            {
                name: 'Descrizione',
                selector: row => row.description
            },
            {
                name: 'Gruppi',
                selector: row => !isNull(row.toBuy) && !isEmpty(row.groups) ? row.groups.length <= 1
                    ?
                    row.groups
                    :
                    <MyTooltip
                        title={row.groups.map(el => {
                            return (
                                <ul key={el} style={{ margin: '0.5vh', paddingLeft: '10px', fontSize: '16px' }}>
                                    <li>{el}</li>
                                </ul>
                            );
                        })
                        }
                        position="top"
                        content={<span className="moreProd">More Groups</span>}
                    />
                    :
                    "-"
            },
            {
                name: 'Nome Prodotto',
                selector: row => !isNull(row.productNames) ? row.productNames.length <= 1
                    ?
                    row.productNames
                    :
                    <MyTooltip
                        title={row.productNames.map(el => {
                            return (
                                <ul key={el} style={{ margin: '0.5vh', paddingLeft: '10px', fontSize: '16px' }}>
                                    <li>{el}</li>
                                </ul>
                            );
                        })
                        }
                        position="top"
                        content={<span className="moreProd">More Products</span>}
                    />
                    :
                    "-",
            },
            {
                name: 'Sconto',
                selector: row => !isNull(row.discountValue) ? row.discountValue : !isNull(row.discount) ? (row.discount) * 100 + "%" : "-",
                maxWidth: '80px',
                center: true
            },
            {
                name: 'Prezzo Fisso',
                selector: row => (row.priceValue === '' || row.priceValue === null) ? "-" : row.priceValue,
                maxWidth: '150px',
                center: true
            },
            {
                name: 'Usato / Totali',
                selector: row => row.startItems === null ? row.totalUses + "/ Illimitato" : row.totalUses + "/" + row.startItems,
                center: true
            },
            {
                selector: row => <ActionMenu actionClick={this.actionClick} promo={row} role={role} />,
                right: true,
                button: true,
                allowOverflow: true,
                wrap: true,
                minWidth: '75px'
            },
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        const { role, promo, getPromoList, setIdPaymentForDetail } = this.props;

        return (
            <Fragment>
                {
                    this.state.loadingForRole ? <Loading /> :
                        <Fragment>
                            <div className="row">
                                <div className="col-12 promoDesktop">
                                    <div className="contentFilterSearch">
                                        <FormFilter
                                            setLoading={this.setLoading}

                                            player={this.props.player}
                                            promo={promo}
                                            getPromoList={getPromoList}

                                            history={this.props.history}

                                            warningNotify={this.props.warningNotify}
                                            infoNotify={this.props.infoNotify}
                                            errorNotify={this.props.errorNotify}
                                            successNotify={this.props.successNotify}
                                        />
                                    </div>

                                    <div className="contentPromo">
                                        <div className="row">
                                            <div className="col-6">
                                                <h2 className='inlineBlock'>{promoSection ? "Promo" : "Coupon"}</h2>
                                                {
                                                    promo.totalElements &&
                                                    <div className="mybadge">
                                                        {promo.totalElements}
                                                    </div>
                                                }
                                            </div>

                                            <div className="col-6 text-right">
                                                {
                                                    this.state.selectedCount > 0 ?
                                                        <CSVExport
                                                            data={this.state.dataCSV}
                                                            headers={this.state.headersCSV}
                                                            filename="list-promo.csv"
                                                            label={"Scarica la lista delle promo selezionate"}
                                                        />
                                                        :
                                                        "Seleziona almeno una riga per scaricare il CSV"
                                                }
                                            </div>

                                            <div className="col-12 text-center">
                                                {
                                                    controlRole(role.promo, "api/Promo/Coupon", "POST") && !promoSection &&
                                                    <Fragment>
                                                        {
                                                            couponGenerate &&
                                                            <button className="btn btn-success" onClick={this.activeNewCouponGenerate} style={{ 'marginRight': '2rem' }}>
                                                                <i className="fas fa-plus" style={{ 'marginRight': '10px' }}></i>
                                                                Aggiungi Coupon Generato
                                                            </button>
                                                        }
                                                        {
                                                            couponBuy &&
                                                            <button className="btn btn-success" onClick={this.activeNewCouponBuy} style={{ 'marginRight': '2rem' }}>
                                                                <i className="fas fa-plus" style={{ 'marginRight': '10px' }}></i>
                                                                Aggiungi Coupon Venduto
                                                            </button>
                                                        }
                                                    </Fragment>
                                                }

                                                {
                                                    controlRole(role.promo, "api/Promo", "POST") && promoSection &&
                                                    <button className="btn btn-info" onClick={this.activeNewPromo}>
                                                        <i className="fas fa-plus" style={{ 'marginRight': '10px' }}></i>
                                                        Aggiungi Promo
                                                    </button>
                                                }
                                                {
                                                    (this.state.selectedCount > 1 && !controlRole(role.promo, "api/Promo/DeleteMany", "DELETE")) &&
                                                    <button className="btn btn-danger" onClick={this.activeDeleteAllPromo} disabled style={{ 'margin': '0 1rem' }}>
                                                        <i className="fas fa-minus" style={{ 'marginRight': '10px' }}></i>
                                                        Non hai il permesso di cancellare!
                                                    </button>
                                                }
                                                {
                                                    (this.state.selectedCount > 1 && controlRole(role.promo, "api/Promo/DeleteMany", "DELETE")) &&
                                                    <button className="btn btn-danger" onClick={this.activeDeleteAllPromo} style={{ 'margin': '0 1rem' }}>
                                                        <i className="fas fa-minus" style={{ 'marginRight': '10px' }}></i>
                                                        Rimuovi Coupon / Promo
                                                    </button>
                                                }
                                            </div>
                                        </div>

                                        <IconLicence legend forPromo />

                                        <div className="row">
                                            <div className="col-12">
                                                <hr />
                                                <DataTable
                                                    columns={columns}
                                                    data={promo.promoList}
                                                    customStyles={customStyles}
                                                    noHeader
                                                    striped
                                                    highlightOnHover
                                                    pointerOnHover
                                                    selectableRows
                                                    selectableRowsHighlight
                                                    expandableRows={this.state.isLoading ? false : true}
                                                    onSelectedRowsChange={this.onChangeSelectRow}
                                                    clearSelectedRows={this.state.clearSelectedRows}
                                                    expandOnRowClicked
                                                    expandableRowsComponent={ExpanableComponent}
                                                    expandableRowsComponentProps={{
                                                        "history": this.props.history,
                                                        "role": role,
                                                        "refreshPromo": getPromoList,
                                                        "setIdPaymentForDetail": setIdPaymentForDetail
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    {(promo.isLoading || this.state.isLoading) && <Loading />}

                                </div>
                            </div>

                            {
                                (this.state.hasClickNewPromo || this.state.hasClickEditPromo) &&
                                <ModalPromo
                                    titleModal={this.state.hasClickNewPromo ? 'Crea Promo' : 'Modifica Promo'}
                                    clickNew={this.props.sendNewPromo}
                                    clickEdit={this.props.sendEditPromo}
                                    toggle={this.toggleModal}
                                    stateModal={this.state.modal}

                                    errorNotify={this.props.errorNotify}
                                    infoNotify={this.props.infoNotify}
                                    successNotify={this.props.successNotify}
                                    warningNotify={this.props.warningNotify}

                                    // valori di controllo per i ruoli
                                    canPostPromoApi={controlRole(role.promo, "api/Promo", "POST")}
                                    canEditPromoApi={controlRole(role.promo, "api/Promo", "PUT")}

                                    // i dati della PROMO da passare alla modale
                                    promoTemp={this.state.promoTemp}

                                    // chiamata lista dei prodotti su cui può essere creata una PROMO
                                    productList={this.props.getProductList}

                                    // chiamata lista dei gruppi su cui può essere creata una PROMO
                                    getGroupList={this.props.getGroupList}

                                    // chiamata lista delle email per selezionare il webUser da DB per creare una PROMO
                                    listDevice={this.props.getListDevice}

                                    // variabili passate per avere la lista dei gruppi disponibili e la lista dei prodotti
                                    promo={this.props.promo}
                                    group={this.props.group}
                                    preferences={this.props.preferences}

                                />
                            }

                            {
                                (this.state.hasClickNewCouponGenerate || this.state.hasClickEditCoupon) &&
                                <ModalCouponGenerate
                                    titleModal={this.state.hasClickNewCouponGenerate ? 'Crea Coupon Generato' : 'Modifica Coupon'}
                                    clickNew={this.props.sendNewPromo}
                                    clickEdit={this.props.sendEditPromo}
                                    toggle={this.toggleModal}
                                    stateModal={this.state.modal}

                                    errorNotify={this.props.errorNotify}
                                    infoNotify={this.props.infoNotify}
                                    successNotify={this.props.successNotify}
                                    warningNotify={this.props.warningNotify}

                                    // valori di controllo per i ruoli
                                    canPostCouponApi={controlRole(role.promo, "api/Promo/Coupon", "POST")}
                                    canEditCouponApi={controlRole(role.promo, "api/Promo/Coupon", "PUT")}

                                    // i dati del COUPON da passare alla modale
                                    couponTemp={this.state.promoTemp}
                                    history={this.props.history}

                                    // chiamata lista dei prodotti su cui può essere creata una PROMO
                                    productList={this.props.getProductList}

                                    // array con i prodotti gratuiti da fornire con l'utilizzo del COUPON
                                    arrayProduct={this.state.arrayProduct}

                                    // chiamata lista delle email per selezionare il webUser da DB per creare una PROMO
                                    listDevice={this.props.getListDevice}

                                    // variabili passate per avere la lista dei gruppi disponibili e la lista dei prodotti
                                    promo={this.props.promo}
                                    group={this.props.group}
                                    preferences={this.props.preferences}
                                />
                            }

                            {
                                (this.state.hasClickNewCouponBuy) &&
                                <ModalCouponBuy
                                    titleModal="Crea Coupon Venduto"
                                    clickNew={this.props.sendNewPromo}
                                    toggle={this.toggleModal}
                                    stateModal={this.state.modal}

                                    errorNotify={this.props.errorNotify}
                                    infoNotify={this.props.infoNotify}
                                    successNotify={this.props.successNotify}
                                    warningNotify={this.props.warningNotify}

                                    // valori di controllo per i ruoli
                                    canPostCouponApi={controlRole(role.promo, "api/Promo/Coupon", "POST")}

                                    // chiamata lista dei prodotti su cui può essere creata una PROMO
                                    productList={this.props.getProductList}

                                    // chiamata lista delle email per selezionare il webUser da DB per creare una PROMO
                                    listDevice={this.props.getListDevice}

                                    // variabili passate per avere la lista dei gruppi disponibili e la lista dei prodotti
                                    promo={this.props.promo}
                                    group={this.props.group}
                                    preferences={this.props.preferences}
                                />
                            }

                            {
                                (this.state.hasClickDeletePromo || this.state.hasClickDeleteAllPromo || this.state.hasClickAssignedCoupon) &&
                                <Modal
                                    click={this.state.hasClickAssignedCoupon ? () => this.sendEditPromo(this.state.promoTemp, true) : this.deletePromo}
                                    titleModal={this.state.titleModal}
                                    contentModal={this.state.contentModal}
                                    toggle={this.toggleModal}
                                    stateModal={this.state.modal}
                                />
                            }
                        </Fragment>
                }
            </Fragment>
        )
    }
}