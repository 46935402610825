/**
 * Ritorn tutti i mesi in base alla label ricevuta.
 * 
 * @param {*} mese 
 * @returns 
 */
 export var returnMonths = function (mese) {
    switch (mese) {
        case 'jan': return 0;
        case 'feb': return 1;
        case 'mar': return 2;
        case 'apr': return 3;
        case 'may': return 4;
        case 'jun': return 5;
        case 'jul': return 6;
        case 'aug': return 7;
        case 'sep': return 8;
        case 'oct': return 9;
        case 'nov': return 10;
        case 'dec': return 11;
        default: return;
    }
}