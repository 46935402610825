import React, { Component } from 'react';
import { NativeSelect, FormControl } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

import { months } from '../../../common/actions/months';
import Loading from '../../../common/loading/loadingInBlock';

export default class expiringPlayers extends Component {
    render() {
        const { trialMonth, onChange, labelExpiringLicence, valueExpiringLicence, loadingGraph10 } = this.props;

        const optionsExpiringLicence = {
            responsive: true,
            layout: {
                padding: {
                    left: 15,
                    right: 25,
                    top: 10
                }
            },

            legend: {
                display: true,
                position: 'top',
                align: 'end'
            },

            plugins: {
                title: {
                    display: true,
                    text: 'Licenze in Scadenza',
                    font: {
                        size: 20
                    }
                }
            },

            scales: {
                y: {
                    ticks: {
                        beginAtZero: true
                    }
                }
            }
        };

        const dataExpiringLicence = {
            labels: labelExpiringLicence,
            datasets: [
                {
                    label: "Licenze in Scadenza",
                    backgroundColor: 'rgba(42,110,255, 0.5)',
                    data: valueExpiringLicence,
                    hoverBackgroundColor: 'rgba(42,110,255, 0.5)',
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                }
            ],
        };

        return (
            <div className="containerChart">
                <div className="homeChart">
                    <FormControl className="licenceMonth">
                        <NativeSelect
                            name="trialMonth"
                            value={trialMonth}
                            onChange={onChange}
                        >
                            {
                                months.map((item, k) => {
                                    return (
                                        <option className="colorMonth" key={k} value={item.value}>{item.label}</option>
                                    );
                                })
                            }
                        </NativeSelect>
                    </FormControl>
                    {
                        loadingGraph10 ? <Loading /> :
                            <Bar options={optionsExpiringLicence} data={dataExpiringLicence} />
                    }
                </div>
            </div>
        )
    }
}