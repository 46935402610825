import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormControl, TextField, Input, InputLabel, InputAdornment } from '@material-ui/core';
import { NOT_REGISTERED } from '../../../config';
import SelectorCopy from '../../../common/selectorCopy';
import moment from 'moment';

import './style.scss';

class tabExpanableLicence extends Component {
    /**
     * Data una data in formato iso, restituisce una data correttamente stampata
     * nel seguente formato: September 4 1986 - 08:30:00 PM
     */
    formatData = (data) => {
        if (data === null || data === undefined || data === '') {
            return '';
        } else {
            return moment(data).format('LL') + ' - ' + moment(data).format('LTS');
        }
    }

    render() {
        return (
            <div className="tabExpanableLicence">
                <FormControl>
                    <div className="row">
                        <div className="col-4">
                            <FormControl>
                                <InputLabel htmlFor="key-licence">Key</InputLabel>
                                <Input
                                    id="key-licence"
                                    value={this.props.data.key || ''}
                                    readOnly
                                    endAdornment={
                                        this.props.data.key &&
                                        <InputAdornment position="end">
                                            <SelectorCopy
                                                item={this.props.data.key}
                                                infoNotify={this.props.infoNotify}
                                                noLabel
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.props.data.platform || ''}
                                label="Piattaforma"
                                InputProps={{ readOnly: true }}
                            />
                        </div>

                        <div className="col-4">
                            <FormControl>
                                <InputLabel htmlFor="id-player">Device ID</InputLabel>
                                <Input
                                    id="id-player"
                                    value={this.props.data.playerId || ''}
                                    readOnly
                                    endAdornment={
                                        this.props.data.playerId &&
                                        <InputAdornment position="end">
                                            <SelectorCopy
                                                item={this.props.data.playerId}
                                                infoNotify={this.props.infoNotify}
                                                noLabel
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.props.data.description || ''}
                                label="Descrizione"
                                InputProps={{ readOnly: true }}
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.formatData(this.props.data.createdAt) || ''}
                                label="Data Creazione"
                                InputProps={{ readOnly: true }}
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.formatData(this.props.data.expireAt) || ''}
                                label="Data Scadenza"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <FormControl>
                                <InputLabel htmlFor="id-user">Utente</InputLabel>
                                <Input
                                    id="id-user"
                                    value={this.props.data.user || ''}
                                    readOnly
                                    endAdornment={
                                        this.props.data.user &&
                                        <InputAdornment position="end">
                                            <SelectorCopy
                                                item={this.props.data.user}
                                                infoNotify={this.props.infoNotify}
                                                noLabel
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>

                        <div className="col-4">
                            <FormControl>
                                <InputLabel htmlFor="id-payment-licence">ID Pagamento</InputLabel>
                                <Input
                                    id="id-payment-licence"
                                    value={this.props.data.paymentId === NOT_REGISTERED ? 'Non Presente...' : this.props.data.paymentId}
                                    readOnly
                                    endAdornment={
                                        this.props.data.paymentId &&
                                        <InputAdornment position="end">
                                            <SelectorCopy
                                                item={this.props.data.paymentId}
                                                infoNotify={this.props.infoNotify}
                                                noLabel
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.props.data.maxAllowedPeers || ''}
                                label="Numero MAX Peers"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>
                </FormControl>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        player: state.player,
    };
};

export default connect(mapStateToProps)(tabExpanableLicence);