import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'date-fns';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Radio } from '@material-ui/core';

import { returnTypeName } from '../actions/returnTypeName';
import { countries } from '../countries';
import isEmpty from 'lodash/isEmpty';

// variabile presa a caso solo per mandarla al BE in fase di carico merce 
import { VPNBOX_THREE_MONTH_HW } from '../../config';
import { isNil } from 'lodash';

/**
 * Attivazione modale per vpn stock
 */
export default class modalVpnStock extends Component {
    // non uso questa variabile nello state perchè la uso nel rendering... (evito loop)
    deviceTempForLoad = [];

    constructor(props) {
        super(props);
        this.state = {
            productName: '',
            operationType: '',
            quantity: '',
            createPayment: false,
            paymentId: null,
            movId: this.props.dataTemp.movId || '',
            nameCourier: this.props.dataTemp.nameCourier || '',
            trackingNumber: '',

            // inizialmente è un array vuoto
            deviceIds: this.props.dataTemp.deviceIds || [],

            // dati per la creazione del pagamento
            email: '',
            name: '',
            surname: '',
            code: '',
            firstAddress: '',
            secondAddress: '',
            telephone: '',
            cap: '',
            city: '',
            province: '',
            country: '',

            // variabile di controllo per creazione fattura nello scarico merci
            checkedPayment: false,

            selectRadio: '',
            discount: 0
        }
        this.setChange = this.setChange.bind(this);
    }

    componentDidMount() {
        /**
         * Quando apro la modale, se mi trovo in modalita per associare il tracking,
         * devo popolare l'array dei device con stringhe vuote (tante quante ne sono le quantità), in modo che 
         * se l'utente vuole inserire i dati non a partire dall'inizio, non si verificano inserimenti
         * a caso negli indici.
         */
        const { dataTemp } = this.props;
        let devicesTemp = [];
        if (dataTemp.qty && isEmpty(dataTemp.deviceIds)) {
            for (let i = 0; i < dataTemp.qty; i++)
                devicesTemp.push("");
            this.setState({ deviceIds: devicesTemp })
        }
    }

    componentWillUnmount() {
        this.setState({ deviceIds: [] })
    }

    /**
     * Usato per cambiare solo il valore nell'array dei device.
     * 
     * Quando mi trovo in modalità di carico, se inserisco un solo device,
     * tutti gli altri saranno = ""
     * 
     * Quando il codice QR delle box viene sparato, il risultato è un insieme di MAC + Seriale (per le box bianche) - 34 caratteri,
     * e un insieme di Mac + seriale + versione (per le box gialle) - 42 caratteri (rimane solo la versione alla fine, bug non necessario da fixare...).
     * Quindi, arrivati a quella lunghezza, pulisco e rimango solo il seriale.
     * Il seriale è sempre alla seconda posizione.
     * 
     * @param {*} event 
     */
    setChangeDevice = (event) => {
        let devicesTemp = this.state.operationType === 'L' ? this.deviceTempForLoad : this.state.deviceIds;

        // prende la posizione della textInput e funge da indice per l'array dei devices
        let newIndex = event.target.name.split('_');

        if (event.target.value.length === 42 || event.target.value.length === 34) {
            devicesTemp.splice(newIndex[1], 1, event.target.value.split(' ')[1]);
        } else {
            devicesTemp.splice(newIndex[1], 1, event.target.value);
        }

        this.setState({ deviceIds: devicesTemp })
    }

    setChange = (event) => {
        if (event.target.name === 'createPayment') {
            this.setState({ createPayment: !this.state.createPayment });
        } else if (event.target.name === 'checkedPayment') {
            this.setState({
                checkedPayment: !this.state.checkedPayment,
                paymentId: null
            });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    /**
     * Prima di inviare i dati da caricare, controllo se i device corrispondono alla quantity
     * inserita dall'utente.
     * Esempio, se la quantity è 5, il deviceIds deve avere almeno 5 campi vuoti.
     */
    controlDeviceForLoad = () => {
        if (parseInt(this.state.quantity) === this.state.deviceIds.length)
            return this.state.deviceIds
        else {
            let devicesTemp = [];
            // crea un array vuoto in base alla quantity che l'utente inserisce
            for (let i = 0; i < parseInt(this.state.quantity); i++)
                devicesTemp.push("");
            return devicesTemp
        }
    }

    confirmSend = () => {
        const { type, errorNotify, addVpnStockMovement, toggle, removeVpnStockMovement, dataTemp, associateTracking } = this.props;
        switch (type) {
            case 'addMovement':
                if (this.state.quantity === '') {
                    errorNotify('Inserisci una quantità');
                } else if (this.state.productName === '' && this.state.operationType !== 'L') {
                    errorNotify('Inserisci una Tipo di Prodotto');
                } else if (this.state.operationType === '') {
                    errorNotify('Inserisci un Tipo di Operazione');
                } else {
                    if (this.state.operationType === "U" && this.state.checkedPayment && this.state.email === '') {
                        errorNotify('Inserisci una mail');
                    } else if (this.state.operationType === "U" && this.state.checkedPayment && this.state.name === '' && this.state.selectRadio === 'privato') {
                        errorNotify('Inserisci un nome');
                    } else if (this.state.operationType === "U" && this.state.checkedPayment && this.state.surname === '' && this.state.selectRadio === 'privato') {
                        errorNotify('Inserisci un cognome');
                    } else if (this.state.operationType === "U" && this.state.checkedPayment && this.state.surname === '' && this.state.selectRadio === 'azienda') {
                        errorNotify('Inserisci una Ragione Sociale');
                    } else if (this.state.operationType === "U" && this.state.checkedPayment && this.state.country === '') {
                        errorNotify('Inserisci un country');
                    } else if (this.state.operationType === "U" && this.state.checkedPayment && this.state.code === '' && this.state.selectRadio === 'azienda') {
                        errorNotify('Inserisci una Partita IVA');
                    } else {
                        addVpnStockMovement({
                            "paymentId": this.state.paymentId,
                            "productName": this.state.operationType === 'L' ? VPNBOX_THREE_MONTH_HW : this.state.productName,
                            "operationType": this.state.operationType,
                            "qty": this.state.quantity,
                            "discount": parseFloat((this.state.discount / 100).toFixed(2)),
                            "paymentFrom": this.state.checkedPayment ? {
                                "email": this.state.email,
                                "ip": '12.10.32.43',
                                "address": {
                                    "name": this.state.name,
                                    "surname": this.state.surname,
                                    "isBusiness": this.state.selectRadio === 'azienda',
                                    "code": this.state.code,
                                    "firstAddress": this.state.firstAddress,
                                    "secondAddress": this.state.secondAddress,
                                    "telephone": this.state.telephone,
                                    "cap": this.state.cap,
                                    "city": this.state.city,
                                    "province": this.state.province,
                                    "country": this.state.country,
                                }
                            } : null,
                            "deviceIds": this.state.operationType === 'L' ? [] : this.controlDeviceForLoad()
                        });
                        toggle();
                    }
                }
                break;
            case 'delete':
                removeVpnStockMovement(dataTemp.movId);
                toggle();
                break;
            case 'associateTracking':
                if (this.controlForm()) {
                    associateTracking(dataTemp.movId, {
                        shippingProvider: this.state.nameCourier,
                        trackingNumber: this.state.trackingNumber === '' ? null : this.state.trackingNumber,
                        deviceIds: this.state.deviceIds
                    });
                    toggle();
                }
                break;
            default: break;
        }
    }

    /**
     * Controlla se sono stati riempiti correttamente i campi con gli ID device.
     * Faccio un controllo approfondito
     */
    controlForm = () => {
        let value = true;
        //controllo che ogni seriale sia 8byte senza caratteri strani
        const regex = /^[0-9a-zA-Z]{16}$/;
        // eslint-disable-next-line
        this.state.deviceIds.map((el, key) => {
            if (!regex.test(el)) {
                value = false;
                this.props.errorNotify(`Il Device ${key + 1} non rispetta la validazione dei caratteri`);
            }
        })
        return value;
    }

    /**
     * Crea un TXT con tutti i dati della spedizione ed effettua il download di un TXT.
     * Permette di stampare un etichetta per la spedizione.
     */
    downloadShippingLabel = () => {
        const { address } = this.props.dataTemp.payment.shipping;

        // NON SPOSTARE INDENTAZIONE!! Altrimenti nel txt le informazioni vengono messe indentate in modo sbagliato
        let dataForLabel =
            `DESTINATARIO:
        ${address.name} ${address.surname}
        Tel: ${address.telephone}
        ${address.country} - ${address.city} (${address.province}) - ${address.cap}
        ${address.firstAddress}
        ${address.secondAddress}
        ${address.extraInfo}

        MITTENTE:
        Nanomid Emea srl
        Municipal Timisoara
        Street Ion Vidu n°15, Ap. 1
        Judet Timis - 300225
        `;

        const downloadUrl = window.URL.createObjectURL(new Blob([dataForLabel]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'Etichetta-spedizione.txt'); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    render() {
        const { stateModal, toggle, titleModal, type, preferences, dataTemp, stock } = this.props;

        let deviceIds = [];
        let deviceIdsForLoad = [];

        for (let i = 0; i < dataTemp.qty; i++) {
            deviceIds.push(
                <div className="col-4" key={i}>
                    <TextField
                        name={"deviceId_" + i}
                        label={"Device ID " + (i + 1)}
                        value={this.state.deviceIds[i]}
                        onChange={this.setChangeDevice}
                    />
                </div>
            )
        }

        // se quantity è un campo non vuoto
        if (this.state.quantity !== '') {
            // crea nuovi campi in base alla quantity che trovo
            for (let i = 0; i < parseInt(this.state.quantity); i++) {
                deviceIdsForLoad.push(
                    <div className="col-4" key={i}>
                        <TextField
                            name={"deviceId_" + i}
                            label={"Device ID " + (i + 1)}
                            value={this.state.deviceIds[i]}
                            onChange={this.setChangeDevice}
                        />
                    </div>
                )
            }

            let devicesTemp = [];
            // crea un array vuoto in base alla quantity che l'utente inserisce
            for (let i = 0; i < deviceIdsForLoad.length; i++)
                devicesTemp.push("");

            this.deviceTempForLoad = devicesTemp
        }

        return (
            <Fragment>
                <Modal isOpen={stateModal} className="modalVpnStock">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        {
                            type === 'addMovement' &&
                            <FormControl>
                                <div className="row">
                                    <div className="col-md-4 col-sm-12 col-12">
                                        <FormControl>
                                            <InputLabel>Tipo di operazione</InputLabel>
                                            <Select
                                                name="operationType"
                                                value={this.state.operationType}
                                                onChange={this.setChange}
                                            >
                                                <MenuItem value="L"> Carica BOX </MenuItem>
                                                {
                                                    stock.available > 0 &&
                                                    <MenuItem value="U"> Vendi BOX </MenuItem>
                                                }

                                            </Select>
                                        </FormControl>
                                    </div>

                                    {
                                        this.state.operationType !== '' &&
                                        <Fragment>
                                            <div className="col-md-2 col-sm-12 col-12">
                                                <TextField
                                                    name="quantity"
                                                    label="Quantità"
                                                    value={this.state.quantity}
                                                    onChange={this.setChange}
                                                    type="number"
                                                />
                                            </div>
                                            {
                                                this.state.operationType !== 'L' &&
                                                <div className="col-md-4 col-sm-12 col-12">
                                                    <FormControl>
                                                        <InputLabel>Prodotto</InputLabel>
                                                        <Select
                                                            name="productName"
                                                            value={this.state.productName}
                                                            onChange={this.setChange}
                                                        >
                                                            {
                                                                preferences.listProductBoxHw.map((item, k) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={k}
                                                                            value={item.name}
                                                                        >
                                                                            {returnTypeName(item.type)} - {returnTypeName(item.name)}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            }
                                            <div className="col-md-2 col-sm-12 col-12">
                                                <TextField
                                                    name="discount"
                                                    label="Sconto"
                                                    value={this.state.discount}
                                                    onChange={this.setChange}
                                                    type="number"
                                                    inputProps={{ min: 0, max: 100 }}
                                                />
                                            </div>
                                        </Fragment>
                                    }
                                </div>

                                {
                                    this.state.operationType === 'U' &&
                                    <Fragment>
                                        <div className="row" style={{ 'marginBottom': '15px' }}>
                                            <div className="col-6">
                                                <TextField
                                                    name="paymentId"
                                                    label="ID Pagamento"
                                                    value={this.state.paymentId}
                                                    onChange={this.setChange}
                                                    disabled={this.state.checkedPayment}
                                                />
                                            </div>
                                            <div className="col-6" style={{ 'paddingTop': '15px' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={this.setChange}
                                                            checked={this.state.checkedPayment}
                                                            name="checkedPayment"
                                                        />
                                                    }
                                                    label="Oppure Crea Fattura"
                                                />
                                            </div>
                                        </div>

                                        {
                                            (this.state.quantity !== '') &&
                                            <Fragment>
                                                <h5 className="createDevice">
                                                    Inserisci gli ID device da vendere
                                                </h5>
                                                <div className="row" style={{ 'marginBottom': '15px' }}>
                                                    {deviceIdsForLoad}
                                                </div>
                                            </Fragment>
                                        }
                                    </Fragment>
                                }

                                {
                                    this.state.checkedPayment &&
                                    <Fragment>
                                        <h5 className="createPayment"> Crea Fattura</h5>
                                        <div className="row">
                                            <div className="col-md-4 col-sm-12 col-12">
                                                <FormControlLabel value="male" control={
                                                    <Radio
                                                        checked={this.state.selectRadio === 'azienda'}
                                                        onChange={this.setChange}
                                                        value="azienda"
                                                        name="selectRadio"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                    />
                                                } label="Azienda" />

                                                <FormControlLabel value="male" control={
                                                    <Radio
                                                        checked={this.state.selectRadio === 'privato'}
                                                        onChange={this.setChange}
                                                        value="privato"
                                                        name="selectRadio"
                                                        inputProps={{ 'aria-label': 'B' }}
                                                    />
                                                } label="Privato" />
                                            </div>
                                        </div>
                                    </Fragment>
                                }

                                {
                                    this.state.selectRadio !== '' &&
                                    <Fragment>
                                        <div className="row" style={{ 'marginBottom': '15px' }}>
                                            <div className="col-4">
                                                <TextField
                                                    name="surname"
                                                    label={this.state.selectRadio === 'azienda' ? "Ragione Sociale" : "Cognome"}
                                                    value={this.state.surname}
                                                    onChange={this.setChange}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <TextField
                                                    name="name"
                                                    label={this.state.selectRadio === 'azienda' ? "Nome Cognome" : "Nome"}
                                                    value={this.state.name}
                                                    onChange={this.setChange}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <TextField
                                                    name="code"
                                                    label={this.state.selectRadio === 'azienda' ? "Partita IVA" : "Codice Fiscale"}
                                                    value={this.state.code}
                                                    onChange={this.setChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="row" style={{ 'marginBottom': '15px' }}>
                                            <div className="col-4">
                                                <TextField
                                                    name="email"
                                                    label="Email"
                                                    value={this.state.email}
                                                    onChange={this.setChange}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <TextField
                                                    name="firstAddress"
                                                    label="Indirizzo 1"
                                                    value={this.state.firstAddress}
                                                    onChange={this.setChange}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <TextField
                                                    name="telephone"
                                                    label="Telefono"
                                                    value={this.state.telephone}
                                                    onChange={this.setChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="row" style={{ 'marginBottom': '15px' }}>
                                            <div className="col-2">
                                                <TextField
                                                    name="cap"
                                                    label="CAP"
                                                    value={this.state.cap}
                                                    onChange={this.setChange}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <TextField
                                                    name="province"
                                                    label="Provincia"
                                                    value={this.state.province}
                                                    onChange={this.setChange}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <FormControl>
                                                    <InputLabel>Country</InputLabel>
                                                    <Select
                                                        labelId="keyCountry"
                                                        name="country"
                                                        value={this.state.country}
                                                        onChange={this.setChange}
                                                    >
                                                        {
                                                            countries.map((item, k) => {
                                                                return (
                                                                    <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="col-4">
                                                <TextField
                                                    name="city"
                                                    label="Città"
                                                    value={this.state.city}
                                                    onChange={this.setChange}
                                                />
                                            </div>
                                        </div>
                                    </Fragment>
                                }
                            </FormControl>
                        }
                        {
                            type === 'associateTracking' &&
                            <FormControl>
                                <div className="row associateTracking">
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <TextField
                                            name="movId"
                                            label="Movimento ID"
                                            value={this.state.movId}
                                            onChange={this.setChange}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <TextField
                                            name="nameCourier"
                                            label="Nome Corriere"
                                            value={this.state.nameCourier}
                                            onChange={this.setChange}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            className="classForTracking"
                                            name="trackingNumber"
                                            label="Numero Spedizione"
                                            value={this.state.trackingNumber}
                                            onChange={this.setChange}
                                        />
                                    </div>
                                </div>

                                <div className="row associateTracking2 justify-content-center">
                                    <div className="col-12">
                                        <h6>Dati dell'ordine</h6>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <TextField
                                            label="Acquirente"
                                            value={dataTemp.payment.invoiceAddress.name + ' ' + dataTemp.payment.invoiceAddress.surname}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <TextField
                                            label="Email"
                                            value={dataTemp.payment.email}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </div>
                                    {
                                        !isNil(dataTemp.payment.shipping) &&
                                        <div className="col-md-8 col-sm-12 col-12">
                                            <TextField
                                                label="Indirizzo Spedizione"
                                                value={
                                                    dataTemp.payment.shipping.address.city + ' ('
                                                    + dataTemp.payment.shipping.address.province + ') - '
                                                    + dataTemp.payment.shipping.address.country + ' - '
                                                    + dataTemp.payment.shipping.address.firstAddress + ' - Telefono: '
                                                    + dataTemp.payment.shipping.address.telephone
                                                }
                                                inputProps={{ readOnly: true }}
                                            />
                                        </div>
                                    }
                                    <div className="col-md-4 col-sm-12 col-12">
                                        <TextField
                                            label="Quale prodotto sta acquistando"
                                            value={returnTypeName(dataTemp.payment.products[0].type) + ' - ' + returnTypeName(dataTemp.payment.products[0].name)}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-12 text-center" style={{ 'marginTop': '10px' }}>
                                        <a className="btn btn-warning" href={dataTemp.payment.invoiceUrl} download>
                                            <i className="fas fa-download" style={{ 'fontSize': '1.2em', 'padding': '0 5px' }} />
                                            Fattura: {dataTemp.payment.invoiceNumber}
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-12 text-center" style={{ 'marginTop': '10px' }}>
                                        {/* <a className="btn btn-warning" href={dataTemp.payment.invoiceUrl} download>
                                            <i className="fas fa-download" style={{ 'fontSize': '1.2em', 'padding': '0 5px' }} />
                                            Stampa etichetta spedizione
                                        </a> */}
                                        <button className="btn btn-warning" onClick={this.downloadShippingLabel}>
                                            Stampa etichetta spedizione
                                        </button>
                                    </div>
                                </div>

                                <div className="row associateTracking2">
                                    <div className="col-12">
                                        <h6>Assicurati di inserire correttamente tutti i campi prima di inviare i dati</h6>
                                    </div>

                                    {deviceIds}

                                </div>
                            </FormControl>
                        }
                        {
                            type === 'delete' &&
                            <div>
                                Sei sicuro di voler eliminare questo stock?
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.confirmSend}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}