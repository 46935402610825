import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';

import apm from './rum';
apm.setInitialPageLoadName("Nanomid_BackOffice_FrontEnd");

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);

root.render(<App />);
