import React, { Component } from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { promoActions, notifyActions } from '../../../store/actions';
import './style.scss';
import Loading from '../../../common/loading';

class tabExpanablePromo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            idPromo: this.props.data._id,
            description: this.props.data.description,
            startAt: this.props.data.startAt,
            endAt: this.props.data.endAt,
            maxItems: this.props.data.maxItems,
            discount: this.props.data.discount === null ? null : (this.props.data.discount * 100) + '%',
            discountValue: this.props.data.discountValue,
            priceValue: this.props.data.priceValue,
            groups: this.props.data.groups,
            toBuy: this.props.data.toBuy,
            productNames: this.props.data.productNames,
            countries: this.props.data.countries === null ? 'Nessun Paese Associato' : this.props.data.countries,
            startItems: this.props.data.startItems === null ? this.props.data.totalUses + "/ Illimitato" : this.props.data.totalUses + "/" + this.props.data.startItems,
            totalUses: this.props.data.totalUses,

            // variabili usate solo per mostrare i formati data in maniera corretta nelle caselle
            dateStartOnlyView: moment(this.props.data.startAt).format('DD-MM-YYYY') + ' alle ' + moment(this.props.data.startAt).format('LT'),
            dateEndOnlyView: this.props.data.endAt === null ? 'Illimitata' : moment(this.props.data.endAt).format('DD-MM-YYYY') + ' alle ' + moment(this.props.data.endAt).format('LT'),
            maxItemsOnlyView: this.props.data.maxItems === null ? 'Infinite volte' : this.props.data.maxItems,
        }
    }

    render() {
        return (
            <div className="tabExpanablePromo">

                {this.props.promo.isLoading && <Loading />}

                <FormControl>
                    <div className="row">
                        <div className="col-5">
                            <TextField
                                value={this.state.productNames}
                                label="Nome Prodotto"
                                InputProps={{ readOnly: true }}
                            />
                        </div>

                        <div className="col-5">
                            <TextField
                                value={this.state.description}
                                label="Descrizione"
                                InputProps={{ readOnly: true }}
                            />
                        </div>

                        {
                            this.state.discount !== null &&
                            <div className="col-2">
                                <TextField
                                    value={this.state.discount || ''}
                                    label="Percentuale di Sconto"
                                    InputProps={{ readOnly: true }}
                                />
                            </div>
                        }

                        {
                            this.state.discountValue !== null &&
                            <div className="col-2">
                                <TextField
                                    value={this.state.discountValue || ''}
                                    label="Sconto Intero"
                                    InputProps={{ readOnly: true }}
                                />
                            </div>
                        }

                        {
                            this.state.priceValue !== null &&
                            <div className="col-2">
                                <TextField
                                    value={this.state.priceValue || ''}
                                    label="Prezzo Fisso"
                                    InputProps={{ readOnly: true }}
                                />
                            </div>
                        }
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.state.idPromo}
                                label="ID Prodotto"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={this.state.dateStartOnlyView}
                                label="Creato il"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={this.state.dateEndOnlyView}
                                label="Fino al"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={this.state.countries || ''}
                                label="Paesi associati"
                                InputProps={{ readOnly: true }}
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.state.maxItemsOnlyView}
                                label="Utilizzi Rimanenti"
                                InputProps={{ readOnly: true }}
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={this.state.totalUses || ''}
                                label="Utilizzi Effettuati"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            {
                                !isEmpty(this.state.groups) &&
                                <div className="row" style={{ padding: '1.5vh' }}>
                                    <div className="col-12">
                                        <h6>Gruppi di appartenenza della promo:</h6>
                                        {
                                            this.state.groups.map(el => {
                                                return (
                                                    <span key={el}>
                                                        {`${el} - `}
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </FormControl>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        promo: state.promo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendEditPromo: (data) => {
            dispatch(promoActions.sendEditPromo(data))
        },
        deletePromo: (idPromo) => {
            dispatch(promoActions.deletePromo(idPromo))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(tabExpanablePromo);