import React, { Component, Fragment } from 'react';
import './style.scss';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Row, Col } from 'react-bootstrap';
import { API_GET_PLAYER_LIST_URL } from '../../config';
import axios from 'axios';

/**
 * Attivazione modale per aggiungere / modificare playlist al player
 * 
 *      "click" : se clicco OK, attivo una determinata funzione
 *      "titleModal" : il titolo della modale quando si apre
 *      "contentModal" : il contenuto che deve essere mostrato nella modale
 *      "userMail" : mail dell'utente a cui mandare la mail
 *      "toggle" : boolean che apre o chiude la modale
 *      "stateModal" : booleano che indica lo stato della modale (aperta o chiusa)
 */
export default class modalManageList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceId: this.props.deviceId !== '' ? this.props.deviceId : '',
            url: this.props.url !== '' ? this.props.url : '',
            title: this.props.title !== '' ? this.props.title : '',
            keyPlaylist: this.props.keyPlaylist !== '' ? this.props.keyPlaylist : '',

            deviceIdNull: false,
            titleNull: false,
            urlNull: false,

            // parametri per autocomplete
            listPlayers: [],
            activeSeeAutocomplete: false
        }
        this.setTitlePlaylist = this.setTitlePlaylist.bind(this);
        this.setUrlPlaylist = this.setUrlPlaylist.bind(this);
    }

    setUrlPlaylist = (event) => {
        this.setState({ url: event.target.value, urlNull: false });
    }

    setTitlePlaylist = (event) => {
        this.setState({ title: event.target.value, titleNull: false });
    }

    setDeviceIdPlaylist = (event) => {
        this.setState({ deviceId: event.target.value, deviceIdNull: false });
    }

    /**
     * Per attivare l'autocompletamento del device,
     * Carica tutti i device appena apri la modale
     */
    componentDidMount() {
        if (this.props.type === 'newPlaylist')
            this.getListDevice();
    }

    /**
     * Chiama il BE a ogni cambiamento di carattere dell'id device
     */
    setChangeId = (event) => {
        this.setState({ deviceId: event.target.value, deviceIdNull: false });

        if (event.target.value === '') {
            this.setState({ activeSeeAutocomplete: false });
        } else if (!this.state.activeSeeAutocomplete) {
            this.setState({ activeSeeAutocomplete: true });
        }

        setTimeout(() => {
            if (event.target.value.length > 5 && event.target.value.length < 12) {
                this.getListDevice();
            }
        }, 100);
    }

    /**
     * Setta il valore, al click dell'item autocomplete
     */
    setValueChangeId = (value) => {
        this.setState({
            newDeviceId: value,
            deviceId: value,
            activeSeeAutocomplete: false
        });
    }

    /**
     * Carica "solamente" 10 device per iniziare
     */
    getListDevice = async () => {
        try {
            let params = {
                'elementsByPage': '20',
                'filters[0][DeviceId]': this.state.deviceId,
            };

            let response = await axios.get(`${API_GET_PLAYER_LIST_URL}`, { params: params });

            let arrayTemp = [];

            Object.values(response.data.value.value).forEach((k) => {
                arrayTemp.push(k._id);
            })

            this.setState({ listPlayers: arrayTemp });


        } catch (result) {
            const { errorNotify } = this.props;
            if (result && result.response && result.response.status) {
                switch (result.response.status) {
                    default: errorNotify(`Error ${result.response.status} su ${API_GET_PLAYER_LIST_URL} - GET`); break;
                }
            }
        }
    }

    confirm = () => {
        const { type, clickEdit, clickNew, clickRemove, errorNotify } = this.props;

        if (type === 'delete') {
            clickRemove(this.state.keyPlaylist);
        }
        else {
            if (this.state.title !== '') {
                if (this.state.url !== '') {
                    if (type === 'new') {
                        clickNew(this.state.title, this.state.url);

                    } else if (type === 'newPlaylist') {
                        clickNew(this.state.title, this.state.url, this.state.deviceId);

                    } else if (type === 'edit') {
                        clickEdit(this.state.title, this.state.url, this.state.keyPlaylist);
                    }
                } else {
                    errorNotify('Inserisci un url!');
                    this.setState({ titleNull: false, urlNull: true });
                }
            } else {
                errorNotify('Inserisci il titolo!');
                this.setState({ titleNull: true, urlNull: false });
            }
        }
    }

    render() {
        const { stateModal, toggle, titleModal, type } = this.props;
        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalPlayer">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        {
                            type === 'delete' ?
                                <p>
                                    Sei sicuro di vuol rimuovere questa playlist?
                                </p>
                                :
                                <Form>
                                    {
                                        type === 'newPlaylist' &&
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="2" className="text-right">
                                                Device ID
                                            </Form.Label>
                                            <Col sm="10">
                                                <Form.Control
                                                    type="text"
                                                    value={this.state.deviceId}
                                                    onChange={this.setChangeId}
                                                />
                                                {
                                                    this.state.activeSeeAutocomplete &&
                                                    <ul className="autoCompleteDevice">
                                                        {
                                                            this.state.listPlayers.map((item, key) => {
                                                                return (
                                                                    <li key={key} onClick={() => this.setValueChangeId(item)} >
                                                                        {item}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                }
                                            </Col>
                                        </Form.Group>
                                    }
                                    <Form.Group as={Row} controlId="formPlaintextTitle">
                                        <Form.Label column sm="2" className="text-right">
                                            Title
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                type="text"
                                                value={this.state.title}
                                                onChange={this.setTitlePlaylist}
                                                isInvalid={this.state.titleNull}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextUrl">
                                        <Form.Label column sm="2" className="text-right">
                                            URL
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                type="text"
                                                value={this.state.url}
                                                onChange={this.setUrlPlaylist}
                                                isInvalid={this.state.urlNull}
                                            />
                                        </Col>
                                    </Form.Group>
                                </Form>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.confirm}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}