import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';

import './style.scss';
import FormFilter from '../components/formFilters';
import Date from '../../common/date';
import ModalBundle from '../../common/modal/modalBundle';
import Loading from '../../common/loading';
import ActionMenu from '../components/actionMenu';
import ExpanableComponent from '../components/expanableComponent';
import CSVExport from '../../common/csvExport';

import { randomString } from '../../common/actions/randomString';
import { returnTypeName } from '../../common/actions/returnTypeName';
import { controlRole } from '../../common/actions/controlRole';
import { isEmpty } from 'lodash';

export default class role extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            openFilters: true,
            openFiltersDesktop: false,

            shortId: randomString(),

            // contiene la lista dei bundle dopo la chiamata al BE
            getBundleList: [],

            // variabili che gestiscono l'apertura della modale
            hasClickNewBundle: false,
            hasClickEditBundle: false,

            // id temporaneo del bundle selezionato
            idBundleTemp: '',
            // dati temporanei del bundle selezionato
            dataBundleTemp: '',

            modal: false,
            titleModal: '',
            contentModal: '',
            type: '',

            loadingForRole: true

        }
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare i bundle, viene rispedito alla home
        if (!isEmpty(this.props.role.bundle)) {
            if (!controlRole(this.props.role.bundle, "api/Bundle", "GET")) {
                this.props.history.push('/');
                this.props.infoNotify('Non hai nessun permesso per visualizzare i bundle!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    // quando si crea o modifica un bundle, devo avere a disposizione tutti i prodotti nanomid nello store
                    this.props.getProductList();
                }
            }
        }
    }

    componentDidUpdate() {
        // se l'utente non ha nessun permesso per visualizzare le i bundle, viene rispedito alla home
        if (!isEmpty(this.props.role.bundle)) {
            if (!controlRole(this.props.role.bundle, "api/Bundle", "GET")) {
                this.props.history.push('/');
                this.props.infoNotify('Non hai nessun permesso per visualizzare i bundle!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    // quando si crea o modifica un bundle, devo avere a disposizione tutti i prodotti nanomid nello store
                    this.props.getProductList();
                }
            }
        }
    }

    /**
    * Chiude la modale
    */
    toggleModal = () => {
        this.setState({
            hasClickNewBundle: false,
            hasClickEditBundle: false,
            idBundleTemp: '',
            dataBundleTemp: '',
            shortId: randomString(),
            titleModal: '',
            contentModal: '',
            modalRole: false,
            modal: false,
            type: ''
        });
    }

    /**
    * Apre la modale di creazione bundle
    */
    hasClickNewBundle = () => {
        this.setState({
            hasClickNewBundle: true,
            titleModal: 'Nuovo Bundle',
            type: 'newBundle'
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
    * Apre la modale di modifica bundle
    * 
    * @param {Object} data
    */
    hasClickEditBundle = (data) => {
        this.setState({
            idBundleTemp: data.id,
            dataBundleTemp: data,
            hasClickEditBundle: true,
            titleModal: 'Modifica Bundle',
            type: 'editBundle'
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
    * Action menu per la modifica e la cancellazione di un bundle
    */
    actionClick = (action, data) => {
        switch (action) {
            case 'editBundle': this.hasClickEditBundle(data); break;
            default: break;
        }
    }

    setLoading = (value) => {
        this.setState({ isLoading: value });
    }

    render() {
        const { role, user, bundle, successNotify, errorNotify, warningNotify, setBundle, preferences } = this.props;

        const columns = [
            {
                name: 'Prodotto',
                selector: row => returnTypeName(row.deviceType) + ' ' + returnTypeName(row.productName),
                sortable: false,
            },
            {
                name: 'Creato il',
                selector: row => <Date format="L" date={row.createdAt} />,
                sortable: false,
            },
            {
                selector: row => <ActionMenu actionClick={this.actionClick} row={row} role={role} />,
                sortable: false,
                right: true,
                button: true,
                allowOverflow: true,
                wrap: true
            },
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                <div className="row">
                    <div className="col-12 bundle">
                        {(preferences.isLoading || bundle.isLoading || role.isLoading || this.state.isLoading || this.state.loadingForRole) && <Loading />}

                        <div className="contentFilterSearch">
                            <FormFilter
                                setLoading={this.setLoading}

                                user={user}
                                role={role}
                                bundle={bundle}
                                preferences={preferences}

                                getBundleList={this.props.getBundleList}

                                warningNotify={this.props.warningNotify}
                                infoNotify={this.props.infoNotify}
                                errorNotify={this.props.errorNotify}
                                successNotify={this.props.successNotify}
                            />
                        </div>

                        <div className="contentUser">
                            <div className="row">
                                <div className="col-6">
                                    <h2 className='inlineBlock'>Bundle</h2>
                                    {
                                        bundle.totalBundle &&
                                        <div className="mybadge">
                                            {bundle.totalBundle}
                                        </div>
                                    }
                                </div>

                                <div className="col-6 text-right">
                                    <CSVExport
                                        data={this.state.getBundleList}
                                        filename="list-bundle.csv"
                                        label="Scarica la lista del bundle"
                                    />
                                </div>

                                <div className="col-12 text-center">
                                    {
                                        !role.isLoading && controlRole(role.bundle, "api/Bundle", "PUT") &&
                                        <button className="btn btn-success addMarginForBtn" onClick={this.hasClickNewBundle}>
                                            <i className="fas fa-plus" style={{ 'marginRight': '10px' }}></i>
                                            Nuovo Bundle
                                        </button>
                                    }
                                </div>
                                <div className="col-4 text-right">
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <hr />
                                    <DataTable
                                        columns={columns}
                                        data={bundle.listBundle}
                                        customStyles={customStyles}
                                        noHeader
                                        striped
                                        highlightOnHover
                                        pointerOnHover
                                        clearSelectedRows
                                        expandableRows={this.state.isLoading ? false : true}
                                        expandOnRowClicked
                                        expandableRowsComponent={ExpanableComponent}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {
                    (this.state.hasClickNewBundle || this.state.hasClickEditBundle) &&
                    <ModalBundle
                        titleModal={this.state.titleModal}
                        contentModal={this.state.contentModal}

                        setBundle={setBundle}

                        toggle={this.toggleModal}
                        stateModal={this.state.modal}

                        dataBundle={this.state.dataBundleTemp}
                        type={this.state.type}

                        successNotify={successNotify}
                        errorNotify={errorNotify}
                        warningNotify={warningNotify}
                        bundle={bundle}
                        preferences={preferences}
                        key={this.state.shortId}
                    />
                }

            </Fragment >
        )
    }
}