import React, { Component, Fragment } from 'react';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { controlRole } from '../../../common/actions/controlRole';

export default class actionMenuPass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setOpen: false,
        };
    }

    toggle = () => {
        this.setState({ setOpen: !this.state.setOpen });
    }

    render() {
        const { role, vpnPass } = this.props;
        return (
            <Fragment>
                <ButtonDropdown isOpen={this.state.setOpen} toggle={this.toggle}>
                    <DropdownToggle caret>
                        <i className="fas fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu>
                        {
                            controlRole(role.vpnPass, "api/VpnPass", "DELETE", true) &&
                            <DropdownItem onClick={() => { this.props.actionClick('remove', vpnPass); }}>
                                <div className="row">
                                    <div className="col-3 icon deleteUser">
                                        <i className="far fa-trash-alt"></i>
                                    </div>
                                    <div className="col-9">
                                        Elimina
                                    </div>
                                </div>
                            </DropdownItem>
                        }
                    </DropdownMenu>
                </ButtonDropdown>

            </Fragment>
        )
    }
}