import React, { Component, Fragment } from 'react';
import './style.scss';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

/**
 * Attivazione modale tramite button
 * 
 *      "click" : se clicco OK, attivo una determinata funzione
 *      "titleModal" : il titolo della modale quando si apre
 *      "contentModal" : il contenuto che deve essere mostrato nella modale
 *      "cnModal" : se voglio aggiungere una classe per la modale
 *      "stateModal" : stato della modale (booleano)
 */
export default class index extends Component {
    render() {
        const { stateModal, toggle, titleModal, contentModal, cnModal, click } = this.props;
        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className={cnModal}>
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        {contentModal}
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={click}>OK</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}