import React, { Component } from 'react';
import { Fragment } from 'react';
import { controlRole } from '../../common/actions/controlRole';

import Loading from '../../common/loading/loadingInBlock';
import MyTooltip from '../../common/tooltip';

export default class jobTaskServices extends Component {
    contentTooltip = (item) => {
        return <ul>
            <li>Descrizione: {item.description}</li>
            <li>Input: {JSON.stringify(item.input)}</li>
        </ul>
    }

    contentTooltipSavedJob = (item) => {
        return <ul>
            <li>LoadAtStartUp: {item.loadAtStartUp ? "true" : "false"}</li>
            <li>Service Name: {item.serviceName}</li>
            <li>Service Input: {JSON.stringify(item.serviceInput)}</li>
            <li>Task Name: {item.taskName}</li>
            <li>Task Input: {JSON.stringify(item.taskInput)}</li>
        </ul>
    }

    /**
     * Controlla se il job salvato a db, è gia presente nei job attivi del worker.
     * Tramite una props (listActiveJobsName) possiamo vedere i job attivi sul worker.
     * Tramite parametro viene passato il nome da controllare.
     * La funzione restituisce un valore booleano. 
     * True se il nome è presente, false altrimenti.
     * 
     * @param {*} name 
     */
    controlActiveJob = (name) => {
        const { listActiveJobsName } = this.props;
        let x = 0;
        // eslint-disable-next-line
        listActiveJobsName.map(el => {
            if (name === el) x = 1;
        })
        if (x === 1) return true;
        else return false;
    }

    render() {
        const {
            loadingTask,
            listTasks,
            loadingServices,
            listServices,
            loadingJob,
            listJobs,
            activeJob,
            role
        } = this.props;


        return (
            <div className="jobTaskService">
                <h5> JOBS </h5>
                { (loadingJob && controlRole(role.worker, "api/Worker/SavedJobs", "GET")) && <Loading />}
                { (!loadingJob && controlRole(role.worker, "api/Worker/SavedJobs", "GET")) &&
                    <ul>
                        {
                            listJobs.map((item, key) => {
                                return (
                                    <li key={key}>
                                        <MyTooltip
                                            title={this.contentTooltipSavedJob(item)}
                                            position="right"
                                            content={
                                                <span>
                                                    {
                                                        this.controlActiveJob(item.jobId) ?
                                                            item.jobId
                                                            :
                                                            <Fragment>
                                                                {item.jobId}
                                                                {
                                                                    controlRole(role.worker, "api/Worker/ActiveJobs", "POST") &&
                                                                    <i className="far fa-play-circle iconStart" onClick={() => { activeJob(item.jobId) }}></i>
                                                                }
                                                            </Fragment>
                                                    }
                                                </span>
                                            }
                                        />
                                    </li>
                                );
                            })
                        }
                    </ul>
                }
                {
                    (loadingJob && !controlRole(role.worker, "api/Worker/SavedJobs", "GET")) &&
                    <Fragment>
                        Non hai nessun permesso per visionare i Job Salvati
                    </Fragment>
                }

                <hr></hr>

                <h5> TASK </h5>
                { (loadingTask && controlRole(role.worker, "api/Worker/Tasks", "GET")) && <Loading />}
                { (!loadingTask && controlRole(role.worker, "api/Worker/Tasks", "GET")) &&
                    <ul>
                        {
                            listTasks.map((item, key) => {
                                return (
                                    <li key={key}>
                                        <MyTooltip
                                            title={this.contentTooltip(item)}
                                            position="right"
                                            content={<span>{item.name}</span>}
                                        />
                                    </li>
                                );
                            })
                        }
                    </ul>
                }
                {
                    (loadingTask && !controlRole(role.worker, "api/Worker/Tasks", "GET")) &&
                    <Fragment>
                        Non hai nessun permesso per visionare i Tasks
                    </Fragment>
                }

                <hr></hr>
                <h5> SERVICES </h5>
                { (loadingServices && controlRole(role.worker, "api/Worker/Services", "GET")) && <Loading />}
                { (!loadingServices && controlRole(role.worker, "api/Worker/Services", "GET")) &&
                    <ul>
                        {
                            listServices.map((item, key) => {
                                return (
                                    <li key={key}>
                                        <MyTooltip
                                            title={this.contentTooltip(item)}
                                            position="right"
                                            content={<span>{item.name}</span>}
                                        />
                                    </li>
                                );
                            })
                        }
                    </ul>
                }
                {
                    (loadingServices && !controlRole(role.worker, "api/Worker/Services", "GET")) &&
                    <Fragment>
                        Non hai nessun permesso per visionare i Services
                    </Fragment>
                }
            </div>
        )
    }
}