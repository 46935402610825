import { connect } from 'react-redux'
import { playerActions, notifyActions } from '../store/actions';
import pagePlayers from './pages';

const mapStateToProps = state => {
    return {
        ...state,
        player: state.player,
        role: state.role,
        user: state.user
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getPlayerList: (downloadAllPlaylists, data) => {
            dispatch(playerActions.getPlayerList(downloadAllPlaylists, data))
        },
        createLicences: (data, filters) => {
            dispatch(playerActions.createLicences(data, filters))
        },
        removePlayer: (idPlayer) => {
            dispatch(playerActions.removePlayer(idPlayer))
        },
        sendEditPlayer: (data, action) => {
            dispatch(playerActions.sendEditPlayer(data, action))
        },
        setIdPlayerForDetail: (data) => {
            dispatch(playerActions.setIdPlayerForDetail(data))
        },
        refreshPlayer: (data) => {
            dispatch(playerActions.refreshPlayer(data))
        },
        sendMessagePlayer: (data) => {
            dispatch(playerActions.sendMessagePlayer(data))
        },
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(pagePlayers);