import axios from 'axios';
import store from '../index';
import { notifyActions, bundleActionType } from './';
import {
  API_BUNDLE
} from '../../config';

export const bundleActions = {
  setNumberBundle,
  getBundleList,
  setBundle,
};

/**
 * Setta nel payload un id di pagamento che è stato selezionato per visualizzarlo nel dettaglio
 * 
 * @param {String} totalBundle 
 */
function setNumberBundle(totalBundle) {
  return {
    type: bundleActionType.SET_NUMBER_BUNDLE,
    totalBundle
  }
}

/**
 * Ritorna la lista dei bundle.
 * 
 * @param {Object} data
 */
function getBundleList(data) {
  return (dispatch) => {
    dispatch({
      type: bundleActionType.GET_BUNDLE,
      async payload() {
        try {
          let response = await axios.get(API_BUNDLE, { params: data });
          dispatch(setNumberBundle(response.data.info.total));

          return {
            listBundle: response.data.value.value,
            totalPages: response.data.info.totalPages,
            paramsBundleTemp: data
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_BUNDLE} - GET`)); break;
            }
          }
          return {
            listBundle: [],
            totalPages: '',
            paramsBundleTemp: {}
          }
        }
      }
    })
  }
}

/**
 *Crea un nuovo bundle.
 * 
 * @param {Object} data
 */
function setBundle(data) {
  return (dispatch) => {
    dispatch({
      type: bundleActionType.SET_BUNDLE,
      async payload() {
        try {
          await axios.put(API_BUNDLE, data)
            .then(() => {
              dispatch(notifyActions.successAction('Bundle Aggiornato! 😉'));
              dispatch(getBundleList(store.getState().bundle.paramsBundleTemp));
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_BUNDLE} - PUT`)); break;
            }
          }
        }
      }
    })
  }
}