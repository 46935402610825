import React, { Component } from 'react';
import { Tooltip, Zoom } from '@material-ui/core';
import './style.scss';

/**
 * Tooltip di material design: https://material-ui.com/components/tooltips/
 * 
 * Parametri da passare:
 * - title (il contenuto da visualizzare nel tooltip)
 * - content (il contenuto su cui deve apparire la label)
 * - position (nel componente è il placement, cioe dove si vuole far apparire il tooltip)
 * 
 */
export default class index extends Component {
    render() {

        const { title, position, content } = this.props;

        return (
            <Tooltip
                title={title}
                placement={position}
                TransitionComponent={Zoom}
                arrow
            >
                {content}
            </Tooltip>
        )
    }
}