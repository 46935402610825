import { connect } from 'react-redux'
import { promoActions, notifyActions, groupActions, preferencesActions, paymentActions } from '../store/actions';
import pagePromo from './pages';

const mapStateToProps = state => {
    return {
        ...state,
        promo: state.promo,
        group: state.group,
        preferences: state.preferences,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // funzione per passare nella sezione pagamenti con un paymentID già selezionato
        setIdPaymentForDetail: (data) => {
            dispatch(paymentActions.setIdPaymentForDetail(data))
        },

        // funzioni per ottenere la lista dei gruppi e la lista dei prodotti e delle emails a DB
        getListDevice: (email) => {
            dispatch(promoActions.getListDevice(email))
        },
        getGroupList: () => {
            dispatch(groupActions.getGroupList())
        },
        getProductList: (data) => {
            dispatch(preferencesActions.getProductList(data))
        },
        // funzioni per gestire la creazione, la modifica, l'eliminazione e la visualizzazione delle PROMO e dei COUPON
        getPromoList: (data, isReset) => {
            dispatch(promoActions.getPromoList(data, isReset))
        },
        sendNewPromo: (data, isCoupon) => {
            dispatch(promoActions.sendNewPromo(data, isCoupon))
        },
        sendEditPromo: (data, isCoupon) => {
            dispatch(promoActions.sendEditPromo(data, isCoupon))
        },
        deletePromo: (data) => {
            dispatch(promoActions.deletePromo(data))
        },

        // funzioni per messaggi di notifica
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(pagePromo);