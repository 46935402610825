import React, { Component } from 'react';
import { NativeSelect, FormControl } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';

import { months } from '../../../common/actions/months';
import { days } from '../../../common/actions/days';
import Loading from '../../../common/loading/loadingInBlock';

export default class paymentOfMonth extends Component {
    render() {
        const { valueNations, dayPaired, monthPaired, onChange, labelsNations, loadingGraph4 } = this.props;

        const optionsMonthPayments = {
            responsive: true,
            layout: {
                padding: {
                    left: 15,
                    right: 25,
                    top: 10
                }
            },

            legend: {
                display: true,
                position: 'top',
                align: 'end'
            },

            plugins: {
                title: {
                    display: true,
                    text: 'Pagamenti al Giorno',
                    font: {
                        size: 20
                    }
                }
            },

            scales: {
                y: {
                    ticks: {
                        beginAtZero: true
                    }
                },
            }
        };

        const dataMonthPayments = {
            labels: labelsNations,
            datasets: [

                {
                    label: "Nazioni",
                    data: valueNations,
                    backgroundColor: "rgba(52,117,255,0.5)",
                    hoverBackgroundColor: "rgba(52,117,255,0.5)",
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                }
            ],
        };

        return (
            <div className="containerChart">
                <div className="homeChart">
                    <FormControl className="licenceMonth">
                        <NativeSelect
                            name="monthPaired"
                            value={monthPaired}
                            onChange={onChange}
                        >
                            {
                                months.map((item, k) => {
                                    return (
                                        <option className="colorMonth" key={k} value={item.value}>{item.label}</option>
                                    );
                                })
                            }
                        </NativeSelect>
                    </FormControl>

                    <FormControl className="licenceDay">
                        <NativeSelect
                            name="dayPaired"
                            value={dayPaired}
                            onChange={onChange}
                        >
                            {
                                days.map((item, k) => {
                                    return (
                                        <option className="colorMonth" key={k} value={item.value}>{item.label}</option>
                                    );
                                })
                            }
                        </NativeSelect>
                    </FormControl>
                    {
                        loadingGraph4 ? <Loading /> :
                            <Bar options={optionsMonthPayments} data={dataMonthPayments} />
                    }
                </div>
            </div>
        )
    }
}