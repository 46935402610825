import React, { Component } from 'react';
import { FormControl } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import Loading from '../../../common/loading/loadingInBlock';

export default class VpnCountry extends Component {
    render() {
        const { labelsVpnCountry, vpnOneMonthCountry, vpnThreeMonthsCountry, vpnSixMonthsCountry, vpnTwelveMonthsCountry, setDateIssuedStartVpnChange, setDateIssuedEndVpnChange, paymentIssuedAtStart, paymentIssuedAtEnd, loadingGraph11 } = this.props;

        const optionsVpnCountry = {
            responsive: true,
            layout: {
                padding: {
                    left: 15,
                    right: 25,
                    top: 10
                }
            },

            legend: {
                display: true,
                position: 'top',
                align: 'end'
            },

            plugins: {
                title: {
                    display: true,
                    text: 'Licenze VPN Pass per Paese',
                    font: {
                        size: 20
                    }
                }
            },

            scales: {
                y: {
                    ticks: {
                        beginAtZero: true
                    },
                    stacked: true
                },
                x: {
                    stacked: true
                }
            }
        };

        const dataVpnCountry = {
            labels: labelsVpnCountry,
            datasets: [
                {
                    label: "VPN Pass 1 Month",
                    backgroundColor: 'rgba(42,110,255, 0.5)',
                    data: vpnOneMonthCountry,
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "VPN Pass 3 Months",
                    backgroundColor: 'rgba(88,255,112,0.5)',
                    data: vpnThreeMonthsCountry,
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "VPN Pass 6 Months",
                    backgroundColor: 'white',
                    data: vpnSixMonthsCountry,
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },
                {
                    label: "VPN Pass 12 Months",
                    backgroundColor: 'rgba(255,166,59,0.5)',
                    data: vpnTwelveMonthsCountry,
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                }
            ],
        };

        return (
            <div className="containerChart">
                <div className="homeChartGains">
                    <FormControl className="licenceGains">
                        <div className="row">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="col-6">
                                    <KeyboardDatePicker
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        label="Inizo"
                                        value={paymentIssuedAtStart}
                                        onChange={setDateIssuedStartVpnChange}
                                    />
                                </div>

                                <div className="col-6">
                                    <KeyboardDatePicker
                                        variant="inline"
                                        label="Fine"
                                        value={paymentIssuedAtEnd}
                                        onChange={setDateIssuedEndVpnChange}
                                        // se la data di inizio è presente, disabilita tutte le date da quella data (compresa)
                                        minDate={paymentIssuedAtEnd ? moment(paymentIssuedAtStart).add(1, 'days')._d : false}
                                        minDateMessage="Attenzione! Data precedente a quella di inizio"
                                        format="dd/MM/yyyy"
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                    </FormControl>

                    {
                        loadingGraph11 ? <Loading /> :
                            <Bar options={optionsVpnCountry} data={dataVpnCountry} />
                    }

                </div>
            </div>
        )
    }
}