import { playerActionType } from '../actions';

const initialState = {
    isLoading: false,
    isLoadingChange: false,
    isLoadingTrial: false,
    idPlayerForDetail: '',
    listPlayer: [],
    paramsGetPlayerListTemp: {},
    totalPlayers: '',
    totalPages: '',
    hasNext: null,
    isComplete: null,

    listLicences: [],
    totalLicences: '',
    totalPagesLicences: '',
    elementForPageLicences: '',
    currentPageLicences: '',
    paramsGetLicenceListTemp: {},

    listPlaylist: [],
    totalPlaylist: '',
    totalPagesPlaylist: '',
    elementForPagePlaylist: '',
    currentPagePlaylist: '',
    paramsGetPlayListTemp: {}
}

export default function user(state = initialState, action = {}) {
    switch (action.type) {
        case `${playerActionType.SET_ID_PLAYER_FOR_DETAIL}`:
            return {
                ...state,
                idPlayerForDetail: action.idPlayerForDetail
            }
        case `${playerActionType.SEND_NEW_PLAYLIST}_PENDING`:
        case `${playerActionType.REFRESH_PLAYER}_PENDING`:
        case `${playerActionType.REMOVE_PLAYLIST}_PENDING`:
        case `${playerActionType.SEND_MESSAGE_PLAYER}_PENDING`:
        case `${playerActionType.GET_PLAYER}_PENDING`:
        case `${playerActionType.GET_PLAYER_LICENCE}_PENDING`:
        case `${playerActionType.GET_PLAYLIST}_PENDING`:
            return {
                ...state,
                isLoading: true
            }
        case `${playerActionType.CHANGE_DNS_TO_PLAYLIST}_PENDING`:
        case `${playerActionType.CREATE_NEW_LICENCE}_PENDING`:
            return {
                ...state,
                isLoadingChange: true
            }
        case `${playerActionType.GET_PLAYER}_FULFILLED`:
            return {
                ...state,
                isLoading: false,
                listPlayer: action.payload.listPlayer,
                totalPlayers: action.payload.totalPlayers,
                totalPages: action.payload.totalPages,
                hasNext: action.payload.hasNext,
                isComplete: action.payload.isComplete,
                paramsGetPlayerListTemp: action.payload.paramsGetPlayerListTemp
            }
        case `${playerActionType.GET_PLAYER_LICENCE}_FULFILLED`:
            return {
                ...state,
                isLoading: false,
                listLicences: action.payload.listLicences,
                totalLicences: action.payload.totalLicences,
                totalPagesLicences: action.payload.totalPagesLicences,
                elementForPageLicences: action.payload.elementForPageLicences,
                currentPageLicences: action.payload.currentPageLicences,
                paramsGetLicenceListTemp: action.payload.paramsGetLicenceListTemp
            }
        case `${playerActionType.GET_PLAYLIST}_FULFILLED`:
            return {
                ...state,
                isLoading: false,
                listPlaylist: action.payload.listPlaylist,
                totalPlaylist: action.payload.totalPlaylist,
                totalPagesPlaylist: action.payload.totalPagesPlaylist,
                elementForPagePlaylist: action.payload.elementForPagePlaylist,
                currentPagePlaylist: action.payload.currentPagePlaylist,
                paramsGetPlayListTemp: action.payload.paramsGetPlayListTemp
            }
        case `${playerActionType.SEND_NEW_PLAYLIST}_FULFILLED`:
        case `${playerActionType.REMOVE_PLAYLIST}_FULFILLED`:
        case `${playerActionType.REFRESH_PLAYER}_FULFILLED`:
        case `${playerActionType.SEND_MESSAGE_PLAYER}_FULFILLED`:
            return {
                ...state,
                isLoading: false
            }
        case `${playerActionType.CHANGE_DNS_TO_PLAYLIST}_FULFILLED`:
        case `${playerActionType.CREATE_NEW_LICENCE}_FULFILLED`:
            return {
                ...state,
                isLoadingChange: false
            }
        default:
            return state;
    }
}