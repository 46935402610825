/**
 * Dato un numero, restituisce lo stesso numero in modo da essere correttamente
 * formattato secondo le posizioni decimali.
 * 
 * ESEMPIO: 10000 => 10.000
 * ESEMPIO2: 1000000 => 1.000.000
 * 
 * @param {*} number 
 */
export var returnNumber = function (number) {
    let n = String(number);
    if (n > 3) {
        let cont = 0;
        let tempArrayString = '';
        let tempArrayString2 = '';
        for (let i = n.length - 1; i >= 0; i--) {
            if (cont % 3 === 0 && cont !== 0) {
                tempArrayString = tempArrayString + '.' + n[i]
            } else {
                tempArrayString = tempArrayString + n[i]
            }
            cont++;
        }
        for (let i = tempArrayString.length - 1; i >= 0; i--) {
            tempArrayString2 += tempArrayString[i];
        }
        return tempArrayString2;
    } else {
        return number;
    }
}