import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { Badge } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import DataTable from 'react-data-table-component';

import { API_VPN_BOX } from '../../../config';
import Loading from '../../../common/loading';
import SelectorCopy from '../../../common/selectorCopy';
import ExpanableVpnBox from './tabExpanableVpnBox';
import MyTooltip from '../../../common/tooltip';
import IconLicence from '../../../common/iconLicence';
import { returnNationality } from '../../../common/actions/returnNationality';

export default class tabVpnBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            listVpnBox: [],
            counterListVpnBox: ''
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.getListVpnBox();
        }, 500);
    }

    /**
     * La funzione prende in input l'id dell'utente selezionato (input preso in automatico dallo stato), 
     * e verifica le vpnBox associate ad esso
     * 
     */
    getListVpnBox = async () => {
        this.setState({ isLoading: true });
        try {
            let params = {
                'elementsByPage': '1000',
                'page': '1',
                'filters[0][User]': this.props.userId,
            };

            let response = await axios.get(`${API_VPN_BOX}`, { params: params });

            this.setState({
                isLoading: false,
                listVpnBox: response.data.value.value,
                counterListVpnBox: response.data.value.value.length
            });

        } catch (result) {
            const { errorNotify } = this.props;
            this.setState({ isLoading: false });
            if (result && result.response && result.response.status) {
                switch (result.response.status) {
                    default: errorNotify(`Error ${result.response.status} su ${API_VPN_BOX} - GET`); break;
                }
            }
        }
    }

    /**
     * Dato un ID device, vai alla schermata delle VPN Pass portando id vpnBox nei filtri
     */
     goToVpnPass = (idVpnPass) => {
        this.props.setKeyVpnPassForDetail(idVpnPass);
        this.props.history.push(`/vpn/pass`);
    }

    render() {
        const { history } = this.props;
        const columns = [
            {
                name: '',
                selector: row => <IconLicence typeIcon={row.isOnline ? 'online' : 'offline'} forOnline />,
                width: '60px',
            },
            {
                name: 'Serial',
                selector: row =>
                    <Fragment>
                        <span className="clickGoTo" onClick={() => { this.goToVpnPass(row.id) }} style={{ marginRight: '10px' }}>
                            <MyTooltip
                                title="Vai alla VPN Pass Associata"
                                position="top"
                                content={<i className="fas fa-share-square"></i>}
                            />
                        </span>
                        <SelectorCopy item={row.id} infoNotify={this.props.infoNotify} />
                    </Fragment>
            },
            {
                name: 'Description',
                selector: row => isEmpty(row.description) ? '-' : row.description
            },
            {
                name: 'Country',
                selector: row => isEmpty(row.country) ? '-' : returnNationality(row.country)
            }
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                {this.state.isLoading && <Loading />}
                <div className="row title">
                    <div className="col-4 col-sm-4 col-md-4 col-xl-4">
                        <Badge variant="warning">
                            Vpn Box: {this.state.counterListVpnBox}
                        </Badge>
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={this.state.listVpnBox}
                    customStyles={customStyles}
                    noHeader
                    striped
                    highlightOnHover
                    pointerOnHover
                    expandableRows
                    expandOnRowClicked
                    expandableRowsComponent={ExpanableVpnBox}
                    expandableRowsComponentProps={{
                        "setKeyVpnPassForDetail": this.props.setKeyVpnPassForDetail,
                        "history": history,
                    }}
                />

            </Fragment>
        )
    }
}