import { controlRole } from '../../common/actions/controlRole';
import { roleActionType } from '../actions';

const initialState = {
    isLoading: false,
    vpnPass: [],
    partners: [],
    product: [],
    promo: [],
    role: [],
    support: [],
    user: [],
    payment: [],
    player: [],
    preferences: [],
    vpnMachine: [],
    vpnPeer: [],
    vpnBox: [],
    vpnBoxStock: [],
    bundle: [],
    worker: [],
    changeLog: [],
    roleList: [],
    allRoleList: [],
    configFlush: [],
    statistics: true
};

export default function role(state = initialState, action) {
    switch (action.type) {
        case `${roleActionType.ASSIGN_ROLE}`:

            /**
             * Questa variabile mi dice se l'utente è abilitato a vedere o meno, una o nessuna statistica.
             */
            let statisticsTemp = true;
            if (
                controlRole(action.payload.user, "api/User/Statistics", "GET") ||
                controlRole(action.payload.player, "api/Player/Statistics", "GET") ||
                controlRole(action.payload.payment, "api/Payment/Statistics", "GET") ||
                controlRole(action.payload.vpnPass, "api/VpnPass/Statistics", "GET")
            ) statisticsTemp = true;
            else statisticsTemp = false;

            return {
                ...state,
                vpnPass: action.payload.vpnPass,
                partners: action.payload.partners,
                product: action.payload.product,
                promo: action.payload.promo,
                role: action.payload.role,
                support: action.payload.support,
                user: action.payload.user,
                payment: action.payload.payment,
                player: action.payload.player,
                preferences: action.payload.preferences,
                vpnMachine: action.payload.vpnMachine,
                vpnPeer: action.payload.vpnPeer,
                vpnBox: action.payload.vpnBox,
                vpnBoxStock: action.payload.vpnBoxStock,
                bundle: action.payload.bundle,
                worker: action.payload.worker,
                changeLog: action.payload.changeLog,
                allRoleList: action.payload.roleList,
                configFlush: action.payload.configFlush,
                statistics: statisticsTemp
            }
        case `${roleActionType.NEW_ROLE}_PENDING`:
        case `${roleActionType.UPDATE_ROLE}_PENDING`:
        case `${roleActionType.GET_ROLE}_PENDING`:
        case `${roleActionType.DELETE_ROLE}_PENDING`:
        case `${roleActionType.PATCH_ROLE}_PENDING`:
        case `${roleActionType.PATCH_UNASSIGN_ROLE}_PENDING`:
            return {
                ...state,
                isLoading: true
            }
        case `${roleActionType.NEW_ROLE}_FULFILLED`:
        case `${roleActionType.UPDATE_ROLE}_FULFILLED`:
        case `${roleActionType.DELETE_ROLE}_FULFILLED`:
        case `${roleActionType.PATCH_ROLE}_FULFILLED`:
        case `${roleActionType.PATCH_UNASSIGN_ROLE}_FULFILLED`:
            return {
                ...state,
                isLoading: false
            }
        case `${roleActionType.GET_ROLE}_FULFILLED`:
            return {
                ...state,
                isLoading: false,
                roleList: action.payload.roleList
            }
        default:
            return state;
    }
}