import { returnNationality } from '../../common/actions/returnNationality';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';

/**
 * Sanificazione dell'array di dati da mandare al BE durante la modifica di una PROMO.
 * viene preso l'oggetto creato dalla select e viene mappato solo il value in un array per mandare al BE il valore giusto.
 */
export function sanificationArray(value, name, groupsTemp) {
    let arrayTemp = [];
    if (!isNull(value) && !isEmpty(value)) {
        switch (name) {
            case 'countries':
                // eslint-disable-next-line
                value.map(el => {
                    arrayTemp.push(el.value);
                });

                return arrayTemp;

            case 'products':
                // eslint-disable-next-line
                value.map(el => {
                    arrayTemp.push(el.value);
                });

                return arrayTemp;

            case 'userGroup':
                if (value === groupsTemp) {
                    // eslint-disable-next-line
                    sanificationValueBE(value, 'userGroupEdit').map(el => {
                        arrayTemp.push(el.value);
                    });

                } else {
                    // eslint-disable-next-line
                    value.map(el => {
                        arrayTemp.push(el.value);
                    });
                }

                return arrayTemp;

            default: return [];
        }
    }
}

/**
* Sanificazione del valore che arriva dal BE per trasformarlo in un oggetto da passare alla select per la corretta visualizzazione dei vari dati usati nella multiSelect.
*/
export function sanificationValueBE(value, name) {
    let arrayTemp = [];
    let arrayTempTwo = [];

    if (!isNull(value) && !isEmpty(value)) {
        switch (name) {
            case 'countries':
                // eslint-disable-next-line
                value.map(el => {
                    arrayTemp.push({ label: returnNationality(el), value: el });
                });

                return arrayTemp;

            case 'products':
                // eslint-disable-next-line
                value.map(el => {
                    arrayTemp.push({ label: el, value: el });
                });

                return arrayTemp;

            case 'webUsers':
                arrayTempTwo.push(value);

                // eslint-disable-next-line
                arrayTempTwo.map(el => {
                    arrayTemp.push({ label: el, value: el });
                });

                return arrayTemp;

            /**
             * Per la creazione di una nuova Promo fatta da userInDetail.
             * Prendo il valore dal BE e lo metto in un array con un singolo elemento
             * per mostrarlo precompilato nella select.
             */
            case 'userInDetail':
                arrayTempTwo.push(value);

                // eslint-disable-next-line
                arrayTempTwo.map(el => {
                    arrayTemp.push(el);
                });

                return arrayTemp;
                

            case 'productList':
                // eslint-disable-next-line
                value.map(el => {
                    arrayTemp.push({ label: el.name, value: el.name });
                });

                return arrayTemp;

            case 'userGroup':
                // eslint-disable-next-line
                value.map(el => {
                    arrayTemp.push({ label: el.name, value: el.name });
                });

                return arrayTemp;

            case 'userGroupEdit':
                if (!isEmpty(value)) {
                    // eslint-disable-next-line
                    value.map(el => {
                        arrayTemp.push({ label: el, value: el });
                    });
                }

                return arrayTemp;

            default: return [];
        }
    }
}