import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { isEmpty, isNull } from 'lodash';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { FormGroup, FormControlLabel, Switch, FormControl, TextField, InputLabel, Input, InputAdornment, IconButton, Checkbox, Select, MenuItem, Radio } from '@material-ui/core';

import './style.scss';

import { countries } from '../countries';

import MultiSelect from 'react-select';
import makeAnimated from 'react-select/animated';

/**
 * Attivazione modale per aggiungere / modificare promo * 
 */
export default class modalCouponBuy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: '',
            discount: '',
            discountValue: '',
            priceValue: '',
            toBuy: null,
            startAt: null,
            endAt: null,
            productName: '',
            qty: 1,
            countries: null,
            totalUses: '',
            startItems: '',

            /**
             * Variabile Passata da userInDetails per capire se sto attivando il COUPON da li.
             * Se è true mi sto gestendo il COUPON da userInDetails, se è false la sto gestendo dalla sezione Promo/Coupon
             */
            isEditCouponUserInDetail: this.props.isEditCouponUserInDetail || false,

            descriptionNull: false,
            discountNull: false,
            toBuyNull: false,
            startItemsNull: false,
            emailFromListNull: false,
            startAtNull: false,
            endAtNull: false,
            productNameNull: false,
            qtyNull: false,
            countriesNull: false,

            unlimitedUse: false,
            unlimitedDate: false,

            disableDate: false,
            disableUse: false,
            disableField: false,

            listCoupon: false,

            /**
             * Array rispettivamente di countries e prodotti da mandare al BE durante la creazine di un COUPON.
             */
            countriesFromListArray: null,
            productsFromListArray: null,

            paymentId: '',
            checkedPayment: false,
            selectRadio: '',
            price: '',
            buyDiscount: '',

            email: '',
            isBusiness: true,
            isShipping: true,
            name: '',
            surname: '',
            code: '',
            firstAddress: '',
            secondAddress: '',
            telephone: '',
            cap: '',
            city: '',
            province: '',
            extraInfo: '',
            country: '',
            isFavorite: true,

            editCoupon: this.props.titleModal === 'Modifica Coupon',
        }
    }

    componentDidMount() {
        if (!this.state.isEditCouponUserInDetail) {
            this.props.listDevice();
            this.props.productList();
        }
    }

    /**
     * onChange utilizzata nella multiSelect quando seleziono i dati.
     * Quando seleziono i dati da mandare al BE le trasformo da oggetti {label: xx, value: xx}
     * a un array di value (valore accettato dal BE).
    */
    setDataFromOptionsList = (options, name) => {
        let arrayTemp = [];

        switch (name) {
            case 'countries':
                if (!isNull(options) || !isEmpty(options)) {
                    // eslint-disable-next-line
                    options.map(el => {
                        arrayTemp.push(el.value);
                    });
                }

                this.setState({
                    countriesFromListArray: arrayTemp,
                    countries: null
                });

                break;

            default:
                this.setState({
                    productsFromListArray: null,
                    countriesFromListArray: null,
                    countries: null
                });
                break;
        }
    }

    setChange = (event) => {
        if (event.target.name === 'checkedPayment') {
            this.setState({
                checkedPayment: !this.state.checkedPayment,
                paymentId: '',
                selectRadio: ''
            });
        } else if (event.target.name === 'productName') {
            this.setState({
                productName: event.target.value,
                price: this.returnPriceForProduct(event.target.value)
            });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }

        switch (event.target.name) {
            case 'description': this.setState({ descriptionNull: false }); break;
            case 'discount': this.setState({ discountNull: false }); break;
            case 'startItems': this.setState({ startItemsNull: false }); break;
            case 'productName': this.setState({ productNameNull: false }); break;
            default: break;
        }
    }

    setPromoTime = (newDate, name) => {
        switch (name) {
            case 'startAt':
                this.setState({
                    startAt: moment(newDate).toISOString(),
                    startAtNull: false
                });
                break;

            case 'endAt':
                this.setState({
                    endAt: moment(newDate).toISOString(),
                    endAtNull: false
                });
                break;

            default: break;
        }
    }

    /**
     * Setta lo stato del numero di usi dell'app in modo che sia illimitato
     */
    setUnlimitedUse = () => {
        this.setState({ unlimitedUse: !this.state.unlimitedUse })
        setTimeout(() => {
            if (this.state.unlimitedUse) {
                this.setState({
                    startItems: null,
                    disableUse: true
                });
            } else {
                this.setState({
                    startItems: '',
                    disableUse: false
                });
            }
        }, 300);
    }

    /**
     * Setta lo stato della data di fine in modo che la promo non scada mai
     */
    setUnlimitedDate = () => {
        this.setState({ unlimitedDate: !this.state.unlimitedDate });
        setTimeout(() => {
            if (this.state.unlimitedDate) {
                this.setState({
                    endAt: null,
                    disableDate: true
                });
            } else {
                this.setState({ disableDate: false });
            }
        }, 300);
    }

    setListCoupon = () => {
        this.setState({ listCoupon: !this.state.listCoupon });
    }

    confirm = () => {
        const { canPostCouponApi, infoNotify, errorNotify } = this.props;

        if (this.state.productName === '') {
            this.setState({ productNameNull: true });
            errorNotify('Prodotto al quale associare il Coupon obbligatorio');
        } else if (this.state.description === '') {
            this.setState({ descriptionNull: true });
            errorNotify('Descrizione del Coupon obbligatoria');
        } else if (this.state.startItems === '') {
            this.setState({ startItemsNull: true });
            errorNotify('Numero di volte di utilizzo del Coupon obbligatorio');
        } else if ((this.state.discount === '' || this.state.discount === null) && (this.state.discountValue === '' || this.state.discountValue === null) && (this.state.priceValue === '' || this.state.priceValue === null)) {
            this.setState({ discountNull: true });
            errorNotify('Sconto del Coupon obbligatoria');
        } else if (this.state.discount > 100) {
            this.setState({ discountNull: true });
            this.props.warningNotify('La percentuale del Coupon non puo superare i 100!');
        } else if (this.state.qty > 1000) {
            this.setState({ qtyNull: true });
            errorNotify('Il Coupon non può superare le 1000 unità!');
        } else if (this.state.qty < 1) {
            this.setState({ qtyNull: true });
            errorNotify('Il Coupon non può avere una quantità minore di 1!');
        } else if (this.state.startAt === null) {
            this.setState({ startAtNull: true });
            errorNotify('Data di inizio del Coupon obbligatoria');
        } else if (this.state.endAt === null && !this.state.unlimitedDate) {
            this.setState({ endAtNull: true });
            errorNotify('Data di fine del Coupon obbligatoria');
        } else if (this.state.checkedPayment && this.state.email === '') {
            errorNotify('Inserisci una mail');
        } else if ((!this.state.checkedPayment && this.state.paymentId === '') || this.state.selectRadio === '') {
            errorNotify('Dati per la fatturazione obbligatori');
        } else if (this.state.checkedPayment && this.state.name === '' && this.state.selectRadio === 'privato') {
            errorNotify('Inserisci un nome');
        } else if (this.state.checkedPayment && this.state.surname === '' && this.state.selectRadio === 'privato') {
            errorNotify('Inserisci un cognome');
        } else if (this.state.checkedPayment && this.state.surname === '' && this.state.selectRadio === 'azienda') {
            errorNotify('Inserisci una Ragione Sociale');
        } else if (this.state.checkedPayment && this.state.country === '') {
            errorNotify('Inserisci un country per la fattura');
        } else if (this.state.checkedPayment && this.state.code === '' && this.state.selectRadio === 'azienda') {
            errorNotify('Inserisci una Partita IVA');
        } else {
            // SOLO CREAZIONE
            let data = {
                webUser: null,
                description: this.state.description,
                startAt: this.state.startAt,
                endAt: this.state.endAt,
                productNames: [this.state.productName],
                discount: this.state.discount === '' ? null : (this.state.discount * 0.01).toFixed(2),
                discountValue: this.state.discountValue === '' ? null : parseFloat(this.state.discountValue).toFixed(2),
                priceValue: this.state.priceValue === '' ? null : parseFloat(this.state.priceValue).toFixed(2),
                qty: this.state.qty,
                listCoupon: this.state.listCoupon,
                countries: this.state.countriesFromListArray,
                startItems: this.state.startItems,
                totalUses: this.state.totalUses,
                paymentId: this.state.paymentId === '' ? null : this.state.paymentId,
                paymentFrom: {
                    email: this.state.email,
                    address: {
                        isBusiness: this.state.selectRadio === 'azienda',
                        isShipping: this.state.isShipping,
                        name: this.state.name,
                        surname: this.state.surname,
                        code: this.state.code,
                        firstAddress: this.state.firstAddress,
                        secondAddress: this.state.secondAddress,
                        telephone: this.state.telephone,
                        cap: this.state.cap,
                        city: this.state.city,
                        province: this.state.province,
                        extraInfo: this.state.extraInfo,
                        country: this.state.country,
                        isFavorite: this.state.isFavorite
                    }
                },
                price: this.state.price,
                buyDiscount: (this.state.buyDiscount * 0.01).toFixed(2)
            };

            if (canPostCouponApi) {
                this.props.clickNew(data, true);
            } else {
                infoNotify('Non hai i permessi per creare un coupon!');
            }
            this.props.toggle();
        }
    }

    /**
     * Ritorna il prezzo associato al nome del prodotto che abbiamo in listino.
     * Serve solo a mostrarlo all'utente, poi il BE metterà i prezzi in automatico, 
     * prendendoli dal listino.
     * 
     * @param {*} item 
     */
    returnPriceForProduct = (item) => {
        const { preferences } = this.props;
        let price;
        // eslint-disable-next-line
        preferences.allProducts.map(el => {
            if (el.name === item) {
                price = el.price;
            }
        })
        return price;
    }

    render() {
        const { stateModal, toggle, titleModal, preferences } = this.props;
        const animatedComponents = makeAnimated();
        return (
            <Fragment>
                <Modal isOpen={stateModal} className="modalCoupon">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormControl>
                                <div className="row">
                                    <div className="col-md-4 col-sm-12 col-12">
                                        <FormControl>
                                            <InputLabel>Seleziona il prodotto</InputLabel>
                                            <Select
                                                name="productName"
                                                value={this.state.productName}
                                                onChange={this.setChange}
                                            >
                                                {
                                                    preferences.allProducts.map((el, key) => {
                                                        return (
                                                            <MenuItem value={el.name} key={key}> {el.name} </MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className="col-md-4 col-sm-12 col-12">
                                        <TextField
                                            name="description"
                                            label="Descrizione"
                                            value={this.state.description}
                                            onChange={this.setChange}
                                            error={this.state.descriptionNull}
                                        />
                                    </div>

                                    <div className="col-md-4 col-sm-12 col-12">
                                        <TextField
                                            name="price"
                                            label="Prezzo del prodotto"
                                            value={this.state.price}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </div>
                                </div>
                            </FormControl>

                            <FormControl>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <InputLabel htmlFor="standard-adornment-switch1" style={{ marginLeft: '15px' }}>Quanti utilizzi?</InputLabel>
                                        <Input
                                            // className="passwordVisibility"
                                            id="standard-adornment-switch1"
                                            name="startItems"
                                            type="number"
                                            value={this.state.startItems}
                                            onChange={this.setChange}
                                            error={this.state.startItemsNull}
                                            disabled={this.state.disableUse || this.state.unlimitedUse} // il controllo con maxItems è fatto per evitare la modifica degli usi a dispozione prima della scadenza del COUPON
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton className="iconVisibility"
                                                        aria-label="toggle password visibility"
                                                        onClick={this.changeShowPassword}
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.unlimitedUse}
                                                                        onChange={this.setUnlimitedUse}
                                                                        value="unlimitedUse"
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Illimitato"
                                                            />
                                                        </FormGroup>
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </div>

                                    <div className="col-md-2 col-sm-12 col-12">
                                        <TextField
                                            name="qty"
                                            type="number"
                                            label="Quantità"
                                            value={this.state.qty}
                                            onChange={this.setChange}
                                            error={this.state.qtyNull}
                                            inputProps={{
                                                readOnly: this.state.editCoupon,
                                                min: 0,
                                                max: 100
                                            }}
                                        />
                                    </div>
                                </div>
                            </FormControl>

                            <FormControl>
                                <div className='row'>
                                    <div className='col-md-4 col-sm-12 col-12'>
                                        <TextField
                                            name="discount"
                                            type="number"
                                            label="Sconto (%)"
                                            value={this.state.discount}
                                            onChange={this.setChange}
                                            error={this.state.discountNull}
                                            disabled={this.state.discountValue !== '' || this.state.priceValue !== ''}
                                            inputProps={{
                                                min: 0,
                                                max: 100
                                            }}
                                        />
                                    </div>

                                    <div className='col-md-4 col-sm-12 col-12'>
                                        <TextField
                                            name="discountValue"
                                            type="number"
                                            label="Sconto (int)"
                                            value={this.state.discountValue}
                                            onChange={this.setChange}
                                            error={this.state.discountNull}
                                            disabled={this.state.discount !== '' || this.state.priceValue !== ''}
                                            inputProps={{
                                                min: 0
                                            }}
                                        />
                                    </div>

                                    <div className='col-md-4 col-sm-12 col-12'>
                                        <TextField
                                            name="priceValue"
                                            type="number"
                                            label="Prezzo Fisso"
                                            value={this.state.priceValue}
                                            onChange={this.setChange}
                                            error={this.state.discountNull}
                                            disabled={this.state.discount !== '' || this.state.discountValue !== ''}
                                            inputProps={{
                                                min: 0
                                            }}
                                        />
                                    </div>
                                </div>
                            </FormControl>

                            <FormControl>
                                <div className="row">
                                    {
                                        !this.state.isEditCouponUserInDetail &&
                                        <div className="col-md-6 col-sm-12 col-12 downSelect">
                                            <MultiSelect
                                                placeholder="Seleziona Paese"
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                isMulti
                                                name="countries"
                                                defaultValue={
                                                    this.state.editCoupon ? this.state.countries : undefined
                                                }
                                                options={countries}
                                                onChange={options => this.setDataFromOptionsList(options, 'countries')}
                                            />
                                        </div>
                                    }

                                    {
                                        !this.state.editCoupon &&
                                        <div className="col-md-4 col-sm-12 col-12 downLabel">
                                            <FormGroup>
                                                <FormControlLabel
                                                    label="Download Coupons"
                                                    control={
                                                        <Switch
                                                            checked={this.state.listCoupon}
                                                            disabled={this.state.editCoupon}
                                                            onChange={this.setListCoupon}
                                                            value="listCoupon"
                                                            color="primary"
                                                        />
                                                    }
                                                />
                                            </FormGroup>
                                        </div>
                                    }
                                </div>
                            </FormControl>

                            <FormControl>
                                <div className="row">

                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="col-md-4 col-sm-12 col-12">
                                            <KeyboardDatePicker
                                                id="dateStartAtPromo"
                                                variant="inline"
                                                label="Inizio Promo"
                                                value={this.state.startAt || null}
                                                onChange={newDate => this.setPromoTime(newDate, 'startAt')}
                                                format="dd/MM/yyyy"
                                            />
                                        </div>

                                        <div className="col-md-4 col-sm-12 col-12">
                                            <KeyboardDatePicker
                                                id="dateEndAtPromo"
                                                variant="inline"
                                                label="Fine Promo"
                                                value={this.state.endAt || null}
                                                onChange={newDate => this.setPromoTime(newDate, 'endAt')}
                                                disabled={this.state.disableDate || this.state.unlimitedDate}
                                                // se la data di inizio è presente, disabilita tutte le date da quella data (compresa)
                                                minDate={this.state.startAt ? moment(this.state.startAt).add(1, 'days')._d : false}
                                                minDateMessage="Attenzione! Data precedente a quella di inizio"
                                                format="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="col-md-2 col-sm-12 col-12 downLabel">
                                            <FormGroup>
                                                <FormControlLabel
                                                    className="positionSwitch"
                                                    control={
                                                        <Switch
                                                            checked={this.state.unlimitedDate}
                                                            onChange={this.setUnlimitedDate}
                                                            value="unlimitedDate"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Illimitato"
                                                // labelPlacement="bottom"
                                                />
                                            </FormGroup>
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </FormControl>

                            <FormControl>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <TextField
                                            name="paymentId"
                                            label="ID Pagamento"
                                            value={this.state.paymentId}
                                            onChange={this.setChange}
                                            disabled={this.state.checkedPayment}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12" style={{ 'paddingTop': '15px' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={this.setChange}
                                                    checked={this.state.checkedPayment}
                                                    name="checkedPayment"
                                                />
                                            }
                                            label="Oppure Crea Fattura"
                                        />
                                    </div>
                                </div>
                            </FormControl>

                            {
                                this.state.checkedPayment &&
                                <Fragment>
                                    <h5 className="createPayment">Crea Fattura</h5>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-12">
                                            <FormControlLabel value="male" control={
                                                <Radio
                                                    checked={this.state.selectRadio === 'azienda'}
                                                    onChange={this.setChange}
                                                    value="azienda"
                                                    name="selectRadio"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                />
                                            } label="Azienda" />

                                            <FormControlLabel value="male" control={
                                                <Radio
                                                    checked={this.state.selectRadio === 'privato'}
                                                    onChange={this.setChange}
                                                    value="privato"
                                                    name="selectRadio"
                                                    inputProps={{ 'aria-label': 'B' }}
                                                />
                                            } label="Privato" />
                                        </div>
                                    </div>
                                </Fragment>
                            }

                            {
                                this.state.selectRadio !== '' &&
                                <Fragment>
                                    <div className="row" style={{ 'marginBottom': '15px' }}>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <TextField
                                                type="number"
                                                name="buyDiscount"
                                                label="Sconto sul prezzo"
                                                value={this.state.buyDiscount}
                                                inputProps={{ min: 0, max: 100 }}
                                                onChange={this.setChange}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <TextField
                                                name="buyDiscount"
                                                label="Prezzo scontato"
                                                value={parseFloat((this.state.price - ((this.state.price * this.state.buyDiscount) / 100)).toFixed(2))}
                                                inputProps={{ readOnly: true }}
                                            />
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-12">
                                            <TextField
                                                name="surname"
                                                label={this.state.selectRadio === 'azienda' ? "Ragione Sociale" : "Cognome"}
                                                value={this.state.surname}
                                                onChange={this.setChange}
                                            />
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-12">
                                            <TextField
                                                name="name"
                                                label={this.state.selectRadio === 'azienda' ? "Nome Cognome" : "Nome"}
                                                value={this.state.name}
                                                onChange={this.setChange}
                                            />
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-12">
                                            <TextField
                                                name="code"
                                                label={this.state.selectRadio === 'azienda' ? "Partita IVA" : "Codice Fiscale"}
                                                value={this.state.code}
                                                onChange={this.setChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row" style={{ 'marginBottom': '15px' }}>
                                        <div className="col-md-4 col-sm-12 col-12">
                                            <TextField
                                                name="email"
                                                label="Email"
                                                value={this.state.email}
                                                onChange={this.setChange}
                                            />
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-12">
                                            <TextField
                                                name="firstAddress"
                                                label="Indirizzo 1"
                                                value={this.state.firstAddress}
                                                onChange={this.setChange}
                                            />
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-12">
                                            <TextField
                                                name="telephone"
                                                label="Telefono"
                                                value={this.state.telephone}
                                                onChange={this.setChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row" style={{ 'marginBottom': '15px' }}>
                                        <div className="col-md-2 col-sm-12 col-12">
                                            <TextField
                                                name="cap"
                                                label="CAP"
                                                value={this.state.cap}
                                                onChange={this.setChange}
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-12">
                                            <TextField
                                                name="province"
                                                label="Provincia"
                                                value={this.state.province}
                                                onChange={this.setChange}
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-12">
                                            <FormControl>
                                                <InputLabel>Country</InputLabel>
                                                <Select
                                                    labelId="keyCountry"
                                                    name="country"
                                                    value={this.state.country}
                                                    onChange={this.setChange}
                                                >
                                                    {
                                                        countries.map((item, k) => {
                                                            return (
                                                                <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                                            );
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-12">
                                            <TextField
                                                name="city"
                                                label="Città"
                                                value={this.state.city}
                                                onChange={this.setChange}
                                            />
                                        </div>
                                    </div>
                                </Fragment>
                            }

                        </Form>
                    </ModalBody>

                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.confirm}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment >
        )
    }
}