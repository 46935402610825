import { init as initApm } from '@elastic/apm-rum';
import { ENVIRONMENT_APM } from './config';

var apm = initApm({
    serviceName: 'Nanomid_BackOffice_FrontEnd',
    // serviceVersion: '0.90',
    // secretToken: 'XNoO856C5hJfovn0wz',
    serverUrl: 'https://fbd5d28c694e4c87b984715e39c67786.apm.westeurope.azure.elastic-cloud.com:443',
    environment: ENVIRONMENT_APM
});

export default apm;