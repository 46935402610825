import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Select, FormControl, MenuItem, InputLabel, TextField } from '@material-ui/core';
import { Tabs, Tab } from 'react-bootstrap';

import './style.scss';
import { MAINTENANCE } from '../../config';
import TabModalChange from './tabModalChange';

export default class modalChangeLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typeChangeLog: this.props.typeChangeLog || '',

            newTypeChangeLog: '',
            dateCreation: null,
            version: this.props.version || '',
            oldVersion: this.props.version || '',
            createdAt: this.props.dateVersion || null,
            logsIt: this.props.type === 'editVersionLog' ? this.props.logsIt : '',
            logsEn: this.props.type === 'editVersionLog' ? this.props.logsEn : '',
            logsEs: this.props.type === 'editVersionLog' ? this.props.logsEs : '',
            logsDe: this.props.type === 'editVersionLog' ? this.props.logsDe : '',
            logsFr: this.props.type === 'editVersionLog' ? this.props.logsFr : '',
        }
    }

    /**
     * Rimuove gli spazi bianchi all'inizio e alla fine di una stringa( gli spazi tra due parole restano es. 'Prova prova ').
     */
    removeWhiteSpace = (roleName) => {
        let removeSpace = roleName.trim();
        return removeSpace;
    }

    confirmSend = () => {
        const { type, newChangeLog, errorNotify, toggle, editVersionLog, editWarning } = this.props;

        /* regex che controlla il formato della versione (X.X.X). possono essere inserite fino a 3 cifre prima del punto 
           (es. XXX.X.XX è un formato valido, XXXX.X. non è un formato valido) */
        const regex = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/;
        const valid = regex.test(this.state.version);

        switch (type) {
            case 'newChangeLog':
                if (this.state.newTypeChangeLog === '') {
                    errorNotify('Inserisci un nome per aggiungere un nuovo prodotto!');
                } else {
                    newChangeLog(this.state.newTypeChangeLog);
                    toggle();
                }
                break;

            case 'newVersionLog':
            case 'editVersionLog':
                if (this.state.logsIt === '') {
                    errorNotify('Inserisci i logs italiani!');
                } else if (this.state.logsEn === '') {
                    errorNotify('Inserisci i logs inglesi!');
                } else if (this.state.logsEs === '') {
                    errorNotify('Inserisci i logs spagnoli!');
                } else if (this.state.logsDe === '') {
                    errorNotify('Inserisci i logs tedeschi!');
                } else if (this.state.logsFr === '') {
                    errorNotify('Inserisci i logs francesi!');
                } else if (this.state.version === '') {
                    errorNotify('Inserisci la versione!');
                } else if (this.state.createdAt === null) {
                    errorNotify('Inserisci la data di creazione!');
                } else {
                    if (!valid) {
                        errorNotify('La versione deve essere in formato X.X.X');
                    } else {
                        if(this.state.typeChangeLog === MAINTENANCE) {
                            editWarning({
                                "productName": this.state.typeChangeLog,
                                "oldVersion": this.state.oldVersion,
                                "version": this.state.version,
                                "createdAt": this.state.createdAt,
                                "logLanguage": {
                                    "it-IT": this.removeWhiteSpace(this.state.logsIt),
                                    "en-GB": this.removeWhiteSpace(this.state.logsEn),
                                    "es-SP": this.removeWhiteSpace(this.state.logsEs),
                                    "de-DE": this.removeWhiteSpace(this.state.logsDe),
                                    "fr-FR": this.removeWhiteSpace(this.state.logsFr)
                                }
                            });
                        } else {
                            editVersionLog({
                                "productName": this.state.typeChangeLog,
                                "version": this.state.version,
                                "createdAt": this.state.createdAt,
                                "logLanguage": {
                                    "it-IT": this.removeWhiteSpace(this.state.logsIt),
                                    "en-GB": this.removeWhiteSpace(this.state.logsEn),
                                    "es-SP": this.removeWhiteSpace(this.state.logsEs),
                                    "de-DE": this.removeWhiteSpace(this.state.logsDe),
                                    "fr-FR": this.removeWhiteSpace(this.state.logsFr)
                                }
                            });
                        }
                        toggle();
                    }
                }
                break;

            default: break;
        }
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    setDate = (newDate) => {
        this.setState({ createdAt: newDate });
    }

    render() {
        const { stateModal, toggle, titleModal, type, typeChangeLog } = this.props;
        return (
            <Fragment>
                <Modal isOpen={stateModal} className="modalChangeLog">
                    <ModalHeader toggle={toggle}>
                        {
                            this.state.typeChangeLog === MAINTENANCE ? 'WARNING SUL SITO' :
                                titleModal
                        }
                    </ModalHeader>
                    <ModalBody>
                        {
                            type === 'newChangeLog' &&
                            <div className="row justify-content-center">
                                <div className="col-5">
                                    <TextField
                                        name="newTypeChangeLog"
                                        label="Nuovo Prodotto"
                                        value={this.state.newTypeChangeLog}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        }

                        {
                            (type === 'newVersionLog' || type === 'editVersionLog') &&
                            <Fragment>
                                <div className="row justify-content-center">
                                    {
                                        this.state.typeChangeLog === MAINTENANCE &&
                                        <div className="col-6">
                                            <FormControl>
                                                <InputLabel>Colore dell'ALERT</InputLabel>
                                                <Select
                                                    labelId="version"
                                                    name="version"
                                                    value={this.state.version}
                                                    onChange={this.onChange}
                                                >
                                                    <MenuItem value="0.0.1">ROSSO</MenuItem>
                                                    <MenuItem value="0.0.2">VERDE</MenuItem>
                                                    <MenuItem value="0.0.3">GIALLO</MenuItem>
                                                    <MenuItem value="0.0.4">BLUE</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    }
                                    {
                                        this.state.typeChangeLog !== MAINTENANCE &&
                                        <Fragment>
                                            <div className="col-6">
                                                <TextField
                                                    name="version"
                                                    // label={this.state.typeChangeLog !== MAINTENANCE ? "Versione" : "Colore Background"}
                                                    label="Versione"
                                                    value={this.state.version}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        id="dateCreation"
                                                        variant="inline"
                                                        label="Data Creazione Commit"
                                                        value={this.state.createdAt}
                                                        onChange={this.setDate}
                                                        format="dd/MM/yyyy"
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </Fragment>
                                    }
                                </div>

                                <Tabs defaultActiveKey="Italiano" id="uncontrolled-tab-changelog" style={{ 'marginTop': '25px' }}>
                                    <Tab eventKey="Italiano" title="Italiano">
                                        <TabModalChange
                                            type={type}
                                            typeChangeLog={typeChangeLog}
                                            logsIt={this.state.logsIt}
                                            onChange={this.onChange}
                                            language="it-IT"
                                        />
                                    </Tab>
                                    <Tab eventKey="Inglese" title="Inglese">
                                        <TabModalChange
                                            type={type}
                                            typeChangeLog={typeChangeLog}
                                            logsEn={this.state.logsEn}
                                            onChange={this.onChange}
                                            language="en-GB"
                                        />
                                    </Tab>
                                    <Tab eventKey="Spagnolo" title="Spagnolo">
                                        <TabModalChange
                                            type={type}
                                            typeChangeLog={typeChangeLog}
                                            logsEs={this.state.logsEs}
                                            onChange={this.onChange}
                                            language="es-SP"
                                        />
                                    </Tab>
                                    <Tab eventKey="Tedesco" title="Tedesco">
                                        <TabModalChange
                                            type={type}
                                            typeChangeLog={typeChangeLog}
                                            logsDe={this.state.logsDe}
                                            onChange={this.onChange}
                                            language="de-DE"
                                        />
                                    </Tab>
                                    <Tab eventKey="Francese" title="Francese">
                                        <TabModalChange
                                            type={type}
                                            typeChangeLog={typeChangeLog}
                                            logsFr={this.state.logsFr}
                                            onChange={this.onChange}
                                            language="fr-FR"
                                        />
                                    </Tab>
                                </Tabs>

                            </Fragment>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.confirmSend}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}