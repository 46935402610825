import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { Badge } from 'react-bootstrap';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';

import ActionMenuPromo from './actionMenuPromo';
import ModalPromo from '../../../common/modal/modalPromo';
import ModalCoupon from '../../../common/modal/modalCouponGenerate';
import Modal from '../../../common/modal';
import Loading from '../../../common/loading';
import ExpanablePromo from './tabExpanablePromo';
import { controlRole } from '../../../common/actions/controlRole';
import MyTooltip from '../../../common/tooltip';
import SelectorCopy from '../../../common/selectorCopy';

export default class tabPromo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,

            // quante promo visualizzare
            numberPromo: '25',

            // numerpo di pagina selezionato
            currentNumberPage: '1',
            changeNumberPage: false,

            modal: false,
            modalDelete: false,
            hasClickNewPromo: false,
            hasClickEditPromo: false,
            hasClickEditCoupon: false,
            hasClickDeletePromo: false,

            idPromo: '',
            promoTemp: '',
            isNewPromoUserInDetail: false,
            isEditPromoUserInDetail: false,
            isEditCouponUserInDetail: false,

        }
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleModalDelete = this.toggleModalDelete.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            this.getPromoList();
        }, 500);
    }

    toggleModal = () => {
        this.setState(prevState => ({ modal: !prevState.modal }));
        if (this.state.modal) {
            this.setState({
                hasClickNewPromo: false,
                hasClickEditPromo: false,
                hasClickEditCoupon: false,
                hasClickDeletePromo: false,
            });
        }
    }

    toggleModalDelete = () => {
        this.setState(prevState => ({ modalDelete: !prevState.modalDelete }));
        if (this.state.modalDelete) {
            this.setState({
                hasClickNewPromo: false,
                hasClickEditPromo: false,
                hasClickEditCoupon: false,
                hasClickDeletePromo: false,
            });
        }
    }

    /**
     * Attiva la modale per inserire una nuova promo
     * promoTemp resetto il valore dei data della promo prima di aprirla
     */
    activeNewPromo = () => {
        this.setState({
            hasClickNewPromo: true,
            isNewPromoUserInDetail: true,
            hasClickEditCoupon: false,
            isEditPromoUserInDetail: false,
            isEditCouponUserInDetail: false,
            modal: true,
            promoTemp: '',
        });
    }

    /**
     * Attiva la modale per la modifica della promo
     */
    activeEditPromo = (idPromo, dataPromo) => {
        this.setState({
            idPromo: idPromo,
            promoTemp: dataPromo
        });
        setTimeout(() => {
            this.setState({
                hasClickEditPromo: true,
                isNewPromoUserInDetail: false,
                isEditPromoUserInDetail: true,
                isEditCouponUserInDetail: false,
                hasClickEditCoupon: false,
                modal: true
            });
        }, 300);
    }

    /**
     * Attiva la modale per la modifica della promo
     */
    activeEditCoupon = (idPromo, dataPromo) => {
        this.setState({
            idPromo: idPromo,
            promoTemp: dataPromo
        });
        setTimeout(() => {
            this.setState({
                hasClickEditCoupon: true,
                hasClickEditPromo: false,
                isNewPromoUserInDetail: false,
                isEditPromoUserInDetail: false,
                isEditCouponUserInDetail: true,
                modal: true
            });
        }, 300);
    }

    /**
     * Attiva la modale per eliminare la promo
     */
    activeDeletePromo = (idPromo, dataPromo) => {
        this.setState({
            hasClickDeletePromo: true,
            modalDelete: true,
            idPromo: idPromo,
            promoTemp: dataPromo
        });
    }

    /**
     * Chiama il BE, cancella la promo e refresha la lista delle promo
     */
    deletePromo = () => {
        let arrayIdPromo = [];
        arrayIdPromo.push(this.state.idPromo);
        this.props.deletePromo(arrayIdPromo);
        this.toggleModalDelete();
    }

    /**
    * La funzione prende in input l'id dell'utente selezionato (input preso in automatico dallo stato), 
    * e verifica i player associati ad esso
    * 
    */
    getPromoList = () => {
        let params = {
            'promoFilters[0][WebUser]': this.props.userId,
            'elementsByPage': this.state.numberPromo,
            'page': this.state.changeNumberPage ? this.state.currentNumberPage : 1,
        };

        this.props.getPromoList(params, false);

        if (!this.state.changeNumberPage) {
            this.setState({
                currentNumberPage: 1
            });
        }

        if (this.state.changeNumberPage) {
            this.setState({
                changeNumberPage: false,
            });
        }

        this.setState({
            promoTemp: {
                webUser: this.props.userInDetail.id,
                startAt: null,
                endAt: null,
                discount: '',
                discountValue: '',
                priceValue: '',
                description: '',
                productNames: '',
                toBuy: '',
                code: '',
                countries: '',
                startItems: '',
                totalUses: '',
                isReseller: false,
                isRotten: true,
                isCoupon: ''
            }
        });

        this.setState({ isLoading: false });
    }


    /**
     * Azioni del menu a tendina.
     * 
     * @param {*} action tipo di azione
     * @param {*} idPromo id della promozione
     * @param {*} dataPromo dati della promozione
     */
    actionClick = (action, idPromo, dataPromo) => {
        switch (action) {
            case 'deletePromo': this.activeDeletePromo(idPromo, dataPromo); break;
            case 'editPromo': this.activeEditPromo(idPromo, dataPromo); break;
            case 'editCoupon': this.activeEditCoupon(idPromo, dataPromo); break;
            default: break;
        }
    }

    render() {
        const { role, productList, group, preferences, promo } = this.props;

        const columns = [
            {
                name: 'Type',
                selector: row => row.isCoupon ? <i className="fas fa-gift iconCoupon" /> && row.isRotten ? <i className="fas fa-gift iconCouponExpired" /> : <i className="fas fa-gift iconCoupon" /> : <i className="fas fa-user-tag iconPromo" /> && row.isRotten ? <i className="fas fa-user-tag iconPromoExpired" /> : <i className="fas fa-user-tag iconPromo" />,
                maxWidth: '70px',
                minWidth: '70px',
                center: true
            },
            {
                name: 'Codice',
                selector: row => row.isCoupon ? <SelectorCopy item={row.code} infoNotify={this.props.infoNotify} /> : "-"
            },
            {
                name: 'Descrizione',
                selector: row => row.description
            },
            {
                name: 'Gruppi',
                selector: row => !isNull(row.toBuy) && !isEmpty(row.groups) ? row.groups.length <= 1
                    ?
                    row.groups
                    :
                    <MyTooltip
                        title={row.groups.map(el => {
                            return (
                                <ul key={el} style={{ margin: '0.5vh', paddingLeft: '10px', fontSize: '16px' }}>
                                    <li>{el}</li>
                                </ul>
                            );
                        })
                        }
                        position="top"
                        content={<span className="moreProd">More Groups</span>}
                    />
                    :
                    "-"
            },
            {
                name: 'Nome Prodotto',
                selector: row => !isNull(row.productNames) ? row.productNames.length <= 1
                    ?
                    row.productNames
                    :
                    <MyTooltip
                        title={row.productNames.map(el => {
                            return (
                                <ul key={el} style={{ margin: '0.5vh', paddingLeft: '10px', fontSize: '16px' }}>
                                    <li>{el}</li>
                                </ul>
                            );
                        })
                        }
                        position="top"
                        content={<span className="moreProd">More Products</span>}
                    />
                    :
                    "-",
            },
            {
                name: 'Sconto',
                selector: row => !isNull(row.discountValue) ? row.discountValue : !isNull(row.discount) ? (row.discount) * 100 + "%" : "-",
                maxWidth: '80px',
                center: true
            },
            {
                name: 'Prezzo Fisso',
                selector: row => (row.priceValue === '' || row.priceValue === null) ? "-" : row.priceValue,
                maxWidth: '150px',
                center: true
            },
            {
                name: 'Usato / Totali',
                selector: row => row.startItems === null ? row.totalUses + "/ Illimitato" : row.totalUses + "/" + row.startItems,
                center: true
            },
            {
                selector: row => <ActionMenuPromo actionClick={this.actionClick} promoTemp={row} role={role} />,
                right: true,
                button: true,
                allowOverflow: true,
                wrap: true
            },
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                {this.state.isLoading && <Loading />}
                <div className="row title">
                    <div className="col-6">
                        <Badge variant="warning">
                            Promo attive: {promo.totalElements}
                        </Badge>
                    </div>
                    <div className="col-12 text-center btnAdd">
                        {
                            controlRole(role.promo, "api/Promo", "POST") &&
                            <button className="btn btn-success" onClick={this.activeNewPromo}>
                                <i className="fas fa-plus"></i>
                                Aggiungi Promo
                            </button>
                        }
                    </div>
                </div>

                <hr />

                <DataTable
                    columns={columns}
                    data={promo.promoList}
                    customStyles={customStyles}
                    noHeader
                    striped
                    highlightOnHover
                    pointerOnHover
                    expandableRows
                    expandOnRowClicked
                    expandableRowsComponent={ExpanablePromo}
                    expandableRowsComponentProps={{
                        "refreshList": this.getPromoList,
                        "webUser": this.state.webUser,
                        "getPromoList": this.getPromoList,
                    }}
                />

                {
                    (this.state.hasClickNewPromo || this.state.hasClickEditPromo) &&
                    <ModalPromo
                        titleModal={this.state.hasClickNewPromo ? 'Crea Promo' : 'Modifica Promo'}
                        clickNew={this.props.sendNewPromo}
                        clickEdit={this.props.sendEditPromo}
                        toggle={this.toggleModal}
                        stateModal={this.state.modal}

                        errorNotify={this.props.errorNotify}
                        successNotify={this.props.successNotify}
                        warningNotify={this.props.warningNotify}
                        infoNotify={this.props.infoNotify}

                        // valori di controllo per i ruoli
                        canPostPromoApi={controlRole(role.promo, "api/Promo", "POST")}
                        canEditPromoApi={controlRole(role.promo, "api/Promo", "PUT")}

                        isNewPromoUserInDetail={this.state.isNewPromoUserInDetail}
                        isEditPromoUserInDetail={this.state.isEditPromoUserInDetail}
                        userId={this.props.userId}

                        promoTemp={this.state.promoTemp}

                        // chiamata lista dei prodotti su cui può essere creata una PROMO
                        productList={productList}

                        // variabili passate per avere la lista dei gruppi disponibili e la lista dei prodotti
                        group={group}
                        preferences={preferences}
                    />
                }

                {
                    this.state.hasClickEditCoupon &&
                    <ModalCoupon
                        titleModal={'Modifica Coupon'}
                        clickNew={this.props.sendNewPromo}
                        clickEdit={this.props.sendEditPromo}
                        toggle={this.toggleModal}
                        stateModal={this.state.modal}

                        errorNotify={this.props.errorNotify}
                        infoNotify={this.props.infoNotify}
                        successNotify={this.props.successNotify}
                        warningNotify={this.props.warningNotify}

                        // valori di controllo per i ruoli
                        canPostCouponApi={controlRole(role.promo, "api/Promo/Coupon", "POST")}
                        canEditCouponApi={controlRole(role.promo, "api/Promo/Coupon", "PUT")}

                        isEditCouponUserInDetail={this.state.isEditCouponUserInDetail}
                        userId={this.props.userId}

                        // i dati del COUPON da passare alla modale
                        couponTemp={this.state.promoTemp}

                        // chiamata lista dei prodotti su cui può essere creata una PROMO
                        productList={productList}

                        // variabili passate per avere la lista dei gruppi disponibili e la lista dei prodotti
                        promo={this.props.promo}
                        preferences={this.props.preferences}
                    />
                }

                {
                    this.state.hasClickDeletePromo &&
                    <Modal
                        click={this.deletePromo}
                        titleModal="Elimina Promo"
                        contentModal="Sei sicuro di voler cancellare questa promozione?"
                        toggle={this.toggleModalDelete}
                        stateModal={this.state.modalDelete}
                    />
                }
            </Fragment>
        )
    }
}