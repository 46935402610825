import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isObject } from 'lodash';

import './style.scss';

/**
 * Modale per mostrare i contenuti di un oggetto che cambia in maniera dinamica.
 */
export default class modalShowDynamicData extends Component {
    render() {
        const { dynamicData, stateModal, toggle, titleModal } = this.props;
        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalShowDynamicData">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        {
                            Object.keys(dynamicData).map(el => {
                                let tempData = dynamicData[el];
                                return (
                                    <div className="row" key={el}>
                                        <div className="col-4 text-right">
                                            <b>{el}:</b>
                                        </div>
                                        <div className="col elem">
                                            {
                                                !isObject(tempData) ? tempData :
                                                    Object.keys(tempData).map(el => {
                                                        return (
                                                            <div className="row subRow" key={el}>
                                                                <div className="col-4 text-right">
                                                                    <b>{el}:</b>
                                                                </div>
                                                                <div className="col elem">
                                                                    {
                                                                        !isObject(tempData[el]) ? tempData[el] :
                                                                            JSON.stringify(tempData[el])
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-info" onClick={toggle}>Chiudi</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}