/**
 * Controlla se un JSON è correttamente compilato.
 * 
 * @param {String} str è la stringa da controllare
 */
export var isJson = function (str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}