import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormControlLabel, TextField, Radio, Checkbox } from '@material-ui/core';
import 'date-fns';

import './style.scss';
import { isEmpty } from 'lodash';

/**
 * Modale per effettuare reso o assistenza di uno stock
 */
export default class modalRenderStock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectRadio: '',
            note: '',

            // i devices con chiave, valore (key: id-Device)
            devicesWithKey: {}
        }
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    setChangeDevices = (key, el) => (event) => {
        let devicesTemp = this.state.devicesWithKey;

        if (event.target.checked) {
            devicesTemp["device-" + key] = el;
        } else {
            delete devicesTemp["device-" + key];
        }

        this.setState({ devicesWithKey: devicesTemp })
    }

    /**
     * Sanifico l'oggetto e prendo solo gli id, inserendoli nell'array.
     * 
     * @param {*} data 
     */
    sanitizeArrayFromSend = (data) => {
        let devicesFromSendTemp = [];
        
        // eslint-disable-next-line 
        Object.keys(data).map(el => {
            devicesFromSendTemp.push(data[el])
        })
        return devicesFromSendTemp;
    }

    send = () => {
        const { toggle, errorNotify, startRender, dataTemp, endRender, rebook, type } = this.props;

        if (type === 'startRender') {
            if (this.state.selectRadio === '') {
                errorNotify('Devi selezionare se è un reso o assistenza!')
            } else if (isEmpty(this.sanitizeArrayFromSend(this.state.devicesWithKey))) {
                errorNotify('Devi selezionare almeno un device ID!')
            } else {
                startRender(dataTemp.movId, {
                    "deviceIds": this.sanitizeArrayFromSend(this.state.devicesWithKey),
                    "notes": this.state.note,
                    "operationType": this.state.selectRadio === 'renderRefund' ? 'R' : 'D'
                })
                toggle();
            }
        }

        if (type === 'endRender') {
            endRender(dataTemp.movId);
            toggle();
        }

        if (type === 'rebook') {
            rebook(dataTemp.movId);
            toggle();
        }
    }

    render() {
        const { stateModal, toggle, titleModal, type, dataTemp } = this.props;

        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalRenderStock">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        {
                            type === 'startRender' &&
                            <Fragment>
                                <div className="row">
                                    <div className="col-6">
                                        <FormControlLabel value="male" control={
                                            <Radio
                                                checked={this.state.selectRadio === 'renderRefund'}
                                                onChange={this.setChange}
                                                value="renderRefund"
                                                name="selectRadio"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                        } label="Reso per rimborso" />
                                    </div>

                                    <div className="col-6">
                                        <FormControlLabel value="male" control={
                                            <Radio
                                                checked={this.state.selectRadio === 'renderBroken'}
                                                onChange={this.setChange}
                                                value="renderBroken"
                                                name="selectRadio"
                                                inputProps={{ 'aria-label': 'B' }}
                                            />
                                        } label="Assistenza tecnica / Guasto" />
                                    </div>
                                </div>

                                <div className="row" style={{ margin: '-10px -15px 15px -15px' }}>
                                    <div className="col-12">
                                        <TextField
                                            name="note"
                                            label="Note"
                                            value={this.state.note}
                                            onChange={this.setChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            {
                                                dataTemp.deviceIds.map((el, key) => {
                                                    return (
                                                        <div className="col-4" key={key}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        onChange={this.setChangeDevices(key, el)}
                                                                        checked={"device-" + key in this.state.devicesWithKey ? true : false}
                                                                        name={"device-" + key}
                                                                    />
                                                                }
                                                                label={el}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        }

                        {
                            type === 'endRender' &&
                            <div className="row">
                                <div className="col-12">
                                    Confermi la chiusura della pratica di reso / assistenza?
                                </div>
                            </div>
                        }

                        {
                            type === 'rebook' &&
                            <div className="row">
                                <div className="col-12">
                                    Vuoi rimettere in Booking?
                                </div>
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.send}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}