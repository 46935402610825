import React, { Component, Fragment } from 'react';
import { Select, FormControl, MenuItem, InputLabel, TextField } from '@material-ui/core';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import './style.scss';
import { COUNTRY_VATS } from '../../config';
import { returnNationality } from '../../common/actions/returnNationality';

/**
 * Attivazione modale per countryVats
 *      "clickEdit" : se clicco OK, modifico una nazione e l'iva
 *      "titleModal" : il titolo della modale quando si apre
 *      "nationReference" : nazione alla quale fare riferimento (usato per edit e remove)
 *      "ivaReference" : iva alla quale fare riferimento (usato per edit e remove)
 *      "toggle" : boolean che apre o chiude la modale
 *      "stateModal" : booleano che indica lo stato della modale (aperta o chiusa)
 */
export default class modalCountry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nation: this.props.nationReference,
            iva: this.props.ivaReference
        }
        this.setChange = this.setChange.bind(this);
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    confirmSend = () => {
        if (this.state.iva === "") {
            this.props.errorNotify('Inserisci il valore dell\'iva');
        } else {
            this.props.clickEdit(COUNTRY_VATS, {
                [this.state.nation]: {
                    "Vat": this.state.iva
                }
            });
        }
    }

    render() {
        const { stateModal, toggle, titleModal } = this.props;
        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalMail">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        <FormControl className="choose-form">
                            <div className="row justify-content-center">
                                <div className="col-5">
                                    <TextField
                                        name="nation"
                                        label="Nazione"
                                        value={this.state.nation === 'XX' ? '--' : returnNationality(this.state.nation)}
                                        readOnly
                                    />
                                </div>

                                <div className="col-4">
                                    <FormControl>
                                        <InputLabel>IVA</InputLabel>
                                        <Select
                                            name="iva"
                                            value={this.state.iva}
                                            onChange={this.setChange}
                                            error={this.state.iva === ''}
                                        >
                                            <MenuItem value={15}>15 %</MenuItem>
                                            <MenuItem value={16}>16 %</MenuItem>
                                            <MenuItem value={17}>17 %</MenuItem>
                                            <MenuItem value={18}>18 %</MenuItem>
                                            <MenuItem value={19}>19 %</MenuItem>
                                            <MenuItem value={20}>20 %</MenuItem>
                                            <MenuItem value={21}>21 %</MenuItem>
                                            <MenuItem value={22}>22 %</MenuItem>
                                            <MenuItem value={23}>23 %</MenuItem>
                                            <MenuItem value={24}>24 %</MenuItem>
                                            <MenuItem value={25}>25 %</MenuItem>
                                            <MenuItem value={26}>26 %</MenuItem>
                                            <MenuItem value={27}>27 %</MenuItem>
                                            <MenuItem value={28}>28 %</MenuItem>
                                            <MenuItem value={29}>29 %</MenuItem>
                                            <MenuItem value={30}>30 %</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </FormControl>

                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.confirmSend}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}