import { groupActionType } from '../actions';

const initialState = {
    isLoading: false,
    listGroup: [],
    totalGroup: ''
};

export default function group(state = initialState, action) {
    switch (action.type) {
        case `${groupActionType.GET_GROUP}_PENDING`:
        case `${groupActionType.ADD_GROUP}_PENDING`:
        case `${groupActionType.UPDATE_GROUP}_PENDING`:
        case `${groupActionType.DELETE_GROUP}_PENDING`:
        case `${groupActionType.ASSIGN_GROUP}_PENDING`:
            return {
                ...state,
                isLoading: true
            }
        case `${groupActionType.ADD_GROUP}_FULFILLED`:
        case `${groupActionType.UPDATE_GROUP}_FULFILLED`:
        case `${groupActionType.DELETE_GROUP}_FULFILLED`:
        case `${groupActionType.ASSIGN_GROUP}_FULFILLED`:
            return {
                ...state,
                isLoading: true
            }
        case `${groupActionType.GET_GROUP}_FULFILLED`:
            return {
                ...state,
                isLoading: false,
                listGroup: action.payload.listGroup,
                totalGroup: action.payload.totalGroup
            }
        default:
            return state;
    }
}