import moment from 'moment';
import { promoActionType, userActionType } from '../actions';

const initialState = {
    isLoading: false,
    idPromoForDetail: '',

    totalElements: '',
    totalPages: '',
    promoList: [],
    listEmails: [],

    // contiene i parametri che vengono usati per la chiamata GET (nel caso vengano usati i dispatch con la GET)
    paramsPromoTemp: {
        'elementsByPage': 25,
        'page': 1,
        'sortField': '',
        'sortType': '',
        'promoFilters[0][IsCoupon]': localStorage.getItem('path') === '/promo/createdCoupon' || localStorage.getItem('path') === '/promo/buyCoupon' || localStorage.getItem('path') === '/promo/expiredCoupon',
        'promoFilters[1][StartAtStart]': moment().utc().startOf('month').format('YYYY-MM-DD'),
        'promoFilters[2][EndAtEnd]': null,
        'promoFilters[3][StartAtEnd]': moment().utc().endOf('month').format('YYYY-MM-DD'),
        'promoFilters[4][EndAtStart]': null,
        'promoFilters[5][WebUser]': '',
        'promoFilters[6][Description]': '',
        'promoFilters[7][Product]': '',
        'promoFilters[8][DiscountMax]': '',
        'promoFilters[9][DiscountMin]': '',
        'promoFilters[10][UnlimitedUses]': '',
        'promoFilters[11][Id]': '',
        'promoFilters[12][Code]': '',
        'promoFilters[13][Group]': '',
        'promoFilters[14][AssignedCoupon]': '',
        'promoFilters[15][IsPaid]': localStorage.getItem('path') === '/promo/buyCoupon',
        'promoFilters[15][IsRotten]': (localStorage.getItem('path') === 'promo/expiredPromo' || localStorage.getItem('path') === 'promo/expiredCoupon')
    },
};

export default function promo(state = initialState, action) {
    switch (action.type) {
        case `${promoActionType.GET_PROMO_LIST}_PENDING`:
        case `${promoActionType.SEND_NEW_PROMO}_PENDING`:
        case `${promoActionType.SEND_EDIT_PROMO}_PENDING`:
        case `${promoActionType.DELETE_PROMO}_PENDING`:
            return {
                ...state,
                isLoading: true
            }
        case `${promoActionType.SEND_NEW_PROMO}_FULFILLED`:
        case `${promoActionType.SEND_EDIT_PROMO}_FULFILLED`:
        case `${promoActionType.DELETE_PROMO}_FULFILLED`:
            return {
                ...state,
                isLoading: false
            }
        case `${promoActionType.GET_PROMO_LIST}_FULFILLED`:
            return {
                ...state,
                isLoading: false,
                totalElements: action.payload.totalElements,
                totalPages: action.payload.totalPages,
                paramsPromoTemp: action.payload.paramsPromoTemp,
                promoList: action.payload.promoList,
            }
        case `${userActionType.GET_LIST_EMAILS}_FULFILLED`:
            return {
                ...state,
                isLoading: false,
                listEmails: action.payload.listEmails
            }
        default:
            return state;
    }
}