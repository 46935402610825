import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

import Loading from '../../../common/loading/loadingInBlock';

export default class licenceOfMonth extends Component {
    render() {
        const { trial, lifeTime, loadingGraph3 } = this.props;

        const optionsLicence = {
            responsive: true,
            layout: {
                padding: {
                    left: 15,
                    right: 25,
                    top: 10
                }
            },

            legend: {
                display: true,
                position: 'top',
                align: 'end'
            },

            plugins: {
                title: {
                    display: true,
                    text: 'Licenze Mensili',
                    font: {
                        size: 20
                    }
                }
            },

            scales: {
                y: {
                    ticks: {
                        beginAtZero: true
                    }
                }
            }
        };

        const dataLicence = {
            labels: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
            datasets: [
                {
                    label: "Trial",
                    backgroundColor: "rgba(42,110,255, 0.5)",
                    data: trial,
                    hoverBackgroundColor: 'rgba(42,110,255, 0.5)',
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                },

                {
                    label: "LifeTime",
                    backgroundColor: "Whitesmoke",
                    data: lifeTime,
                    hoverBackgroundColor: 'Whitesmoke',
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,
                }
            ],
        };

        return (
            <div className="containerChart">
                <div className="homeChart">
                    {
                        loadingGraph3 ? <Loading /> :
                            <Bar options={optionsLicence} data={dataLicence} />
                    }
                </div>
            </div>
        )
    }
}