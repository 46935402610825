import React, { Component, Fragment } from 'react';
import './style.scss';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Row, Col } from 'react-bootstrap';

export default class modalWhiteList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ip: this.props.ipReference || '',
            keyReference: this.props.keyReference || '',
            subKey: this.props.subKeyReference || '',
            keyIp: this.props.keyIp || '',
            ipReferenceNull: false
        }
        this.setIpReference = this.setIpReference.bind(this);
    }

    setIpReference = (event) => {
        this.setState({ ip: event.target.value, ipReferenceNull: false });
    }

    confirmSend = () => {
        const { type, clickNewKey, clickEdit, clickRemoveIp, clickRemoveKey, clickNewIp } = this.props;

        if (this.state.ip === "" && type === 'newKey') {
            this.props.errorNotify('Inserisci una chiave!');
            this.setState({ ipReferenceNull: true });
        } else if (this.state.ip === "" && type !== 'removeKey' && type !== 'newKey') {
            this.props.errorNotify('Inserisci l\'indirizzo IP');
            this.setState({ ipReferenceNull: true });
        } else {
            if (type === 'newKey') {
                clickNewKey(this.state.ip);
            } else if (type === 'newIp') {
                clickNewIp(this.state.ip);
            } else if (type === 'edit') {
                clickEdit(this.state.keyReference, this.state.subKey, this.state.ip);
            } else if (type === 'removeIp') {
                clickRemoveIp(this.state.keyReference, this.state.subKey);
            } else if (type === 'removeKey') {
                clickRemoveKey(this.state.keyReference);
            }
        }
    }

    render() {
        const { stateModal, toggle, titleModal, type } = this.props;
        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalMail">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        {
                            type === 'edit' &&
                            // (type === 'new' || type === 'edit') &&
                            <Form>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" className="text-right">
                                        Indirizzo IP
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            value={this.state.ip}
                                            onChange={this.setIpReference}
                                            isInvalid={this.state.ipReferenceNull}
                                        />
                                    </Col>
                                </Form.Group>

                            </Form>
                        }
                        {
                            type === 'newKey' &&
                            <Form>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="3" className="text-right">
                                        Nuova chiave
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            type="text"
                                            value={this.state.ip}
                                            onChange={this.setIpReference}
                                            isInvalid={this.state.ipReferenceNull}
                                        />
                                    </Col>
                                </Form.Group>

                            </Form>
                        }
                        {
                            type === 'newIp' &&
                            <Form>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="3" className="text-right">
                                        IP
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            type="text"
                                            value={this.state.ip}
                                            onChange={this.setIpReference}
                                            isInvalid={this.state.ipReferenceNull}
                                        />
                                    </Col>
                                </Form.Group>

                            </Form>
                        }
                        {
                            type === 'removeIp' &&
                            <div>
                                Sei sicuro di voler rimuovere l'indirizzo <b>{this.state.ip}</b> dalla WhiteList?
                            </div>
                        }
                        {
                            type === 'removeKey' &&
                            <div>
                                Sei sicuro di voler rimuovere tutto l'oggetto key <b>{this.state.keyReference}</b>? <br />
                                <p style={{ 'color': 'red' }}>Verranno cancellati tutti gli indirizzi Ipv4 e Ipv6 associati a esso!!!</p>
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.confirmSend}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}