import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { Badge } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

import { API_VPN_PASS } from '../../../config';
import Loading from '../../../common/loading';
import SelectorCopy from '../../../common/selectorCopy';
import ExpanableVpnPass from './tabExpanableVpnPass';
import MyTooltip from '../../../common/tooltip';
import { randomString } from '../../../common/actions/randomString';
import ActionMenu from './actionMenuVpnPass';
import Modal from '../../../common/modal';

export default class tabVpnPass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            listVpnPass: [],
            counterListVpnPass: '',

            // variabili di controllo per modali
            hasClickRemoveVpnPass: false,

            // variabile temporanea per trasportare i dati da editare di una vpn
            vpnTempEdit: {}
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.getListVpnPass();
        }, 500);
    }

    /**
     * La funzione prende in input l'id dell'utente selezionato (input preso in automatico dallo stato), 
     * e verifica i player associati ad esso
     * 
     */
    getListVpnPass = async () => {
        this.setState({ isLoading: true });
        try {
            let params = {
                'elementsByPage': '1000',
                'page': '1',
                'filters[0][User]': this.props.userId,
            };

            let response = await axios.get(`${API_VPN_PASS}`, { params: params });

            this.setState({
                isLoading: false,
                listVpnPass: response.data.value.value,
                counterListVpnPass: response.data.value.value.length
            });

        } catch (result) {
            const { errorNotify } = this.props;
            this.setState({ isLoading: false });
            if (result && result.response && result.response.status) {
                switch (result.response.status) {
                    default: errorNotify(`Error ${result.response.status} su ${API_VPN_PASS} - GET`); break;
                }
            }
        }
    }

    /**
     * Dato un id, vai alla schermata dei pagamenti portando id pagamento nei filtri
     */
    goToPayment = (paymentId) => {
        this.props.setIdPaymentForDetail(paymentId)
        this.props.history.push(`/payments`);
    }

    /**
     * Chiude le modali per la gestione della VPN Pass, resettando i valori
     */
    toggleModal = () => {
        this.setState(prevState => ({ modal: !prevState.modal }));
        this.setState({
            hasClickRemoveVpnPass: false,
            vpnTempEdit: {},
            idTempEdit: ''
        });
    }

    /**
     * Quando utilizzo una modale, viene attivata la funzione per quella determinata modale.
     * 
     * @param data
     */
    actionModal = () => {
        this.setState({ isLoading: true });

        if (this.state.hasClickRemoveVpnPass) {
            this.props.removeVpnPass(this.state.vpnTempEdit.id);
            this.toggleModal();
            this.getListVpnPass();
            this.setState({ isLoading: false });
        }
    }

    /**
     * Se clicca rimuovi VPN Account, attiva la modale
     */
    hasClickRemoveVpnPass = (data) => {
        this.setState({
            hasClickEditVpnPass: false,
            hasClickRemoveVpnPass: true,
            titleModal: "Rimuovi VPN Account",
            contentModal: 'Sei sicuro di voler rimuovere la Pass: ' + data._id + ' ?',
            shortId: randomString(),
            vpnTempEdit: {
                id: data._id,
                productName: data.productName,
                createdAt: data.createdAt,
                expireAt: data.expireAt,
                maxAllowedPeers: data.maxAllowedPeers
            }
        });

        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * In base al click dell'azione dal menu della vpn, effettua una determinata funzione.
     * @param {*} data 
     */
    actionClick = (action, data) => {
        switch (action) {
            case 'remove': this.hasClickRemoveVpnPass(data); break;
            default: break;
        }
    }

    render() {
        const { role } = this.props;
        const columns = [
            {
                name: 'ID',
                selector: row => <SelectorCopy item={row._id} infoNotify={this.props.infoNotify} />
            },
            {
                name: 'Key Pass',
                selector: row => <SelectorCopy item={row.key} infoNotify={this.props.infoNotify} />
            },
            {
                name: 'ID Pagamento',
                selector: row =>
                    <Fragment>
                        <SelectorCopy item={row.paymentId} infoNotify={this.props.infoNotify} />
                        <span className="clickGoTo" onClick={() => { this.goToPayment(row.paymentId) }}>
                            <MyTooltip
                                title="Vai a questo pagamento"
                                position="top"
                                content={<i className="fas fa-share-square"></i>}
                            />
                        </span>
                    </Fragment>
            },
            {
                selector: row => <ActionMenu actionClick={this.actionClick} vpnPass={row} role={role} />,
                right: true,
                button: true,
                allowOverflow: true,
                wrap: true
            },
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                {this.state.isLoading && <Loading />}
                <div className="row title">
                    <div className="col-4 col-sm-4 col-md-4 col-xl-4">
                        <Badge variant="warning">
                            VPN Pass: {this.state.counterListVpnPass}
                        </Badge>
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={this.state.listVpnPass}
                    customStyles={customStyles}
                    noHeader
                    striped
                    highlightOnHover
                    pointerOnHover
                    expandableRows
                    expandOnRowClicked
                    expandableRowsComponent={ExpanableVpnPass}
                />

                {
                    this.state.hasClickRemoveVpnPass &&
                    <Modal
                        click={this.actionModal}
                        titleModal={this.state.titleModal}
                        contentModal={this.state.contentModal}
                        toggle={this.toggleModal}
                        stateModal={this.state.modal}
                    />
                }
            </Fragment>
        )
    }
}