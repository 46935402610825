import React, { Component } from 'react';
import './style.scss';

export default class LoadingBar extends Component {
    render() {
        return (
            <div className="row loadingBar">
                <div className="col-12">
                    <div className="contentLoading">
                        <div className="loaderLine"></div>
                        <div className="label">Attendi</div>
                    </div>
                </div>
            </div>
        )
    }
}