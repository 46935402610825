import React, { Component, Fragment } from 'react';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { isNull } from 'lodash';
import './style.scss';
import { controlRole } from '../../common/actions/controlRole';

export default class actionMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setOpen: false
        };
    }

    toggle = () => {
        this.setState({ setOpen: !this.state.setOpen });
    }

    render() {
        const { role, payment, hasClickModalDeletePayment, hasClickModalRefundPayment, hasClickModalDeactiveSub } = this.props;
        return (
            <Fragment>
                <ButtonDropdown isOpen={this.state.setOpen} toggle={this.toggle}>
                    <DropdownToggle caret>
                        <i className="fas fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu>
                        {
                            !isNull(payment.invoiceUrl) &&
                            <DropdownItem href={payment.invoiceUrl} download>
                                <div className="row">
                                    <div className="col-3 icon downloadPayment">
                                        <i className="fas fa-download" />
                                    </div>
                                    <div className="col-9 label">
                                        Download Fattura
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            !isNull(payment.deliveryNoteUrl) &&
                            <DropdownItem href={payment.deliveryNoteUrl} download>
                                <div className="row">
                                    <div className="col-3 icon bollaPayment">
                                        <i className="fas fa-download" />
                                    </div>
                                    <div className="col-9 label">
                                        Download Bolla
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            // se il prodotto è un'acquisto con status 1 (pagato) o status 6 (rimborsato parzialmente)', allora permette un rimborso sulle quantità disponibili
                            (
                                payment.paymentProvider !== 'Stark' && (payment.totalpaid !== payment.totalRefunded) &&
                                (payment.paymentStatus === 1 || payment.paymentStatus === 6 || payment.paymentStatus === 3) &&
                                controlRole(role.payment, "api/Payment/partialRefund", "POST")
                            ) &&
                            <DropdownItem onClick={() => hasClickModalRefundPayment(payment)}>
                                <div className="row">
                                    <div className="col-3 icon downloadPayment">
                                        <i className="fas fa-sync-alt"></i>
                                    </div>
                                    <div className="col-9 label">
                                        Rimborsa Pagamento
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            controlRole(role.payment, "api/Payment/CancelSubscription", "POST") && payment.paymentStatus === 4 &&
                            <DropdownItem onClick={() => hasClickModalDeactiveSub(payment._id)}>
                                <div className="row">
                                    <div className="col-3 icon deletePayment">
                                        <i className="fas fa-times"></i>
                                    </div>
                                    <div className="col-9 label">
                                        Disattiva Sottoscrizione
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            controlRole(role.payment, "api/Payment", "DELETE") &&
                            <DropdownItem onClick={() => hasClickModalDeletePayment(payment._id)}>
                                <div className="row">
                                    <div className="col-3 icon deletePayment">
                                        <i className="far fa-trash-alt"></i>
                                    </div>
                                    <div className="col-9 label">
                                        Elimina Pagamento
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                    </DropdownMenu>
                </ButtonDropdown>
            </Fragment>
        )
    }
}