import React, { Component, Fragment } from 'react';
import './style.scss';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Row, Col } from 'react-bootstrap';

/**
 * Attivazione modale per inviare mail
 * 
 *      "click" : se clicco OK, attivo una determinata funzione
 *      "titleModal" : il titolo della modale quando si apre
 *      "contentModal" : il contenuto che deve essere mostrato nella modale
 *      "userMail" : mail dell'utente a cui mandare la mail
 *      "toggle" : boolean che apre o chiude la modale
 *      "stateModal" : booleano che indica lo stato della modale (aperta o chiusa)
 */
export default class modalMail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oggetto: '',
            testo: '',
            objectNull: false,
            contentNull: false,
        }
        this.setContentMail = this.setContentMail.bind(this);
        this.setObjectMail = this.setObjectMail.bind(this);
    }

    setObjectMail = (event) => {
        this.setState({ oggetto: event.target.value, objectNull: false });
    }

    setContentMail = (event) => {
        this.setState({ testo: event.target.value, contentNull: false });
    }

    confirmSend = () => {
        if (this.state.oggetto === "") {
            this.props.errorNotify('Inserisci l\'oggetto');
            this.setState({ objectNull: true });
        } else if (this.state.testo === "") {
            this.props.errorNotify('Inserisci il messaggio');
            this.setState({ contentNull: true });
        } else {
            this.setState({ contentNull: false, objectNull: false });
            this.props.click(this.props.userMail, this.state.oggetto, this.state.testo);
        }
    }

    render() {
        const { userMail, stateModal, toggle, titleModal } = this.props;
        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalMail">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" className="text-right">
                                    Destinatario
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        plaintext
                                        readOnly
                                        defaultValue={userMail}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" className="text-right">
                                    Oggetto
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        value={this.state.oggetto}
                                        onChange={this.setObjectMail}
                                        isInvalid={this.state.objectNull}
                                        />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextPassword">
                                <Form.Label column sm="2" className="text-right">
                                    Messaggio
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        as="textarea"
                                        rows="3"
                                        value={this.state.content}
                                        onChange={this.setContentMail}
                                        isInvalid={this.state.contentNull}
                                    />
                                </Col>
                            </Form.Group>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.confirmSend}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}