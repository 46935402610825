import { connect } from 'react-redux'
import { userActions, roleActions, notifyActions, groupActions } from '../store/actions';
import pageUsers from './pages';

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user,
        role: state.role,
        group: state.group
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserList: (data) => {
            dispatch(userActions.getUserList(data))
        },
        getGroupList: () => {
            dispatch(groupActions.getGroupList())
        },
        assignGroup: (data) => {
            dispatch(groupActions.assignGroup(data))
        },
        resetPassword: (user) => {
            dispatch(userActions.resetPassword(user))
        },
        resetTfa: (user) => {
            dispatch(userActions.resetTfa(user))
        },
        deleteUser: (userMail) => {
            dispatch(userActions.deleteUser(userMail))
        },
        sendMail: (data) => {
            dispatch(userActions.sendMail(data))
        },
        sendNewUser: (data) => {
            dispatch(userActions.sendNewUser(data))
        },
        updateAddress: (data, id) => {
            dispatch(userActions.updateAddress(data,id))
        },
        updateUser: (data) => {
            dispatch(userActions.updateUser(data))
        },
        getRoleList: () => {
            dispatch(roleActions.getRoleList())
        },
        assignRole: (data) => {
            dispatch(roleActions.assignRole(data))
        },
        unassignRole: (data) => {
            dispatch(roleActions.unassignRole(data))
        },
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(pageUsers);