import { bundleActionType } from '../actions';

const initialState = {
    isLoading: false,
    totalBundle: 0,

    // contiene la lista di tutti i bundle (risposta della GET)
    listBundle: [],

    // contiene i parametri che vengono usati per la chiamata GET (nel caso vengano usati i dispatch con la GET)
    paramsBundleTemp: {},

    totalPages: ''
};

export default function role(state = initialState, action) {
    switch (action.type) {
        case `${bundleActionType.SET_NUMBER_BUNDLE}`:
            return {
                ...state,
                totalBundle: action.totalBundle
            }
        case `${bundleActionType.GET_BUNDLE}_PENDING`:
        case `${bundleActionType.SET_BUNDLE}_PENDING`:
            return {
                ...state,
                isLoading: true
            }
        case `${bundleActionType.GET_BUNDLE}_FULFILLED`:
            return {
                ...state,
                isLoading: false,
                listBundle: action.payload.listBundle,
                paramsBundleTemp: action.payload.paramsBundleTemp,
                totalPages: action.payload.totalPages,
            }
        case `${bundleActionType.SET_BUNDLE}_FULFILLED`:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
}