import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './store';
import { loadReCaptcha } from './common/reCaptcha';
import RouterComponent from './routing/RouterComponent';

export default class App extends Component {

  componentDidMount() {
    loadReCaptcha();
  }

  render() {
    return (
      <Provider store={store}>
        <div className="appContainer">
          <RouterComponent />
        </div>
      </Provider>
    );
  }

}