import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Select, FormControl, MenuItem, InputLabel } from '@material-ui/core';
import 'date-fns';
import { Form } from 'react-bootstrap';

import './style.scss';
import { BOX_HW_TYPE, PLAYER_TYPE } from '../../config';
import { returnTypeName } from '../actions/returnTypeName';

/**
 * Attivazione modale per bundle
 */
export default class modalBundle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceIds: [],
            deviceType: this.props.dataBundle.deviceType || '', // readOnly (es: 'VPN-BOX')
            productName: this.props.dataBundle.productName || '', // productName selezionato (es: 'VPNBOX-ONE-MONTH')

            // viene selezionato alla selezione del device type
            arrayProductName: [],
        }
    }

    /**
     * Se è in modalità edit, allora l'array lo formatto in stringa per la textarea
     */
    componentDidMount() {
        const { dataBundle, type } = this.props;

        if (type === 'editBundle') {
            let stringTemp = '';

            // eslint-disable-next-line
            dataBundle.deviceIds.map((el, key) => {
                stringTemp += el + '\n';
            })

            this.setState({
                deviceIds: stringTemp.substring(0, stringTemp.length - 1) // rimuovo il \n finale
            })
        }
    }

    /**
     * Controlla una stringa se è un array o meno.
     * Se non lo è, converte quella stringa in un array
     * 
     * @param {*} data dati da controllare e convertire
     */
    convertStringInArray = (data) => {
        let arrayTemp = data.split('\n');

        // se l'ultimo elemento è vuoto, allora non lo manda
        if (arrayTemp[arrayTemp.length - 1] === '')
            arrayTemp = arrayTemp.splice(0, arrayTemp.length - 1);

        return arrayTemp;
    }

    /**
     * Controlla che i seriali inseriti siano validi.
     * Ritorna true se sono tutti validi, false altrimenti.
     * 
     * @param {*} data 
     */
    validateAllSerial = (data, deviceType) => {
        let value = true;
        // controllo che ogni seriale sia 8byte ed alfanumerico
        const regex = /^[0-9a-zA-Z]{16}$/;

        if (deviceType !== PLAYER_TYPE) {
            // eslint-disable-next-line
            data.map(el => {
                if (!regex.test(el)) {
                    value = false;
                    this.props.errorNotify(`Il seriale ${el} non rispetta la validazione dei caratteri`);
                }
            })

            return value;

        } else return value;
    }

    setChange = (event) => {
        const { preferences } = this.props;

        this.setState({ [event.target.name]: event.target.value });

        // se ho cambiato il deviceType, associa solo i prodotti per quel device
        setTimeout(() => {
            if (event.target.name === 'deviceType') {
                let valueTempName = [];

                // eslint-disable-next-line
                preferences.allProducts.map((el) => {
                    if (el.type === this.state.deviceType)
                        valueTempName.push(el.name)
                })

                this.setState({ arrayProductName: valueTempName })
            }
        }, 200);
    }

    /**
     * Se i dati sono corretti, prepara l'oggetto da mandare al BE
     * per aggiungere o modificare o eliminare un bundle
     */
    actionClick = () => {
        const { errorNotify, toggle, setBundle } = this.props;

        if (this.state.deviceType === BOX_HW_TYPE) {
            if (this.formIsValid() && this.validateAllSerial(this.convertStringInArray(this.state.deviceIds), this.state.deviceType)) {
                setBundle({
                    "deviceType": this.state.deviceType,
                    "productName": this.state.productName,
                    "deviceIds": this.convertStringInArray(this.state.deviceIds)
                })
                toggle();
            } else {
                errorNotify('Compila correttamente tutti i campi!')
            }
        } else {
            if (this.formIsValid() && this.convertStringInArray(this.state.deviceIds)) {
                setBundle({
                    "deviceType": this.state.deviceType,
                    "productName": this.state.productName,
                    "deviceIds": this.convertStringInArray(this.state.deviceIds)
                })
                toggle();
            } else {
                errorNotify('Compila correttamente tutti i campi!')
            }
        }

    }

    /**
     * Controlla se i campi sono stati inseriti tutti
     */
    formIsValid = () => {
        if (
            (this.state.deviceType === '') ||
            (this.state.productName === '') ||
            (this.state.deviceIds === [])
        ) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        const { stateModal, toggle, titleModal, type } = this.props;

        return (
            <Fragment>
                <Modal isOpen={stateModal} className="modalBundle">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        {
                            (type === 'newBundle' || type === 'editBundle') &&
                            <FormControl className="choose-form">
                                <div className="row">
                                    <div className="col-6">
                                        <FormControl>
                                            <InputLabel>Tipo Prodotto</InputLabel>
                                            <Select
                                                labelId="deviceType"
                                                name="deviceType"
                                                value={this.state.deviceType}
                                                onChange={this.setChange}
                                                // non modificabile se si trova in modalita EDIT
                                                disabled={type === 'editBundle'}
                                            >
                                                <MenuItem value={PLAYER_TYPE}>{returnTypeName(PLAYER_TYPE)}</MenuItem>
                                                <MenuItem value={BOX_HW_TYPE}>{returnTypeName(BOX_HW_TYPE)}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className="col-6">
                                        <FormControl>
                                            <InputLabel>Nome Prodotto</InputLabel>
                                            <Select
                                                labelId="productName"
                                                name="productName"
                                                value={this.state.productName}
                                                onChange={this.setChange}
                                                // non selezionabile se il tipo non è stato definito
                                                disabled={type === 'editBundle'}
                                            >
                                                {
                                                    type === 'editBundle' ?
                                                        <MenuItem value={this.state.productName}>{returnTypeName(this.state.productName)}</MenuItem>
                                                        :
                                                        this.state.arrayProductName.map((item, k) => {
                                                            return (
                                                                <MenuItem key={k} value={item}>{returnTypeName(item)}</MenuItem>
                                                            );
                                                        })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row justify-content-center">
                                    <div className="col-12 miniLegend">
                                        Premere 'Invio' nella textArea a ogni inserimento di un seriale
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <Form>
                                            <Form.Control
                                                as="textarea"
                                                rows="10"
                                                name="deviceIds"
                                                value={this.state.deviceIds}
                                                onChange={this.setChange}
                                            />
                                        </Form>
                                    </div>
                                </div>

                            </FormControl>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.actionClick}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment >
        )
    }
}