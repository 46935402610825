import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

import './style.scss';
import Loading from '../loading';

/**
 * Modale per rimborsare un pagamento
 */
export default class modalRefundPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,

            //quantità del prodotto disponibili per il rimborso
            qtyTemp: 0,

            qty: 0,
            qtyRefunded: 0,

            // array contentente i prodotti da rimborsare
            arrayProduct: [{
                productName: '',
                qty: 1,
                price: 0.0
            }],

            verify: true,

            arrayProva: [{
                productName: '',
                qty: 0,
                price: 0.0,
            }],

            //quanti prodotti ci sono in totale nel pagamento selezionato
            qtyProductsEntry: this.props.dataPayment.products.length,

            // variabile che indica se prendere(false) il prezzo di listino o quello a scelta dell'utente(true)
            checkedForce: false,
        }
    }

    componentDidMount() {
        this.verifyRefund();
    }

    setChange = (event) => {
        if (event.target.name === 'checkedForce') {
            this.setState({ checkedForce: !this.state.checkedForce });
        } else if (event.target.name === 'price') {
            this.setState({ checkedForce: parseFloat(event.target.value) });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    verifyProduct = () => {
        let count = 0;

        // eslint-disable-next-line
        this.state.arrayProduct.map((el) => {
            if ((el.productName === this.state.arrayProva[0].productName) || (el.qty === this.state.arrayProva[0].qty)) {
                count += 1;
            }
        })
        
        if (count === 0) {
            this.setState({ verify: false });
        }

        setTimeout(() => {
            this.refundPayment();
        }, 100);
    }

    refundPayment = () => {
        const { refundPayment, dataPayment, toggle, errorNotify } = this.props;

        if (this.state.verify) {
            errorNotify('Inserisci prodotto, quantità e prezzo correttamente');

        } else {
            refundPayment(dataPayment._id, {
                "purchaseId": dataPayment.providerTransactionId,
                "elements": this.state.arrayProduct
            });
            toggle();
        }
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    /**
     * Aggiunge un prodotto nuovo all'array dei prodotti da rimborsare.
     */
    addProduct = () => {
        let newProduct = {
            productName: '',
            qty: 0,
            price: 0.0
        };
        const arrayProduct = [...this.state.arrayProduct, newProduct];
        this.setState({ arrayProduct });
    }

    /**
     * Aggiorna l'oggetto selezionato che si trova all'interno dell'array dei prodotti.
     * 
     * @param {*} k è l'indice in cui si trova l'oggetto
     * @param {*} i è l'oggetto modificato
     */
    updateItem = (k, i) => {
        let arrayProduct = [...this.state.arrayProduct];
        let item = { ...arrayProduct[k] };

        if (!this.state.checkedForce) {
            item = {
                productName: i.productName,
                qty: i.qty,
                price: this.returnPriceForProduct(i),
                discount: i.discount
            }

        } else {
            item = i;
        }

        arrayProduct[k] = item;
        this.setState({ arrayProduct });
    }

    /**
     * Ritorna il prezzo associato al nome del prodotto che abbiamo in listino.
     * Serve solo a mostrarlo all'utente, poi il BE metterà i prezzi in automatico, 
     * prendendoli dal listino.
     * 
     * @param {*} item 
     */
    returnPriceForProduct = (item) => {
        const { dataPayment } = this.props;
        let price;

        // eslint-disable-next-line
        dataPayment.products.map(el => {
            if (el.name === item.productName) {
                if (el.discountedPrice === '' || el.discountedPrice === null) {
                    price = el.price;

                } else {
                    price = el.discountedPrice;

                }
            }
        })

        return price;
    }

    /**
     * Rimuove un prodotto dall'array dei prodotti da rimborsare.
     */
    removeProduct = (k) => {
        this.setState(state => {
            // eslint-disable-next-line
            const arrayProduct = state.arrayProduct.filter((item, i) => k !== i);
            return { arrayProduct }
        });
    }

    verifyRefund = () => {
        // eslint-disable-next-line
        this.props.dataPayment.products.map(el => {
            if (el.qty !== el.qtyRefunded) {
                return (
                    this.setState({
                        qty: el.qty,
                        qtyRefunded: el.qtyRefunded
                    })
                )
            }
        })
    }

    render() {
        const { stateModal, toggle, titleModal, dataPayment } = this.props;

        return (
            <Fragment>
                {this.state.isLoading && <Loading />}
                <Modal isOpen={stateModal} toggle={toggle} className="modalRefundPayment">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        <div className="row titleTable">
                            <div className="col-6">
                                Prodotto
                            </div>
                            <div className="col-3 text-center">
                                Disponibile per rimborso
                            </div>
                            <div className="col-3 text-center">
                                Rimborsata
                            </div>
                        </div>
                        {
                            dataPayment.products.map((el, key) => {
                                return (
                                    <div className="elemRefund" key={key}>
                                        {
                                            el.discountedPrice === 0 ?
                                                <div className='row'>
                                                    <div className="col-6">
                                                        <span className="product">{el.name} </span>
                                                    </div>

                                                    <div className='col-6 text-center'>
                                                        <i><strong>ACQUISTO CON SCONTO 100%</strong></i>
                                                    </div>
                                                </div>
                                                :
                                                <div className="row">
                                                    <div className="col-6">
                                                        <span className="product">{el.name} </span>
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        {el.qty - el.qtyRefunded}
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        {el.qtyRefunded}
                                                    </div>
                                                </div>

                                        }
                                    </div>
                                )
                            })
                        }

                        <hr style={{ 'width': '100%' }}></hr>

                        <div className="row">
                            <div className="col-12">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.setChange}
                                            checked={this.state.checkedForce}
                                            name="checkedForce"
                                        />
                                    }
                                    label="Prezzi personalizzati"
                                />
                            </div>
                        </div>

                        {
                            this.state.qty === this.state.qtyRefunded ?
                                <div>Sono stati rimborsati già tutti i prodotti</div>
                                :
                                <div className="viewRefunded">
                                    {
                                        this.state.arrayProduct.map((el, key) => {
                                            return (
                                                <div className="row rowProduct" key={key}>
                                                    <div className="col-5">
                                                        <FormControl>
                                                            <InputLabel>Prodotto selezionato</InputLabel>
                                                            <Select
                                                                defaultValue={''}
                                                                labelId="productName"
                                                                name="productName"
                                                                value={this.state.arrayProduct[key].productName}
                                                                onChange={(event) => {
                                                                    this.updateItem(key, {
                                                                        productName: event.target.value,
                                                                        qty: this.state.arrayProduct[key].qty,
                                                                        price: this.state.arrayProduct[key].price
                                                                    })
                                                                }}
                                                            >
                                                                {
                                                                    // eslint-disable-next-line
                                                                    dataPayment.products.map((el, key) => {
                                                                        if (el.discountedPrice !== 0) {
                                                                            return (
                                                                                <MenuItem key={key} value={el.name}>{el.name}</MenuItem>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField
                                                            name="qty"
                                                            label="Quantità"
                                                            inputProps={{ min: 0 }}
                                                            value={this.state.arrayProduct[key].qty}
                                                            type="number"
                                                            onChange={(event) => {
                                                                this.updateItem(key, {
                                                                    productName: this.state.arrayProduct[key].productName,
                                                                    qty: parseInt(event.target.value),
                                                                    price: this.state.arrayProduct[key].price
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        {
                                                            !this.state.checkedForce ?
                                                                <TextField
                                                                    name="price"
                                                                    label="Prezzo"
                                                                    value={this.state.arrayProduct[key].price}
                                                                    disabled
                                                                />
                                                                :
                                                                <TextField
                                                                    name="price"
                                                                    label="Prezzo"
                                                                    value={this.state.arrayProduct[key].price}
                                                                    onChange={(event) => {
                                                                        this.updateItem(key, {
                                                                            productName: this.state.arrayProduct[key].productName,
                                                                            qty: this.state.arrayProduct[key].qty,
                                                                            price: parseFloat(event.target.value),
                                                                        })
                                                                    }}
                                                                    type="number"
                                                                />
                                                        }
                                                    </div>
                                                    <div className="col">
                                                        <div className="iconCancel" onClick={() => { this.removeProduct(key) }}>
                                                            <i className="fas fa-times"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }

                        {
                            // se la quantità di prodotti che voglio rimborsare raggiunge la quantità di prodotti disponibili, togli il pulsante
                            this.state.arrayProduct.length < this.state.qtyProductsEntry && this.state.qty !== this.state.qtyRefunded &&
                            <div className="row">
                                <div className="col-1" style={{ 'padding': '0' }}>
                                    <div className="iconPlus" onClick={this.addProduct}>
                                        <i className="fas fa-plus"></i>
                                    </div>
                                </div>
                                <div className="col" style={{ 'paddingLeft': '0' }}>
                                    <hr className="hrForPlus" />
                                </div>
                            </div>
                        }

                    </ModalBody>
                    <ModalFooter>
                        {
                            this.state.qty !== this.state.qtyRefunded ?
                                <button className="btn btn-success" onClick={this.verifyProduct}>Avvia Rimborso</button>
                                :
                                ""
                        }
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment >
        )
    }
}