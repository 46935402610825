import { connect } from 'react-redux'
import { playerActions, notifyActions } from '../store/actions';
import pagePlaylists from './pages/playlists';

const mapStateToProps = state => {
    return {
        ...state,
        player: state.player,
        role: state.role,
        user: state.user
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getAllPlayList: (data) => {
            dispatch(playerActions.getAllPlayList(data))
        },
        sendNewPlaylist: (data) => {
            dispatch(playerActions.sendNewPlaylist(data))
        },
        sendModifyPlaylist: (data) => {
            dispatch(playerActions.sendModifyPlaylist(data))
        },
        removePlaylist: (data) => {
            dispatch(playerActions.removePlaylist(data));
        },
        changeDns: (data) => {
            dispatch(playerActions.changeDns(data));
        },
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(pagePlaylists);