import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { isEmpty } from 'lodash';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormControl, TextField, InputLabel, Select, MenuItem } from '@material-ui/core';
import 'date-fns';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import './style.scss';
import { preferencesActions } from '../../store/actions';

/**
 * Attivazione modale per aggiungere una vpn pass/account
 * 
 * titleModal: titolo della modale
 * click: funzione da attivare al click di OK
 * stateModal: stato della modale (booleano)
 * successNotify
 * errorNotify
 * warningNotify
 * toggle: per chiudere la modale
 * key: non viene passato per le operazioni di modale ma solo per permetter di resettare il costruttore ogni volta che apro la modale
 */
class modalVpnPass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productName: this.props.vpnTempEdit.productName || '',
            createdAt: this.props.vpnTempEdit.createdAt || null,
            expireAt: this.props.vpnTempEdit.expireAt || null,
            maxAllowedPeers: this.props.vpnTempEdit.maxAllowedPeers || '',
            user: this.props.vpnTempEdit.user || '',
            paymentId: this.props.vpnTempEdit.paymentId || '',
            // bundleIn: this.props.vpnTempEdit.bundleIn || '',
            id: this.props.vpnTempEdit._id || '',
            description: this.props.vpnTempEdit.description || '',

            formIsValid: false,

            // variabile di controllo per verificare se sono in "Modifica Pass"
            inEdit: !isEmpty(this.props.vpnTempEdit)
        }
        this.setChange = this.setChange.bind(this);
        this.setCreatedAt = this.setCreatedAt.bind(this);
        this.setExpireAt = this.setExpireAt.bind(this);
    }

    componentDidMount() {
        this.props.getProductList();
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    setCreatedAt = (newDate) => {
        this.setState({ createdAt: moment(newDate).toISOString() });
    }

    setExpireAt = (newDate) => {
        this.setState({ expireAt: moment(newDate).toISOString() });
    }

    /**
     * Se i dati sono corretti, prepara l'oggetto da mandare al BE
     * per aggiungere o modificare una VPN
     */
    sendVpn = () => {
        if (this.formIsValid()) {
            let data = {};
            if (this.state.id === '') {
                data = {
                    createdAt: this.state.createdAt,
                    expireAt: this.state.expireAt,
                    maxAllowedPeers: parseInt(this.state.maxAllowedPeers),
                    user: this.state.user,
                    paymentId: this.state.paymentId,
                    description: this.state.description
                }
            } else {
                data = {
                    id: this.state.id,
                    productName: this.state.productName,
                    createdAt: this.state.createdAt,
                    expireAt: this.state.expireAt,
                    maxAllowedPeers: parseInt(this.state.maxAllowedPeers),
                    user: this.state.user,
                    paymentId: this.state.paymentId,
                    description: this.state.description
                }
            }
            this.props.click(data);
        } else {
            this.props.errorNotify('Compila tutti i campi!');
        }

    }

    /**
     * Controlla se i campi sono stati inseriti tutti
     */
    formIsValid = () => {
        if (isNaN(this.state.maxAllowedPeers)) {
            this.props.errorNotify('Devi inserire un numero intero come numero massimo peers!');
            return false;
        }
        if (
            (this.state.productName === '' && this.state.inEdit) ||
            this.state.maxAllowedPeers === '' ||
            this.state.createdAt === null ||
            this.state.expireAt === null
        ) {
            this.setState({ formIsValid: true });
            return false;
        } else {
            this.setState({ formIsValid: false });
            return true;
        }
    }

    render() {
        const { stateModal, toggle, titleModal, preferences } = this.props;

        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalVpn">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        <FormControl className="choose-form">
                            <div className="row">
                                {
                                    this.state.inEdit &&
                                    <div className="col-6">
                                        <FormControl>
                                            <InputLabel>Prodotto</InputLabel>
                                            <Select
                                                labelId="typeProductLabel"
                                                name="productName"
                                                value={this.state.productName}
                                                onChange={this.setChange}
                                                error={this.state.productName === '' && this.state.formIsValid}
                                            >
                                                {
                                                    // eslint-disable-next-line
                                                    preferences.allProducts.map((item, key) => {
                                                        if(item.type === "VPN-PASS" || item.type === "VPN-BOX"){
                                                            return (
                                                                <MenuItem key={key} value={item.name}> {item.name} </MenuItem>
                                                            )
                                                        }
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                }
                                <div className="col-6">
                                    <TextField
                                        name="maxAllowedPeers"
                                        label="Numero Massimo Peers"
                                        value={this.state.maxAllowedPeers}
                                        onChange={this.setChange}
                                        error={(this.state.maxAllowedPeers === '' && this.state.formIsValid) || (isNaN(this.state.maxAllowedPeers))}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4">
                                    <TextField
                                        name="user"
                                        label="Associa utente"
                                        value={this.state.user}
                                        onChange={this.setChange}
                                        error={this.state.user === '' && this.state.formIsValid}
                                    />
                                </div>

                                <div className="col-4">
                                    <TextField
                                        name="description"
                                        label="Descrizione"
                                        value={this.state.description}
                                        onChange={this.setChange}
                                    />
                                </div>

                                <div className="col-4">
                                    <TextField
                                        name="paymentId"
                                        label="ID Pagamento"
                                        value={this.state.paymentId}
                                        onChange={this.setChange}
                                        error={this.state.paymentId === '' && this.state.formIsValid}
                                    />
                                </div>
                            </div>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="row">
                                    <div className="col-6">
                                        <KeyboardDatePicker
                                            id="createdAtPass"
                                            variant="inline"
                                            label="A partire da"
                                            value={this.state.createdAt}
                                            onChange={this.setCreatedAt}
                                            format="dd/MM/yyyy"
                                            helperText={(this.state.createdAt === null && this.state.formIsValid) && "Data Obbligatoria"}
                                        />
                                    </div>

                                    <div className="col-6">
                                        <KeyboardDatePicker
                                            id="expireAtPass"
                                            variant="inline"
                                            label="Fino al"
                                            value={this.state.expireAt}
                                            onChange={this.setExpireAt}
                                            format="dd/MM/yyyy"
                                            // se la data di creazione è presente, disabilita tutte le date precedenti a quella data
                                            minDate={this.state.createdAt ? moment(this.state.createdAt)._d : false}
                                            minDateMessage="Attenzione! Data precedente a quella di creazione!"
                                            helperText={(this.state.expireAt === null && this.state.formIsValid) && "Data Obbligatoria"}
                                        />
                                    </div>
                                </div>
                            </MuiPickersUtilsProvider>
                        </FormControl>

                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.sendVpn}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        preferences: state.preferences
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProductList: (productType) => {
            dispatch(preferencesActions.getProductList(productType))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(modalVpnPass);