import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Select, FormControl, MenuItem, InputLabel, TextField } from '@material-ui/core';

import { countries } from '../countries';
import { returnNationality } from '../actions/returnNationality';
import { returnTypeName } from '../actions/returnTypeName';
import './style.scss';

export default class modalPricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyReference: this.props.keyReference || 'AF', // inserisco AF poichè la select dei country ha preselezionato Afghanistan, quindi a meno che non si cambi, rimane questo valore
            subKey: this.props.subKeyReference || '',
            // keyIp: this.props.keyIp || '',
            elementReference: this.props.elementReference || {},

            priceReference: this.props.elementReference.Price || '', // viariabile temporanea usata per salvare il valore di price inserito dall'utente

            listProductCoupon: this.props.preferences.listProductCoupon || [],
            listProductVpnPass: this.props.preferences.listProductVpnPass || [],
            listProductPlayer: this.props.preferences.listProductPlayer || [],

            allElementPricings: this.props.allElementPricings || [],
            allElementExclusions: this.props.allElementExclusions || []
        }
        this.setChange = this.setChange.bind(this);
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    confirmSend = () => {
        const { type, clickNewKey, clickRemoveKey, clickEdit } = this.props;

        if (this.state.keyReference === "" && type === 'newKey') {
            this.props.errorNotify('Inserisci una chiave!');
        } else {
            if (type === 'newKey') {
                clickNewKey(this.state.keyReference);
            } else if (type === 'edit') {
                let data = {
                    ProductName: this.state.elementReference.ProductName,
                    ProductType: this.state.elementReference.ProductType,
                    Price: this.state.priceReference,
                }
                clickEdit(this.state.keyReference, this.state.subKey, data);
            } else if (type === 'exclude') {
                let data = {
                    ProductName: this.state.elementReference.ProductName,
                    ProductType: this.state.elementReference.ProductType
                }
                clickEdit(this.state.keyReference, this.state.subKey, data);
            } else if (type === 'include') {
                clickEdit(this.state.keyReference, this.state.subKey, this.state.elementReference);
            } else if (type === 'removeKey') {
                clickRemoveKey(this.state.keyReference);
            }
        }
    }

    render() {
        const { stateModal, toggle, titleModal, type } = this.props;
        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalPricing">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        {
                            type === 'newKey' && // creazione di un nuovo country
                            <Fragment>
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <FormControl>
                                            <InputLabel>Nuova Nazione</InputLabel>
                                            <Select
                                                labelId="keyReferenceNation"
                                                name="keyReference"
                                                value={this.state.keyReference}
                                                onChange={this.setChange}
                                            >
                                                {
                                                    countries.map((item, k) => {
                                                        return (
                                                            <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </Fragment>
                        }

                        {
                            type === 'edit' &&
                            <Fragment>
                                <div className="row justify-content-center">
                                    <div className="col-12 text-center">
                                        <p>
                                            Modifica del prezzo del &nbsp;
                                        <b>{returnTypeName(this.state.elementReference.ProductType).toUpperCase()} - {returnTypeName(this.state.elementReference.ProductName).toUpperCase()}</b> &nbsp;
                                        in &nbsp;
                                        <b>{returnNationality(this.state.keyReference).toUpperCase()}</b>
                                        </p>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-2 text-center">
                                        <TextField
                                            // type="number"
                                            name="priceReference"
                                            value={this.state.priceReference}
                                            onChange={this.setChange}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        }

                        {
                            type === 'exclude' && // esclusione di un prodotto
                            <div className="row justify-content-center">
                                <div className="col-12 text-center">
                                    <p>
                                        Sei sicuro di voler escludere &nbsp;
                                        <b>{returnTypeName(this.state.elementReference.ProductType).toUpperCase()} - {returnTypeName(this.state.elementReference.ProductName).toUpperCase()}</b> &nbsp;
                                        in &nbsp;
                                        <b>{returnNationality(this.state.keyReference).toUpperCase()}</b>?
                                    </p>
                                </div>
                            </div>
                        }
                        
                        {
                            type === 'include' && // inclusione di un prodotto
                            <div className="row justify-content-center">
                                <div className="col-12 text-center">
                                    <p>
                                        Sei sicuro di voler includere &nbsp;
                                        <b>{returnTypeName(this.state.elementReference.ProductType).toUpperCase()} - {returnTypeName(this.state.elementReference.ProductName).toUpperCase()}</b> &nbsp;
                                        in &nbsp;
                                        <b>{returnNationality(this.state.keyReference).toUpperCase()}</b>?
                                    </p>
                                </div>
                            </div>
                        }

                        {
                            type === 'removeKey' && // rimozione di un country
                            <div>
                                Sei sicuro di voler rimuovere tutto l'oggetto country <b>{returnNationality(this.state.keyReference)}</b>? <br />
                                <p style={{ 'color': 'red' }}>Verranno cancellati tutti i pricing ed esclusioni collegati ad esso!!!</p>
                            </div>
                        }

                    </ModalBody>

                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.confirmSend}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>

                </Modal>
            </Fragment>
        )
    }
}