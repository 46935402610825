import React, { Component, Fragment } from 'react';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import './style.scss';
import Modal from '../../common/modal';
import { controlRole } from '../../common/actions/controlRole';
import { isUndefined } from 'lodash';

export default class actionMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setOpen: false,
            modal: false,
            titleModal: '',
            contentModal: '',
            action: ''
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggle = () => {
        this.setState({ setOpen: !this.state.setOpen });
    }

    toggleModal() {
        this.setState(prevState => ({ modal: !prevState.modal }));
    }

    /**
     * Invia all'azione del click, il tipo di azione e i dati del player
     */
    actionClick = (action, player) => {
        this.props.actionClick(action, player);
    }

    setInfoModal = (titleModal, contentModal, action) => {
        this.setState({
            titleModal: titleModal,
            contentModal: contentModal,
            action: action
        });
        this.setState(prevState => ({ modal: !prevState.modal }));
    }

    render() {
        const { role, player, playlist } = this.props;

        return (
            <Fragment>
                <ButtonDropdown isOpen={this.state.setOpen} toggle={this.toggle}>
                    <DropdownToggle caret>
                        <i className="fas fa-ellipsis-v"></i>
                    </DropdownToggle>
                    {
                        !isUndefined(playlist) ?
                            <DropdownMenu>
                                {
                                    controlRole(role.player, "api/Player/UpdatePlaylist", "PUT") &&
                                    <DropdownItem onClick={() => { this.props.actionClick('editPlaylist', playlist.id, playlist.deviceId, playlist.title, playlist.source); }}>
                                        <div className="row">
                                            <div className="col-3 icon editPlayer">
                                                <i className="far fa-edit"></i>
                                            </div>
                                            <div className="col-9">
                                                Modifica Playlist
                                            </div>
                                        </div>
                                    </DropdownItem>
                                }

                                {
                                    controlRole(role.player, "api/Player/RemovePlaylist", "DELETE") &&
                                    <DropdownItem onClick={() => { this.props.actionClick('removePlaylist', playlist.id, playlist.deviceId); }}>
                                        <div className="row">
                                            <div className="col-3 icon deactiveLicence">
                                                <i className="far fa-thumbs-down"></i>
                                            </div>
                                            <div className="col-9">
                                                Rimuovi Playlist
                                            </div>
                                        </div>
                                    </DropdownItem>
                                }
                            </DropdownMenu>
                            :
                            <DropdownMenu>
                                {
                                    (
                                        player.licence !== null &&
                                        player.licence.expireAt === null &&
                                        controlRole(role.player, "api/Player", "PUT")
                                    ) &&
                                    <DropdownItem onClick={() => { this.setInfoModal('Disattiva Licenza', 'Verrà assegnata una licenza non valida a questo player. Continuare ?', 'deactiveLicence'); }}>
                                        <div className="row">
                                            <div className="col-3 icon deactiveLicence">
                                                <i className="far fa-thumbs-down"></i>
                                            </div>
                                            <div className="col-9">
                                                Disattiva Licenza
                                            </div>
                                        </div>
                                    </DropdownItem>
                                }

                                {
                                    controlRole(role.player, "api/Player/Reboot", "POST") &&
                                    <DropdownItem onClick={() => { this.setInfoModal('Riavvia Player', 'Sei sicuro di voler riavviare questo player?', 'refreshPlayer'); }}>
                                        <div className="row">
                                            <div className="col-3 icon refreshPlayer">
                                                <i className="fas fa-sync-alt"></i>
                                            </div>
                                            <div className="col-9 playerLabel">
                                                Riavvia Player
                                            </div>
                                        </div>
                                    </DropdownItem>
                                }

                                {
                                    controlRole(role.player, "api/Player/SendMessage", "POST") &&
                                    <DropdownItem onClick={() => { this.actionClick('sendMessagePlayer', player); }}>
                                        <div className="row">
                                            <div className="col-3 icon sendMessagePlayer">
                                                <i className="fas fa-comment-dots"></i>
                                            </div>
                                            <div className="col-9 playerLabel">
                                                Messaggio al Player
                                            </div>
                                        </div>
                                    </DropdownItem>
                                }

                                {
                                    controlRole(role.player, "api/Player", "PUT") &&
                                    <DropdownItem onClick={() => { this.actionClick('editPlayer', player); }}>
                                        <div className="row">
                                            <div className="col-3 icon editPlayer">
                                                <i className="far fa-edit"></i>
                                            </div>
                                            <div className="col-9 playerLabel">
                                                Modifica Player
                                            </div>
                                        </div>
                                    </DropdownItem>
                                }

                                {
                                    controlRole(role.player, "api/Player", "DELETE") &&
                                    <DropdownItem onClick={() => { this.setInfoModal('Elimina Player', 'Sei sicuro di voler eliminare questo player?', 'deletePlayer'); }}>
                                        <div className="row">
                                            <div className="col-3 icon deletePlayer">
                                                <i className="far fa-trash-alt"></i>
                                            </div>
                                            <div className="col-9 playerLabel">
                                                Rimuovi Player
                                            </div>
                                        </div>
                                    </DropdownItem>
                                }

                            </DropdownMenu>
                    }
                </ButtonDropdown>

                <Modal
                    click={() => { this.actionClick(this.state.action, player); this.toggleModal(); }}
                    titleModal={this.state.titleModal}
                    contentModal={this.state.contentModal}
                    toggle={this.toggleModal}
                    stateModal={this.state.modal}
                />
            </Fragment>
        )
    }
}