import { connect } from 'react-redux'
import { preferencesActions, notifyActions, groupActions } from '../store/actions';
import pagePricing from './pages/groups';

const mapStateToProps = state => {
    return {
        ...state,
        preferences: state.preferences,
        role: state.role,
        group: state.group,
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getGroupList: () => {
            dispatch(groupActions.getGroupList())
        },
        addGroup: (data) => {
            dispatch(groupActions.addGroup(data))
        },
        updateGroup: (data) => {
            dispatch(groupActions.updateGroup(data))
        },
        deleteGroup: (data) => {
            dispatch(groupActions.deleteGroup(data))
        },
        flush: () => {
            dispatch(preferencesActions.flush())
        },
        flushConfig: () => {
            dispatch(preferencesActions.flushConfig())
        },
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(pagePricing);