import { connect } from 'react-redux'
import { stockActions, notifyActions, preferencesActions } from '../store/actions';
import pageVpnBoxStock from './pages/vpnBoxStock';

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user,
        vpn: state.vpn,
        role: state.role,
        stock: state.stock,
        preferences: state.preferences,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getVpnBoxStockInfo: () => {
            dispatch(stockActions.getVpnBoxStockInfo())
        },
        getVpnBoxStock: (data) => {
            dispatch(stockActions.getVpnBoxStock(data))
        },
        addVpnStockMovement: (data) => {
            dispatch(stockActions.addVpnStockMovement(data))
        },
        sellVpnStock: (data) => {
            dispatch(stockActions.sellVpnStock(data))
        },
        removeVpnStockMovement: (id) => {
            dispatch(stockActions.removeVpnStockMovement(id))
        },
        associateTracking: (id, data) => {
            dispatch(stockActions.associateTracking(id, data))
        },
        startRender: (id, data) => {
            dispatch(stockActions.startRender(id, data))
        },
        rebook: (id) => {
            dispatch(stockActions.rebook(id))
        },
        endRender: (id) => {
            dispatch(stockActions.endRender(id))
        },
        getProductList: (data) => {
            dispatch(preferencesActions.getProductList(data))
        },
        getCountryPurchasableList: () => {
            dispatch(preferencesActions.getCountryPurchasableList())
        },
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(pageVpnBoxStock);