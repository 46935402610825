import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers';
import promise from 'redux-promise-middleware';
import { createStore, applyMiddleware, compose  } from 'redux';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middleWare = [thunk, promise];

if (process.env.NODE_ENV === 'development') {
    middleWare = [thunk, promise, logger];
}

const store = createStore(rootReducer, composeEnhancers(applyMiddleware( ...middleWare )));

export default store;