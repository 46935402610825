import React, { Component } from 'react';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { controlRole } from '../../common/actions/controlRole';
import './style.scss';

export default class actionMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setOpen: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle = () => {
        this.setState({ setOpen: !this.state.setOpen });
    }

    render() {
        const { role, promo } = this.props;
        return (
            <ButtonDropdown isOpen={this.state.setOpen} toggle={this.toggle}>
                <DropdownToggle caret>
                    <i className="fas fa-ellipsis-v"></i>
                </DropdownToggle>

                <DropdownMenu>
                    {
                        (controlRole(role.promo, "api/Promo/Coupon", "PUT") || controlRole(role.promo, "api/Promo", "PUT")) &&
                        <DropdownItem onClick={() => { this.props.actionClick('editPromo', promo); }}>
                            <div className="row">
                                <div className="col-3 icon editPlayer">
                                    <i className="far fa-edit"></i>
                                </div>
                                <div className="col-9">
                                    Modifica {promo.isCoupon ? 'coupon' : 'promo'}
                                </div>
                            </div>
                        </DropdownItem>
                    }

                    {
                        promo.isCoupon &&
                        <DropdownItem onClick={() => { this.props.actionClick('assignedCoupon', promo); }}>
                            <div className="row">
                                <div className="col-3 icon assignedCoupon">
                                    <i className={promo.assignedCoupon ? "fas fa-user-alt-slash" : "fas fa-user-check"}></i>
                                </div>
                                <div className="col-9">
                                    {promo.assignedCoupon ? "Libera Coupon" : "Assegna Coupon"}
                                </div>
                            </div>
                        </DropdownItem>
                    }

                    {
                        controlRole(role.promo, "api/Promo/DeleteMany", "DELETE") &&
                        <DropdownItem onClick={() => { this.props.actionClick('deletePromo', promo); }}>
                            <div className="row">
                                <div className="col-3 icon deletePlayer">
                                    <i className="far fa-trash-alt"></i>
                                </div>
                                <div className="col-9">
                                    Rimuovi {promo.isCoupon ? 'coupon' : 'promo'}
                                </div>
                            </div>
                        </DropdownItem>
                    }
                </DropdownMenu>
            </ButtonDropdown>
        )
    }
}