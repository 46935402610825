import React, { Component, Fragment } from 'react';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { controlRole } from '../../common/actions/controlRole';
import { SUPER_ADMIN } from '../../config';

export default class actionMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setOpen: false,
        }
    }

    toggle = () => {
        this.setState({ setOpen: !this.state.setOpen });
    }

    render() {
        const { role, row, user,
            hasClickDeleteVpnBoxStock, hasClickAssociateTracking, hasClickStartRender, hasClickEndRender, hasClickRebook
        } = this.props;
        
        return (
            <Fragment>
                <ButtonDropdown isOpen={this.state.setOpen} toggle={this.toggle}>
                    <DropdownToggle caret>
                        <i className="fas fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu>
                        {
                            (controlRole(role.vpnBoxStock, "api/VpnBoxStocks", "PUT") && row.operationType === 'B') &&
                            <DropdownItem onClick={() => hasClickAssociateTracking(row)}>
                                <div className="row">
                                    <div className="col-3 icon refundPayment">
                                        <i className="fas fa-shipping-fast"></i>
                                    </div>
                                    <div className="col-9 label">
                                        Gestisci Ordine
                                    </div>
                                </div>
                            </DropdownItem>
                        }
                        {
                            (controlRole(role.vpnBoxStock, "api/VpnBoxStocks/startReturn", "POST") && row.operationType === 'U') &&
                            <DropdownItem onClick={() => hasClickStartRender(row)}>
                                <div className="row">
                                    <div className="col-3 icon downloadPayment">
                                        <i className="fas fa-wrench"></i>
                                    </div>
                                    <div className="col-9 label">
                                        Reso / Assistenza
                                    </div>
                                </div>
                            </DropdownItem>
                        }
                        {
                            (controlRole(role.vpnBoxStock, "api/VpnBoxStocks/endReturn", "PUT") && (row.operationType === 'R' || row.operationType === 'D')) &&
                            <DropdownItem onClick={() => hasClickEndRender(row)}>
                                <div className="row">
                                    <div className="col-3 icon downloadPayment">
                                        <i className="fas fa-wrench"></i>
                                    </div>
                                    <div className="col-9 label">
                                        {row.operationType === 'R' && 'Chiudi Reso'}
                                        {row.operationType === 'D' && 'Chiudi Assistenza'}
                                    </div>
                                </div>
                            </DropdownItem>
                        }
                        {
                            (controlRole(role.vpnBoxStock, "api/VpnBoxStocks/rebook", "PUT") && (row.operationType === 'DD')) &&
                            <DropdownItem onClick={() => hasClickRebook(row)}>
                                <div className="row">
                                    <div className="col-3 icon downloadPayment">
                                        <i className="fas fa-truck-loading"></i>
                                    </div>
                                    <div className="col-9 label">
                                        Rimetti in Booking
                                    </div>
                                </div>
                            </DropdownItem>
                        }
                        {
                            // controlRole(role.vpnBoxStock, "api/VpnBoxStocks", "DELETE") &&
                            user.user.role === SUPER_ADMIN &&
                            <DropdownItem onClick={() => hasClickDeleteVpnBoxStock(row)}>
                                <div className="row">
                                    <div className="col-3 icon deletePayment">
                                        <i className="far fa-trash-alt"></i>
                                    </div>
                                    <div className="col-9 label">
                                        Elimina Movimento
                                    </div>
                                </div>
                            </DropdownItem>
                        }
                    </DropdownMenu>
                </ButtonDropdown>
            </Fragment>
        )
    }
}