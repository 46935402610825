import axios from 'axios';
import store from '../index';
import { promoActionType, notifyActions, userActionType } from './';

import {
  API_USER,
  API_PROMO,
  API_COUPON,
  API_DELETE_MANY_PROMO,
  API_GET_PROMO_LIST
} from '../../config';

export const promoActions = {
  getListDevice,
  getPromoList,
  sendNewPromo,
  sendEditPromo,
  deletePromo
};

/**
 * Chiama il BE e ritorna la lista delle PROMO e dei COUPONS.
 * 
 * @param {Object} data
 */
function getPromoList(data, isReset) {
  return (dispatch) => {
    dispatch({
      type: promoActionType.GET_PROMO_LIST,
      async payload() {
        try {
          if (isReset) {
            return {
              totalElements: '',
              totalPages: '',
              promoList: [],
              paramsPromoTemp: {},
            }

          } else {
            let response = await axios.get(`${API_GET_PROMO_LIST}`, { params: data });

            return {
              totalElements: response.data.info.total,
              totalPages: response.data.info.totalPages,
              paramsPromoTemp: data,
              promoList: response.data.value.value,
            }
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: notifyActions.errorAction('Inserisci un numero valido!'); break;
              case 403: notifyActions.errorAction('Error 403 - Non sei autorizzato!'); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_GET_PROMO_LIST} - GET`)); break;
            }
          }

          return {
            totalElements: '',
            totalPages: '',
            promoList: [],
            paramsPromoTemp: {},
          }
        }
      }
    })
  }
}

/**
 * Chiamata per ricevere le emails degli utenti dal DB per utilizzarle nella select dei webUsers.
 * Quando isNewPromoUserInDetail è false la chiamata non viene effettuata perchè dalla sezione UserInDetails non posso selezionare l'email,
 * il campo è già precompilato.
 * 
*/
function getListDevice(email) {
  return (dispatch) => {
    dispatch({
      type: userActionType.GET_LIST_EMAILS,
      async payload() {
        try {
          let params = {
            'elementsByPage': '10',
            'filters[0][Id]': email,
          };

          let response = await axios.get(`${API_USER}`, { params: params });
          let arrayTemp = [];

          Object.values(response.data.value.value).forEach((k) => {
            arrayTemp.push({ label: k.id, value: k.id });
          })

          return {
            listEmails: arrayTemp
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_USER} - GET`)); break;
            }
          }

          return {
            listEmails: []
          }
        }
      }
    })
  }
}

/**
 * Crea una promo/coupon
 * 
 * @param {Object} data dati della promo da mandare per la creazione
 * @param {Boolean} isCoupon se true chiama l'API per creare un coupon, se false chiama l'API per creare una promo associata ad un utente
 */
function sendNewPromo(data, isCoupon) {
  return (dispatch) => {
    dispatch({
      type: promoActionType.SEND_NEW_PROMO,
      async payload() {
        try {
          if (isCoupon) {
            if (data.listCoupon) {
              await axios.request({
                url: API_COUPON,
                method: 'POST',
                params: { "listCoupon": data.listCoupon },
                data: data,
                // responseType: 'blob'
              })
                .then(({ data }) => {
                  let tempArray = [];
                  // eslint-disable-next-line
                  data.map((el) => {
                    tempArray.push(el.code);
                  })

                  const downloadUrl = window.URL.createObjectURL(new Blob([tempArray]));
                  const link = document.createElement('a');
                  link.href = downloadUrl;
                  link.setAttribute('download', 'ListCoupon.txt'); //any other extension
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  dispatch(notifyActions.successAction('Coupon creato!'));
                  dispatch(getPromoList(store.getState().promo.paramsPromoTemp, false));
                });
            } else {
              await axios.post(`${API_COUPON}`, data, { params: { "listCoupon": data.listCoupon } })
                .then(() => {
                  dispatch(notifyActions.successAction('Coupon creato!'));
                  dispatch(getPromoList(store.getState().promo.paramsPromoTemp, false));
                });
            }
          } else {
            await axios.post(`${API_PROMO}`, data)
              .then(() => {
                dispatch(notifyActions.successAction('Promo creata!'));
                dispatch(getPromoList(store.getState().promo.paramsPromoTemp, false));
              });
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: dispatch(notifyActions.errorAction('Promo già inserita!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_PROMO} o ${API_COUPON} - POST`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Modifica la promo/coupon dal DB tramite un id promo/coupon
 * 
 * @param {Object} data dati della promo da mandare per la modifica
 * @param {Boolean} isCoupon se true chiama l'API per modificare un coupon, se false chiama l'API per modificare una promo associata ad un utente
 */
function sendEditPromo(data, isCoupon) {
  return (dispatch) => {
    dispatch({
      type: promoActionType.SEND_EDIT_PROMO,
      async payload() {
        try {
          if (isCoupon) {
            await axios.put(`${API_COUPON}`, data)
              .then(() => {
                dispatch(notifyActions.successAction('Coupon modificato!'));
                dispatch(getPromoList(store.getState().promo.paramsPromoTemp, false));
              });
          } else {
            await axios.put(`${API_PROMO}`, data)
              .then(() => {
                dispatch(notifyActions.successAction('Promo modificata!'));
                dispatch(getPromoList(store.getState().promo.paramsPromoTemp, false));
              });
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('Promo non trovata!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_COUPON} o ${API_PROMO} - PUT`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Cancella la promo dal DB tramite un id promo
 * 
 * @param {String} idPromo 
 */
function deletePromo(data) {
  return (dispatch) => {
    dispatch({
      type: promoActionType.DELETE_PROMO,
      async payload() {
        try {
          await axios.delete(`${API_DELETE_MANY_PROMO}`, { data })
            .then(() => {
              dispatch(notifyActions.successAction('Promo eliminate!'));
              dispatch(getPromoList(store.getState().promo.paramsPromoTemp, false));
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('Promo non trovata!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_DELETE_MANY_PROMO} - DELETE`)); break;
            }
          }
        }
      }
    })
  }
}