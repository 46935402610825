import React, { Component, Fragment } from 'react';
import Pagination from '@material-ui/lab/Pagination';

export default class formFiltersPeer extends Component {
    render() {
        const { numberVpnPeer, totalPages, setChange, currentNumberPage } = this.props;
        return (
            <Fragment>
                <div className="row itemForSearch" style={{ 'marginTop': '3rem' }}>
                    <div className="col-12 text-center">
                        <button
                            className={numberVpnPeer === '10' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChange({ target: { name: 'numberVpnPeer' } }, '10')}
                        > 10 </button>
                        <button
                            className={numberVpnPeer === '25' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChange({ target: { name: 'numberVpnPeer' } }, '25')}
                        > 25 </button>
                        <button
                            className={numberVpnPeer === '50' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChange({ target: { name: 'numberVpnPeer' } }, '50')}
                        > 50 </button>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <Pagination
                            boundaryCount={2}
                            count={parseInt(totalPages)}
                            page={parseInt(currentNumberPage)}
                            onChange={setChange}
                            color="primary"
                            showFirstButton
                            showLastButton
                            size="small"
                        />
                    </div>
                </div>
            </Fragment>
        )
    }
}