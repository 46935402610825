import React, { Component, Fragment } from 'react';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import './style.scss';
import Modal from '../../common/modal';
import { controlRole } from '../../common/actions/controlRole';
import { SUPER_ADMIN } from '../../config';

export default class actionMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setOpen: false,
            modal: false,
            titleModal: '',
            contentModal: '',
            action: ''
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggle = () => {
        this.setState({ setOpen: !this.state.setOpen });
    }

    toggleModal() {
        this.setState(prevState => ({ modal: !prevState.modal }));
    }

    actionClick = (action, user) => {
        this.props.actionClick(action, user);
        this.toggleModal();
    }

    setInfoModal = (titleModal, contentModal, action) => {
        this.setState({
            titleModal: titleModal,
            contentModal: contentModal,
            action: action
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 100);
    }

    setReseller = () => {
        if (this.props.user.isReseller) {
            this.setInfoModal('Rimuovi da Reseller', 'Sei sicuro di voler rimuove l\'utente dai Reseller?', 'editUser');

        } else {
            this.setInfoModal('Rendi Reseller', 'Sei sicuro di voler rendere l\'utente Reseller?', 'editUser');

        }
    }

    render() {
        const { role, userRole, user } = this.props;
        return (
            <Fragment>
                <ButtonDropdown isOpen={this.state.setOpen} toggle={this.toggle}>
                    <DropdownToggle caret>
                        <i className="fas fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu>
                        <DropdownItem onClick={() => { this.actionClick('moreDetail', user); }}>
                            <div className="row">
                                <div className="col-3 icon moreDetail">
                                    <i className="fas fa-cogs"></i>
                                </div>
                                <div className="col-9">
                                    Maggiori Dettagli
                                </div>
                            </div>
                        </DropdownItem>

                        {
                            userRole.user.role === SUPER_ADMIN &&
                            <DropdownItem onClick={() => { this.actionClick('editRole', user) }}>
                                <div className="row">
                                    <div className="col-3 icon editRole">
                                        <i className="fas fa-address-book"></i>
                                    </div>
                                    <div className="col-9">
                                        Assegna Ruolo
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            (
                                user.role !== "" &&
                                user.role !== null &&
                                userRole.user.role === SUPER_ADMIN
                            ) &&
                            <DropdownItem onClick={() => { this.setInfoModal('Rimuovi Ruolo', 'Sei sicuro di voler eliminare il ruolo a questo utente?', 'removeRole') }}>
                                <div className="row">
                                    <div className="col-3 icon removeRole">
                                        <i className="fas fa-address-book"></i>
                                    </div>
                                    <div className="col-9">
                                        Rimuovi Ruolo
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            controlRole(role.user, "api/User/Group/AssignGroup", "PATCH") &&
                            <DropdownItem onClick={() => { this.actionClick('editGroup', user) }}>
                                <div className="row">
                                    <div className="col-3 icon editRole">
                                        <i className="fas fa-users-cog"></i>
                                    </div>
                                    <div className="col-9">
                                        Assegna Gruppo
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            controlRole(role.user, "api/User/Address", "PUT") &&
                            <DropdownItem onClick={() => { this.actionClick('editAddress', user) }}>
                                <div className="row">
                                    <div className="col-3 icon editUser">
                                        <i className="fas fa-address-card"></i>
                                    </div>
                                    <div className="col-9">
                                        Modifica Indirizzi
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            controlRole(role.user, "api/User", "PUT") &&
                            <DropdownItem onClick={() => this.setReseller()}>
                                <div className="row">
                                    {
                                        user.isReseller ?
                                            <Fragment>
                                                <div className="col-3 icon editUser">
                                                    <i className="fas fa-address-card"></i>
                                                </div>
                                                <div className="col-9">
                                                    Rimuovi da Reseller
                                                </div>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <div className="col-3 icon editUser">
                                                    <i className="fas fa-address-card"></i>
                                                </div>
                                                <div className="col-9">
                                                    Rendi Reseller
                                                </div>
                                            </Fragment>
                                    }
                                </div>
                            </DropdownItem>
                        }

                        {
                            controlRole(role.user, "api/User", "DELETE") &&
                            <DropdownItem onClick={() => { this.setInfoModal('Elimina Utente', 'Sei sicuro di voler eliminare l\'utente?', 'deleteUser'); }}>
                                <div className="row">
                                    <div className="col-3 icon deleteUser">
                                        <i className="fas fa-user-times"></i>
                                    </div>
                                    <div className="col-9">
                                        Elimina Utente
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            controlRole(role.support, "api/Support/BackOfficeSendMail", "POST") &&
                            <DropdownItem onClick={() => { this.actionClick('sendMail', user) }}>
                                <div className="row">
                                    <div className="col-3 icon sendMail">
                                        <i className="far fa-paper-plane"></i>
                                    </div>
                                    <div className="col-9">
                                        Manda Email
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            controlRole(role.user, "api/User/ResetPassword", "PUT") &&
                            <DropdownItem onClick={() => { this.setInfoModal('Reset Password', 'Sei sicuro di voler resettare la password dell\'utente?', 'resetPassword'); }}>
                                <div className="row">
                                    <div className="col-3 icon resetPassword">
                                        <i className="fas fa-key"></i>
                                    </div>
                                    <div className="col-9">
                                        Resetta Password
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            controlRole(role.user, "api/User/ResetTfa", "PUT") && user.tfa &&
                            <DropdownItem onClick={() => { this.setInfoModal('Reset TFA', 'Sei sicuro di voler resettare il TFA dell\'utente?', 'resetTfa'); }}>
                                <div className="row">
                                    <div className="col-3 icon resetPassword">
                                        <i className="fas fa-key"></i>
                                    </div>
                                    <div className="col-9">
                                        Resetta TFA
                                    </div>
                                </div>
                            </DropdownItem>
                        }
                    </DropdownMenu>
                </ButtonDropdown>

                {
                    (this.state.action === 'resetPassword' ||
                        this.state.action === 'resetTfa' ||
                        this.state.action === 'deleteUser' ||
                        this.state.action === 'editUser' ||
                        this.state.action === 'removeRole') &&

                    <Modal
                        click={() => { this.actionClick(this.state.action, user) }}
                        titleModal={this.state.titleModal}
                        contentModal={this.state.contentModal}
                        toggle={this.toggleModal}
                        stateModal={this.state.modal}
                    />
                }
            </Fragment>
        )
    }
}