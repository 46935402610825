import React, { Component, Fragment } from 'react';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import './style.scss';
import { controlRole } from '../../common/actions/controlRole';

export default class actionMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setOpen: false,

            action: ''
        };
    }

    toggle = () => {
        this.setState({ setOpen: !this.state.setOpen });
    }

    /**
     * Invia all'azione del click, il tipo di azione e i dati dei ruoli
     */
    actionClick = (action, row) => {
        this.props.actionClick(action, row);
    }

    render() {
        const { role, row } = this.props;
        return (
            <Fragment>
                <ButtonDropdown isOpen={this.state.setOpen} toggle={this.toggle}>
                    <DropdownToggle caret>
                        <i className="fas fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu>
                        {
                            controlRole(role.role, "api/Role", "PUT") &&
                            <DropdownItem onClick={() => { this.actionClick('editRole', row); }}>
                                <div className="row">
                                    <div className="col-3 icon editPlayer">
                                        <i className="far fa-edit"></i>
                                    </div>
                                    <div className="col-9">
                                        Modifica Ruolo
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            controlRole(role.role, "api/Role/AssignRole", "PATCH") &&
                            <DropdownItem onClick={() => { this.actionClick('assignRole', row); }}>
                                <div className="row">
                                    <div className="col-3 icon editRole">
                                        <i className="fas fa-address-book"></i>
                                    </div>
                                    <div className="col-9">
                                        Assegna Ruolo
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            controlRole(role.role, "api/Role", "DELETE") &&
                            <DropdownItem onClick={() => { this.actionClick('deleteRole', row); }}>
                            {/* <DropdownItem onClick={() => { this.setInfoModal('Elimina Ruolo', 'Sei sicuro di voler eliminare il ruolo ?', 'deleteRole'); }}> */}
                                <div className="row">
                                    <div className="col-3 icon deletePlayer">
                                        <i className="far fa-trash-alt"></i>
                                    </div>
                                    <div className="col-9">
                                        Rimuovi Ruolo
                                    </div>
                                </div>
                            </DropdownItem>
                        }
                    </DropdownMenu>
                </ButtonDropdown>
            </Fragment>
        )
    }
}