import React, { Component, Fragment } from 'react';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { controlRole } from '../../common/actions/controlRole';

export default class actionMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setOpen: false
        };
    }

    toggle = () => {
        this.setState({ setOpen: !this.state.setOpen });
    }

    /**
     * Invia all'azione del click, il tipo di azione e i dati del player
     */
    actionClick = (action, player) => {
        this.props.actionClick(action, player);
    }

    render() {
        const { role, row } = this.props;
        return (
            <Fragment>
                <ButtonDropdown isOpen={this.state.setOpen} toggle={this.toggle}>
                    <DropdownToggle caret>
                        <i className="fas fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu>
                        {
                            controlRole(role.bundle, "api/Bundle", "PUT") &&
                            <DropdownItem onClick={() => { this.actionClick('editBundle', row); }}>
                                <div className="row">
                                    <div className="col-3 icon bundleIconOrange">
                                        <i className="far fa-edit"></i>
                                    </div>
                                    <div className="col-9 bundleLabel">
                                        Modifica Bundle
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                    </DropdownMenu>
                </ButtonDropdown>
            </Fragment>
        )
    }
}