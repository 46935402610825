import React, { Component } from 'react';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import SideNavMobile from 'sidenavjs';
import { Link } from "react-router-dom";

import MyTooltip from '../../common/tooltip';
import { Fragment } from 'react';
import { controlRole } from '../actions/controlRole';
import Loading from '../loading';
import { isEmpty } from 'lodash';
import { SUPER_ADMIN } from '../../config';
import Logo from '../../images/logoWhite.svg';

export default class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nameLocation: 'home' || this.props.history.location.pathname.split('/'),
            openMenuPlayer: false,
            openMenuVpn: false,
            openMenuStock: false,
            openMenuPromo: false,
            openMenuPreference: false,
        }
    }

    componentDidMount() {
        this.nameLocation();
    }

    componentDidUpdate() {
        localStorage.setItem('path', this.props.history.location.pathname)
        this.nameLocation();
    }

    /**
     * Controlla in quale sezione della dashboard mi trovo, 
     * e seleziona l'area della sidebar in base a dove mi trovo.
     * 
     * Ci sono 4 sezioni, quando aggiungo una voce di menu con sottomenu,
     * bisogna aggiornare tutt e 4 le sezioni con le nuove variabili.
     * 
     * Se aggiungo una voce di menu senza sottosezioni, aggiorno solo la sezione 2
     */
    nameLocation = () => {
        let name = this.props.history.location.pathname.split('/');

        if (this.state.nameLocation !== name[2] && name[2] !== undefined) {
            this.setState({ nameLocation: name[2] });

            // controlla se la sezione è un sottomenu, nel caso di refresh, tieni aperto quel determinato menu
            //SECTION 1
            if ((name[2] === 'devices' || name[2] === 'licences' || name[2] === 'playlists') && !this.state.openMenuPlayer) {
                this.setState({
                    openMenuPlayer: true,
                    openMenuVpn: false,
                    openMenuStock: false,
                    openMenuPromo: false,
                    openMenuPreference: false,
                })
            }
            if ((name[2] === 'machine' || name[2] === 'pass' || name[2] === 'box' || name[2] === 'peer') && !this.state.openMenuVpn) {
                this.setState({
                    openMenuPlayer: false,
                    openMenuVpn: true,
                    openMenuStock: false,
                    openMenuPromo: false,
                    openMenuPreference: false,
                })
            }
            if ((name[2] === 'vpnBox') && !this.state.openMenuStock) {
                this.setState({
                    openMenuPlayer: false,
                    openMenuStock: true,
                    openMenuPromo: false,
                    openMenuVpn: false,
                    openMenuPreference: false,
                })
            }
            if ((name[2] === 'whitelist' || name[2] === 'ivacountry' || name[2] === 'pricing' || name[2] === 'groups') && !this.state.openMenuPreference) {
                this.setState({
                    openMenuPlayer: false,
                    openMenuVpn: false,
                    openMenuStock: false,
                    openMenuPromo: false,
                    openMenuPreference: true,
                })
            }
            if ((name[1] === 'promo' || name[2] === 'createdCoupon' || name[2] === 'buyCoupon' || name[2] === 'expiredCoupon' || name[2] === 'expiredPromo') && !this.state.openMenuPromo) {
                this.setState({
                    openMenuPlayer: false,
                    openMenuVpn: false,
                    openMenuStock: false,
                    openMenuPromo: true,
                    openMenuPreference: false,
                })
            }

        } else if (this.state.nameLocation !== name[1] && name[1] !== undefined && name[2] === undefined) {
            this.setState({
                nameLocation: name[1],
                openMenuPlayer: false,
                openMenuVpn: false,
                openMenuStock: false,
                openMenuPromo: false,
                openMenuPreference: false
            });
        }
    }

    goLink = (link) => {
        this.props.history.push(link);
        this.props.expandSidebarMobile();
    }

    logout = () => {
        this.props.expandSidebarMobile();
        this.props.infoNotify(this.props.language.langProps['Logout.success']);
        this.props.history.push('/login');
    }

    render() {
        const {
            valueExpandSidebarDesktop,
            expandSidebarDesktop,
            valueExpandSidebarMobile,
            expandSidebarMobile,
            user,
            history,
            role
        } = this.props;

        // const nameLocation = this.props.history.location.pathname.split('/')[1];

        return (
            <Fragment>
                {
                    (role.isLoading) ? <Loading /> :
                        <Fragment>
                            {
                                (user.isAuthenticated && user.user.email !== undefined) ?
                                    <SideNav onToggle={expandSidebarDesktop} expanded={valueExpandSidebarDesktop}>

                                        <div className="sidebar-header" >
                                            <img className="logo" src={Logo} alt="Logo Nanomid" />
                                            {/* <button
                                                className="btnToggle sidenav---sidenav-toggle---1KRjR"
                                                aria-expanded={obj.ariaExpanded}
                                                onClick={obj.onClick}
                                            >
                                                {obj.children}
                                            </button> */}
                                        </div>

                                        <Nav
                                            className="sidebar"
                                            selected={this.state.nameLocation === '' ? 'home' : this.state.nameLocation}
                                        >

                                            {
                                                // visualizzo solo se l'utente è abilitato a vedere una delle statistiche, altrimenti non mostrare nulla
                                                (
                                                    controlRole(role.user, "api/User/Statistics", "GET") ||
                                                    controlRole(role.player, "api/Player/Statistics", "GET") ||
                                                    controlRole(role.payment, "api/Payment/Statistics", "GET") ||
                                                    controlRole(role.vpnPass, "api/VpnPass/Statistics", "GET")
                                                ) &&
                                                <NavItem className="item" eventKey="home" onClick={() => { history.push('/') }}>
                                                    <NavIcon>
                                                        <MyTooltip
                                                            title="Home"
                                                            position="right"
                                                            content={<i className="fas fa-home" />}
                                                        />
                                                    </NavIcon>
                                                    <NavText>
                                                        Home
                                                    </NavText>
                                                </NavItem>
                                            }

                                            {
                                                // visualizzo solo se l'utente è abilitato a vedere gli utenti
                                                controlRole(role.user, "api/User", "GET") &&
                                                <NavItem className="item" eventKey="users" onClick={() => { history.push('/users') }}>
                                                    <NavIcon>
                                                        <MyTooltip
                                                            title="Utenti"
                                                            position="right"
                                                            content={<i className="fas fa-users" />}
                                                        />
                                                    </NavIcon>
                                                    <NavText>
                                                        Utenti
                                                    </NavText>
                                                </NavItem>
                                            }

                                            {
                                                (
                                                    !controlRole(role.player, "api/Player/GetPlayers", "GET") &&
                                                    !controlRole(role.player, "api/Player/PlayLists", "GET") &&
                                                    !controlRole(role.player, "api/Player/GetLicences", "GET")
                                                ) ? '' :
                                                    <NavItem className="item"
                                                        eventKey="player"
                                                        expanded={this.state.openMenuPlayer}
                                                    >
                                                        <NavIcon>
                                                            <i className="fas fa-tv" />
                                                        </NavIcon>

                                                        <NavText>
                                                            Players
                                                        </NavText>
                                                        {
                                                            // visualizzo solo se l'utente è abilitato a vedere i players
                                                            controlRole(role.player, "api/Player/GetPlayers", "GET") &&
                                                            <NavItem className="subItem" eventKey="devices" onClick={() => { history.push('/player/devices') }}>
                                                                <NavText>
                                                                    Dispositivi
                                                                </NavText>
                                                            </NavItem>
                                                        }
                                                        {
                                                            // visualizzo solo se l'utente è abilitato a vedere le licenze
                                                            controlRole(role.player, "api/Player/GetLicences", "GET") &&
                                                            <NavItem className="subItem" eventKey="licences" onClick={() => { history.push('/player/licences') }}>
                                                                <NavText>
                                                                    Licenze
                                                                </NavText>
                                                            </NavItem>
                                                        }
                                                        {
                                                            // visualizzo solo se l'utente è abilitato a vedere le playlist
                                                            controlRole(role.player, "api/Player/PlayLists", "GET") &&
                                                            <NavItem className="subItem" eventKey="playlists" onClick={() => { history.push('/player/playlists') }}>
                                                                <NavText>
                                                                    Playlists
                                                                </NavText>
                                                            </NavItem>
                                                        }
                                                    </NavItem>
                                            }

                                            {
                                                // visualizzo solo se l'utente è abilitato a vedere i pagamenti
                                                controlRole(role.payment, "api/Payment", "GET") &&
                                                <NavItem className="item" eventKey="payments" onClick={() => { history.push('/payments') }}>
                                                    <NavIcon>
                                                        <MyTooltip
                                                            title="Pagamenti"
                                                            position="right"
                                                            content={<i className="fas fa-search-dollar" />}
                                                        />
                                                    </NavIcon>
                                                    <NavText>
                                                        Pagamenti
                                                    </NavText>
                                                </NavItem>
                                            }

                                            {
                                                (
                                                    !controlRole(role.vpnMachine, "api/VpnMachine", "GET") &&
                                                    !controlRole(role.vpnPeer, "api/VpnPeer", "GET") &&
                                                    !controlRole(role.vpnPass, "api/VpnPass", "GET") &&
                                                    !controlRole(role.vpnBox, "api/VpnBox", "GET")
                                                ) ? '' :

                                                    <NavItem
                                                        className="item"
                                                        eventKey="vpn"
                                                        expanded={this.state.openMenuVpn}
                                                    >
                                                        <NavIcon>
                                                            <i className="fas fa-globe" />
                                                        </NavIcon>
                                                        <NavText>
                                                            VPN
                                                        </NavText>
                                                        {
                                                            // visualizzo solo se l'utente è abilitato a vedere le vpn machine
                                                            controlRole(role.vpnMachine, "api/VpnMachine", "GET") &&
                                                            <NavItem className="subItem" eventKey="machine" onClick={() => { history.push('/vpn/machine') }}>
                                                                <NavText>
                                                                    Machine
                                                                </NavText>
                                                            </NavItem>
                                                        }
                                                        {
                                                            // visualizzo solo se l'utente è abilitato a vedere le vpn peer
                                                            controlRole(role.vpnPeer, "api/VpnPeer", "GET") &&
                                                            <NavItem className="subItem" eventKey="peer" onClick={() => { history.push('/vpn/peer') }}>
                                                                <NavText>
                                                                    Peer
                                                                </NavText>
                                                            </NavItem>
                                                        }
                                                        {
                                                            // visualizzo solo se l'utente è abilitato a vedere le VPN Pass
                                                            controlRole(role.vpnPass, "api/VpnPass", "GET") &&
                                                            <NavItem className="subItem" eventKey="pass" onClick={() => { history.push('/vpn/pass') }}>
                                                                <NavText>
                                                                    VPN Pass
                                                                </NavText>
                                                            </NavItem>
                                                        }
                                                        {
                                                            // visualizzo solo se l'utente è abilitato a vedere le VPN Box
                                                            controlRole(role.vpnBox, "api/VpnBox", "GET") &&
                                                            <NavItem className="subItem" eventKey="box" onClick={() => { history.push('/vpn/box') }}>
                                                                <NavText>
                                                                    VPN Box
                                                                </NavText>
                                                            </NavItem>
                                                        }
                                                    </NavItem>
                                            }

                                            {
                                                // visualizzo solo se l'utente è abilitato a vedere il bundle
                                                !isEmpty(role.bundle) &&
                                                <NavItem className="item" eventKey="bundle" onClick={() => { history.push('/bundle') }}>
                                                    <NavIcon>
                                                        <MyTooltip
                                                            title="Bundle"
                                                            position="right"
                                                            content={<i className="fas fa-box-open" />}
                                                        />
                                                    </NavIcon>
                                                    <NavText>
                                                        Bundle
                                                    </NavText>
                                                </NavItem>
                                            }

                                            {
                                                (
                                                    !controlRole(role.vpnBoxStock, "api/VpnBoxStocks", "GET")
                                                ) ? '' :

                                                    <NavItem
                                                        className="item"
                                                        eventKey="stock"
                                                        expanded={this.state.openMenuStock}
                                                    >
                                                        <NavIcon>
                                                            <i className="fas fa-shopping-cart" />
                                                        </NavIcon>
                                                        <NavText>
                                                            Stock
                                                        </NavText>
                                                        {
                                                            // visualizzo solo se l'utente è abilitato a vedere lo stock delle vpnBox
                                                            controlRole(role.vpnBoxStock, "api/VpnBoxStocks", "GET") &&
                                                            <NavItem className="subItem" eventKey="vpnBox" onClick={() => { history.push('/stock/vpnBox') }}>
                                                                <NavText>
                                                                    VPN Box
                                                                </NavText>
                                                            </NavItem>
                                                        }
                                                    </NavItem>
                                            }

                                            {
                                                // visualizzo solo se l'utente è abilitato a vedere il worker
                                                !isEmpty(role.worker) &&
                                                <NavItem className="item" eventKey="worker" onClick={() => { history.push('/worker') }}>
                                                    <NavIcon>
                                                        <MyTooltip
                                                            title="Worker"
                                                            position="right"
                                                            content={<i className="fas fa-server" />}
                                                        />
                                                    </NavIcon>
                                                    <NavText>
                                                        Worker
                                                    </NavText>
                                                </NavItem>
                                            }

                                            {
                                                // visualizzo solo se l'utente è abilitato a vedere i ruoli o aggiungerli
                                                user.user.role === SUPER_ADMIN &&
                                                <NavItem className="item" eventKey="role" onClick={() => { history.push('/role') }}>
                                                    <NavIcon>
                                                        <MyTooltip
                                                            title="Ruoli"
                                                            position="right"
                                                            content={<i className="fab fa-accessible-icon" />}
                                                        />
                                                    </NavIcon>
                                                    <NavText>
                                                        Ruoli
                                                    </NavText>
                                                </NavItem>
                                            }

                                            {
                                                // visualizzo solo se l'utente è abilitato a vedere i coupon/promo
                                                !controlRole(role.promo, "api/Promo/GetPromos", "GET") ?
                                                    '' :

                                                    <NavItem
                                                        className="item"
                                                        eventKey="promo"
                                                        expanded={this.state.openMenuPromo}
                                                    >
                                                        <NavIcon>
                                                            <i className="fas fa-gift" />
                                                        </NavIcon>
                                                        <NavText>
                                                            Promo - Coupon
                                                        </NavText>

                                                        <NavItem className="subItem" eventKey="createdPromo" onClick={() => { history.push('/promo/createdPromo') }}>
                                                            <NavText>
                                                                Promo
                                                            </NavText>
                                                        </NavItem>

                                                        <NavItem className="subItem" eventKey="createdCoupon" onClick={() => { history.push('/promo/createdCoupon') }}>
                                                            <NavText>
                                                                Coupon Generati
                                                            </NavText>
                                                        </NavItem>

                                                        <NavItem className="subItem" eventKey="buyCoupon" onClick={() => { history.push('/promo/buyCoupon') }}>
                                                            <NavText>
                                                                Coupon Venduti
                                                            </NavText>
                                                        </NavItem>

                                                        <NavItem className="subItem" eventKey="expiredPromo" onClick={() => { history.push('/promo/expiredPromo') }}>
                                                            <NavText>
                                                                Promo Scadute
                                                            </NavText>
                                                        </NavItem>

                                                        <NavItem className="subItem" eventKey="expiredCoupon" onClick={() => { history.push('/promo/expiredCoupon') }}>
                                                            <NavText>
                                                                Coupon Scaduti
                                                            </NavText>
                                                        </NavItem>
                                                    </NavItem>
                                            }

                                            {
                                                // visualizzo solo se l'utente è abilitato a vedere i changeLog
                                                controlRole(role.changeLog, "api/Changelog", "GET") &&
                                                <NavItem className="item" eventKey="changeLog" onClick={() => { history.push('/changeLog') }}>
                                                    <NavIcon>
                                                        <MyTooltip
                                                            title="News / Change Log"
                                                            position="right"
                                                            content={<i className="fas fa-rss-square" />}
                                                        />
                                                    </NavIcon>
                                                    <NavText>
                                                        Change Log
                                                    </NavText>
                                                </NavItem>
                                            }

                                            {
                                                // visualizzo solo se l'utente è abilitato a vedere le preferences
                                                (
                                                    controlRole(role.preferences, "api/Preferences", "GET") ||
                                                    controlRole(role.user, "api/User/Group", "GET")
                                                ) &&
                                                <NavItem
                                                    className="item"
                                                    eventKey="preferences"
                                                    expanded={this.state.openMenuPreference}
                                                >
                                                    <NavIcon>
                                                        <i className="fas fa-cogs" />
                                                    </NavIcon>
                                                    <NavText>
                                                        Preferenze
                                                    </NavText>
                                                    {
                                                        controlRole(role.preferences, "api/Preferences", "GET") &&
                                                        <NavItem className="subItem" eventKey="whitelist" onClick={() => { history.push('/preferences/whitelist') }}>
                                                            <NavText>
                                                                WhiteList
                                                            </NavText>
                                                        </NavItem>
                                                    }
                                                    {
                                                        controlRole(role.preferences, "api/Preferences", "GET") &&
                                                        <NavItem className="subItem" eventKey="ivacountry" onClick={() => { history.push('/preferences/ivacountry') }}>
                                                            <NavText>
                                                                IVA Country
                                                            </NavText>
                                                        </NavItem>
                                                    }
                                                    {
                                                        controlRole(role.preferences, "api/Preferences", "GET") &&
                                                        <NavItem className="subItem" eventKey="pricing" onClick={() => { history.push('/preferences/pricing') }}>
                                                            <NavText>
                                                                Pricing
                                                            </NavText>
                                                        </NavItem>
                                                    }
                                                    {
                                                        controlRole(role.user, "api/User/Group", "GET") &&
                                                        <NavItem className="subItem" eventKey="groups" onClick={() => { history.push('/preferences/groups') }}>
                                                            <NavText>
                                                                Gruppi
                                                            </NavText>
                                                        </NavItem>
                                                    }
                                                </NavItem>
                                            }

                                            <NavItem className="item" eventKey="logout" onClick={() => { this.props.logout(this.props.history) }}>
                                                <NavIcon>
                                                    <MyTooltip
                                                        title="Esci"
                                                        position="right"
                                                        content={<i className="fas fa-sign-out-alt" />}
                                                    />
                                                </NavIcon>
                                                <NavText>
                                                    Esci
                                                </NavText>
                                            </NavItem>

                                        </Nav>
                                    </SideNav>
                                    :
                                    <SideNav onToggle={expandSidebarDesktop} expanded={valueExpandSidebarDesktop}>
                                        <Toggle
                                            className="btnToggle"
                                            componentClass={(obj) => {
                                                return (
                                                    <div className="sidebar-header" >
                                                        <button
                                                            className="btnToggle sidenav---sidenav-toggle---1KRjR"
                                                            aria-expanded={obj.ariaExpanded}
                                                            onClick={obj.onClick}
                                                        >
                                                            {obj.children}
                                                        </button>
                                                    </div>
                                                )
                                            }}
                                        />

                                        <Nav selected={this.state.nameLocation}>

                                            <NavItem eventKey="home">
                                                <NavIcon>
                                                    <Link to="/dashboard">
                                                        <MyTooltip
                                                            title="Home"
                                                            position="right"
                                                            content={<i className="fas fa-home" style={{ fontSize: '1.75em' }} />}
                                                        />
                                                    </Link>
                                                </NavIcon>
                                                <NavText>
                                                    <Link to="/dashboard" className="itemMenuText">
                                                        Home
                                                    </Link>
                                                </NavText>
                                            </NavItem>
                                        </Nav>
                                    </SideNav>

                            }

                            {/* SIDEBAR MOBILE */}
                            <div className="sidebarMobile">
                                <SideNavMobile
                                    open={valueExpandSidebarMobile}
                                    onSetOpen={expandSidebarMobile}
                                    sidenav={
                                        <Fragment>
                                            {
                                                // visualizzo solo se l'utente è abilitato a vedere una delle statistiche, altrimenti non mostrare nulla
                                                (
                                                    controlRole(role.user, "api/User/Statistics", "GET") ||
                                                    controlRole(role.player, "api/Player/Statistics", "GET") ||
                                                    controlRole(role.payment, "api/Payment/Statistics", "GET") ||
                                                    controlRole(role.vpnPass, "api/VpnPass/Statistics", "GET")
                                                ) &&
                                                <>
                                                    <div className="logo">
                                                        <img src={Logo} alt="Logo Nanomid" />
                                                        <div className="infoVersion">V. {this.props.versionSite}</div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-3 text-center">
                                                            <i className="fas fa-home blue"></i>
                                                        </div>

                                                        <div className="col similHref" onClick={() => { this.goLink('/') }}>
                                                            Home
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            {
                                                !user.isAuthenticated &&
                                                <Fragment>
                                                    <div className="row">
                                                        <div className="col-3 text-center">
                                                            <i className="fas fa-chalkboard-teacher blue"></i>
                                                        </div>
                                                        <div className="col similHref" onClick={() => { this.goLink('/login') }}>
                                                            Login
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            }

                                            {
                                                user.isAuthenticated &&
                                                <Fragment>
                                                    {
                                                        // visualizzo solo se l'utente è abilitato a vedere gli utenti
                                                        controlRole(role.user, "api/User", "GET") &&
                                                        <div className="row">
                                                            <div className="col-3 text-center">
                                                                <i className="fas fa-users blue"></i>
                                                            </div>
                                                            <div className="col similHref" onClick={() => { this.goLink('/users') }}>
                                                                Utenti
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        // visualizzo solo se l'utente è abilitato a vedere i player
                                                        controlRole(role.player, "api/Player/GetPlayers", "GET") &&
                                                        <div className="row">
                                                            <div className="col-3 text-center">
                                                                <i className="fas fa-tv blue"></i>
                                                            </div>
                                                            <div className="col similHref" onClick={() => { this.goLink('/player/devices') }}>
                                                                Dispositivi
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        // visualizzo solo se l'utente è abilitato a vedere i player
                                                        controlRole(role.player, "api/Player/GetLicences", "GET") &&
                                                        <div className="row">
                                                            <div className="col-3 text-center">
                                                                <i className="fas fa-key blue"></i>
                                                            </div>
                                                            <div className="col similHref" onClick={() => { this.goLink('/player/licences') }}>
                                                                Licenze
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        // visualizzo solo se l'utente è abilitato a vedere le playlist
                                                        controlRole(role.player, "api/Player/PlayLists", "GET") &&
                                                        <div className="row">
                                                            <div className="col-3 text-center">
                                                                <i className="fas fa-key blue"></i>
                                                            </div>
                                                            <div className="col similHref" onClick={() => { this.goLink('/player/playlists') }}>
                                                                Playlists
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        // visualizzo solo se l'utente è abilitato a vedere il worker
                                                        !isEmpty(role.worker) &&
                                                        <div className="row">
                                                            <div className="col-3 text-center">
                                                                <i className="fas fa-server blue"></i>
                                                            </div>
                                                            <div className="col similHref" onClick={() => { this.goLink('/worker') }}>
                                                                Worker
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        // visualizzo solo se l'utente è abilitato a vedere i ruoli
                                                        user.user.role === SUPER_ADMIN &&
                                                        <div className="row">
                                                            <div className="col-3 text-center">
                                                                <i className="fab fa-accessible-icon blue"></i>
                                                            </div>
                                                            <div className="col similHref" onClick={() => { this.goLink('/role') }}>
                                                                Ruoli
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        // visualizzo solo se l'utente è abilitato a vedere i pagamenti
                                                        controlRole(role.payment, "api/Payment", "GET") &&
                                                        <div className="row">
                                                            <div className="col-3 text-center">
                                                                <i className="fas fa-search-dollar blue"></i>
                                                            </div>
                                                            <div className="col similHref" onClick={() => { this.goLink('/payments') }}>
                                                                Pagamenti
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        // visualizzo solo se l'utente è abilitato a vedere i changeLog
                                                        controlRole(role.changeLog, "api/Changelog", "GET") &&
                                                        <div className="row">
                                                            <div className="col-3 text-center">
                                                                <i className="fas fa-rss-square blue"></i>
                                                            </div>
                                                            <div className="col similHref" onClick={() => { this.goLink('/changeLog') }}>
                                                                News / Change Log
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        // visualizzo solo se l'utente è abilitato a vedere i coupon/promo
                                                        controlRole(role.promo, "api/Promo/GetPromos", "GET") &&
                                                        <>
                                                            <div className="buttonSections"><p>Promo - Coupon</p></div>
                                                            <div className="row">
                                                                <div className="col-3 text-center">
                                                                    <i className="fas fa-gift blue"></i>
                                                                </div>
                                                                <div className="col similHref" onClick={() => { this.goLink('/promo/createdPromo') }}>
                                                                    Promo
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-3 text-center">
                                                                    <i className="fas fa-gift blue"></i>
                                                                </div>
                                                                <div className="col similHref" onClick={() => { this.goLink('/promo/createdCoupon') }}>
                                                                    Coupon Generati
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-3 text-center">
                                                                    <i className="fas fa-gift blue"></i>
                                                                </div>
                                                                <div className="col similHref" onClick={() => { this.goLink('/promo/buyCoupon') }}>
                                                                    Coupon Venduti
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-3 text-center">
                                                                    <i className="fas fa-gift blue"></i>
                                                                </div>
                                                                <div className="col similHref" onClick={() => { this.goLink('/promo/expiredPromo') }}>
                                                                    Promo Scadute
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-3 text-center">
                                                                    <i className="fas fa-gift blue"></i>
                                                                </div>
                                                                <div className="col similHref" onClick={() => { this.goLink('/promo/expiredCoupon') }}>
                                                                    Coupon Scaduti
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    {
                                                        // visualizzo solo se l'utente è abilitato a vedere le vpnMachine
                                                        controlRole(role.vpnMachine, "api/VpnMachine", "GET") &&
                                                        <>
                                                            <div className="buttonSections"><p>VPN</p></div>
                                                            <div className="row">
                                                                <div className="col-3 text-center">
                                                                    <i className="fas fa-network-wired blue"></i>
                                                                </div>
                                                                <div className="col similHref" onClick={() => { this.goLink('/vpn/machine') }}>
                                                                    VPN Machine
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    {
                                                        // visualizzo solo se l'utente è abilitato a vedere i vpnPeer
                                                        controlRole(role.vpnPeer, "api/VpnPeer", "GET") &&
                                                        <div className="row">
                                                            <div className="col-3 text-center">
                                                                <i className="fas fa-network-wired blue"></i>
                                                            </div>
                                                            <div className="col similHref" onClick={() => { this.goLink('/vpn/peer') }}>
                                                                VPN Peer
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        // visualizzo solo se l'utente è abilitato a vedere le VPN Pass
                                                        controlRole(role.vpnPass, "api/VpnPass", "GET") &&
                                                        <div className="row">
                                                            <div className="col-3 text-center">
                                                                <i className="fas fa-network-wired blue"></i>
                                                            </div>
                                                            <div className="col similHref" onClick={() => { this.goLink('/vpn/pass') }}>
                                                                VPN Pass
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        // visualizzo solo se l'utente è abilitato a vedere le VpnBox
                                                        controlRole(role.vpnBox, "api/VpnBox", "GET") &&
                                                        <div className="row">
                                                            <div className="col-3 text-center">
                                                                <i className="fas fa-user-secret blue"></i>
                                                            </div>
                                                            <div className="col similHref" onClick={() => { this.goLink('/vpn/box') }}>
                                                                VPN Box
                                                            </div>
                                                        </div>
                                                    }


                                                    {
                                                        // visualizzo solo se l'utente è abilitato a vedere le VpnBox
                                                        controlRole(role.vpnBoxStock, "api/VpnBoxStocks", "GET") &&
                                                        <>
                                                            <div className="buttonSections"><p>Stock</p></div>
                                                            <div className="row">
                                                                <div className="col-3 text-center">
                                                                    <i className="fas fa-shopping-cart blue"></i>
                                                                </div>
                                                                <div className="col similHref" onClick={() => { this.goLink('/stock/vpnBox') }}>
                                                                    Stock
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    {
                                                        // visualizzo solo se l'utente è abilitato a vedere le preferenze
                                                        controlRole(role.preferences, "api/Preferences", "GET") &&
                                                        <Fragment>
                                                            <div className="buttonSections"><p>Preferenze</p></div>

                                                            <div className="row">
                                                                <div className="col-3 text-center">
                                                                    <i className="fas fa-list-alt blue"></i>
                                                                </div>
                                                                <div className="col similHref" onClick={() => { this.goLink('/preferences/whitelist') }}>
                                                                    WhiteList
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-3 text-center">
                                                                    <i className="fas fa-cogs blue"></i>
                                                                </div>
                                                                <div className="col similHref" onClick={() => { this.goLink('/preferences/ivacountry') }}>
                                                                    IVA
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-3 text-center">
                                                                    <i className="fas fa-tag blue"></i>
                                                                </div>
                                                                <div className="col similHref" onClick={() => { this.goLink('/preferences/pricing') }}>
                                                                    Pricing
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-3 text-center">
                                                                    <i className="fas fa-users-cog blue"></i>
                                                                </div>
                                                                <div className="col similHref" onClick={() => { this.goLink('/preferences/groups') }}>
                                                                    Gruppi
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    }

                                                    {/* <div className="row">
                                            <div className="col-3 text-center">
                                                <i className="fas fa-hands-helping blue"></i>
                                            </div>
                                            <div className="col similHref" onClick={() => { this.goLink('/preferences/partner') }}>
                                                Partners
                                            </div>
                                        </div> */}

                                                    <hr />

                                                    <div className="row">
                                                        <div className="col-3 text-center">
                                                            <i className="fas fa-sign-out-alt red"></i>
                                                        </div>
                                                        <div className="col red" onClick={() => { this.props.logout(this.props.history) }} >
                                                            Log Out
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            }
                                        </Fragment>
                                    }
                                >
                                    <button className="btn" onClick={() => { this.props.expandSidebarMobile(true) }}>
                                        <i className="fas fa-bars"></i>
                                    </button>
                                </SideNavMobile>
                            </div>
                        </Fragment>
                }
            </Fragment>
        )
    }
}