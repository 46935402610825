import './style.scss';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';

export default class ErrorStatus extends Component {
  render() {
    const { statusCode, errorMsg, errorDesc } = this.props;
    return (
        <div className="errorContainer">
            <h1>{statusCode}</h1>
            <h3>{errorMsg}</h3>
            <p>{errorDesc}</p>
            <Link className="btn btn-orange" to="/">Torna Indietro</Link>
        </div>
    );
  }
}