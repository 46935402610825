import { connect } from 'react-redux';
import Dashboard from './pages';
import { notifyActions } from '../store/actions';

const mapStateToProps = (state) => {
    return {
        ...state,
        user: state.user,
        payment: state.payment,
        vpn: state.vpn,
        player: state.player,
        role: state.role,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);