/**
 * Prende una sigla di un prodotto, e ritorna il nome associato
 */
export var gains = function (sigla) {
    switch (sigla) {
        case 'VIDEO-PLAYER-LIFETIME': return 'Player_Lifetime';
        case 'NANOMID-COUPON-VIDEO-PLAYER-LIFETIME': return 'Coupon_Player';
        case 'VIDEO-PLAYER-ANDROID-LIFETIME': return 'Android_Lifetime';
        case 'PASS-ONE-MONTH': return 'VPN Pass 1 Month';
        case 'PASS-THREE-MONTHS': return 'VPN Pass 3 Months';
        case 'PASS-SIX-MONTHS': return 'VPN Pass 6 Months';
        case 'PASS-TWELVE-MONTHS': return 'VPN Pass 12 Months';
        case 'PASS-TWENTYFOUR-MONTHS': return 'VPN Pass 24 Months';
        case 'PASS-THIRTYSIX-MONTHS': return 'VPN Pass 36 Months';
        case 'SHIPPING': return 'Prezzo Spedizione';
        case 'VPNBOX-THREE-MONTH-HW': return 'Hardware Box 3 Mesi';
        case 'VPNBOX-SIX-MONTH-HW': return 'Hardware Box 6 Mesi';
        case 'VPNBOX-TWELVE-MONTH-HW': return 'Hardware Box 12 Mesi';
        case 'VPNBOX-TWENTYFOUR-MONTH-HW': return 'Hardware Box 24 Mesi';
        default: return '';
    }
}