import { connect } from 'react-redux'
import { preferencesActions, notifyActions } from '../store/actions';
import pagePricing from './pages/pricing';

const mapStateToProps = state => {
    return {
        ...state,
        preferences: state.preferences,
        role: state.role,
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendEditPreferences: (id, data) => {
            dispatch(preferencesActions.sendEditPreferences(id, data))
        },
        deleteKeyFromList: (id, key) => {
            dispatch(preferencesActions.deleteKeyFromList(id, key))
        },
        getProductList: (productType) => {
            dispatch(preferencesActions.getProductList(productType))
        },
        flush: () => {
            dispatch(preferencesActions.flush())
        },
        flushConfig: () => {
            dispatch(preferencesActions.flushConfig())
        },
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(pagePricing);