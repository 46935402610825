import React, { Component, Fragment } from 'react';
import './style.scss';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { API_GET_PLAYER_LIST_URL } from '../../config';

/**
 * Attivazione modale per switchare un pagamento
 * 
 *      "click" : se clicco OK, attivo una determinata funzione
 *      "titleModal" : il titolo della modale quando si apre
 *      "contentModal" : il contenuto che deve essere mostrato nella modale
 *      "toggle" : boolean che apre o chiude la modale
 *      "stateModal" : booleano che indica lo stato della modale (aperta o chiusa)
 */
export default class modalChangePayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceId: this.props.deviceId,
            newDeviceId: '',
            paymentId: this.props.paymentId,
            providerTransactionId: this.props.providerTransactionId,

            newDeviceIdNull: false,

            // parametri per autocomplete
            listPlayers: [],
            deviceIdFromList: '',
            activeSeeAutocomplete: false
        }
    }

    confirm = () => {
        if (this.state.newDeviceId !== '') {
            let data = {
                newDeviceId: this.state.newDeviceId,
                oldDeviceId: this.state.deviceId,
                providerTransactionId: this.state.providerTransactionId
                // paymentId: this.state.paymentId
            }
            this.props.updateDevicePayment(data);
        } else {
            this.props.errorNotify('Inserisci il nuovo Device ID!');
            this.setState({ newDeviceIdNull: true });
        }
    }

    /**
     * Per attivare l'autocompletamento del device,
     * Carica tutti i device appena apri la modale
     */
    componentDidMount() {
        this.getListDevice();
    }

    /**
     * Chiama il BE a ogni cambiamento di carattere dell'id device
     */
    setChangeId = (event) => {
        this.setState({ deviceIdFromList: event.target.value });

        if (event.target.value === '') {
            this.setState({ activeSeeAutocomplete: false });
        } else if (!this.state.activeSeeAutocomplete) {
            this.setState({ activeSeeAutocomplete: true });
        }

        setTimeout(() => {
            this.getListDevice();
        }, 100);
    }

    /**
     * Setta il valore, al click dell'item autocomplete
     */
    setValueChangeId = (value) => {
        this.setState({
            newDeviceId: value,
            deviceIdFromList: value,
            activeSeeAutocomplete: false
        });
    }

    /**
     * Carica "solamente" 10 device per iniziare
     */
    getListDevice = async () => {
        try {
            let params = {
                'elementsByPage': '20',
                'filters[0][DeviceId]': this.state.deviceIdFromList,
            };

            let response = await axios.get(`${API_GET_PLAYER_LIST_URL}`, { params: params });

            let arrayTemp = [];

            Object.values(response.data.value.value).forEach((k) => {
                arrayTemp.push(k._id);
            })

            this.setState({ listPlayers: arrayTemp });


        } catch (result) {
            const { errorNotify } = this.props;
            if (result && result.response && result.response.status) {
                switch (result.response.status) {
                    default: errorNotify(`Error ${result.response.status} su ${API_GET_PLAYER_LIST_URL} - GET`); break;
                }
            }
        }
    }

    render() {
        const { stateModal, toggle, titleModal } = this.props;
        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalPlayer">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Form.Group as={Row}>
                                <Form.Label column sm="4" className="text-right">
                                    ID Pagamento
                                </Form.Label>
                                <Col sm="7">
                                    <Form.Control
                                        type="text"
                                        value={this.state.paymentId}
                                        readOnly
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label column sm="4" className="text-right">
                                    Provider Transaction ID
                                </Form.Label>
                                <Col sm="7">
                                    <Form.Control
                                        type="text"
                                        value={this.state.providerTransactionId}
                                        readOnly
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label column sm="4" className="text-right">
                                    ID Vecchio Device
                                </Form.Label>
                                <Col sm="7">
                                    <Form.Control
                                        type="text"
                                        value={this.state.deviceId}
                                        readOnly
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label column sm="4" className="text-right">
                                    ID Nuovo Device
                                </Form.Label>
                                <Col sm="7">
                                    <Form.Control
                                        type="text"
                                        value={this.state.deviceIdFromList}
                                        onChange={this.setChangeId}
                                    />
                                    {
                                        this.state.activeSeeAutocomplete &&
                                        <ul className="autoCompleteDevice">
                                            {
                                                this.state.listPlayers.map((item, key) => {
                                                    return (
                                                        <li key={key} onClick={() => this.setValueChangeId(item)} >
                                                            {item}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    }
                                </Col>
                            </Form.Group>
                        </Form>

                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.confirm}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}