import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { isNull, isEmpty } from 'lodash';

import './style.scss';
import Loading from '../../common/loading';
import MyTooltip from '../../common/tooltip';
import { API_PREFERENCES, WHITELIST } from '../../config';
import ModalWhiteList from '../../common/modal/modalWhiteList';
import { randomString } from '../../common/actions/randomString';
import { compareString } from '../../common/actions/compareString';
import { controlRole } from '../../common/actions/controlRole';

export default class whitelist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // contiene la lista delle licenze nella whitelist
            getWhiteList: {},

            hasClickNewKey: false,
            hasClickNewIp: false,
            hasClickEdit: false,
            hasClickRemoveIp: false,
            hasClickRemoveKey: false,

            // ip di riferimento nel caso si volesse editare o rimuovere
            ipReference: '',
            // chiave di riferimento nel caso si volesse editare o rimuovere
            keyReference: '',
            // sottochiave di riferimento nel caso si volesse editare o rimuovere
            subKeyReference: '',
            // tutto il contenuto della chiave selezionata (variabile temporanea di appoggio)
            keyContentReference: '',
            // usato per avere come riferimento l'ip da sostituire o eliminare durante l'edit
            keyIp: '',

            // variabili di appoggio per salvare nello stato gli indirizzi ip cui voglio apportare modifiche
            allElementV4: [],
            allElementV6: [],

            shortId: randomString(),

            modal: false,
            isLoading: true,

            loadingForRole: true
        }
        this.toggleModal = this.toggleModal.bind(this);
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare le whitelist, viene rispedito alla home
        if (!isEmpty(this.props.role.preferences)) {
            if (!controlRole(this.props.role.preferences, "api/Preferences", "GET")) {
                this.props.history.push('/');
                this.props.infoNotify('Non hai nessun permesso per visualizzare le whitelist!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    this.getWhiteList();
                }
            }
        }
    }

    componentDidUpdate() {
        // se l'utente non ha nessun permesso per visualizzare le whitelist, viene rispedito alla home
        if (!isEmpty(this.props.role.preferences)) {
            if (!controlRole(this.props.role.preferences, "api/Preferences", "GET")) {
                this.props.history.push('/');
                this.props.infoNotify('Non hai nessun permesso per visualizzare le whitelist!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    this.getWhiteList();
                }
            }
        }
    }

    /**
     * Usato solo per chiudere la modale, poiche resetta tutto lo state
     */
    toggleModal = () => {
        this.setState(prevState => ({ modal: !prevState.modal }));
        this.setState({
            hasClickEdit: false,
            hasClickNewKey: false,
            hasClickNewIp: false,
            hasClickRemoveIp: false,
            hasClickRemoveKey: false,
            ipReference: '',
            keyIp: '',
            keyReference: '',
            subKeyReference: '',
            keyContentReference: '',
            allElementV4: [],
            allElementV6: [],
            shortId: randomString()
        });
    }

    /**
     * Chiama il BE e ritorna la lista degli indirizzi della whitelist
     * 
     * @return type array
     */
    getWhiteList = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await axios.get(`${API_PREFERENCES}/${WHITELIST}`);
            this.setState({
                getWhiteList: response.data,
                isLoading: false
            });
        } catch (result) {
            const { errorNotify } = this.props;
            this.setState({ isLoading: false });
            if (result && result.response && result.response.status) {
                switch (result.response) {
                    default: errorNotify(`Error ${result.response.status} su ${API_PREFERENCES} - GET`); break;
                }
            }
        }
    }

    /**
     * Prima di mandare i dati al BE, bisogna mettere il /32 finale
     * Questa funzione aggiunge il /32 finale a tutti gli indirizzi.
-     */
    resanitizeWhiteList = (list) => {
        let tempList = [];
        // eslint-disable-next-line
        list.map(el => {
            tempList.push(this.resanitizeIp(el));
        });
        this.setState({ getWhiteList: tempList });
    }

    /**
     * Pulisce l'ip rimuovendo /32 alla fine
     * @param {*} ip 
     * @returns 
     */
    sanitizeIp = (ip) => {
        let newIp = ip.split('/');
        return newIp[0];
    }

    /**
     * Aggiunge alla fine dell'ip /32
     * @param {*} ip 
     * @returns 
     */
    resanitizeIp = (ip) => {
        return ip + '/32';
    }

    /**
     * Se viene cliccato il pulsante nuova chiave, attiva la modale per creare un nuovo gruppo
     */
    hasClickNewKey = () => {
        this.setState({ hasClickNewKey: true });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * Se viene cliccato il pulsante nuovo ip, attiva la modale per inviare l'ip nuovo.
     * 
     * @param keyName è il nome della chiave (es: Player)
     * @param keyContent è il contenuto di tutta la chiave (es: Contenuto di Player)
     * @param subKey è il nome contenuto nella chiave (es: Ipv4)
     * 
     */
    hasClickNewIp = (keyName, keyContent, subKey) => {
        this.setState({
            hasClickNewIp: true,
            keyReference: keyName,
            keyContentReference: keyContent,
            subKeyReference: subKey
        });

        setTimeout(() => {
            this.getAllIp();
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * Se viene cliccato il pulsante edit ip, attiva la modale per inviare l'ip editato.
     * 
     * @param keyName è il nome della chiave (es: Player)
     * @param keyContent è il contenuto di tutta la chiave (es: Contenuto di Player)
     * @param subKey è il nome contenuto nella chiave (es: Ipv4)
     * @param ip è l'ip che deve essere modificato (es: 192.168.0.1) 
     * @param keyIp è la chiave dell'indice dell'indirizzo ip che deve essere sostituito
     * 
     */
    hasClickEdit = (keyName, keyContent, subKey, ip, keyIp) => {
        this.setState({
            hasClickEdit: true,
            keyReference: keyName,
            keyContentReference: keyContent,
            subKeyReference: subKey,
            ipReference: ip,
            keyIp: keyIp
        });

        setTimeout(() => {
            this.getAllIp();
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * Se viene cliccato il pulsante rimuovi ip, attiva la modale per rimuovere l'ip
     * 
     * @param keyName è il nome della chiave (es: Player)
     * @param keyContent è il nome della chiave (es: Contenuto di Player)
     * @param subKey è il nome contenuto nella chiave (es: Ipv4)
     * @param ip è l'ip che deve essere eliminato (es: 192.168.0.1) 
     * @param keyIp è la chiave dell'indice dell'indirizzo ip che deve essere eliminato
     * 
     */
    hasClickRemoveIp = (keyName, keyContent, subKey, ip, keyIp) => {
        this.setState({
            hasClickRemoveIp: true,
            keyReference: keyName,
            keyContentReference: keyContent,
            subKeyReference: subKey,
            ipReference: ip,
            keyIp: keyIp
        });
        setTimeout(() => {
            this.getAllIp();
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * Se viene cliccato il pulsante rimuovi key, attiva la modale per rimuovere la key
     * 
     */
    hasClickRemoveKey = (key) => {
        this.setState({
            hasClickRemoveKey: true,
            keyReference: key
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * Aggiunge una nuova chiave alla lista.
     * 
     * @param key è la nuova chiave
     */
    addKey = (key) => {
        let data = {
            [key]: {
                "Ipv4": null
            }
        }
        this.props.sendEditPreferences(WHITELIST, data);
        this.toggleModal();

        setTimeout(() => {
            this.getWhiteList();
        }, 200)
    }

    /**
     * Aggiunge un nuovo indirizzo alla lista degli indirizzi.
     * 
     * @param newIp
     */
    addNewIp = (newIp) => {

        let listTemp = [];
        let data = {};

        // è un indirizzo v4 da modificare?
        if (compareString(this.state.subKeyReference, 'ipv4')) {
            listTemp = this.state.allElementV4;
            listTemp.push(newIp + '/32');

            // controllo se la chiave iniziale aveva anche gli indirizzi v6
            if (isEmpty(this.state.allElementV6)) {
                // preparo i dati da inviare per la modifica
                data = {
                    [this.state.keyReference]: {
                        [this.state.subKeyReference]: listTemp
                    }
                }
            } else {
                // preparo i dati da inviare per la modifica
                data = {
                    [this.state.keyReference]: {
                        [this.state.subKeyReference]: listTemp
                    },
                    "Ipv6": this.state.allElementV6
                }
            }
        }

        // è un indirizzo v6 da modificare?
        if (compareString(this.state.subKeyReference, 'ipv6')) {
            listTemp = this.state.allElementV6;
            listTemp.push(newIp + '/32');

            // controllo se la chiave iniziale aveva anche gli indirizzi v4
            if (isEmpty(this.state.allElementV4)) {
                // preparo i dati da inviare per la modifica
                data = {
                    [this.state.keyReference]: {
                        [this.state.subKeyReference]: listTemp
                    }
                }
            } else {
                // preparo i dati da inviare per la modifica
                data = {
                    "Ipv4": this.state.allElementV4,
                    [this.state.keyReference]: {
                        [this.state.subKeyReference]: listTemp
                    }
                }
            }
        }

        this.props.sendEditPreferences(WHITELIST, data);
        this.toggleModal();

        setTimeout(() => {
            this.getWhiteList();
        }, 1000)
    }

    /**
     * Modifica un indirizzo ip alla lista, e rimanda tutto al BE.
     * 
     * @param key è la chiave principale (es. Player)
     * @param subKey è la sottochiave (es. Ipv4)
     * @param ip è il nuovo indirizzo ip che si sostituirà o aggiungerà alla whitelist
     */
    editIp = (key, subKey, ip) => {
        let listTemp = [];
        let data = {};

        // è un indirizzo v4 da modificare?
        if (compareString(subKey, 'ipv4')) {
            // eslint-disable-next-line
            this.state.allElementV4.map(el => {
                listTemp.push(el);
            });

            // sostituisco il vecchio indirizzo ip con il nuovo
            listTemp.splice(this.state.keyIp, 1, this.resanitizeIp(ip));

            // controllo se la chiave iniziale aveva anche gli indirizzi v6
            if (isEmpty(this.state.allElementV6)) {
                // preparo i dati da inviare per la modifica
                data = {
                    [key]: {
                        [subKey]: listTemp
                    }
                }
            } else {
                // preparo i dati da inviare per la modifica
                data = {
                    [key]: {
                        [subKey]: listTemp
                    },
                    "Ipv6": this.state.allElementV6
                }
            }
        }

        // è un indirizzo v6 da modificare?
        if (compareString(subKey, 'ipv6')) {
            // eslint-disable-next-line
            this.state.allElementV6.map(el => {
                listTemp.push(el);
            });

            // sostituisco il vecchio indirizzo ip con il nuovo
            listTemp.splice(this.state.keyIp, 1, this.resanitizeIp(ip));

            // controllo se la chiave iniziale aveva anche gli indirizzi v4
            if (isEmpty(this.state.allElementV4)) {
                // preparo i dati da inviare per la modifica
                data = {
                    [key]: {
                        [subKey]: listTemp
                    }
                }
            } else {
                // preparo i dati da inviare per la modifica
                data = {
                    "Ipv4": this.state.allElementV4,
                    [key]: {
                        [subKey]: listTemp
                    }
                }
            }
        }
        this.props.sendEditPreferences(WHITELIST, data);
        this.toggleModal();

        setTimeout(() => {
            this.getWhiteList();
        }, 200);
    }

    /**
     * Rimuove un indirizzo ip dalla lista, e rimanda tutto al BE.
     * L'indice dell'indirizzo ip da rimuovere, è conservato nello state come keyIp.
     * 
     * @param key è la chiave principale (es. Player)
     * @param subKey è la sottochiave (es. Ipv4)
     */
    removeIp = (key, subKey) => {
        let listTemp = [];
        let data = {};

        // è un indirizzo v4 da modificare?
        if (compareString(subKey, 'ipv4')) {
            // eslint-disable-next-line
            this.state.allElementV4.map(el => {
                listTemp.push(el);
            });

            // rimuovo l'indirizzo ip
            listTemp.splice(this.state.keyIp, 1);

            // controllo se la chiave iniziale aveva anche gli indirizzi v6
            if (isEmpty(this.state.allElementV6)) {
                // preparo i dati da inviare per la modifica
                data = {
                    [key]: {
                        [subKey]: listTemp
                    }
                }
            } else {
                // preparo i dati da inviare per la modifica
                data = {
                    [key]: {
                        [subKey]: listTemp
                    },
                    "Ipv6": this.state.allElementV6
                }
            }
        }

        // è un indirizzo v6 da modificare?
        if (compareString(subKey, 'ipv6')) {
            // eslint-disable-next-line
            this.state.allElementV6.map(el => {
                listTemp.push(el);
            });

            // rimuovo l'indirizzo ip
            listTemp.splice(this.state.keyIp, 1);

            // controllo se la chiave iniziale aveva anche gli indirizzi v4
            if (isEmpty(this.state.allElementV4)) {
                // preparo i dati da inviare per la modifica
                data = {
                    [key]: {
                        [subKey]: listTemp
                    }
                }
            } else {
                // preparo i dati da inviare per la modifica
                data = {
                    "Ipv4": this.state.allElementV4,
                    [key]: {
                        [subKey]: listTemp
                    }
                }
            }
        }
        this.props.sendEditPreferences(WHITELIST, data);
        this.toggleModal();

        setTimeout(() => {
            this.getWhiteList();
        }, 200);
    }

    /**
     * Rimuove una key dalla lista.
     * 
     * @param key è la chiave da rimuovere
     */
    removeKey = (key) => {
        this.props.deleteKeyFromList(WHITELIST, key);
        this.toggleModal();

        setTimeout(() => {
            this.getWhiteList();
        }, 500);
    }

    /**
     * Invio la modifica della whitelist (che sia nuova, modifica o elimina)
     */
    sendEditWhiteList = () => {
        this.resanitizeWhiteList(this.state.getWhiteList);

        setTimeout(() => {
            let data = {
                id: WHITELIST,
                value: this.state.getWhiteList
            };

            this.props.sendEditPreferences(data);
        }, 200);

        setTimeout(() => {
            this.getWhiteList();
        }, 500);
    }

    /**
     * Ogni volta che l'utente clicca 'modifica un indirizzo ip',
     * questa funzione prende tutti i gli indirizzi ipv4 e ipv6 riferiti a quella chiave e 
     * li inserisce in delle variabili temporanee in modo da tenerli
     * salvati nello state per una possibile put futura.
     * 
     * Esempio: se voglio modificare un indirizzo che si trova all'interno id Ipv4 di player,
     * questa funzione in allElementV4 inserisce tutti gli indirizzi di Ipv4 e tutti
     * gli indirizzi Ipv6 in allElementV6
     */
    getAllIp = () => {
        // eslint-disable-next-line
        Object.keys(this.state.keyContentReference).map(item => {
            if (compareString(item, 'ipv4') && !isEmpty(this.state.keyContentReference[item])) {
                this.setState({ allElementV4: this.state.keyContentReference[item] });
            }
            if (compareString(item, 'ipv6') && !isEmpty(this.state.keyContentReference[item])) {
                this.setState({ allElementV6: this.state.keyContentReference[item] });
            }
        });
    }

    render() {
        const { role } = this.props;
        return (
            <Fragment>
                <div className="row whiteList">
                    <div className="col-sm-4 col-12">
                        <h2>WhiteList</h2>
                    </div>
                    <div className="col-sm-4 col-12 text-center">
                        {
                            controlRole(role.preferences, "api/Preferences/flush", "POST") &&
                            <button style={{ 'marginTop': '5px' }} className="btn btn-warning" onClick={this.props.flush}>
                                Flush
                            </button>
                        }
                        {
                            controlRole(role.configFlush, "api/Config", "POST") &&
                            <button style={{ 'marginTop': '5px', 'marginLeft': '15px' }} className="btn btn-danger" onClick={this.props.flushConfig}>
                                Flush CONFIG
                            </button>
                        }
                    </div>
                    {
                        controlRole(role.preferences, "api/Preferences", "PUT") &&
                        <div className="col-sm-4 col-12 text-right">
                            <button className="btn btn-success" onClick={this.hasClickNewKey} >
                                <i className="fas fa-plus"></i> Aggiungi Nuova Chiave
                            </button>
                        </div>
                    }
                </div>

                {
                    (this.props.preferences.isLoading || this.state.isLoading || this.state.loadingForRole) ? <Loading /> :
                        <Fragment>
                            <div className="contentWhiteList">
                                {
                                    !isNull(this.state.getWhiteList) &&
                                    Object.keys(this.state.getWhiteList).map((item, key) => { // prende tutto l'oggetto con chiave primaria (es. Player)
                                        return (
                                            <Fragment key={key}>
                                                <div className="row keyRow">
                                                    <div className="col-10">
                                                        {item.toUpperCase()}
                                                    </div>
                                                    <div className="col-2 text-right">
                                                        {
                                                            controlRole(role.preferences, "api/Preferences", "DELETE") &&
                                                            <span onClick={() => { this.hasClickRemoveKey(item) }}>
                                                                <MyTooltip
                                                                    title={"Elimina la chiave " + item}
                                                                    position="top"
                                                                    content={<i className="fas fa-trash-alt" ></i>}
                                                                />
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    Object.keys(this.state.getWhiteList[item]).map((el, key2) => { // prende tutti gli oggetti con sottochiave primaria (es. le subKey della key, Ipv4, ...)
                                                        return (
                                                            <Fragment key={key2}>
                                                                <div className="row subKeyRow justify-content-center">
                                                                    <div className="col-10">
                                                                        <div className="row" style={{ 'margin': '10px 0' }}>
                                                                            <div className="col-6">
                                                                                {el}
                                                                            </div>
                                                                            <div className="col-6 text-right">
                                                                                {
                                                                                    controlRole(role.preferences, "api/Preferences", "PUT") &&
                                                                                    <button
                                                                                        className="btnNewIp"
                                                                                        onClick={
                                                                                            () => {
                                                                                                this.hasClickNewIp(item, this.state.getWhiteList[item], el)
                                                                                            }
                                                                                        }
                                                                                    >
                                                                                        <i className="fas fa-plus"></i> Aggiungi un indirizzo
                                                                                    </button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            isNull(this.state.getWhiteList[item][el]) ?
                                                                                <div className="row emptyIp justify-content-center">
                                                                                    <div className="col">
                                                                                        Non ci sono indirizzi
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                this.state.getWhiteList[item][el].map((elem, key3) => { // prende tutto l'array contenente gli indirizzi ip della sottochiave (es. tutti gli indirizzi di Ipv4)
                                                                                    return (
                                                                                        <div className="row ipAddress justify-content-center" key={key3}>
                                                                                            <div className="col-5">
                                                                                                {this.sanitizeIp(elem)}
                                                                                            </div>
                                                                                            <div className="col-3">
                                                                                                {
                                                                                                    controlRole(role.preferences, "api/Preferences", "PUT") &&
                                                                                                    <button
                                                                                                        className="btnEdit"
                                                                                                        onClick={
                                                                                                            () => {
                                                                                                                this.hasClickEdit(item, this.state.getWhiteList[item], el, this.sanitizeIp(elem), key3)
                                                                                                            }
                                                                                                        }
                                                                                                    >
                                                                                                        <i className="far fa-edit"></i> Modifica
                                                                                                    </button>
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-3">
                                                                                                {
                                                                                                    controlRole(role.preferences, "api/Preferences", "DELETE") &&
                                                                                                    <button
                                                                                                        className="btnDelete"
                                                                                                        onClick={
                                                                                                            () => {
                                                                                                                this.hasClickRemoveIp(item, this.state.getWhiteList[item], el, this.sanitizeIp(elem), key3)
                                                                                                            }
                                                                                                        }
                                                                                                    >
                                                                                                        <i className="far fa-trash-alt"></i> Elimina
                                                                                                    </button>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    })
                                }
                            </div>

                            {
                                (this.state.hasClickEdit || this.state.hasClickNewKey || this.state.hasClickRemoveIp || this.state.hasClickRemoveKey || this.state.hasClickNewIp) &&
                                <ModalWhiteList
                                    titleModal={
                                        this.state.hasClickNewKey ? 'Nuova Chiave' :
                                            this.state.hasClickNewIp ? 'Nuovo Indirizzo IP' :
                                                this.state.hasClickEdit ? 'Modifica IP' :
                                                    this.state.hasClickRemoveIp ? 'Rimuovi IP' :
                                                        this.state.hasClickRemoveKey ? 'Rimuovi Chiave' : ''
                                    }

                                    type={
                                        this.state.hasClickNewKey ? 'newKey' :
                                            this.state.hasClickNewIp ? 'newIp' :
                                                this.state.hasClickEdit ? 'edit' :
                                                    this.state.hasClickRemoveIp ? 'removeIp' :
                                                        this.state.hasClickRemoveKey ? 'removeKey' : ''
                                    }

                                    toggle={this.toggleModal}
                                    stateModal={this.state.modal}

                                    clickNewKey={this.addKey}
                                    clickNewIp={this.addNewIp}
                                    clickEdit={this.editIp}
                                    clickRemoveIp={this.removeIp}
                                    clickRemoveKey={this.removeKey}

                                    keyReference={this.state.keyReference}
                                    keyIp={this.state.keyIp}
                                    subKeyReference={this.state.subKeyReference}
                                    ipReference={this.sanitizeIp(this.state.ipReference)}
                                    allElementV4={this.state.allElementV4}
                                    allElementV6={this.state.allElementV6}

                                    key={this.state.shortId}
                                    errorNotify={this.props.errorNotify}
                                />
                            }
                        </Fragment>
                }
            </Fragment>
        )
    }
}