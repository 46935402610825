import React, { Component } from 'react';
import { FormControl, TextField, Input, InputLabel, InputAdornment } from '@material-ui/core';
import MyTooltip from '../../../common/tooltip';
import { isEmpty } from 'lodash';
import { returnNationality } from '../../../common/actions/returnNationality';
import SelectorCopy from '../../../common/selectorCopy';

import './style.scss';
import Loading from '../../../common/loading';

export default class tabExpanableVpnBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
    }

    /**
     * Dato un ID device, vai alla schermata delle VPN Pass portando id vpnBox nei filtri
     */
    goToVpnPass = (idVpnPass) => {
        this.props.setKeyVpnPassForDetail(idVpnPass);
        this.props.history.push(`/vpn/pass`);
    }

    render() {
        const { id, description, country, model, platform, version, wiFiPwd, wiFiSSD } = this.props.data;

        return (
            <div className="tabExpanableVpnBox">
                {this.state.isLoading && <Loading />}
                <FormControl>
                    <div className="row">
                        <div className="col-4">
                            <FormControl>
                                <InputLabel htmlFor="id-pass">ID Vpn Box</InputLabel>
                                <Input
                                    id="id-vpnBox"
                                    value={id}
                                    readOnly
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <SelectorCopy
                                                item={id}
                                                infoNotify={this.props.infoNotify}
                                                noLabel
                                            />
                                            {
                                                <span className="clickGoTo" onClick={() => { this.goToVpnPass(id) }}>
                                                    <MyTooltip
                                                        title="Vai alla VPN Pass Associata"
                                                        position="top"
                                                        content={<i className="fas fa-share-square" style={{ cursor: 'pointer' }}></i>}
                                                    />
                                                </span>
                                            }
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                        <div className="col-4">
                            <TextField
                                value={isEmpty(description) ? '-' : description}
                                label="Descrizione"
                                InputProps={{ readOnly: true }}
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={isEmpty(country) ? '-' : returnNationality(country)}
                                label="Paese"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={isEmpty(model) ? '-' : model}
                                label="Modello"
                                InputProps={{ readOnly: true }}
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={isEmpty(platform) ? '-' : platform}
                                label="Piattaforma"
                                InputProps={{ readOnly: true }}
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={isEmpty(version) ? '-' : version}
                                label="Versione"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={isEmpty(wiFiPwd) ? '-' : wiFiPwd}
                                label="Wifi Password"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={isEmpty(wiFiSSD) ? '-' : wiFiSSD}
                                label="Wifi SSD"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>
                </FormControl>
            </div>
        )
    }
}