import {
    PLAYER_TYPE, VPNBOX_TYPE, VPN_TYPE, COUPON_TYPE, SHIP_TYPE, BOX_HW_TYPE,
    LIFETIME,
    ANDROID,
    PASS_ONE_MONTH, PASS_THREE_MONTHS, PASS_SIX_MONTHS, PASS_TWELVE_MONTHS, PASS_TWENTYFOUR_MONTHS, PASS_THIRTYSIX_MONTHS,
    VPNBOX_ONE_MONTH_HW, VPNBOX_THREE_MONTH_HW, VPNBOX_SIX_MONTH_HW, VPNBOX_TWELVE_MONTHS_HW, VPNBOX_TWENTYFOUR_MONTHS_HW, VPNBOX_THIRTYSIX_MONTHS_HW,
    COUPON_LIFETIME,
    COUPON_ANDROID_LIFETIME,
    SHIPPING,
} from '../../config';

/**
 * Ritorn il name di un tipo di prodotto.
 * Ritorna un valore facilmente leggibile.
 * 
 * Esempio: VIDEO-PLAYER-LIFETIME = Lifetime
 * 
 * @param {*} type 
 * @returns 
 */
export var returnTypeName = function (type) {
    switch (type) {
        case VPN_TYPE: return 'VPN Pass';
        case VPNBOX_TYPE: return 'Licenza Vpn Box';
        case BOX_HW_TYPE: return 'HW Vpn Box';
        case PLAYER_TYPE: return 'Player';
        case SHIP_TYPE: return 'Spedizione';
        case LIFETIME: return 'Lifetime';
        case ANDROID: return 'Lifetime Android';
        case PASS_ONE_MONTH: case VPNBOX_ONE_MONTH_HW: return '1 Mese';
        case PASS_THREE_MONTHS: case VPNBOX_THREE_MONTH_HW: return '3 Mesi';
        case PASS_SIX_MONTHS: case VPNBOX_SIX_MONTH_HW: return '6 Mesi';
        case PASS_TWELVE_MONTHS: case VPNBOX_TWELVE_MONTHS_HW: return '12 Mesi';
        case PASS_TWENTYFOUR_MONTHS: case VPNBOX_TWENTYFOUR_MONTHS_HW: return '24 Mesi';
        case PASS_THIRTYSIX_MONTHS: case VPNBOX_THIRTYSIX_MONTHS_HW: return '36 Mesi';
        case COUPON_TYPE: return 'Coupon';
        case COUPON_ANDROID_LIFETIME: return 'Video Player Android';
        case COUPON_LIFETIME: return 'Video Player';
        case SHIPPING: return 'Box';
        default: return;
    }
}