import axios from 'axios';
import store from '../index';
import { notifyActions, userActions, groupActionType } from './';
import { API_USER_GROUP, API_USER_GROUP_CREATE, API_USER_GROUP_UPDATE, API_USER_GROUP_ASSIGN } from '../../config';

export const groupActions = {
  getGroupList,
  addGroup,
  updateGroup,
  deleteGroup,
  assignGroup
};


/**
 * Restituisce la lista dei gruppi
 */
function getGroupList() {
  return (dispatch) => {
    dispatch({
      type: groupActionType.GET_GROUP,
      async payload() {
        try {
          let response = await axios.get(API_USER_GROUP);

          return {
            listGroup: response.data,
            totalGroup: response.data.length
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su api/User/Group - GET`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Crea un nuovo gruppo.
 * 
 * @param {Object} data contiene: nome e descrizione
 */
function addGroup(data) {
  return (dispatch) => {
    dispatch({
      type: groupActionType.ADD_GROUP,
      async payload() {
        try {
          await axios.post(`${API_USER_GROUP_CREATE}`, data)
            .then(() => {
              dispatch(notifyActions.successAction('Gruppo creato! 😉'));
              dispatch(getGroupList());
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: dispatch(notifyActions.errorAction('400 - Gruppo non creato! Compila correttamente i campi')); break;
              case 409: dispatch(notifyActions.errorAction('409 - Gruppo gia esistente!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_USER_GROUP_CREATE} - POST`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Modifica di un gruppo.
 * 
 * @param {Object} data contiene: nome, descrizione e data creazione
 */
function updateGroup(data) {
  return (dispatch) => {
    dispatch({
      type: groupActionType.UPDATE_GROUP,
      async payload() {
        try {
          await axios.put(`${API_USER_GROUP_UPDATE}`, data)
            .then(() => {
              dispatch(notifyActions.successAction('Gruppo modificato! 😉'));
              dispatch(getGroupList());
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: dispatch(notifyActions.errorAction('400 - Gruppo non modificato! Compila correttamente i campi')); break;
              case 404: dispatch(notifyActions.errorAction('404 - Gruppo non trovato!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_USER_GROUP_UPDATE} - PUT`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Cancella un gruppo dal DB tramite un nome (ID)
 * 
 * @param {String} data 
 */
function deleteGroup(data) {
  return (dispatch) => {
    dispatch({
      type: groupActionType.DELETE_GROUP,
      async payload() {
        try {
          await axios.delete(API_USER_GROUP, { data })
            .then(() => {
              dispatch(notifyActions.successAction('Gruppo eliminato! 😉'));
              dispatch(getGroupList());
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('Gruppo non trovato! 🙁')); break;
              default: dispatch(notifyActions.errorAction('Error ' + result.response.status + ' su delete group')); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Crea un nuovo ruolo.
 * 
 * @param {Object} data contiene: l'email dell'utente il ruolo da associare
 */
function assignGroup(data) {
  return (dispatch) => {
    dispatch({
      type: groupActionType.ASSIGN_GROUP,
      async payload() {
        try {
          await axios.patch(`${API_USER_GROUP_ASSIGN}`, data)
            .then(() => {
              dispatch(notifyActions.successAction('Gruppo associato! 😉'));
              dispatch(userActions.getUserList(store.getState().user.paramsGetUserListTemp));
              dispatch(getGroupList());
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('Gruppo non trovato! 🙁')); break;
              case 400: dispatch(notifyActions.errorAction('Utente non assegnato! 🙁')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_USER_GROUP_ASSIGN} - PATCH`)); break;
            }
          }
        }
      }
    })
  }
}
