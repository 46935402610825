import React, { Component, Fragment } from 'react';
import './style.scss';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isEmpty, isNull } from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormGroup, Checkbox, FormControlLabel, TextField, FormControl, AccordionDetails, AccordionSummary, Accordion } from '@material-ui/core';
import { Form } from 'react-bootstrap';
import MultiSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import axios from 'axios';
import { API_ROLE } from '../../config';

export default class modalRole extends Component {
    constructor(props) {
        super(props);
        this.state = {

            roleName: this.props.roleName || '',
            editRole: this.props.editRole,
            assignRole: this.props.assignRole,

            // contiene i metodi selezionati da inviare per la creazione/modifica di un nuovo ruolo
            selectedMethods: {},

            // contiene la lista di tutti i metodi a DB
            methodsList: this.props.methodsList,

            // variabile che conterrà l'utente a cui verrà assegnato un ruolo
            webUsers: '',

            // Variabile usata per capire quando fare la chiamata degli utenti durante la digitazione nel campo select di webUser.
            lengthWebUser: 3,

            expandedValue: '',

            // oggetto che contiene tutti i valori booleani delle checkbox dei gruppi principali
            checkedGroupPrincipal: {
                checkedAllPass: false,
                checkedAllPartners: false,
                checkedAllProduct: false,
                checkedAllPromo: false,
                checkedAllRole: false,
                checkedAllSupport: false,
                checkedAllUser: false,
                checkedAllPayment: false,
                checkedAllPlayer: false,
                checkedAllPreferences: false,
                checkedAllVpnMachine: false,
                checkedAllVpnPeer: false,
                checkedAllWorker: false,
            }
        }
        this.setChange = this.setChange.bind(this);
    }

    componentDidMount() {
        this.methodsRole();
        this.props.listDevice();
    }

    /**
    * Funzione che restituisce tutti i metodi associati ad un ruolo
    */
    methodsRole = async () => {
        if (this.state.editRole) {
            this.setState({ isLoading: true });
            try {
                await axios.get(`${API_ROLE}/${this.state.roleName}`)
                    .then(response => {
                        let map = this.state.selectedMethods;
                        response.data.accessibleMethods.forEach(el => {
                            map[el.action + " - " + el.httpVerb] = el;
                        });
                        this.setState({
                            selectedMethods: map,
                            isLoading: false,
                        });
                    });

            } catch (result) {
                const { errorNotify } = this.props;
                this.setState({ isLoading: false });
                if (result && result.response && result.response.status) {
                    switch (result.response) {
                        default: errorNotify(`Error ${result.response.status} su ${API_ROLE} - GET`); break;
                    }
                }
            }
            this.controlCheckedGroupPrincipal();
        }
        return;
    }

    /**
     * Controlla se la checkbox principale debba essere attiva o meno.
     * 
     * @param data this.state.methodsList[item]
     * @param item item
     */
    controlCheckedGroupPrincipal = (item, data) => {
        if (data !== undefined && !isEmpty(this.state.selectedMethods)) {
            let countTemp = 0;
            // eslint-disable-next-line
            Object.keys(this.state.selectedMethods).map(el => {
                if (this.state.selectedMethods[el].controller === item) {
                    countTemp++;
                }
            })
            if (countTemp === data.length) return true;
            else return false;
        }
    }

    // Funzione per selezionare i metodi da inviare per la creazione di un nuovo ruolo
    handleChange = (key, el) => (event) => {
        let map = this.state.selectedMethods;
        if (event.target.checked) {
            map[key] = el;
        } else {
            delete map[key];
        }
        this.setState({
            selectedMethods: map
        })
    }

    /**
     * Se l'utente seleziona/deseleziona il checkbox principale,
     * allora tutte le caselle di quel gruppo si selezionano o deselezionano.
     * 
     * @param {*} groupElement 
     */
    toggleAllCheckboxOfList = (groupElement, event) => {
        let selectedMethodsTemp = this.state.selectedMethods;
        // eslint-disable-next-line
        groupElement.map(el => {
            if (event.target.checked) {
                selectedMethodsTemp[el.action + " - " + el.httpVerb] = el;
            } else {
                delete selectedMethodsTemp[el.action + " - " + el.httpVerb];
            }
        })
        this.setState({
            selectedMethods: selectedMethodsTemp
        })
    }

    /**
      * Rimuove gli spazi bianchi all'inizio e alla fine di una stringa( gli spazi tra due parole restano es. 'Prova prova ').
     */
    removeWhiteSpace = (roleName) => {
        let removeSpace = roleName.trim();
        return removeSpace;
    }

    /**
     * onChange utilizzata nella select dei webUsers.
    */
    setWebUsersFromList = (options) => {
        if (!isNull(options)) {
            this.setState({ webUsers: options.value });
        } else {
            this.setState({ webUsers: null });
        }
    }

    /**
     * Funzione per non chiamare getListDevice ad ogni carattere digitato nella select per i webUsers.
     * Viene chiamata getDeviceList dal quinto carattere digitato fino all'ottavo. 
     * Quando si cancella la chiamata non viene effettuata. (fatto così per ridurre il numero di chiamate per ogni digitazione). 
    */
    updateListUsers = (event) => {
        if (event !== '') {
            if (!isNull(this.state.webUsers)) {
                this.setState({ webUsers: event });
                if (event.length > 5 && event.length < 12) {
                    this.props.listDevice(event);
                }

            } else {
                this.setState({ webUsers: '' });
            }
        }
    }

    // Funzione che prepara i dati da inviare per la creazione, la modifica o l'asssegnazione di un ruolo
    sendRole = () => {
        if (!this.state.assignRole) {
            const resultChecked = Object.keys(this.state.selectedMethods).map((key) => this.state.selectedMethods[key]);

            let data = {
                roleName: this.removeWhiteSpace(this.state.roleName),
                accessibleMethods: resultChecked
            }

            if (this.state.roleName === '') {
                this.props.errorNotify('Inserisci il nome del ruolo');
            } else if (isEmpty(this.state.selectedMethods)) {
                this.props.errorNotify('Seleziona almeno un metodo da assegnare al ruolo');
            } else if (!this.state.editRole) {
                this.props.clickNew(data);

                setTimeout(() => {
                    this.props.getRoleList();
                }, 300);
            } else if (this.state.editRole) {
                this.props.clickEdit(data);

                setTimeout(() => {
                    this.props.getRoleList();
                }, 300);
            }
        } else {
            let data = {
                roleName: this.removeWhiteSpace(this.state.roleName),
                user: this.state.webUsers
            }

            if (this.state.roleName === '') {
                this.props.errorNotify('Inserisci il nome del ruolo');
            } else if (this.state.webUsers === '' || this.state.webUsers === null) {
                this.props.errorNotify('Inserisci un utente');
            } else if (this.state.assignRole && this.state.roleName !== '' && (this.state.webUsers !== '' || this.state.webUsers !== null)) {
                this.props.clickAssign(data);
            }
        }
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    changeExpandedValue = (index) => {
        this.setState({ expandedValue: index });
    }

    render() {
        const { stateModal, toggle, titleModal, promo } = this.props;
        const animatedComponents = makeAnimated();

        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalRole">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody className="maxHeigthScroll">
                        {
                            !this.state.assignRole
                                ?
                                <FormControl className="choose-form">
                                    <div className="row">
                                        <div className="col-12">
                                            <TextField
                                                name="roleName"
                                                label="Inserisci nome ruolo"
                                                value={this.state.roleName}
                                                onChange={this.setChange}
                                                disabled={this.state.editRole}
                                            />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        {
                                            !isEmpty(this.state.methodsList) &&
                                            Object.keys(this.state.methodsList).map((item, key) => {
                                                return (
                                                    <Fragment key={key}>
                                                        {
                                                            item !== 'Role' &&
                                                            <div className="col-8">
                                                                <Accordion
                                                                    expanded={this.state.expandedValue === key}
                                                                    onChange={() => {
                                                                        if (this.state.expandedValue === key) this.setState({ expandedValue: '' });
                                                                        else this.setState({ expandedValue: key });
                                                                    }}
                                                                >
                                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                                                        <FormControlLabel
                                                                            onClick={(event) => event.stopPropagation()}
                                                                            onFocus={(event) => event.stopPropagation()}
                                                                            control={
                                                                                <Checkbox
                                                                                    onChange={(event) => this.toggleAllCheckboxOfList(this.state.methodsList[item], event)}
                                                                                    checked={this.controlCheckedGroupPrincipal(item, this.state.methodsList[item]) ? true : false}
                                                                                />
                                                                            }
                                                                            label={item}
                                                                        />
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <FormGroup>
                                                                            {
                                                                                this.state.methodsList[item].map((el, key2) => {
                                                                                    return (
                                                                                        <Fragment key={key2}>
                                                                                            <FormControlLabel
                                                                                                control={
                                                                                                    <Checkbox
                                                                                                        checked={el.action + " - " + el.httpVerb in this.state.selectedMethods ? true : false}
                                                                                                        onChange={this.handleChange(el.action + " - " + el.httpVerb, el)}
                                                                                                        name={el.action}
                                                                                                    />
                                                                                                }
                                                                                                label={el.action + " - " + el.httpVerb}
                                                                                            />
                                                                                        </Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </FormGroup>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </div>
                                                        }
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                </FormControl>
                                :
                                <FormControl>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextField
                                                name="roleName"
                                                label="Nome del ruolo"
                                                value={this.state.roleName}
                                                onChange={this.setChange}
                                                error={this.state.email === '' && this.state.formIsValid}
                                                disabled={this.state.assignRole}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <Form.Label>
                                                Web User
                                            </Form.Label>

                                            <MultiSelect
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                options={promo.listEmails}
                                                isClearable
                                                isSearchable
                                                name="users"
                                                onChange={this.setWebUsersFromList}
                                                onInputChange={this.updateListUsers}
                                            />
                                        </div>
                                    </div>
                                </FormControl>

                        }
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.sendRole}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}