import React, { Component } from 'react';
import './style.scss';

export default class Loading extends Component {
    render() {
        return (
            <div className="loading">
                <div className="contentLoading">
                    <div className="loaderLine"></div>
                    <div className="label">Attendi</div>
                </div>
            </div>
        )
    }
}