import React, { Component, Fragment } from 'react';
import './style.scss';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Row, Col } from 'react-bootstrap';

/**
 * Attivazione modale per inviare un messaggio al player
 * 
 *      "click" : se clicco OK, attivo una determinata funzione
 *      "deviceId" : l'id del player a cui mandare il messaggio
 *      "toggle" : al click di annulla o un'area esterna alla modale, chiude la modale
 *      "stateModal" : stato della modale (chiusa o aperta), boolean
 *      "titleModal" : titolo della modale
 */
export default class modalSendMessagePlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            messageNull: false,
        }
        this.setMessage = this.setMessage.bind(this);
    }

    setMessage = (event) => {
        this.setState({ 
            message: event.target.value, 
            messageNull: false 
        });
    }

    confirmSend = () => {
        if (this.state.message === "") {
            this.props.errorNotify('Inserisci un messaggio');
            this.setState({ messageNull: true });
        } else {
            this.setState({ messageNull: false });
            let data = {
                deviceId: this.props.deviceId,
                message: this.state.message
            };
            this.props.click(data);
        }
    }

    render() {
        const { deviceId, stateModal, toggle, titleModal } = this.props;
        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalMail">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" className="text-right">
                                    ID Device
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        plaintext
                                        readOnly
                                        defaultValue={deviceId}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextPassword">
                                <Form.Label column sm="2" className="text-right">
                                    Messaggio
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        as="textarea"
                                        rows="3"
                                        value={this.state.message}
                                        onChange={this.setMessage}
                                        isInvalid={this.state.messageNull}
                                    />
                                </Col>
                            </Form.Group>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.confirmSend}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}