/**
 * Controlla se un azione è presente nelle regole nello store.
 * 
 * Il primo parametro è l'array da controllare che si trova nello store.
 * (Esempi: user, worker, player, ecc...)
 * 
 * Il secondo parametro è l'azione da controllare. 
 * (Esempio: api/user, api/player, api/worker, ecc...)
 * 
 * Il terzo parametro è il tipo di metodo da controllare.
 * (Esempio: GET, POST, DELETE, ecc...)
 * 
 * Ritorna true se la corrispondenza è stata trovata.
 * False altrimenti.
 * 
 * @param {Array} arrayRoleInStore 
 * @param {String} action 
 * @param {String} httpVerb 
 * @returns 
 */
export var controlRole = function (arrayRoleInStore, action, httpVerb) {
    let x = 0;
    // eslint-disable-next-line
    arrayRoleInStore.map(el => {
        if (el.action === action && el.httpVerb === httpVerb) {
            x = 1;
        }
    })
    if (x === 1) return true;
    else if (x === 0) return false;
}