import { connect } from 'react-redux'
import { paymentActions, vpnActions, notifyActions, preferencesActions } from '../store/actions';
import pagePayments from './pages';

const mapStateToProps = state => {
    return {
        ...state,
        payment: state.payment,
        role: state.role,
        preferences: state.preferences,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateDevicePayment: (data) => {
            dispatch(paymentActions.updateDevicePayment(data))
        },
        getPaymentList: (data) => {
            dispatch(paymentActions.getPaymentList(data))
        },
        setLoadingForPayment: (data) => {
            dispatch(paymentActions.setLoadingForPayment(data))
        },
        setIdPaymentForDetail: (data) => {
            dispatch(paymentActions.setIdPaymentForDetail(data))
        },
        setIdVpnPassForDetail: (data) => {
            dispatch(vpnActions.setIdVpnPassForDetail(data))
        },
        deactiveSub: (idPayment) => {
            dispatch(paymentActions.deactiveSub(idPayment))
        },
        deletePayment: (idPayment) => {
            dispatch(paymentActions.deletePayment(idPayment))
        },
        refundPayment: (idPayment, data) => {
            dispatch(paymentActions.refundPayment(idPayment, data))
        },
        getProductList: (data) => {
            dispatch(preferencesActions.getProductList(data))
        },
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(pagePayments);