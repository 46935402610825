import React, { Component, Fragment } from 'react';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

export default class actionMenuActiveJob extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setOpen: false,
            data: this.props.data
        };
    }

    toggle = () => {
        this.setState({ setOpen: !this.state.setOpen });
    }

    actionClick = (action, data) => {
        this.props.actionClick(action, data);
    }

    render() {
        const { data } = this.state;

        return (
            <Fragment>
                <ButtonDropdown isOpen={this.state.setOpen} toggle={this.toggle}>
                    <DropdownToggle caret>
                        <i className="fas fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu>
                            {/* <DropdownItem onClick={() => { this.actionClick('edit', this.state.vpn); }}> */}
                            {/* <DropdownItem>
                                <div className="row">
                                    <div className="col-3 icon moreDetail">
                                        <i className="far fa-edit"></i>
                                    </div>
                                    <div className="col-9">
                                        Modifica
                                    </div>
                                </div>
                            </DropdownItem> */}
                            <DropdownItem onClick={() => { this.actionClick('remove', data.id); }}>
                                <div className="row">
                                    <div className="col-3 icon deleteUser">
                                        <i className="far fa-trash-alt"></i>
                                    </div>
                                    <div className="col-9">
                                        Elimina
                                    </div>
                                </div>
                            </DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>

            </Fragment>
        )
    }
}