import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

export default class expanableComponent extends Component {
    render() {
        const { data } = this.props;

        let listDevice = '';
        // eslint-disable-next-line
        data.deviceIds.map((el, key) => {
            listDevice += el + '\n'
        })

        return (
            <div className="tabExpanablePlayer">
                <Form>
                    <Form.Label>
                        Lista dei device associati al Bundle
                    </Form.Label>
                    <Form.Control
                        name="description"
                        as="textarea"
                        rows="5"
                        defaultValue={listDevice}
                        readOnly
                    />
                </Form>
            </div>
        )
    }
}