import React, { Component, Fragment } from 'react';
import { FormControl, TextField } from '@material-ui/core';
import moment from 'moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { returnNationality } from '../../common/actions/returnNationality';
import isNull from 'lodash/isNull';
import isNil from 'lodash/isNil';
import { isEmpty } from 'lodash';

export default class expanableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: ''
        }
    }

    /**
     * Data una data in formato iso, restituisce una data correttamente stampata
     * nel seguente formato: September 4 1986 - 08:30:00 PM
     */
    formatData = (data, onlyDate) => {
        if (data === null || data === undefined || data === '') {
            return '';
        } else {
            return onlyDate ? moment(data).format('LL') : moment(data).format('LL') + ' - ' + moment(data).format('LTS');
        }
    }

    handleChange = (event) => {
        this.setState({
            isExpanded: event
        });
    }

    render() {
        const { payment, deviceIds, createdAt, history } = this.props.data;

        return (
            <div className="tabExpanableVpnStock">
                {
                    (payment === null && deviceIds !== null) ?
                        <div className="row">
                            {
                                deviceIds.map((el, key) => {
                                    return (
                                        <div className="col-4" key={key}>
                                            <TextField
                                                value={el || ''}
                                                label="Device ID"
                                                InputProps={{ readOnly: true }}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        :
                        (payment === null && deviceIds === null)
                            ?
                            <p>Nessun pagamento associato</p>
                            :
                            <FormControl>
                                <div className="row">
                                    <div className="col-4">
                                        <TextField
                                            value={payment._id || ''}
                                            label="ID Pagamento"
                                            InputProps={{ readOnly: true }}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <TextField
                                            value={payment._parentPayment || ''}
                                            label="Parent payment"
                                            InputProps={{ readOnly: true }}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <TextField
                                            value={this.formatData(createdAt) || ''}
                                            label="Creato il"
                                            InputProps={{ readOnly: true }}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-8">
                                        <TextField
                                            value={payment.providerTransactionId || ''}
                                            label="Transaction ID"
                                            InputProps={{ readOnly: true }}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <TextField
                                            value={payment.paymentProvider || ''}
                                            label="Provider"
                                            InputProps={{ readOnly: true }}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <TextField
                                            value={
                                                payment.paymentStatus === 1 ? 'Pagato' :
                                                    payment.paymentStatus === 3 ? 'Rimborsato' :
                                                        payment.paymentStatus === 6 ? 'Rimborso Parziale' :
                                                            payment.paymentStatus === 0 ? 'Non confermato' :
                                                                payment.paymentStatus === -1 ? 'Cancellato / Respinto' :
                                                                    payment.paymentStatus === 4 ? 'Sottoscrizione Attiva' :
                                                                        payment.paymentStatus === 5 ? 'Sottoscrizione Cancellata' :
                                                                            payment.paymentStatus === 2 ? 'In Attesa' : 'Non Pagato'
                                            }
                                            label="Stato pagamento"
                                            InputProps={{ readOnly: true }}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-3">
                                        <TextField
                                            value={payment.invoiceNumber || ''}
                                            label="Numero Fattura"
                                            InputProps={{ readOnly: true }}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <TextField
                                            value={this.formatData(payment.issuedAt) || ''}
                                            label="Emessa il"
                                            InputProps={{ readOnly: true }}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <TextField
                                            value={this.formatData(payment.refundedAt) || ''}
                                            label="Rimborsato il"
                                            InputProps={{ readOnly: true }}
                                        />
                                    </div>

                                    <div className="col-2 text-center">
                                        {
                                            !isNull(payment.invoiceUrl) ?
                                                <a className="btn btn-info" style={{ 'color': 'white' }} href={payment.invoiceUrl} download>
                                                    Download Fattura
                                                    <i className="fas fa-download" style={{ 'fontSize': '1.2em', 'padding': '5px' }} />
                                                </a>
                                                :
                                                <p style={{ 'marginTop': '10px' }}> Non esiste fattura! </p>
                                        }
                                    </div>

                                    <div className="col-2 text-center">
                                        {
                                            !isNull(payment.deliveryNoteUrl) ?
                                                <a className="btn btn-info" style={{ 'color': 'white' }} href={payment.deliveryNoteUrl} download>
                                                    Download Bolla
                                                    <i className="fas fa-download" style={{ 'fontSize': '1.2em', 'padding': '5px' }} />
                                                </a>
                                                :
                                                <p style={{ 'marginTop': '10px' }}> Non esiste Bolla! </p>
                                        }
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-3">
                                        <TextField
                                            value={payment.email || ''}
                                            label="Email"
                                            InputProps={{ readOnly: true }}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <TextField
                                            value={payment.customerIp || ''}
                                            label="IP Acquirente"
                                            InputProps={{ readOnly: true }}
                                        />
                                    </div>
                                    {
                                        !isNull(payment.shipping) &&
                                        <Fragment>
                                            <div className="col-3">
                                                <TextField
                                                    value={payment.shipping.by || ''}
                                                    label="Da"
                                                    InputProps={{ readOnly: true }}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <TextField
                                                    value={payment.shipping.trackingNumber || ''}
                                                    label="Numero di spedizione"
                                                    InputProps={{ readOnly: true }}
                                                />
                                            </div>
                                        </Fragment>
                                    }
                                </div>

                                {
                                    !isEmpty(payment.creditNotes) &&
                                    <Accordion expanded={this.state.isExpanded === 'panel0'} onChange={() => this.handleChange('panel0')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography variant="h5" component="h5" className="red">Note Credito</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="h6" component="div">
                                                <div className="row">
                                                    {
                                                        payment.creditNotes.map((el, key) => {
                                                            return (
                                                                <div key={key} className="col-3">
                                                                    <a className="btn btn-info" style={{ 'color': 'white' }} href={el.refundUrl} download>
                                                                        Nota credito del {this.formatData(el.refundedAt, true)}
                                                                        <i className="fas fa-download" style={{ 'fontSize': '1.2em', 'padding': '5px' }} />
                                                                    </a>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                }

                                <Accordion expanded={this.state.isExpanded === 'panel1'} onChange={() => this.handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography variant="h5" component="h5" className="red">Prodotti acquistati</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="h6" component="div">
                                            {
                                                payment.products.map((el, key) => {
                                                    return (
                                                        <div key={key} className="row">
                                                            <div className="col-3">
                                                                <TextField
                                                                    value={el.description || ''}
                                                                    label="Prodotto"
                                                                    InputProps={{ readOnly: true }}
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <TextField
                                                                    value={el.qty || ''}
                                                                    label="Quantità"
                                                                    InputProps={{ readOnly: true }}
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <TextField
                                                                    value={el.price || ''}
                                                                    label="Prezzo"
                                                                    InputProps={{ readOnly: true }}
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <TextField
                                                                    value={el.promo === null ? 'NO' : 'SI'}
                                                                    label="In promozione?"
                                                                    InputProps={{ readOnly: true }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                            {
                                                !isNil(deviceIds) &&
                                                <div className="row">
                                                    {
                                                        deviceIds.map((el, key) => {
                                                            return (
                                                                <div className="col-xl-3 col-lg-3 col-md-4" key={key}>
                                                                    <TextField
                                                                        value={el || ''}
                                                                        label="Device ID"
                                                                        InputProps={{ readOnly: true }}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={this.state.isExpanded === 'panel2'} onChange={() => this.handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <Typography variant="h5" component="h5" className="green">Indirizzo di Fatturazione</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="h6" component="div">
                                            <div className="row">
                                                <div className="col-4">
                                                    <TextField
                                                        value={payment.invoiceAddress.id || ''}
                                                        label="ID"
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <TextField
                                                        value={payment.invoiceAddress.name || ''}
                                                        label="Nome"
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <TextField
                                                        value={payment.invoiceAddress.surname || ''}
                                                        label="Cognome"
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <TextField
                                                        value={payment.invoiceAddress.firstAddress || ''}
                                                        label="Indirizzo 1"
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <TextField
                                                        value={payment.invoiceAddress.secondAddress || ''}
                                                        label="Indirizzo 2"
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-2">
                                                    <TextField
                                                        value={payment.invoiceAddress.cap || ''}
                                                        label="CAP"
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <TextField
                                                        value={payment.invoiceAddress.city || ''}
                                                        label="Città"
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <TextField
                                                        value={payment.invoiceAddress.province || ''}
                                                        label="Provincia"
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <TextField
                                                        value={returnNationality(payment.invoiceAddress.country) || ''}
                                                        label="Country"
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <TextField
                                                        value={payment.invoiceAddress.telephone || ''}
                                                        label="Telefono"
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <TextField
                                                        value={payment.invoiceAddress.extraInfo || ''}
                                                        label="Info Corriere"
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                {
                                    !isNull(payment.shipping) &&
                                    <Accordion expanded={this.state.isExpanded === 'panel3'} onChange={() => this.handleChange('panel3')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3bh-content"
                                            id="panel3bh-header"
                                        >
                                            <Typography variant="h5" component="h5" className="orange">Indirizzo di Spedizione</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="h6" component="div">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <TextField
                                                            value={payment.shipping.address.id || ''}
                                                            label="ID"
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField
                                                            value={payment.shipping.address.name || ''}
                                                            label="Nome"
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField
                                                            value={payment.shipping.address.surname || ''}
                                                            label="Cognome"
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField
                                                            value={payment.shipping.address.firstAddress || ''}
                                                            label="Indirizzo 1"
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField
                                                            value={payment.shipping.address.secondAddress || ''}
                                                            label="Indirizzo 2"
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-2">
                                                        <TextField
                                                            value={payment.shipping.address.cap || ''}
                                                            label="CAP"
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField
                                                            value={payment.shipping.address.city || ''}
                                                            label="Città"
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField
                                                            value={payment.shipping.address.province || ''}
                                                            label="Provincia"
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField
                                                            value={returnNationality(payment.shipping.address.country) || ''}
                                                            label="Country"
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField
                                                            value={payment.shipping.address.telephone || ''}
                                                            label="Telefono"
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <TextField
                                                            value={payment.shipping.address.extraInfo || ''}
                                                            label="Info Corriere"
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </div>
                                                </div>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                }

                                {
                                    !isEmpty(history) &&
                                    <Accordion expanded={this.state.isExpanded === 'panel4'} onChange={() => this.handleChange('panel4')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography variant="h5" component="h5" className="red">History</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography component="div">
                                                <ol>
                                                    {
                                                        history.map((el, key) => {
                                                            return (
                                                                <li key={key}>
                                                                    <b>
                                                                        {el.operationType === 'R' && 'Avviata procedura di reso'}
                                                                        {el.operationType === 'RR' && 'Chiusa procedura di reso'}
                                                                        {el.operationType === 'D' && 'Avviata procedura di assistenza tecnica'}
                                                                        {el.operationType === 'DD' && 'Chiusa procedura di assistenza tecnica'}
                                                                        {el.operationType === 'U' && 'Stock spedito'}
                                                                        {el.operationType === 'L' && 'Stock caricato'}
                                                                        {el.operationType === 'B' && 'Stock in attesa di essere spedito'}
                                                                    </b>
                                                                    &nbsp;
                                                                    <span>
                                                                        ({this.formatData(el.createdAt)})
                                                                    </span>
                                                                    <br />
                                                                    <span>
                                                                        <b>ID Movimento:</b> {el.movId}
                                                                    </span>
                                                                    <br />
                                                                    <span>
                                                                        <b>Note:</b> {el.note ? el.note : 'nessuna'}
                                                                    </span>
                                                                    <br />
                                                                    {
                                                                        !isEmpty(el.deviceIds) &&
                                                                        <span>
                                                                            <i>
                                                                                Devices ID:
                                                                                <ul>
                                                                                    {
                                                                                        el.deviceIds.map((elem, k) => {
                                                                                            return (
                                                                                                <li key={k}>{elem}</li>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            </i>
                                                                        </span>
                                                                    }
                                                                    <br />
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ol>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                }

                            </FormControl>
                }
            </div>
        )
    }
}