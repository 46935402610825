import { connect } from 'react-redux'
import { bundleActions, notifyActions, preferencesActions } from '../store/actions';
import pageBundle from './pages';

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user,
        role: state.role,
        bundle: state.bundle,
        preferences: state.preferences
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProductList: () => {
            dispatch(preferencesActions.getProductList())
        },
        setNumberBundle: (data) => {
            dispatch(bundleActions.setNumberBundle(data))
        },
        getBundleList: (data) => {
            dispatch(bundleActions.getBundleList(data))
        },
        setBundle: (data) => {
            dispatch(bundleActions.setBundle(data))
        },
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(pageBundle);