import React, { Component, Fragment } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Select, FormControl, MenuItem, InputLabel, TextField, Switch, FormControlLabel } from '@material-ui/core';
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { countries } from '../../common/countries';

export default class formFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // quale campo ordinare
            fieldOrder: 'createdAt',

            // quanti utenti vuoi visualizzare
            numberUser: '25',

            // numerpo di pagina selezionato
            currentNumberPage: '1',

            // tipo di ordinamento
            typeOrder: 'desc',

            dateLogin: null,
            dateLogout: null,
            startFilter: null,
            endFilter: null,
            registrationIp: '',
            group: '',
            name: '',
            surname: '',
            id: '',
            country: '',
            isRegistered: true,
            isReseller: '',
            hasRole: '',

            // switch utenti registrati e non
            checkedRegistered: true,
            checkedUnregistered: false,
        }

        this.setChange = this.setChange.bind(this);
        this.setDateLoginChange = this.setDateLoginChange.bind(this);
        this.setDateLogoutChange = this.setDateLogoutChange.bind(this);
        this.setStartFilterChange = this.setStartFilterChange.bind(this);
        this.setEndFilterChange = this.setEndFilterChange.bind(this);
    }

    setChange = (event, page) => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === '' || event.target.name === undefined) {
            this.setState({
                currentNumberPage: page,
            });

            setTimeout(() => {
                this.getUserList(true);
            }, 200);
        }
        if (
            event.target.name === 'numberUser' ||
            event.target.name === 'country' ||
            event.target.name === 'typeOrder' ||
            event.target.name === 'hasRole' ||
            event.target.name === 'isReseller') {
            setTimeout(() => {
                this.getUserList();
            }, 200);
        }
    }

    /**
     * Se decido di cambiare il numero degli utenti.
     * 
     * @param {*} number 
     */
    setNumberUserForSearch = (number) => {
        this.setState({ numberUser: number });
        setTimeout(() => {
            this.getUserList();
        }, 200);
    }

    /**
     * Se viene premuto il tasto enter (Invio) avvia una ricerca
     */
    clickEnter = (event) => {
        if (event.keyCode === 13) {
            this.getUserList(false);
        }
    }

    getUserList = (changeNumberPage) => {
        let params = {
            'elementsByPage': this.state.numberUser,
            'page': changeNumberPage ? this.state.currentNumberPage : 1,
            'sortField': this.state.fieldOrder,
            'sortType': this.state.typeOrder,
            'filters[0][IsRegistered]': this.state.isRegistered,
            'filters[1][LoginStart]': this.state.dateLogin === null ? '' : this.setHourStart(this.state.dateLogin),
            'filters[2][LoginEnd]': this.state.dateLogout === null ? '' : this.setHourEnd(this.state.dateLogout),
            'filters[3][Id]': this.state.id,
            'filters[4][RegistrationIp]': this.state.registrationIp,
            'filters[5][Country]': this.state.country,
            'filters[6][IsReseller]': this.state.isReseller === 'off' ? '' : this.state.isReseller,
            'filters[7][HasRole]': this.state.hasRole === 'off' ? '' : this.state.hasRole,
            'filters[8][StartFilter]': this.state.startFilter === null ? '' : this.setHourStart(this.state.startFilter),
            'filters[9][EndFilter]': this.state.endFilter === null ? '' : this.setHourEnd(this.state.endFilter),
            'filters[10][Name]': this.state.name,
            'filters[11][Surname]': this.state.surname,
            'filters[12][Group]': this.state.group
        }

        setTimeout(() => {
            this.props.getUserList(params);
        }, 500);

        if (!changeNumberPage) {
            this.setState({
                currentNumberPage: 1
            });
        }
    }

    setHourStart = (newHour) => {
        newHour.setHours(1);
        newHour.setMinutes(0);
        newHour.setSeconds(0);
        newHour.setMilliseconds(0);
        return newHour;
    }

    setHourEnd = (newHour) => {
        newHour.setHours(24);
        newHour.setMinutes(59);
        newHour.setSeconds(59);
        newHour.setMilliseconds(999);
        return newHour;
    }

    setDateLoginChange = (newDate) => {
        this.setState({ dateLogin: newDate });
    }

    setDateLogoutChange = (newDate) => {
        this.setState({ dateLogout: newDate });
    }

    setStartFilterChange = (newDate) => {
        this.setState({ startFilter: newDate });
    }

    setEndFilterChange = (newDate) => {
        this.setState({ endFilter: newDate });
    }

    // Filtra gli utenti che hanno confermato la registrazione e non, alla fine richiama il BE col nuovo filtro settato
    isRegistered = () => {
        if ((this.state.checkedRegistered && this.state.checkedUnregistered) || (!this.state.checkedRegistered && !this.state.checkedUnregistered)) {
            this.setState({ isRegistered: '' });
        } else if (!this.state.checkedRegistered && this.state.checkedUnregistered) {
            this.setState({ isRegistered: false });
        } else if (this.state.checkedRegistered && !this.state.checkedUnregistered) {
            this.setState({ isRegistered: true });
        }
        setTimeout(() => {
            this.getUserList();
        }, 200);
    }

    setCheckedRegistered = (event) => {
        this.setState({
            checkedRegistered: event.target.checked,
            checkedUnregistered: !event.target.checked
        });
        setTimeout(() => {
            this.isRegistered();
        }, 100);
    }

    setCheckedUnregistered = (event) => {
        this.setState({
            checkedUnregistered: event.target.checked,
            checkedRegistered: !event.target.checked
        });
        setTimeout(() => {
            this.isRegistered();
        }, 100);
    }

    // Resetta tutti i filtri
    resetFilter = () => {
        this.setState({
            numberUser: '25',
            currentNumberPage: '1',
            fieldOrder: 'createdAt',
            typeOrder: 'desc',
            dateLogin: null,
            dateLogout: null,
            registrationIp: '',
            country: '',
            group: '',
            name: '',
            surname: '',
            id: '',
            isRegistered: true,
            checkedRegistered: true,
            checkedUnregistered: false,
            isReseller: '',
            hasRole: '',
            startFilter: null,
            endFilter: null
        });

        setTimeout(() => {
            this.getUserList(false);
        }, 300);
    }

    render() {
        return (
            <Fragment>
                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="id"
                            value={this.state.id}
                            onChange={this.setChange}
                            onKeyDown={this.clickEnter}
                            label="Email"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <FormControl>
                            <InputLabel>Reseller</InputLabel>
                            <Select
                                labelId="isReseller"
                                name="isReseller"
                                value={this.state.isReseller}
                                onChange={this.setChange}
                            >
                                <MenuItem value='off'>OFF</MenuItem>
                                <MenuItem value={false}>No Reseller</MenuItem>
                                <MenuItem value={true}>Reseller</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.checkedRegistered}
                                        onChange={this.setCheckedRegistered}
                                        value="checkedRegistered"
                                        color="primary"
                                    />
                                }
                                label="Confermati"
                            />
                        </FormControl>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.checkedUnregistered}
                                        onChange={this.setCheckedUnregistered}
                                        value="checkedUnregistered"
                                        color="primary"
                                    />
                                }
                                label="Non Confermati"
                            />
                        </FormControl>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button
                            className={this.state.numberUser === '10' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberUserForSearch('10')}
                        > 10 </button>
                        <button
                            className={this.state.numberUser === '25' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberUserForSearch('25')}
                        > 25 </button>
                        <button
                            className={this.state.numberUser === '50' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberUserForSearch('50')}
                        > 50 </button>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <Pagination
                            boundaryCount={2}
                            count={parseInt(this.props.user.totalPages)}
                            page={parseInt(this.state.currentNumberPage)}
                            onChange={this.setChange}
                            color="primary"
                            showFirstButton
                            showLastButton
                            size="small"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button className="btn btn-primary" style={{ 'margin': '10px' }} onClick={() => this.getUserList(false)}>
                            Cerca
                        </button>
                        <button className="btn btn-secondary" style={{ 'margin': '10px' }} onClick={this.resetFilter}>
                            Reset
                        </button>
                    </div>
                </div>

                <Accordion className="myAccordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    // aria-controls="panel1a-content"
                    // id="panel1a-header"
                    >
                        <Typography>Altri filtri</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div">
                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl>
                                        <InputLabel>Come vuoi ordinare?</InputLabel>
                                        <Select
                                            labelId="fieldOrderLabel"
                                            name="fieldOrder"
                                            value={this.state.fieldOrder}
                                            onChange={this.setChange}
                                        >
                                            <MenuItem value={'_id'}>ID Utente / Email</MenuItem>
                                            <MenuItem value={'isRegistered'}>Utenti Registrati</MenuItem>
                                            <MenuItem value={'registrationIp'}>IP Registrazione</MenuItem>
                                            <MenuItem value={'group'}>Gruppo</MenuItem>
                                            <MenuItem value={'country'}>Country</MenuItem>
                                            <MenuItem value={'createdAt'}>Data Creazione</MenuItem>
                                            <MenuItem value={'role'}>Ruolo</MenuItem>
                                            <MenuItem value={'name'}>Nome</MenuItem>
                                            <MenuItem value={'surname'}>Cognome</MenuItem>
                                            <MenuItem value={'personalPromosUsageCount'}>Promo Utilizzate</MenuItem>
                                            <MenuItem value={'personalPromosCount'}>Promo Possedute</MenuItem>
                                            <MenuItem value={'couponsUsageCount'}>Coupons Utilizzati</MenuItem>
                                            <MenuItem value={'couponsCount'}>Coupons Posseduti</MenuItem>
                                            <MenuItem value={'geoPromosUsageCount'}>Geo Promo Utilizzate</MenuItem>
                                            <MenuItem value={'geoPromosCount'}>Geo Promo Possedute</MenuItem>
                                            <MenuItem value={'paymentsCount'}>Pagamenti Andati a buon fine</MenuItem>
                                            <MenuItem value={'playersCount'}>Players Associati</MenuItem>
                                            <MenuItem value={'vpnPassesCount'}>VPN Pass Associate</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-12 itemForSearch">
                                    <FormControl disabled={this.state.fieldOrder === ''}>
                                        <InputLabel>Tipo di Ordinamento</InputLabel>
                                        <Select
                                            labelId="typeOrderLabel"
                                            name="typeOrder"
                                            value={this.state.typeOrder}
                                            onChange={this.setChange}
                                        >
                                            <MenuItem value={'asc'}>Crescente [A-Z]</MenuItem>
                                            <MenuItem value={'desc'}>Decrescente [Z-A]</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="col-12 itemForSearch">
                                        <KeyboardDatePicker
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="dateLogin"
                                            label="Accesso da"
                                            value={this.state.dateLogin}
                                            onChange={this.setDateLoginChange}
                                        />
                                    </div>

                                    <div className="col-12 itemForSearch">
                                        <KeyboardDatePicker
                                            variant="inline"
                                            id="dateLogout"
                                            label="Fino a"
                                            value={this.state.dateLogout}
                                            onChange={this.setDateLogoutChange}
                                            // se la data di inizio è presente, disabilita tutte le date da quella data (compresa)
                                            minDate={this.state.dateLogin ? moment(this.state.dateLogin).add(1, 'days')._d : false}
                                            minDateMessage="Attenzione! Data precedente a quella di inizio"
                                            format="dd/MM/yyyy"
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>

                            <div className="row">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="col-12 itemForSearch">
                                        <KeyboardDatePicker
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="dateStartFilter"
                                            label="Contatori da"
                                            value={this.state.startFilter}
                                            onChange={this.setStartFilterChange}
                                        />
                                    </div>

                                    <div className="col-12 itemForSearch">
                                        <KeyboardDatePicker
                                            variant="inline"
                                            id="dateEndFilter"
                                            label="Fino a"
                                            value={this.state.endFilter}
                                            onChange={this.setEndFilterChange}
                                            // se la data di inizio è presente, disabilita tutte le date da quella data (compresa)
                                            minDate={this.state.startFilter ? moment(this.state.startFilter).add(1, 'days')._d : false}
                                            minDateMessage="Attenzione! Data precedente a quella di inizio"
                                            format="dd/MM/yyyy"
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl>
                                        <InputLabel>Country</InputLabel>
                                        <Select
                                            name="country"
                                            value={this.state.country}
                                            onChange={this.setChange}
                                        >
                                            {
                                                countries.map((item, k) => {
                                                    return (
                                                        <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-12 itemForSearch">
                                    <FormControl>
                                        <InputLabel>Ruolo</InputLabel>
                                        <Select
                                            labelId="hasRole"
                                            name="hasRole"
                                            value={this.state.hasRole}
                                            onChange={this.setChange}
                                        >
                                            <MenuItem value='off'>OFF</MenuItem>
                                            <MenuItem value={false}>Utenti senza Ruolo</MenuItem>
                                            <MenuItem value={true}>Utenti con Ruolo</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="nome"
                                    />
                                </div>
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="surname"
                                        value={this.state.surname}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="cognome"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="registrationIp"
                                        value={this.state.registrationIp}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="indirizzo IP"
                                    />
                                </div>
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="group"
                                        value={this.state.group}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="gruppo"
                                    />
                                </div>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

            </Fragment>
        )
    }
}