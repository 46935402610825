import React, { Component, Fragment } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Select, FormControl, MenuItem, InputLabel, TextField } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { countries } from '../../common/countries';

export default class formFiltersMachine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // parametri per filtri ricerca
            id: '',
            mantainer: '',
            createdStartAt: null,
            createdEndAt: null,
            updateStartAt: null,
            updateEndAt: null,
            country: '',
            os: '',
            city: '',
            model: '',
            subnet: '',
            cpuDescription: '',
            status: '',
            isOnline: '',
            publicIp: '',
            typeOrder: '',
            fieldOrder: ''
        };

        this.onChange = this.onChange.bind(this);
        this.setDateCreatedStartAt = this.setDateCreatedStartAt.bind(this);
        this.setDateCreatedEndAt = this.setDateCreatedEndAt.bind(this);
        this.setDateUpdateStartAt = this.setDateUpdateStartAt.bind(this);
        this.setDateUpdateEndAt = this.setDateUpdateEndAt.bind(this);
    }

    /**
     * Cambia il valore di quello stato.
     * 
     * @param {*} event 
     */
    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === 'country' || event.target.name === 'typeOrder' || event.target.name === 'status') {
            setTimeout(() => {
                this.sendParamsForSearch();
            }, 200);
        }
    }

    setHourStart = (newHour) => {
        newHour.setHours(1);
        newHour.setMinutes(0);
        newHour.setSeconds(0);
        newHour.setMilliseconds(0);
        return newHour;
    }

    setHourEnd = (newHour) => {
        newHour.setHours(24);
        newHour.setMinutes(59);
        newHour.setSeconds(59);
        newHour.setMilliseconds(999);
        return newHour;
    }

    // change per le date
    setDateCreatedStartAt = (newDate) => { this.setState({ createdStartAt: newDate }); }
    setDateCreatedEndAt = (newDate) => { this.setState({ createdEndAt: newDate }); }
    setDateUpdateStartAt = (newDate) => { this.setState({ updateStartAt: newDate }); }
    setDateUpdateEndAt = (newDate) => { this.setState({ updateEndAt: newDate }); }

    // Resetta tutti i filtri
    resetFilter = () => {
        this.setState({
            id: '',
            mantainer: '',
            createdStartAt: null,
            createdEndAt: null,
            updateStartAt: null,
            updateEndAt: null,
            country: '',
            os: '',
            city: '',
            model: '',
            subnet: '',
            cpuDescription: '',
            status: '',
            isOnline: '',
            publicIp: '',
            typeOrder: '',
            fieldOrder: ''
        });

        setTimeout(() => {
            this.sendParamsForSearch('vuoto');
        }, 300);
    }

    /**
     * Se viene premuto il tasto enter (Invio) avvia una ricerca
     */
    clickEnter = (event) => {
        if (event.keyCode === 13) {
            this.sendParamsForSearch();
        }
    }

    /**
     * Invia i parametri filtro per la ricerca.
     * Se il parametro vuoto è presente, invia stringhe vuote per la ricerca.
     * 
     * @param {*} label se è vuoto, manda parametri vuoti
     */
    sendParamsForSearch = (label) => {
        if (label === 'vuoto') {
            this.props.setParamsForSearch({
                id: '',
                mantainer: '',
                createdStartAt: '',
                createdEndAt: '',
                updateStartAt: '',
                updateEndAt: '',
                country: '',
                os: '',
                city: '',
                model: '',
                subnet: '',
                cpuDescription: '',
                status: '',
                isOnline: '',
                publicIp: '',
                typeOrder: '',
                fieldOrder: ''
            });
        } else {
            this.props.setParamsForSearch({
                id: this.state.id,
                mantainer: this.state.mantainer,
                createdStartAt: this.state.createdStartAt === null ? '' : this.setHourStart(this.state.createdStartAt),
                createdEndAt: this.state.createdEndAt === null ? '' : this.setHourEnd(this.state.createdEndAt),
                updateStartAt: this.state.updateStartAt === null ? '' : this.setHourStart(this.state.updateStartAt),
                updateEndAt: this.state.updateEndAt === null ? '' : this.setHourEnd(this.state.updateEndAt),
                country: this.state.country,
                os: this.state.os,
                city: this.state.city,
                model: this.state.model,
                subnet: this.state.subnet,
                cpuDescription: this.state.cpuDescription,
                status: this.state.status,
                isOnline: this.state.isOnline,
                publicIp: this.state.publicIp,
                typeOrder: this.state.typeOrder,
                fieldOrder: this.state.fieldOrder
            });
        }
    }

    render() {
        const {
            numberVpnMachine,
            totalPages,
            setChangePagination,
            currentNumberPage
        } = this.props;

        return (
            <Fragment>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="id"
                            value={this.state.id}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="ID VPN Machine"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <FormControl>
                            <InputLabel>Cerca per Country</InputLabel>
                            <Select
                                name="country"
                                value={this.state.country}
                                onChange={this.onChange}
                            >
                                {
                                    countries.map((item, k) => {
                                        return (
                                            <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <FormControl>
                            <InputLabel>Cerca Stato Macchina</InputLabel>
                            <Select
                                labelId="statusLabel"
                                name="status"
                                value={this.state.status}
                                onChange={this.onChange}
                            >
                                <MenuItem value={'-2'}>Fallito</MenuItem>
                                <MenuItem value={'-1'}>Sospeso</MenuItem>
                                <MenuItem value={'0'}>Creato</MenuItem>
                                <MenuItem value={'1'}>Installato</MenuItem>
                                <MenuItem value={'2'}>Pronto</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="mantainer"
                            value={this.state.mantainer}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="Cerca per Mantainer"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button
                            className={numberVpnMachine === '10' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChangePagination({ target: { name: 'numberVpnMachine' } }, '10')}
                        > 10 </button>
                        <button
                            className={numberVpnMachine === '25' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChangePagination({ target: { name: 'numberVpnMachine' } }, '25')}
                        > 25 </button>
                        <button
                            className={numberVpnMachine === '50' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChangePagination({ target: { name: 'numberVpnMachine' } }, '50')}
                        > 50 </button>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <Pagination
                            boundaryCount={2}
                            count={parseInt(totalPages)}
                            page={parseInt(currentNumberPage)}
                            onChange={setChangePagination}
                            color="primary"
                            showFirstButton
                            showLastButton
                            size="small"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button className="btn btn-primary" style={{ 'margin': '10px' }} onClick={this.sendParamsForSearch}>
                            Cerca
                        </button>
                        <button className="btn btn-secondary" style={{ 'margin': '10px' }} onClick={this.resetFilter}>
                            Reset
                        </button>
                    </div>
                </div>

                <Accordion className="myAccordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    // aria-controls="panel1a-content"
                    // id="panel1a-header"
                    >
                        <Typography>Altri filtri</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div">
                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl>
                                        <InputLabel>Come vuoi ordinare?</InputLabel>
                                        <Select
                                            labelId="fieldOrderLabel"
                                            name="fieldOrder"
                                            value={this.state.fieldOrder}
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value={'Id'}>ID VPN Machine</MenuItem>
                                            <MenuItem value={'Mantainer'}>Mantainer</MenuItem>
                                            <MenuItem value={'Country'}>Country</MenuItem>
                                            <MenuItem value={'City'}>City</MenuItem>
                                            <MenuItem value={'Subnet'}>Subnet</MenuItem>
                                            <MenuItem value={'Model'}>Model</MenuItem>
                                            <MenuItem value={'Os'}>OS</MenuItem>
                                            <MenuItem value={'CreatedAtStart'}>Data creazione</MenuItem>
                                            <MenuItem value={'UpdatedAtStart'}>Data modifica</MenuItem>
                                            <MenuItem value={'Score'}>Score</MenuItem>
                                            <MenuItem value={'Tx'}>TX</MenuItem>
                                            <MenuItem value={'Rx'}>RX</MenuItem>
                                            <MenuItem value={'Memory'}>RAM</MenuItem>
                                            <MenuItem value={'Cpu'}>CPU</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-12 itemForSearch">
                                    <FormControl disabled={this.state.fieldOrder === ''}>
                                        <InputLabel>Tipo di Ordinamento</InputLabel>
                                        <Select
                                            labelId="typeOrderLabel"
                                            name="typeOrder"
                                            value={this.state.typeOrder}
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value={'asc'}>Crescente [A-Z]</MenuItem>
                                            <MenuItem value={'desc'}>Decrescente [Z-A]</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="col-12 itemForSearch">
                                        <KeyboardDatePicker
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="createdStartAt"
                                            label="Creata dal"
                                            value={this.state.createdStartAt}
                                            onChange={this.setDateCreatedStartAt}
                                        />
                                    </div>

                                    <div className="col-12 itemForSearch">
                                        <KeyboardDatePicker
                                            variant="inline"
                                            id="createdEndAt"
                                            label="Fino a"
                                            value={this.state.createdEndAt}
                                            onChange={this.setDateCreatedEndAt}
                                            // se la data di inizio è presente, disabilita tutte le date da quella data (compresa)
                                            minDate={this.state.createdStartAt ? moment(this.state.createdStartAt).add(1, 'days')._d : false}
                                            minDateMessage="Attenzione! Data precedente a quella di inizio"
                                            format="dd/MM/yyyy"
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>

                            <div className="row">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="col-12 itemForSearch">
                                        <KeyboardDatePicker
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="updateStartAt"
                                            label="Modificata dal"
                                            value={this.state.updateStartAt}
                                            onChange={this.setDateUpdateStartAt}
                                        />
                                    </div>

                                    <div className="col-12 itemForSearch">
                                        <KeyboardDatePicker
                                            variant="inline"
                                            id="updateEndAt"
                                            label="Fino a"
                                            value={this.state.updateEndAt}
                                            onChange={this.setDateUpdateEndAt}
                                            // se la data di inizio è presente, disabilita tutte le date da quella data (compresa)
                                            minDate={this.state.updateStartAt ? moment(this.state.updateStartAt).add(1, 'days')._d : false}
                                            minDateMessage="Attenzione! Data precedente a quella di inizio"
                                            format="dd/MM/yyyy"
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="city"
                                        value={this.state.city}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Cerca per Città"
                                    />
                                </div>

                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="subnet"
                                        value={this.state.subnet}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Cerca per Subnet"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="os"
                                        value={this.state.os}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Cerca per OS"
                                    />
                                </div>
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="cpuDescription"
                                        value={this.state.cpuDescription}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Cerca Descrizione CPU"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="publicIp"
                                        value={this.state.publicIp}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Cerca per IP Pubblico"
                                    />
                                </div>
                                {/* <div className="col-12 itemForSearch">

                                </div> */}
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

            </Fragment>
        )
    }
}