import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormControl, TextField, Select, InputLabel, MenuItem } from '@material-ui/core';
import 'date-fns';

import './style.scss';
import { preferencesActions } from '../../store/actions';

/**
 * Attivazione modale per modificare vpnBox
 * 
 */
class modalVpnBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.vpnBox.id || '',
            description: this.props.vpnBox.description || '',
            country: this.props.vpnBox.country || '',
            wiFiPwd: this.props.vpnBox.wiFiPwd || '',
            wiFiSSD: this.props.vpnBox.wiFiSSD || '',

            formIsValid: false,
        }
        this.setChange = this.setChange.bind(this);
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    /**
     * Se i dati sono corretti, prepara l'oggetto da mandare al BE
     * per aggiungere o modificare una VPN
     */
    sendVpn = () => {
        if (this.formIsValid()) {
            let data = {};
            let serial = this.state.id;
            data = {
                "boxFields": [
                    {
                        "fieldName": "description",
                        "fieldValue": this.state.description,
                    },
                    {
                        "fieldName": "country",
                        "fieldValue": this.state.country,
                    },
                    {
                        "fieldName": "wiFiSSD",
                        "fieldValue": this.state.wiFiSSD,
                    },
                    {
                        "fieldName": "wiFiPwd",
                        "fieldValue": this.state.wiFiPwd,
                    }
                ]
            }
            this.props.click(serial, data);
        } else {
            this.props.errorNotify('Compila tutti i campi!');
        }

    }

    /**
     * Controlla se i campi sono stati inseriti tutti
     */
    formIsValid = () => {
        if (
            (this.state.description === '') ||
            (this.state.country === '') ||
            (this.state.wiFiPwd === '') ||
            (this.state.wiFiSSD === '')
        ) {
            this.setState({ formIsValid: true });
            return false;
        } else {
            this.setState({ formIsValid: false });
            return true;
        }
    }

    render() {
        const { stateModal, toggle, titleModal, getCountriesList } = this.props;

        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalVpn">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        <FormControl className="choose-form">
                            <div className="row">
                                <div className="col-6">
                                    <TextField
                                        name="id"
                                        label="Seriale"
                                        value={this.state.id}
                                        onChange={this.setChange}
                                        error={this.state.id === '' && this.state.formIsValid}
                                        disabled
                                    />
                                </div>

                                <div className="col-6">
                                    <TextField
                                        name="description"
                                        label="Descrizione"
                                        value={this.state.description}
                                        onChange={this.setChange}
                                        error={(this.state.description === '' && this.state.formIsValid)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <TextField
                                        name="wiFiSSD"
                                        label="wiFiSSD"
                                        value={this.state.wiFiSSD}
                                        onChange={this.setChange}
                                        error={this.state.wiFiSSD === '' && this.state.formIsValid}
                                    />
                                </div>
                                <div className="col-6">
                                    <TextField
                                        name="wiFiPwd"
                                        label="WiFiPwd"
                                        value={this.state.wiFiPwd}
                                        onChange={this.setChange}
                                        error={this.state.wiFiPwd === '' && this.state.formIsValid}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <FormControl>
                                        <InputLabel>Country</InputLabel>
                                        <Select
                                            labelId="Country"
                                            name="country"
                                            value={this.state.country}
                                            onChange={this.setChange}
                                        >
                                            {
                                                getCountriesList.map((item, key) => {
                                                    return (
                                                        <MenuItem key={key} value={item.key} >
                                                            {item.text}
                                                        </MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </FormControl>

                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.sendVpn}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        preferences: state.preferences
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProductList: (productType) => {
            dispatch(preferencesActions.getProductList(productType))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(modalVpnBox);