import { stockActionType } from '../actions';

const initialState = {
    isLoading: false,

    available: 0, // disponibili per l'acquisto
    busy: 0, // prenotati per la spedizione
    load: 0, // caricati a magazzino
    unload: 0, // rimossi dal magazzino

    // contiene le vpn box stock totali nella chiamata dei movimenti della vpn box (coi filtri)
    totalNumberVpnBoxStockMovement: 0,

    // contiene la lista di tutti i bundle (risposta della GET)
    listVpnBoxStockMovement: [],

    // contiene i parametri che vengono usati per la chiamata GET (nel caso vengano usati i dispatch con la GET dopo il CRUD)
    paramsVpnBoxStockMovementTemp: {},

    totalPages: '',

    // variabile usata solo per mostrare il titolo nella lista delle stock
    valueTypeForTitle: 'B'
};

export default function stock(state = initialState, action) {
    switch (action.type) {
        case `${stockActionType.SET_NUMBER_BOXSTOCK_MOVEMENT}`:
            return {
                ...state,
                totalNumberVpnBoxStockMovement: action.totalNumberVpnBoxStockMovement
            }
        case `${stockActionType.GET_VPNBOX_STOCK_INFO}_PENDING`:
        case `${stockActionType.ADD_MOVEMENT_BOXSTOCK}_PENDING`:
        case `${stockActionType.REMOVE_MOVEMENT_BOXSTOCK}_PENDING`:
        case `${stockActionType.ASSOCIED_TRACKING}_PENDING`:
        case `${stockActionType.VPN_BOX_STOCK}_PENDING`:
            return {
                ...state,
                isLoading: true
            }
        case `${stockActionType.ADD_MOVEMENT_BOXSTOCK}_FULFILLED`:
        case `${stockActionType.REMOVE_MOVEMENT_BOXSTOCK}_FULFILLED`:
        case `${stockActionType.ASSOCIED_TRACKING}_FULFILLED`:
            return {
                ...state,
                isLoading: false
            }
        case `${stockActionType.GET_VPNBOX_STOCK_INFO}_FULFILLED`:
            return {
                ...state,
                isLoading: false,
                available: action.payload.available,
                busy: action.payload.busy,
                load: action.payload.load,
                unload: action.payload.unload
            }
        case `${stockActionType.VPN_BOX_STOCK}_FULFILLED`:
            return {
                ...state,
                isLoading: false,
                listVpnBoxStockMovement: action.payload.listVpnBoxStockMovement,
                totalPages: action.payload.totalPages,
                paramsVpnBoxStockMovementTemp: action.payload.paramsVpnBoxStockMovementTemp,
                valueTypeForTitle: action.payload.valueTypeForTitle
            }
        default:
            return state;
    }
}