import axios from 'axios';
import { notifyActions, roleActionType, userActions } from './';
import store from '../index';
import {
  VPN_PASS_ROLE,
  PARTNERS_ROLE,
  PRODUCT_ROLE,
  PROMO_ROLE,
  ROLE_ROLE,
  SUPPORT_ROLE,
  USER_ROLE,
  PAYMENT_ROLE,
  PLAYER_ROLE,
  PREFERENCES_ROLE,
  VPN_MACHINE_ROLE,
  VPN_PEER_ROLE,
  VPN_BOX_ROLE,
  VPN_BOX_STOCKS_ROLE,
  BUNDLE_ROLE,
  WORKER_ROLE,
  CHANGE_LOG_ROLE,
  API_ROLE,
  API_GET_BACKOFFICE_ROLES,
  API_ASSIGN_ROLE,
  API_UNASSIGN_ROLE,
  CONFIG_ROLE
} from '../../config';

export const roleActions = {
  deleteRole,
  takeRole,
  newRole,
  getRoleList,
  assignRole,
  unassignRole,
  updateRole
};

/**
 * Mette nello store i ruoli dell'utente che ha effettuato il login o il refreshtoken.
 * 
 * Dopo la chiamata del login e della refreshToken, viene passato un'array a questa funzione 
 * e i valori di questo array contengono i metodi che l'utente è abilitato a usare nel back office.
 * Se qualche metodo non è presente, vuol dire che l'utente non è abilitato a eseguire quell'operazione.
 * L'array in entrata contiene degli oggetti. 
 * Ogni oggetto contiene:
 *  - controller, è il nome del tipo del metodo (es: player, VPN Pass, ecc...)
 *  - action, è il nome dell'api del metodo (es: api/VpnPass, api/Player, ecc...)
 *  - httpVerb, è l'azione http del metodo (es: GET, POST, ecc...)
 * 
 * @param {*} arrayRole - effettuare il redirect al pannello di login con la funzione history
 */
function takeRole(arrayRole) {
  let VpnPassTemp = [];
  let PartnersTemp = [];
  let ProductTemp = [];
  let PromoTemp = [];
  let RoleTemp = [];
  let SupportTemp = [];
  let UserTemp = [];
  let PaymentTemp = [];
  let PlayerTemp = [];
  let PreferencesTemp = [];
  let VpnMachineTemp = [];
  let VpnPeerTemp = [];
  let VpnBoxTemp = [];
  let VpnBoxStockTemp = [];
  let BundleTemp = [];
  let WorkerTemp = [];
  let ChangeLogTemp = [];
  let ConfigTemp = [];

  // eslint-disable-next-line
  arrayRole.map(el => {
    switch (el.controller) {
      case VPN_PASS_ROLE: VpnPassTemp.push(el); break;
      case PARTNERS_ROLE: PartnersTemp.push(el); break;
      case PRODUCT_ROLE: ProductTemp.push(el); break;
      case PROMO_ROLE: PromoTemp.push(el); break;
      case ROLE_ROLE: RoleTemp.push(el); break;
      case SUPPORT_ROLE: SupportTemp.push(el); break;
      case USER_ROLE: UserTemp.push(el); break;
      case PAYMENT_ROLE: PaymentTemp.push(el); break;
      case PLAYER_ROLE: PlayerTemp.push(el); break;
      case PREFERENCES_ROLE: PreferencesTemp.push(el); break;
      case VPN_MACHINE_ROLE: VpnMachineTemp.push(el); break;
      case VPN_PEER_ROLE: VpnPeerTemp.push(el); break;
      case VPN_BOX_ROLE: VpnBoxTemp.push(el); break;
      case VPN_BOX_STOCKS_ROLE: VpnBoxStockTemp.push(el); break;
      case BUNDLE_ROLE: BundleTemp.push(el); break;
      case WORKER_ROLE: WorkerTemp.push(el); break;
      case CHANGE_LOG_ROLE: ChangeLogTemp.push(el); break;
      case CONFIG_ROLE: ConfigTemp.push(el); break;
      default: break;
    }
  })

  return {
    type: roleActionType.ASSIGN_ROLE,
    payload: {
      vpnPass: VpnPassTemp,
      partners: PartnersTemp,
      product: ProductTemp,
      promo: PromoTemp,
      role: RoleTemp,
      support: SupportTemp,
      user: UserTemp,
      payment: PaymentTemp,
      player: PlayerTemp,
      preferences: PreferencesTemp,
      vpnMachine: VpnMachineTemp,
      vpnPeer: VpnPeerTemp,
      vpnBox: VpnBoxTemp,
      vpnBoxStock: VpnBoxStockTemp,
      bundle: BundleTemp,
      worker: WorkerTemp,
      changeLog: ChangeLogTemp,
      allRoleList: arrayRole,
      configFlush: ConfigTemp
    }
  }
}

/**
 * Restituisce la lista dei ruoli presenti a DB
 * 
 */
function getRoleList() {
  return (dispatch) => {
    dispatch({
      type: roleActionType.GET_ROLE,
      async payload() {
        try {
          let response = await axios.get(`${API_GET_BACKOFFICE_ROLES}`);

          return {
            roleList: response.data
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 403: notifyActions.infoAction('Non hai i permessi per visualizzare i ruoli!'); break;
              default:
                notifyActions.errorAction(`Error ${result.response.status} su ${API_GET_BACKOFFICE_ROLES} - GET`); break;
            }
          }
          return {
            roleList: []
          }
        }
      }
    })
  }
}

/**
 * Cancella un ruolo dal DB tramite un roleId
 * 
 * @param {String} roleId 
 */
function deleteRole(roleId) {
  return (dispatch) => {
    dispatch({
      type: roleActionType.DELETE_ROLE,
      async payload() {
        try {
          await axios.delete(`${API_ROLE}/${roleId}`)
            .then(() => {
              dispatch(notifyActions.successAction('Ruolo eliminato! 😉'));
              dispatch(getRoleList());
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('Ruolo non trovato! 🙁')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_ROLE} - DELETE`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Crea un nuovo ruolo.
 * 
 * @param {Object} data contiene: name, i ruoli associti
 */
function newRole(data) {
  return (dispatch) => {
    dispatch({
      type: roleActionType.NEW_ROLE,
      async payload() {
        try {
          await axios.post(`${API_ROLE}`, data)
            .then(() => {
              dispatch(notifyActions.successAction('Ruolo creato! 😉'));
              dispatch(getRoleList());
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: dispatch(notifyActions.errorAction('Ruolo non creato! Compila correttamente i campi')); break;
              case 409: dispatch(notifyActions.errorAction('Ruolo gia esistente!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_ROLE} - POST`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Update di un nuovo ruolo.
 * 
 * @param {Object} data contiene: name, i ruoli associti
 */
function updateRole(data) {
  return (dispatch) => {
    dispatch({
      type: roleActionType.UPDATE_ROLE,
      async payload() {
        try {
          await axios.put(`${API_ROLE}`, data)
            .then(() => {
              dispatch(notifyActions.successAction('Ruolo aggiornato! 😉'));
              dispatch(getRoleList());
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: dispatch(notifyActions.errorAction('Ruolo non modificato! Compila correttamente i campi')); break;
              case 404: dispatch(notifyActions.errorAction('Ruolo non trovato!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_ROLE} - PUT`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Crea un nuovo ruolo.
 * 
 * @param {Object} data contiene: l'email dell'utente il ruolo da associare
 */
function assignRole(data) {
  return (dispatch) => {
    dispatch({
      type: roleActionType.PATCH_ROLE,
      async payload() {
        try {
          await axios.patch(`${API_ASSIGN_ROLE}`, data)
            .then(() => {
              dispatch(notifyActions.successAction('Ruolo associato! 😉'));
              dispatch(userActions.getUserList(store.getState().user.paramsGetUserListTemp));
              dispatch(getRoleList());
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('Utente non trovato! 🙁')); break;
              case 400: dispatch(notifyActions.errorAction('Utente non assegnato! 🙁')); break;
              case 409: dispatch(notifyActions.errorAction('Ruolo gia esistente! (Ruolo esistente)')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_ASSIGN_ROLE} - PATCH`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Rimuove un ruolo ad un utente.
 * 
 * @param {Object} data contiene: l'email dell'utente il ruolo da associare
 */
function unassignRole(data) {
  return (dispatch) => {
    dispatch({
      type: roleActionType.PATCH_UNASSIGN_ROLE,
      async payload() {
        try {
          await axios.patch(`${API_UNASSIGN_ROLE}?user=${data}`)
            .then(() => {
              dispatch(notifyActions.successAction('Ruolo rimosso! 😉'));
              dispatch(userActions.getUserList(store.getState().user.paramsGetUserListTemp));
              dispatch(getRoleList());
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('Utente non trovato! 🙁')); break;
              case 409: dispatch(notifyActions.errorAction('Non è possibile rimuovere un ruolo dal proprio utente')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_UNASSIGN_ROLE} - PATCH`)); break;
            }
          }
        }
      }
    })
  }
}
