import moment from 'moment';
import 'moment/min/locales';
import { isNull } from 'lodash';
import React, { Component, Fragment } from 'react';

import './style.scss';

/**
 * Componente che ritorna un determinato formato data.
 * - format LLL (Gennaio 20, 2010 - 21:20:23 PM)
 * - format L (20/01/2010 - 21:20:23 PM)
 * - date (il valore da renderizzare)
 * - onlyLabel (se presente, mostra solo la data senza nessun tag)
 */
export default class DateColumn extends Component {
    render() {
        const { date, format, onlyLabel } = this.props;
        return (
            <Fragment>
                {
                    onlyLabel ?
                        <span className="onlyLabel">
                            {!isNull(date) && moment(date).format('DD-MM-YYYY - HH:mm:ss')}
                        </span>
                        :
                        <Fragment>
                            <p className="dateFormat">
                                {
                                    isNull(date) ? '' :
                                        format === 'LLL' ? moment(date).format('LLL') :
                                            format === 'L' ? moment(date).format('DD-MM-YYYY') : ''
                                }
                            </p>

                            <p className="dateFormat">
                                {
                                    isNull(date) ? 'Nessuna Data!' :
                                        format === 'L' ? moment(date).format('LT') : ''
                                }
                            </p>
                        </Fragment>
                }
            </Fragment>
        )
    }
}