import { notifyActionType } from '../actions';

const initialState = {

};

export default function notify(state = initialState, action) {
    switch (action.type) {
        case notifyActionType.NOTIFY_SUCCESS:
        case notifyActionType.NOTIFY_ERROR:
        case notifyActionType.NOTIFY_WARNING:
        case notifyActionType.NOTIFY_INFO:
            return { 
                ...state
            };
        default:
            return state;
    }
}