import axios from 'axios';
import store from '../index';
import { paymentActionType, notifyActions } from './';
import {
  API_PAYMENT,
  API_CANCEL_SUB,
  API_PAYMENT_REFUND,
  API_PAYMENT_UPDATE_DEVICE,
} from '../../config';

export const paymentActions = {
  setIdPaymentForDetail,
  getPaymentList,
  deletePayment,
  deactiveSub,
  refundPayment,
  updateDevicePayment
};

/**
 * Setta nel payload un id di pagamento che è stato selezionato per visualizzarlo nel dettaglio
 * 
 * @param {String} idPaymentForDetail 
 */
function setIdPaymentForDetail(idPaymentForDetail) {
  return {
    type: paymentActionType.SET_ID_PAYMENT_FOR_DETAIL,
    idPaymentForDetail
  }
}

/**
 * Prende in input una serie di filtri e restituisce la lista dei pagamenti.
 * 
 * @param {Object} data
 */
function getPaymentList(data) {
  return (dispatch) => {
    dispatch({
      type: paymentActionType.GET_PAYMENT,
      async payload() {
        try {
          let response = await axios.get(`${API_PAYMENT}`, { params: data });

          return {
            listPayments: response.data.value.value,
            totalPages: response.data.info.totalPages,
            totalPayments: response.data.info.total,
            paramsForListPaymentsTemp: data
          }

        } catch (result) {

          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 400: dispatch(notifyActions.errorAction('Inserisci un numero valido!')); break;
              case 403: dispatch(notifyActions.errorAction('Error 403 - Non sei autorizzato!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_PAYMENT} - GET`)); break;
            }
          }

          return {
            listPayments: [],
            totalPages: '',
            totalPayments: '',
            paramsForListPaymentsTemp: {}
          }
        }
      }
    })
  }
}

/**
 * Elimina un pagamento
 * 
 * @param {Object} id del pagamento da eliminare
 */
function deletePayment(id) {
  return (dispatch) => {
    dispatch({
      type: paymentActionType.DELETE_PAYMENT,
      async payload() {
        await axios.delete(`${API_PAYMENT}/${id}`)
          .then(() => {
            dispatch(notifyActions.successAction('Pagamento rimosso correttamente! 😉'));
            dispatch(getPaymentList(store.getState().payment.paramsForListPaymentsTemp));
          })
          .catch(result => {
            if (result && result.response && result.response.status) {
              switch (result.response.status) {
                case 404: dispatch(notifyActions.errorAction('Pagamento non trovato!')); break;
                default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_PAYMENT} - DELETE`)); break;
              }
            }
          });
      }
    })
  }
}

/**
 * Disattiva Sottoscrizione
 * 
 * @param {Object} id della sottoscrizione da disattivare
 */
function deactiveSub(id) {
  return (dispatch) => {
    dispatch({
      type: paymentActionType.CANCEL_SUB,
      async payload() {
        await axios.post(`${API_CANCEL_SUB}/${id}`)
          .then(() => {
            dispatch(notifyActions.successAction('Sottoscrizione disattivata correttamente! 😉'));
            dispatch(getPaymentList(store.getState().payment.paramsForListPaymentsTemp));
          })
          .catch(result => {
            if (result && result.response && result.response.status) {
              switch (result.response.status) {
                case 404: dispatch(notifyActions.errorAction('Pagamento non trovato!')); break;
                default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_CANCEL_SUB} - POST`)); break;
              }
            }
          });
      }
    })
  }
}

/**
 * Rimborsa un pagamento
 * 
 * @param {Object} id del pagamento da rimborsare
 * @param {Object} data
 */
function refundPayment(id, data) {
  return (dispatch) => {
    dispatch({
      type: paymentActionType.REFUND_PAYMENT,
      async payload() {
        await axios.post(`${API_PAYMENT_REFUND}/${id}`, data)
          .then(() => {
            dispatch(notifyActions.successAction('Pagamento rimborsato correttamente! 😉'));
            dispatch(getPaymentList(store.getState().payment.paramsForListPaymentsTemp));
          })
          .catch(result => {
            if (result && result.response && result.response.status) {
              switch (result.response.status) {
                case 404: dispatch(notifyActions.errorAction('Pagamento non trovato! Nessun rimborso!')); break;
                case 406: dispatch(notifyActions.warningAction('Non è ancora possibile rimborsare... Aspetta ancora un pò!')); break;
                default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_PAYMENT_REFUND} - POST`)); break;
              }
            }

          });
      }
    })
  }
}

/**
 * Associa il pagamento a un determinato device.
 * Stacca la licenza al vecchio device (trial), attiva la lifetime all nuova licenza associandogli l'id pagamento
 * 
 * @param {Object} data (newDeviceId è l'id del nuovo device, oldDeviceID è l'id del vecchio device, paymentId è l'id del pagamento da associare)
 */
function updateDevicePayment(data) {
  return (dispatch) => {
    dispatch({
      type: paymentActionType.UPDATE_DEVICE,
      async payload() {
        try {
          let params = {
            oldDeviceId: data.oldDeviceId,
            newDeviceId: data.newDeviceId,
            transactionId: data.providerTransactionId
          }
          // Associa il pagamento a un nuovo device
          await axios.put(`${API_PAYMENT_UPDATE_DEVICE}`, params);
          dispatch(notifyActions.successAction('OK! Pagamento spostato correttamente al device: ' + data.newDeviceId));
          dispatch(notifyActions.warningAction('⚠ Controlla per sicurezza se le licenze sui device sono cambiate!'));

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('Device non trovato! Nessun rimborso!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_PAYMENT_UPDATE_DEVICE} - PUT`)); break;
            }
          }
        }
      }
    })
  }
}
