import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';

import Loading from '../../common/loading/loadingInBlock';
import Modal from '../../common/modal';
import MyTooltip from '../../common/tooltip';
import ModalActiveJob from '../../common/modal/modalActiveJob';
import ExpanableComponent from './expanableComponentActiveJob';
import ActionMenu from './actionMenuActiveJob';
import Date from '../../common/date';
import { randomString } from '../../common/actions/randomString';
import { API_WORKER_ACTIVE_JOBS } from '../../config';
import { controlRole } from '../../common/actions/controlRole';

export default class activeJobs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasClickForModal: false,
            hasClickNewActiveJob: false,
            modal: false,
            modalActiveJob: false,
            titleModal: '',
            contentModal: '',
            dataTemp: '',
            shortId: randomString(),
            isLoading: false
        }
    }

    /**
     * Restituisce l'icona colorata in base allo status.
     * Definisco se la status sia connesso o meno tramite icona verde o rossa.
     * 
     * @param {Boolean} status 
     * @param {String} text
     */
    status = (status, text) => {
        if (status) {
            return <span className="badge badge-success">{text}</span>;
        } else {
            return <span className="badge badge-danger">{text}</span>;
        }
    }

    closeModal = () => {
        this.setState({
            hasClickForModal: false,
            hasClickNewActiveJob: false,
            titleModal: '',
            contentModal: '',
            dataTemp: '',
            modal: false,
            modalActiveJob: false,
        });
    }

    /**
     * Se l'utrente clicca per attivare una modale di cancellazione active job
     * @param {*} data 
     */
    hasClickForModal = (data) => {
        this.setState({
            hasClickForModal: true,
            hasClickNewActiveJob: false,
            titleModal: 'Rimuovi Active JOB',
            contentModal: 'Sei sicuro di voler rimuovere l\'Active Job con il seguente id: ' + data,
            dataTemp: data,
            modalActiveJob: false
        });
        setTimeout(() => {
            this.setState({
                modal: true
            });
        }, 200);
    }

    /**
     * Se l'utrente clicca per attivare una modale di creazione active job
     */
    hasClickNewActiveJob = () => {
        this.setState({
            hasClickForModal: false,
            hasClickNewActiveJob: true,
            titleModal: 'Aggiungi Active JOB',
            contentModal: '',
            dataTemp: '',
            modal: false,
        });
        setTimeout(() => {
            this.setState({
                modalActiveJob: true
            });
        }, 200);
    }

    /**
     * Al click del menu opzioni, controlla quale opzione sia stata selezionata 
     * e di conseguenza esegue l'azione per quella riga.
     * 
     * @param action è il tipo di azione selezionato
     * @param data è l'oggetto contente le informazioni (deviceId, paymentId)
     */
    actionClick = (action, data) => {
        switch (action) {
            case 'remove': this.hasClickForModal(data); break;
            default: break;
        }
    }

    /**
     * Aggiunge un active job e richiama tutte le liste dopo averlo aggiunto.
     * @param {*} data 
     */
    addActiveJob = (data) => {
        let that = this;
        let thatProps = this.props;
        this.setState({ isLoading: true });

        axios.post(`${API_WORKER_ACTIVE_JOBS}`, data)
            .then(function () {
                that.setState({ isLoading: false });
                thatProps.successNotify('Active JOB aggiunto!');
                thatProps.callAllList();
            })
            .catch(function (result) {
                that.setState({ isLoading: false });
                thatProps.errorNotify(`Error ${result.response.status} su ${API_WORKER_ACTIVE_JOBS} - POST`);
            });
    }

    /**
     * Rimuovo un active job e richiama tutte le liste dopo averlo rimosso.
     * @param {*} id 
     */
    removeActiveJob = (id) => {
        let that = this;
        let thatProps = this.props;
        this.setState({ isLoading: true });

        axios.delete(`${API_WORKER_ACTIVE_JOBS}/${id}`)
            .then(function () {
                that.setState({ isLoading: false });
                thatProps.successNotify('Active JOB rimosso!');
                thatProps.callAllList();
            })
            .catch(function (result) {
                that.setState({ isLoading: false });
                thatProps.errorNotify(`Error ${result.response.status} su ${API_WORKER_ACTIVE_JOBS} - DELETE`);
            });
    }

    /**
     * In base allo stato se sia un nuovo active job o un edit active job,
     * invio i dati a una determinata chiamata BE.
     */
    actionModal = (data) => {
        if (this.hasClickNewActiveJob) {
            this.addActiveJob(data);
        }
        this.closeModal();
    }

    render() {
        const {
            loadingActiveJob,
            listActiveJobs,
            listJobs,
            listTasks,
            listServices,
            errorNotify,
            role
        } = this.props;

        const columns = [
            {
                name: 'ID',
                selector: row => row.id,
                sortable: true
            },
            {
                name: 'Service',
                selector: row => row.service,
                sortable: true
            },
            {
                name: 'Task',
                selector: row => row.task,
                sortable: true
            },
            // {
            //     name: 'Last Run',
            //     selector: 'lastRunAt',
            //     sortable: true
            // },
            {
                name: 'Created',
                // selector: 'createdAt',
                selector: row => row.createdAt,
                cell: row => <Date date={row.createdAt} onlyLabel />,
                sortable: true
            },
            // {
            //     name: 'Next Run',
            //     selector: 'nextRunAt',
            //     sortable: true
            // },
            {
                name: 'Is Busy?',
                selector: row => this.status(row.isBusy, row.isBusy ? "Busy" : "Not Busy"),
                sortable: false,
                center: true
            },
            {
                name: 'Ultima eccezione?',
                selector: row =>
                    row.lastException === null ?
                        'Nessuna'
                        :
                        <MyTooltip
                            title={row.lastException}
                            position="top"
                            content={<span>#EXCEPTION</span>}
                        />,
                sortable: false,
                center: true
            },
            // {
            //     name: '',
            //     selector: row => this.status(row.isFinished, row.isFinished ? "Finished" : "Not Finished"),
            //     sortable: false
            // },
            {
                selector: row =>
                    // metto qui il controllo sul ruolo DELETE poiche il menu contiene solo DELETE come azione
                    (row.status === 1 || row.status === '1' || !controlRole(role.worker, "api/Worker/ActiveJobs", "DELETE")) ? '' :
                        <ActionMenu
                            actionClick={this.actionClick}
                            data={row}
                        />,
                sortable: false,
                right: true,
                button: true,
                allowOverflow: true,
                wrap: true,
                minWidth: '50px',
                maxWidth: '50px'
            }
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <div className="activeJob">
                <div className="row activeJobTitle">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                        <h5> Active JOBS </h5>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-right activeJobButton">
                        {
                            controlRole(role.worker, "api/Worker/ActiveJobs", "POST") &&
                            <button className="btn btn-success removeMargin" onClick={this.hasClickNewActiveJob}>
                                <i className="fas fa-plus" style={{ marginRight: '10px' }}></i>
                                Aggiungi Active JOBS
                            </button>
                        }
                    </div>
                </div>
                {
                    (this.state.isLoading || loadingActiveJob) ? <Loading /> :
                        <Fragment>
                            <DataTable
                                columns={columns}
                                data={listActiveJobs}
                                customStyles={customStyles}
                                noHeader
                                striped
                                highlightOnHover
                                pointerOnHover
                                expandableRows
                                expandOnRowClicked
                                expandableRowsComponent={ExpanableComponent}
                                expandableRowsComponentProps={{
                                    "clickMoreDetail": this.actionClick,
                                }}
                            />
                        </Fragment>
                }

                {
                    this.state.hasClickForModal &&
                    <Modal
                        click={() => { this.removeActiveJob(this.state.dataTemp); this.closeModal(); }}
                        titleModal={this.state.titleModal}
                        contentModal={this.state.contentModal}
                        toggle={this.closeModal}
                        stateModal={this.state.modal}
                        key={this.state.shortId}
                    />
                }

                {
                    this.state.hasClickNewActiveJob &&
                    <ModalActiveJob
                        click={this.actionModal}

                        listJobs={listJobs}
                        listTasks={listTasks}
                        listServices={listServices}

                        errorNotify={errorNotify}
                        titleModal={this.state.titleModal}
                        toggle={this.closeModal}
                        stateModal={this.state.modalActiveJob}
                        key={this.state.shortId}
                    />
                }
            </div>
        )
    }
}