import React, { Component, Fragment } from 'react';
import { TextField } from '@material-ui/core';
import moment from 'moment';

import Loading from '../../common/loading';
import { returnNationality } from '../../common/actions/returnNationality';
import { controlRole } from '../../common/actions/controlRole';
import { SUPER_ADMIN } from '../../config';
import { isEmpty } from 'lodash';

export default class detailUserForm extends Component {
    render() {
        const {
            userInDetail,
            userId,
            role,
            user,
            billingAddress
        } = this.props;

        return (
            <Fragment>
                {
                    userId === '' ? <Loading /> :
                        <Fragment>

                            <div className="row itemForSearch">
                                <div className="col-12">
                                    <TextField
                                        label="Email"
                                        value={userInDetail.id}
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div className="row itemForSearch">
                                <div className="col-12">
                                    <TextField
                                        label="Nome e Cognome"
                                        value={`${billingAddress.name || ''}  ${billingAddress.surname || ''}`}
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div className="row itemForSearch">
                                <div className="col-12">
                                    <TextField
                                        label="Nazionalità"
                                        value={returnNationality(billingAddress.country) || ''}
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div className="row itemForSearch">
                                <div className="col-12">
                                    <TextField
                                        label="L'utente è registrato ?"
                                        value={userInDetail.isRegistered || false}
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div className="row itemForSearch">
                                <div className="col-12">
                                    <TextField
                                        label="Creato il"
                                        value={userInDetail.createdAt ? moment(userInDetail.createdAt).format('LLL') : ''}
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div className="row itemForSearch">
                                <div className="col-12">
                                    <TextField
                                        label="Ultimo accesso"
                                        value={userInDetail.lastLogOn ? moment(userInDetail.lastLogOn).format('LLL') : ''}
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div className="row itemForSearch">
                                <div className="col-12">
                                    <TextField
                                        label="IP Registrazione"
                                        value={userInDetail.registrationIp || ''}
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div className="row itemForSearch">
                                <div className="col-12">
                                    <TextField
                                        label="Ruolo"
                                        value={userInDetail.role || ''}
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div className="row itemForSearch">
                                <div className="col-12">
                                    <TextField
                                        label="È un reseller?"
                                        value={userInDetail.isReseller ? 'SI' : 'NO'}
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div className="row itemForSearch">
                                <div className="col-12 text-center">
                                    <b>Gruppi di appartenenza:</b>
                                    {
                                        isEmpty(userInDetail.group) ? '- Nessun gruppo associato - ' :
                                            <div>
                                                {
                                                    userInDetail.group.map((el, k) => {
                                                        return (
                                                            <span key={k}>
                                                                {`${el.name} - `}
                                                            </span>
                                                        )
                                                    })
                                                }
                                            </div>
                                    }
                                </div>
                            </div>

                            <hr />

                            <div className="row itemForSearch">
                                <div className="text-center col-12">
                                    {
                                        controlRole(role.support, "api/Support/BackOfficeSendMail", "POST") &&
                                        <button className="btn btn-primary" onClick={this.props.openModalMail} style={{ 'minWidth': '12rem' }} >
                                            <i className="fas fa-paper-plane"></i> Manda una mail!
                                        </button>
                                    }
                                </div>
                            </div>

                            <div className="row itemForSearch">
                                <div className="text-center col-12">
                                    {
                                        controlRole(role.user, "api/User/ResetPassword", "PUT") &&
                                        <button className="btn btn-success" onClick={() => this.props.openModalUserInDetail("resetPassword")} style={{ 'minWidth': '12rem' }} >
                                            <i className="fas fa-key"></i> Reset Password
                                        </button>
                                    }
                                </div>
                            </div>

                            <div className="row itemForSearch">
                                <div className="text-center col-12">
                                    {
                                        controlRole(role.user, "api/User/ResetTfa", "PUT") && userInDetail.tfa &&
                                        <button className="btn btn-success" onClick={() => this.props.openModalUserInDetail("resetTfa")} style={{ 'minWidth': '12rem' }} >
                                            <i className="fas fa-key"></i> Reset TFA
                                        </button>
                                    }
                                </div>
                            </div>

                            <div className="row itemForSearch">
                                <div className="text-center col-12">
                                    {
                                        controlRole(role.user, "api/User/Address", "PUT") &&
                                        <button className="btn btn-info" onClick={() => this.props.openModalUserInDetail("editAddress")} style={{ 'minWidth': '12rem' }} >
                                            <i className="fas fa-address-card"></i> Modifica Indirizzi
                                        </button>
                                    }
                                </div>
                            </div>

                            <div className="row itemForSearch">
                                <div className="text-center col-12">
                                    {
                                        user.user.role === SUPER_ADMIN &&
                                        <button className="btn btn-info" onClick={() => this.props.openModalUserInDetail("assignRole")} style={{ 'minWidth': '12rem' }} >
                                            <i className="fas fa-address-book"></i> Assegna Ruolo
                                        </button>
                                    }
                                </div>
                            </div>


                            <div className="row itemForSearch">
                                <div className="text-center col-12">
                                    {
                                        controlRole(role.user, "api/User/Group/AssignGroup", "PATCH") &&
                                        <button className="btn btn-info" onClick={() => this.props.openModalUserInDetail("assignGroup")} style={{ 'minWidth': '12rem' }} >
                                            <i className="fas fa-users-cog"></i> Assegna Gruppo
                                        </button>
                                    }
                                </div>
                            </div>


                            <div className="row itemForSearch">
                                <div className="text-center col-12">
                                    {
                                        controlRole(role.user, "api/User", "PUT") &&
                                        <button className={userInDetail.isReseller ? "btn btn-danger" : "btn btn-info"} onClick={() => this.props.openModalUserInDetail("editUser")} style={{ 'minWidth': '12rem' }} >
                                            {
                                                userInDetail.isReseller ?
                                                    <div>Rimuovi da Reseller</div>
                                                    :
                                                    <div>Rendi Reseller</div>
                                            }
                                        </button>
                                    }
                                </div>
                            </div>

                            <div className="row itemForSearch">
                                <div className="text-center col-12">
                                    {
                                        controlRole(role.user, "api/User", "DELETE") &&
                                        <button className="btn btn-danger" onClick={() => this.props.openModalUserInDetail("deleteUser")} style={{ 'minWidth': '12rem' }} >
                                            <i className="fas fa-user-times"></i> Elimina Utente
                                        </button>
                                    }
                                </div>
                            </div>
                        </Fragment>
                }
            </Fragment>
        )
    }
}