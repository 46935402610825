import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';

import './style.scss';
import Loading from '../../common/loading';
import { API_PREFERENCES, COUNTRY_VATS } from '../../config';
import ModalCountry from '../../common/modal/modalCountry';
import { randomString } from '../../common/actions/randomString';
import { returnNationality } from '../../common/actions/returnNationality';
import { controlRole } from '../../common/actions/controlRole';

export default class whitelist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // contiene la lista dei country con le relative IVA, restituita dopo la chiamata al BE
            getCountryList: {},

            // contiene la lista dei country senza sigla con le relative IVA (Esempio: IT diventa Italia, e cosi via)
            countryListSanitized: {},

            // array contenente degli oggetti, ogni oggetto ha una nazione e la relativa IVA
            newCountryList: [],

            hasClickEdit: false,

            // nazione di riferimento nel caso si volesse editare o rimuovere
            nationReference: '',

            // iva di riferimento nel caso si volesse editare o rimuovere
            ivaReference: '',

            shortId: randomString(),

            modal: false,
            isLoading: false,
            loadingForRole: true
        }
        this.setLoading = this.setLoading.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare country vats, viene rispedito alla home
        if (!isEmpty(this.props.role.preferences)) {
            if (!controlRole(this.props.role.preferences, "api/Preferences", "GET")) {
                this.props.history.push('/');
                this.props.infoNotify('Non hai nessun permesso per visualizzare country vats!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    this.getCountryList();
                }
            }
        }
    }

    componentDidUpdate() {
        // se l'utente non ha nessun permesso per visualizzare country vats, viene rispedito alla home
        if (!isEmpty(this.props.role.preferences)) {
            if (!controlRole(this.props.role.preferences, "api/Preferences", "GET")) {
                this.props.history.push('/');
                this.props.infoNotify('Non hai nessun permesso per visualizzare country vats!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    this.getCountryList();
                }
            }
        }
    }

    setLoading = (value) => {
        this.setState({ isLoading: value });
    }

    /**
     * Usato solo per chiudere la modale, poiche resetta tutto lo state
     */
    toggleModal = () => {
        this.setState(prevState => ({ modal: !prevState.modal }));
        this.setState({
            hasClickEdit: false,
            nationReference: '',
            ivaReference: '',
            keyNation: '',
            shortId: randomString()
        });
    }

    /**
     * Chiama il BE e ritorna la lista degli indirizzi della whitelist
     * 
     * @return type array
     */
    getCountryList = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await axios.get(`${API_PREFERENCES}/${COUNTRY_VATS}`);
            this.setState({
                getCountryList: response.data,
                isLoading: false
            });
        } catch (result) {
            const { errorNotify } = this.props;
            this.setState({ isLoading: false });
            if (result && result.response && result.response.status) {
                switch (result.response) {
                    default: errorNotify(`Error ${result.response.status} su ${API_PREFERENCES} - GET`); break;
                }
            }
        }
    }

    /**
     * Se viene cliccato il pulsante edit, attiva la modale per inviare la nazione da editatare
     * @param nation è la nazione che deve essere modificata (è la sigla della nazione)
     * @param iva è il nuovo valore dell'iva che deve essere cambiato
     * 
     */
    hasClickEdit = (nation, iva) => {
        this.setState({
            hasClickEdit: true,
            nationReference: nation,
            ivaReference: iva
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 1000);
    }

    /**
     * Invio la modifica della lista dei country
     */
    sendEditCountryList = (id, data) => {
        this.props.sendEditPreferences(id, data);
        this.toggleModal();
        setTimeout(() => {
            this.getCountryList();
        }, 1000);
    }

    render() {
        const { getCountryList } = this.state;
        const { role } = this.props;

        return (
            <Fragment>
                <div className="row ivaCountry">
                    <div className="col-sm-4 col-12">
                        <h2>IVA Country</h2>
                    </div>
                    <div className="col-sm-4 col-12 text-center">
                        {
                            controlRole(role.preferences, "api/Preferences/flush", "POST") &&
                            <button style={{ 'marginTop': '5px' }} className="btn btn-warning" onClick={this.props.flush}>
                                Flush
                            </button>
                        }
                        {
                           controlRole(role.configFlush, "api/Config", "POST") &&
                            <button style={{ 'marginTop': '5px', 'marginLeft': '15px' }} className="btn btn-danger" onClick={this.props.flushConfig}>
                                Flush CONFIG
                            </button>
                        }
                    </div>
                </div>

                {
                    (this.props.preferences.isLoading || this.state.isLoading || this.state.loadingForRole) ? <Loading /> :
                        <Fragment>
                            <div className="contentCountryList">
                                <div className="row title">
                                    <div className="col">
                                        Nazione
                                    </div>
                                    <div className="col-3 text-center">
                                        Sigla
                                    </div>
                                    <div className="col text-center">
                                        IVA
                                    </div>
                                    <div className="col-3">
                                    </div>
                                </div>
                                {
                                    Object.keys(getCountryList).map(el => {
                                        return (
                                            <div className="row whiteRow" key={el}>
                                                <div className="col">
                                                    {el === 'XX' ? '--' : returnNationality(el)}
                                                </div>
                                                <div className="col-3 text-center">
                                                    {el}
                                                </div>
                                                <div className="col text-center">
                                                    {getCountryList[el].Vat}%
                                                </div>
                                                <div className="col-3">
                                                    {
                                                        controlRole(role.preferences, "api/Preferences", "PUT") &&
                                                        <button className="btnEdit" onClick={() => { this.hasClickEdit(el, getCountryList[el].Vat) }}>
                                                            <i className="far fa-edit"></i> Modifica
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                this.state.hasClickEdit &&
                                <ModalCountry
                                    titleModal="Modifica Nazione"

                                    clickEdit={this.sendEditCountryList}
                                    toggle={this.toggleModal}

                                    nationReference={this.state.nationReference}
                                    ivaReference={this.state.ivaReference}

                                    stateModal={this.state.modal}
                                    key={this.state.shortId}
                                    errorNotify={this.props.errorNotify}
                                />
                            }
                        </Fragment>
                }
            </Fragment>
        )
    }
}