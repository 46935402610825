import React, { Component } from 'react';
import PropTypes from 'prop-types';

// siccome la sitekey è sempre uguale (sempre quella di sviluppo e non development, viene messa la recaptcha key al posto della sitekey
import { RECAPTCHA_KEY } from '../../config';

const propTypes = {
    elementID: PropTypes.string,
    verifyCallbackName: PropTypes.string,
    verifyCallback: PropTypes.func,
    // sitekey: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired
};

const defaultProps = {
    elementID: 'g-recaptcha',
    verifyCallbackName: 'verifyCallback'
};

const isReady = () =>
    typeof window !== 'undefined' &&
    typeof window.grecaptcha !== 'undefined' &&
    typeof window.grecaptcha.execute !== 'undefined';

let readyCheck;

/**
 * Il componente ha bisogno delle seguenti props (sono necessarie tutt e 3):
 * - verifyCallback (è la funzione che assegna il nuovo catpcha generato, ritorna il nuovo token)
 * - action (è la stringa con la quale viene passata la action, per ogni componente c'è un action diversa)
 * - ref (è il riferimento al recaptcha in quel componente)
 */
export default class ReCaptcha extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: isReady()
        }

        this.execute = this.execute.bind(this);

        if (!this.state.ready) {
            readyCheck = setInterval(this._updateReadyState.bind(this), 1000)
        }
    }

    componentDidMount() {
        if (this.state.ready) {
            this.execute();
        }
    }

    componentDidUpdate(_, prevState) {
        if (this.state.ready && !prevState.ready) {
            this.execute();
        }
    }

    componentWillUnmount() {
        clearInterval(readyCheck);
    }

    execute() {
        const { verifyCallback, action } = this.props;

        if (this.state.ready) {
            window.grecaptcha.execute(RECAPTCHA_KEY, { action })
                .then(token => {
                    if (typeof verifyCallback !== 'undefined') {
                        verifyCallback(token);
                    }
                })
        }
    }

    _updateReadyState() {
        if (isReady()) {
            this.setState(() => ({ ready: true }));
            clearInterval(readyCheck);
        }
    }

    render() {
        return this.state.ready ? (
            <div
                id={this.props.elementID}
                data-verifycallbackname={this.props.verifyCallbackName}
            />
        ) : (
                <div id={this.props.elementID} className='g-recaptcha' />
            )
    }
}

ReCaptcha.propTypes = propTypes;
ReCaptcha.defaultProps = defaultProps;