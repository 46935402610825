import React, { Component } from 'react';
import { FormControl, TextField } from '@material-ui/core';
import moment from 'moment';

export default class expanableComponent extends Component {
    /**
     * Data una data in formato iso, restituisce una data correttamente stampata
     * nel seguente formato: September 4 1986 - 08:30:00 PM
     */
    formatData = (data) => {
        if (data === null || data === undefined || data === '') {
            return '';
        } else {
            return moment(data).format('LL') + ' - ' + moment(data).format('LTS');
        }
    }

    render() {
        const { data } = this.props;
        return (
            <div className="tabExpanablePlayer">
                <FormControl>
                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={data.credentials.host || ' '}
                                label="Host"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={data.credentials.userId || ' '}
                                label="Root User"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={data.credentials.password || ' '}
                                label="Root Password"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <hr></hr>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={data.key || ' '}
                                label="ID Macchina VPN"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={data.mantainer || ' '}
                                label="Mantainer"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                value={data.subnet || ' '}
                                label="Subnet"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <TextField
                                value={data.publicKey || ' '}
                                label="Public Key"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                value={data.privateKey || ' '}
                                label="Private Key"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <TextField
                                value={this.formatData(data.createdAt) || ' '}
                                label="Creata il"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                value={this.formatData(data.updatedAt) || ' '}
                                label="Modificata il"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <hr></hr>

                    <div className="row">
                        <div className="col">
                            <TextField
                                value={data.os || ' '}
                                label="Sistema Operativo"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col">
                            <TextField
                                value={data.networkInterfaceName || ' '}
                                label="Nome interfaccia di rete"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col">
                            <TextField
                                value={data.cpuDescription || ' '}
                                label="Descrizione CPU"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col">
                            <TextField
                                value={data.networkSpeed || ' '}
                                label="Network Speed"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <TextField
                                value={data.tx || ' '}
                                label="TX"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col">
                            <TextField
                                value={data.rx || ' '}
                                label="RX"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col">
                            <TextField
                                value={data.memory || ' '}
                                label="RAM"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className="col">
                            <TextField
                                value={data.cpu || ' '}
                                label="CPU"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <TextField
                                value={data.formula || ' '}
                                label="Formula per calcolo Score"
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>

                    <hr></hr>

                    <div className="row justify-content-center">
                        <div className="col-4">
                            <p className="text-center">IP Pubblici:</p>
                            <ul>
                                {
                                    data.publicIp.map((item, key) => {
                                        return (
                                            <li key={key}>
                                                {item}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>



                </FormControl>
            </div>
        )
    }
}