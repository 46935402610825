import axios from 'axios';
import { preferencesActionType, notifyActions } from './';
import {
  API_PREFERENCES,
  API_GET_PRODUCTS,
  API_GET_SHIPPING_COUTRY,
  API_SET_TOKEN_PARTNER,
  API_FLUSH_PREFERENCES,
  // API_FLUSH_PREFERENCES_SITO,
  API_FLUSH_CONFIGURATOR,
  WHITELIST,
  COUNTRY_VATS,
  COUNTRY_PRICINGS,
  // FLUSH_TOKEN,
  VPN_TYPE, VPNBOX_TYPE, PLAYER_TYPE, COUPON_TYPE, BOX_HW_TYPE,
  PARTNERS
} from '../../config';

export const preferencesActions = {
  sendEditPreferences,
  getVersionSite,
  setVersionSite,
  flush,
  flushConfig,
  getProductList,
  deleteKeyFromList,
  setTokenForPartner,
  getCountryPurchasableList
};

/**
 * Con lo stesso metodo, aggiungo, modifico e rimuovo più elementi da:
 *  - whitelist
 *  - countryVats
 *  - pricing
 * 
 * @param {String} id contiene l'id della preference da modificare
 * @param {Object} data contiene l'oggetto che voglio modificare
 */
function sendEditPreferences(id, data) {
  return (dispatch) => {
    dispatch({
      type: preferencesActionType.SEND_EDIT_PREFERENCES,
      async payload() {
        try {
          await axios.put(`${API_PREFERENCES}/${id}`, data);

          if (id === WHITELIST) {
            dispatch(notifyActions.successAction('Whitelist aggiornata correttamente! 😉'));
          } else if (id === COUNTRY_VATS) {
            dispatch(notifyActions.successAction('Country Vats aggiornato correttamente! 😉'));
          } else if (id === COUNTRY_PRICINGS) {
            dispatch(notifyActions.successAction('Country Pricing aggiornato correttamente! 😉'));
          } else if (id === PARTNERS) {
            dispatch(notifyActions.successAction('Partner aggiornato correttamente! 😉'));
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404:
                if (id === WHITELIST) {
                  dispatch(notifyActions.errorAction('Whitelist non trovata!')); break;
                } else if (id === COUNTRY_VATS) {
                  dispatch(notifyActions.errorAction('Country Vats non trovato!')); break;
                } else if (id === COUNTRY_PRICINGS) {
                  dispatch(notifyActions.errorAction('Country Pricing non torvato!')); break;
                } else if (id === PARTNERS) {
                  dispatch(notifyActions.errorAction('Partner non torvato!')); break;
                }
                break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_PREFERENCES} - PUT`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Rimuove una chiave interamente dalla lista. (Assieme a tutti gli elementi associati a essa)
 * 
 * @param {String} id contiene l'id della preference da modificare
 * @param {Object} key è la chiave da eliminare nella lista
 */
function deleteKeyFromList(id, key) {
  return (dispatch) => {
    dispatch({
      type: preferencesActionType.REMOVE_PREFERENCES,
      async payload() {
        try {
          await axios.delete(`${API_PREFERENCES}/${id}/${key}`);
          dispatch(notifyActions.successAction('Chiave ' + key + ' rimossa correttamente! 😉'));

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_PREFERENCES} - DELETE`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Effettua una flush
 */
function flush() {
  return (dispatch) => {
    dispatch({
      type: preferencesActionType.FLUSH_TOKEN,
      async payload() {
        try {
          await axios.post(API_FLUSH_PREFERENCES);
          // await axios.get(`${API_FLUSH_PREFERENCES_SITO}/${FLUSH_TOKEN}`);
        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction('Error ' + result.response.status + ', FLUSH error!')); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Effettua una flush al config (solo superadmin può farlo)
 */
function flushConfig() {
  return (dispatch) => {
    dispatch({
      type: preferencesActionType.FLUSH_TOKEN_CONFIG,
      async payload() {
        try {
          await axios.post(API_FLUSH_CONFIGURATOR);
        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction('Error ' + result.response.status + ', FLUSH Config error!')); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Prende la versione del sito
 */
function getVersionSite() {
  return (dispatch) => {
    dispatch({
      type: preferencesActionType.GET_VERSION_SITE,
      async payload() {
        try {
          let response = await axios.get(`/info.json`);
          dispatch(setVersionSite(response.data.version))
        } catch (result) {
          dispatch(notifyActions.errorAction('Version Site error!'));
        }
      }
    })
  }
}

/**
 * Setta nello store la versione del sito
 * 
 * @param {String} versionSite 
 */
function setVersionSite(versionSite) {
  return {
    type: preferencesActionType.SET_VERSION_SITE,
    versionSite
  }
}

/**
 * Restituisce tutti i prodotti nanomid.
 * Il parametro se non presente, restituisce tutti i prodotti.
 * 
 * @param {*} productType se presente, restituisce i prodotti relativi a quel tipo di prodotto
 */
function getProductList(productType) {
  return (dispatch) => {
    dispatch({
      type: preferencesActionType.GET_PRODUCT_LIST,
      async payload() {
        try {
          let arrayTempPlayer = [];
          let arrayTempPass = [];
          let arrayTempVpnBox = [];
          let arrayTempBoxHw = [];
          let arrayTempCoupon = [];

          let response = await axios.get(`${API_GET_PRODUCTS}`, { params: { productType: productType } });

          let arrayTemp = [];
          // eslint-disable-next-line
          response.data.map((item) => {
            arrayTemp.push(item.name)
          })

          // eslint-disable-next-line
          response.data.map(el => {
            switch (el.type) {
              case PLAYER_TYPE: arrayTempPlayer.push(el); break;
              case VPN_TYPE: arrayTempPass.push(el); break;
              case VPNBOX_TYPE: arrayTempVpnBox.push(el); break;
              case BOX_HW_TYPE: arrayTempBoxHw.push(el); break;
              case COUPON_TYPE: arrayTempCoupon.push(el); break;
              default: break;
            }
          });

          return {
            listProductPlayer: arrayTempPlayer,
            listProductVpnPass: arrayTempPass,
            listProductBox: arrayTempVpnBox,
            listProductBoxHw: arrayTempBoxHw,
            listProductCoupon: arrayTempCoupon,
            allProducts: response.data
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            dispatch(notifyActions.errorAction('Errore ' + result.response.status + ' su chiamata dei prodotti!'));
          }
          return {
            listProductPlayer: [],
            listProductVpnPass: [],
            listProductBox: [],
            listProductBoxHw: [],
            listProductCoupon: [],
            allProducts: []
          }
        }
      }
    })
  }
}

/**
 * Setta il token per un partner.
 * 
 * @param {*} idPartner è l'id del partner da settare (la key della preference)
 * @param {*} dateExpire è la data di scadenza del token che assegno al partner
 */
function setTokenForPartner(idPartner, dateExpire) {
  return (dispatch) => {
    dispatch({
      type: preferencesActionType.SET_TOKEN_FOR_PARTNER,
      async payload() {
        try {
          let params = {
            expirationDate: dateExpire
          }

          await axios.patch(`${API_SET_TOKEN_PARTNER}/${idPartner}`, params);
          dispatch(notifyActions.successAction('Token settato correttamente!'));

        } catch (result) {
          dispatch(notifyActions.errorAction('Version Site error!'));
        }
      }
    })
  }
}

/**
 * Restituisce la lista di country in cui si può acquistare la VPN Box.
 */
function getCountryPurchasableList() {
  return (dispatch) => {
    dispatch({
      type: preferencesActionType.GET_COUNTRY_LIST,
      async payload() {
        try {
          let response = await axios.get(API_GET_SHIPPING_COUTRY);

          return {
            countryPurchasableList: response.data
          }

        } catch (result) {
          dispatch(notifyActions.errorAction(API_GET_SHIPPING_COUTRY + ' - error ' + result.response.status));
          return {
            countryPurchasableList: []
          }
        }
      }
    })
  }
}