import { connect } from 'react-redux'
import { playerActions, notifyActions } from '../store/actions';
import pageLicences from './pages/licences';

const mapStateToProps = state => {
    return {
        ...state,
        player: state.player,
        role: state.role,
        user: state.user
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setIdPlayerForDetail: (data) => {
            dispatch(playerActions.setIdPlayerForDetail(data))
        },
        getLicenceList: (data) => {
            dispatch(playerActions.getLicenceList(data))
        },
        sendEditPlayer: (data, action) => {
            dispatch(playerActions.sendEditPlayer(data, action))
        },
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(pageLicences);