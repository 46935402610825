/**
 * Prende una sigla di un paese, e ritorna il nome per intero
 */
export var returnNationality = function (sigla) {
    switch(sigla) {
        case 'AD': case 'ad' : return 'Andorra';
        case 'AE': case 'ae' : return 'United Arab Emirates';
        case 'AF': case 'af' : return 'Afghanistan';
        case 'AG': case 'ag' : return 'Antigua and Barbuda';
        case 'AI': case 'ai' : return 'Anguilla';
        case 'AL': case 'al' : return 'Albania';
        case 'AM': case 'am' : return 'Armenia';
        case 'AO': case 'ao' : return 'Angola';
        case 'AQ': case 'aq' : return 'Antarctica';
        case 'AR': case 'ar' : return 'Argentina';
        case 'AS': case 'as' : return 'American Samoa';
        case 'AT': case 'at' : return 'Austria';
        case 'AU': case 'au' : return 'Australia';
        case 'AW': case 'aw' : return 'Aruba';
        case 'AX': case 'ax' : return 'Aland Islands';
        case 'AZ': case 'az' : return 'Azerbaijan';
        case 'BA': case 'ba' : return 'Bosnia and Herzegovina';
        case 'BB': case 'bb' : return 'Barbados';
        case 'BD': case 'bd' : return 'Bangladesh';
        case 'BE': case 'be' : return 'Belgium';
        case 'BF': case 'bf' : return 'Burkina Faso';
        case 'BG': case 'bg' : return 'Bulgaria';
        case 'BH': case 'bh' : return 'Bahrain';
        case 'BI': case 'bi' : return 'Burundi';
        case 'BJ': case 'bj' : return 'Benin';
        case 'BL': case 'bl' : return 'Saint Barthelemy';
        case 'BM': case 'bm' : return 'Bermuda';
        case 'BN': case 'bn' : return 'Brunei';
        case 'BO': case 'bo' : return 'Bolivia';
        case 'BQ': case 'bq' : return 'Bonaire, Saint Eustatius and Saba ';
        case 'BR': case 'br' : return 'Brazil';
        case 'BS': case 'bs' : return 'Bahamas';
        case 'BT': case 'bt' : return 'Bhutan';
        case 'BV': case 'bv' : return 'Bouvet Island';
        case 'BW': case 'bw' : return 'Botswana';
        case 'BY': case 'by' : return 'Belarus';
        case 'BZ': case 'bz' : return 'Belize';
        case 'CA': case 'ca' : return 'Canada';
        case 'CC': case 'cc' : return 'Cocos Islands';
        case 'CD': case 'cd' : return 'Democratic Republic of the Congo';
        case 'CF': case 'cf' : return 'Central African Republic';
        case 'CG': case 'cg' : return 'Republic of the Congo';
        case 'CH': case 'ch' : return 'Switzerland';
        case 'CI': case 'ci' : return 'Ivory Coast';
        case 'CK': case 'ck' : return 'Cook Islands';
        case 'CL': case 'cl' : return 'Chile';
        case 'CM': case 'cm' : return 'Cameroon';
        case 'CN': case 'cn' : return 'China';
        case 'CO': case 'co' : return 'Colombia';
        case 'CR': case 'cr' : return 'Costa Rica';
        case 'CU': case 'cu' : return 'Cuba';
        case 'CV': case 'cv' : return 'Cape Verde';
        case 'CW': case 'cw' : return 'Curacao';
        case 'CX': case 'cx' : return 'Christmas Island';
        case 'CY': case 'cy' : return 'Cyprus';
        case 'CZ': case 'cz' : return 'Czech Republic';
        case 'DE': case 'de' : return 'Germany';
        case 'DJ': case 'dj' : return 'Djibouti';
        case 'DK': case 'dk' : return 'Denmark';
        case 'DM': case 'dm' : return 'Dominica';
        case 'DO': case 'do' : return 'Dominican Republic';
        case 'DZ': case 'dz' : return 'Algeria';
        case 'EC': case 'ec' : return 'Ecuador';
        case 'EE': case 'ee' : return 'Estonia';
        case 'EG': case 'eg' : return 'Egypt';
        case 'EH': case 'eh' : return 'Western Sahara';
        case 'ER': case 'er' : return 'Eritrea';
        case 'ES': case 'es' : return 'Spain';
        case 'ET': case 'et' : return 'Ethiopia';
        case 'FI': case 'fi' : return 'Finland';
        case 'FJ': case 'fj' : return 'Fiji';
        case 'FK': case 'fk' : return 'Falkland Islands';
        case 'FM': case 'fm' : return 'Micronesia';
        case 'FO': case 'fo' : return 'Faroe Islands';
        case 'FR': case 'fr' : return 'France';
        case 'GA': case 'ga' : return 'Gabon';
        case 'GB': case 'gb' : return 'United Kingdom';
        case 'GD': case 'gd' : return 'Grenada';
        case 'GE': case 'ge' : return 'Georgia';
        case 'GF': case 'gf' : return 'French Guiana';
        case 'GG': case 'gg' : return 'Guernsey';
        case 'GH': case 'gh' : return 'Ghana';
        case 'GI': case 'gi' : return 'Gibraltar';
        case 'GL': case 'gl' : return 'Greenland';
        case 'GM': case 'gm' : return 'Gambia';
        case 'GN': case 'gn' : return 'Guinea';
        case 'GP': case 'gp' : return 'Guadeloupe';
        case 'GQ': case 'gq' : return 'Equatorial Guinea';
        case 'GR': case 'gr' : return 'Greece';
        case 'GS': case 'gs' : return 'South Georgia and the South Sandwich Islands';
        case 'GT': case 'gt' : return 'Guatemala';
        case 'GU': case 'gu' : return 'Guam';
        case 'GW': case 'gw' : return 'Guinea-Bissau';
        case 'GY': case 'gy' : return 'Guyana';
        case 'HK': case 'hk' : return 'Hong Kong';
        case 'HM': case 'hm' : return 'Heard Island and McDonald Islands';
        case 'HN': case 'hn' : return 'Honduras';
        case 'HR': case 'hr' : return 'Croatia';
        case 'HT': case 'ht' : return 'Haiti';
        case 'HU': case 'hu' : return 'Hungary';
        case 'ID': case 'id' : return 'Indonesia';
        case 'IE': case 'ie' : return 'Ireland';
        case 'IL': case 'il' : return 'Israel';
        case 'IM': case 'im' : return 'Isle of Man';
        case 'IN': case 'in' : return 'India';
        case 'IO': case 'io' : return 'British Indian Ocean Territory';
        case 'IQ': case 'iq' : return 'Iraq';
        case 'IR': case 'ir' : return 'Iran';
        case 'IS': case 'is' : return 'Iceland';
        case 'IT': case 'it' : return 'Italy';
        case 'JE': case 'je' : return 'Jersey';
        case 'JM': case 'jm' : return 'Jamaica';
        case 'JO': case 'jo' : return 'Jordan';
        case 'JP': case 'jp' : return 'Japan';
        case 'KE': case 'ke' : return 'Kenya';
        case 'KG': case 'kg' : return 'Kyrgyzstan';
        case 'KH': case 'kh' : return 'Cambodia';
        case 'KI': case 'ki' : return 'Kiribati';
        case 'KM': case 'km' : return 'Comoros';
        case 'KN': case 'kn' : return 'Saint Kitts and Nevis';
        case 'KP': case 'kp' : return 'North Korea';
        case 'KR': case 'kr' : return 'South Korea';
        case 'KW': case 'kw' : return 'Kuwait';
        case 'KY': case 'ky' : return 'Cayman Islands';
        case 'KZ': case 'kz' : return 'Kazakhstan';
        case 'LA': case 'la' : return 'Laos';
        case 'LB': case 'lb' : return 'Lebanon';
        case 'LC': case 'lc' : return 'Saint Lucia';
        case 'LI': case 'li' : return 'Liechtenstein';
        case 'LK': case 'lk' : return 'Sri Lanka';
        case 'LR': case 'lr' : return 'Liberia';
        case 'LS': case 'ls' : return 'Lesotho';
        case 'LT': case 'lt' : return 'Lithuania';
        case 'LU': case 'lu' : return 'Luxembourg';
        case 'LV': case 'lv' : return 'Latvia';
        case 'LY': case 'ly' : return 'Libya';
        case 'MA': case 'ma' : return 'Morocco';
        case 'MC': case 'mc' : return 'Monaco';
        case 'MD': case 'md' : return 'Moldova';
        case 'ME': case 'me' : return 'Montenegro';
        case 'MF': case 'mf' : return 'Saint Martin';
        case 'MG': case 'mg' : return 'Madagascar';
        case 'MH': case 'mh' : return 'Marshall Islands';
        case 'MK': case 'mk' : return 'Macedonia';
        case 'ML': case 'ml' : return 'Mali';
        case 'MM': case 'mm' : return 'Myanmar';
        case 'MN': case 'mn' : return 'Mongolia';
        case 'MO': case 'mo' : return 'Macao';
        case 'MP': case 'mp' : return 'Northern Mariana Islands';
        case 'MQ': case 'mq' : return 'Martinique';
        case 'MR': case 'mr' : return 'Mauritania';
        case 'MS': case 'ms' : return 'Montserrat';
        case 'MT': case 'mt' : return 'Malta';
        case 'MU': case 'mu' : return 'Mauritius';
        case 'MV': case 'mv' : return 'Maldives';
        case 'MW': case 'mw' : return 'Malawi';
        case 'MX': case 'mx' : return 'Mexico';
        case 'MY': case 'my' : return 'Malaysia';
        case 'MZ': case 'mz' : return 'Mozambique';
        case 'NA': case 'na' : return 'Namibia';
        case 'NC': case 'nc' : return 'New Caledonia';
        case 'NE': case 'ne' : return 'Niger';
        case 'NF': case 'nf' : return 'Norfolk Island';
        case 'NG': case 'ng' : return 'Nigeria';
        case 'NI': case 'ni' : return 'Nicaragua';
        case 'NL': case 'nl' : return 'Netherlands';
        case 'NO': case 'no' : return 'Norway';
        case 'NP': case 'np' : return 'Nepal';
        case 'NR': case 'nr' : return 'Nauru';
        case 'NU': case 'nu' : return 'Niue';
        case 'NZ': case 'nz' : return 'New Zealand';
        case 'OM': case 'om' : return 'Oman';
        case 'PA': case 'pa' : return 'Panama';
        case 'PE': case 'pe' : return 'Peru';
        case 'PF': case 'pf' : return 'French Polynesia';
        case 'PG': case 'pg' : return 'Papua New Guinea';
        case 'PH': case 'ph' : return 'Philippines';
        case 'PK': case 'pk' : return 'Pakistan';
        case 'PL': case 'pl' : return 'Poland';
        case 'PM': case 'pm' : return 'Saint Pierre and Miquelon';
        case 'PN': case 'pn' : return 'Pitcairn';
        case 'PR': case 'pr' : return 'Puerto Rico';
        case 'PS': case 'ps' : return 'Palestinian Territory';
        case 'PT': case 'pt' : return 'Portugal';
        case 'PW': case 'pw' : return 'Palau';
        case 'PY': case 'py' : return 'Paraguay';
        case 'QA': case 'qa' : return 'Qatar';
        case 'RE': case 're' : return 'Reunion';
        case 'RO': case 'ro' : return 'Romania';
        case 'RS': case 'rs' : return 'Serbia';
        case 'RU': case 'ru' : return 'Russia';
        case 'RW': case 'rw' : return 'Rwanda';
        case 'SA': case 'sa' : return 'Saudi Arabia';
        case 'SB': case 'sb' : return 'Solomon Islands';
        case 'SC': case 'sc' : return 'Seychelles';
        case 'SD': case 'sd' : return 'Sudan';
        case 'SE': case 'se' : return 'Sweden';
        case 'SG': case 'sg' : return 'Singapore';
        case 'SH': case 'sh' : return 'Saint Helena';
        case 'SI': case 'si' : return 'Slovenia';
        case 'SJ': case 'sj' : return 'Svalbard and Jan Mayen';
        case 'SK': case 'sk' : return 'Slovakia';
        case 'SL': case 'sl' : return 'Sierra Leone';
        case 'SM': case 'sm' : return 'San Marino';
        case 'SN': case 'sn' : return 'Senegal';
        case 'SO': case 'so' : return 'Somalia';
        case 'SR': case 'sr' : return 'Suriname';
        case 'SS': case 'ss' : return 'South Sudan';
        case 'ST': case 'st' : return 'Sao Tome and Principe';
        case 'SV': case 'sv' : return 'El Salvador';
        case 'SX': case 'sx' : return 'Sint Maarten';
        case 'SY': case 'sy' : return 'Syria';
        case 'SZ': case 'sz' : return 'Swaziland';
        case 'TC': case 'tc' : return 'Turks and Caicos Islands';
        case 'TD': case 'td' : return 'Chad';
        case 'TF': case 'tf' : return 'French Southern Territories';
        case 'TG': case 'tg' : return 'Togo';
        case 'TH': case 'th' : return 'Thailand';
        case 'TJ': case 'tj' : return 'Tajikistan';
        case 'TK': case 'tk' : return 'Tokelau';
        case 'TL': case 'tl' : return 'East Timor';
        case 'TM': case 'tm' : return 'Turkmenistan';
        case 'TN': case 'tn' : return 'Tunisia';
        case 'TO': case 'to' : return 'Tonga';
        case 'TR': case 'tr' : return 'Turkey';
        case 'TT': case 'tt' : return 'Trinidad and Tobago';
        case 'TV': case 'tv' : return 'Tuvalu';
        case 'TW': case 'tw' : return 'Taiwan';
        case 'TZ': case 'tz' : return 'Tanzania';
        case 'UA': case 'ua' : return 'Ukraine';
        case 'UG': case 'ug' : return 'Uganda';
        case 'UM': case 'um' : return 'United States Minor Outlying Islands';
        case 'US': case 'us' : return 'United States';
        case 'UY': case 'uy' : return 'Uruguay';
        case 'UZ': case 'uz' : return 'Uzbekistan';
        case 'VA': case 'va' : return 'Vatican';
        case 'VC': case 'vc' : return 'Saint Vincent and the Grenadines';
        case 'VE': case 've' : return 'Venezuela';
        case 'VG': case 'vg' : return 'British Virgin Islands';
        case 'VI': case 'vi' : return 'U.S. Virgin Islands';
        case 'VN': case 'vn' : return 'Vietnam';
        case 'VU': case 'vu' : return 'Vanuatu';
        case 'WF': case 'wf' : return 'Wallis and Futuna';
        case 'WS': case 'ws' : return 'Samoa';
        case 'XK': case 'xk' : return 'Kosovo';
        case 'YE': case 'ye' : return 'Yemen';
        case 'YT': case 'yt' : return 'Mayotte';
        case 'ZA': case 'za' : return 'South Africa';
        case 'ZM': case 'zm' : return 'Zambia';
        case 'ZW': case 'zw' : return 'Zimbabwe';
        default : return '';
    }
}