import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Badge } from 'react-bootstrap';

import { playerActions, vpnActions } from '../../../store/actions';
import Date from '../../../common/date';
import './style.scss';
import MyTooltip from '../../../common/tooltip';
import ModalPlayList from '../../../common/modal/modalManageList';
import { controlRole } from '../../../common/actions/controlRole';
import { FormControl, Input, InputLabel, InputAdornment, TextField } from '@material-ui/core';
import SelectorCopy from '../../../common/selectorCopy';
import { NOT_REGISTERED } from '../../../config';

class tabExpanablePlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playlistPLayer: this.props.data.playLists,
            playlistPLayerCounter: this.props.data.playLists.length,

            hasClickEdit: false,
            hasClickRemove: false,
            hasClickNewPlaylist: false,
            modal: false,
            titleModal: '',
            type: '',

            // dati da mandare per la modifica/ aggiunta di una playlist
            title: '',
            url: '',
            keyPlaylist: '',
            deviceId: this.props.data._id,
            vpnPass: this.props.data.vpnPass !== null ? this.props.data.vpnPass : '',
        }
    }

    toggleModal = () => {
        this.setState({
            hasClickEdit: false,
            hasClickRemove: false,
            hasClickNewPlaylist: false,
            modal: false,
            title: '',
            url: '',
            type: '',
            keyPlaylist: '',
            titleModal: '',
        });
    }

    hasClickNewPlaylist = () => {
        this.setState({
            hasClickNewPlaylist: true,
            titleModal: 'Nuova Playlist',
            type: 'new',
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 100);
    }

    /**
     * Al click di modifica playlist, riceve in input:
     * - la chiave della playlist da modificare
     * - il titolo della playlist da modificare
     * - l'url della playlist da modificare
     */
    hasClickEdit = (keyPlaylist, title, url) => {
        this.setState({
            hasClickEdit: true,
            titleModal: 'Modifica Playlist',
            type: 'edit',
            title: title,
            url: url,
            keyPlaylist: keyPlaylist,
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 100);
    }

    hasClickRemove = (keyPlaylist) => {
        this.setState({
            titleModal: 'Elimina Playlist',
            type: 'delete',
            hasClickRemove: true,
            keyPlaylist: keyPlaylist
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 100);
    }

    /**
     * Invia la nuova playlist al player.
     * Vengon inviati i seguenti parametri:
     * - titolo della playlist
     * - url della playlist
     * - deviceId a cui associare la playlist
     */
    sendNewPlaylist = (title, url) => {
        this.props.sendNewPlaylist({
            title: title,
            url: url,
            deviceId: this.state.deviceId,
        });
        this.toggleModal();
        setTimeout(() => {
            // WorkAround: TROVARE UN MODO MIGLIORE PER REFRESHARE LE PLAYLIST...
            this.props.refreshList();
        }, 1000);
    }

    /**
     * Modifica la playlist del player.
     * Riceve i seguenti parametri:
     * - title (titolo della playlist)
     * - url (url della sorgente)
     * - key (chiave della playlist)
     */
    sendEditPlaylist = (title, url, keyPlaylist) => {
        this.props.sendModifyPlaylist({
            title: title,
            url: url,
            deviceId: this.state.deviceId,
            playListId: keyPlaylist
        });
        this.toggleModal();
        setTimeout(() => {
            // WorkAround: TROVARE UN MODO MIGLIORE PER REFRESHARE LE PLAYLIST...
            this.props.refreshList();
        }, 1000);
    }

    /**
     * Rimuove la playlist dal player.
     * Vengono passati i seguenti parametri:
     * - key della playlist
     * - device id a cui è associata la playlist
     */
    removePlaylist = (keyPlaylist) => {
        this.props.removePlaylist({
            key: keyPlaylist,
            deviceId: this.state.deviceId,
        });
        this.toggleModal();
        setTimeout(() => {
            // WorkAround: TROVARE UN MODO MIGLIORE PER REFRESHARE LE PLAYLIST...
            this.props.refreshList();
        }, 1000);
    }

    /**
     * Aggiunge una pass al player.
     */
    addPassToPlayer = (keyPass) => {
        this.props.addPassToPlayer({
            keyPass: keyPass,
            deviceId: this.state.deviceId,
        });
        this.toggleModal();
        setTimeout(() => {
            // WorkAround: TROVARE UN MODO MIGLIORE PER REFRESHARE LE PLAYLIST...
            this.props.refreshList();
        }, 500);
    }

    /**
     * Dato una key, vai alla schermata delle pass portando key nei filtri
     */
    goToPass = () => {
        this.props.setKeyVpnPassForDetail(this.state.vpnPass)
        this.props.history.push(`/vpn/pass`);
    }

    render() {
        const { role } = this.props;
        return (
            <div className="tabPlaylistPlayer">
                {
                    this.state.playlistPLayerCounter === 0 ?
                        <div className="row addPlaylist">
                            <div className="col">
                                <h6>Non ci sono playlist</h6>
                            </div>
                            <div className="col text-right">
                                {
                                    controlRole(role.player, "api/Player/AddPlaylist", "POST") &&
                                    <button className="btn btn-success" onClick={this.hasClickNewPlaylist}>
                                        <i className="fas fa-plus"></i>
                                        Aggiungi Playlist
                                    </button>
                                }
                            </div>
                        </div>
                        :
                        <Fragment>
                            {
                                this.props.data.licence !== null &&
                                <>
                                    <h5>Licenza</h5>
                                    <div className='row'>
                                        <div className="col-4">
                                            <TextField
                                                value={this.props.data.licence.key || ''}
                                                label="Codice Attivazione"
                                                InputProps={{ readOnly: true }}
                                            />
                                        </div>

                                        <div className="col-4">
                                            <TextField
                                                value={this.props.data.licence.playerId || ''}
                                                label="Player che sta utilizzando la licenza"
                                                InputProps={{ readOnly: true }}
                                            />
                                        </div>

                                        <div className="col-4">
                                            <TextField
                                                value={this.props.data.licence.user || ''}
                                                label="Utente che sta utilizzando la licenza"
                                                InputProps={{ readOnly: true }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <FormControl>
                                                <InputLabel htmlFor="id-payment-player">ID Pagamento</InputLabel>
                                                <Input
                                                    id="id-payment-player"
                                                    value={this.props.data.licence.paymentId === NOT_REGISTERED ? 'Non Presente...' : this.props.data.licence.paymentId}
                                                    readOnly
                                                    endAdornment={
                                                        this.props.data.licence.paymentId &&
                                                        <InputAdornment position="end">
                                                            <SelectorCopy
                                                                item={this.props.data.licence.paymentId}
                                                                infoNotify={this.props.infoNotify}
                                                                noLabel
                                                            />
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </div>

                                        <div className="col-4">
                                            <TextField
                                                value={this.props.data.licence.description || ''}
                                                label="Descrizione"
                                                InputProps={{ readOnly: true }}
                                            />
                                        </div>

                                        <div className="col-4">
                                            <TextField
                                                value={this.props.data.licence.platform || ''}
                                                label="Platform"
                                                InputProps={{ readOnly: true }}
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="row addPlaylist">
                                <div className="col">
                                    <h5>Lista delle playlists</h5>
                                </div>
                                <div className="col text-center">
                                    <Badge variant="warning"> Playlist presenti: {this.state.playlistPLayerCounter} </Badge>
                                </div>
                                <div className="col text-right">
                                    {
                                        controlRole(role.player, "api/Player/AddPlaylist", "POST") &&
                                        <button className="btn btn-success" onClick={this.hasClickNewPlaylist}>
                                            <i className="fas fa-plus"></i>
                                            Aggiungi Playlist
                                        </button>
                                    }
                                </div>
                            </div>
                            <div className="row title">
                                <div className="col-2">
                                    Nome
                                </div>
                                <div className="col-6">
                                    URL
                                </div>
                                <div className="col-2 text-center">
                                    Data creazione
                                </div>
                                <div className="col-2">
                                </div>
                            </div>
                            {
                                this.state.playlistPLayer.map((playlist, key) => {
                                    return (
                                        <div className="row playlistContent" key={key}>
                                            <div className="col-2">
                                                <p className="only"> {playlist.title} </p>
                                            </div>
                                            <div className="col-6">
                                                <p className="only"> {playlist.source} </p>
                                            </div>
                                            <div className="col-2">
                                                <Date format="L" date={playlist.createdAt} />
                                            </div>
                                            <div className="col-2 text-center">
                                                <div className="row iconRow justify-content-center">
                                                    {
                                                        controlRole(role.player, "api/Player/UpdatePlaylist", "PUT") &&
                                                        <div className="col icon edit" onClick={() => { this.hasClickEdit(playlist.key, playlist.title, playlist.source) }}>
                                                            <MyTooltip
                                                                title="Modifica"
                                                                position="left"
                                                                content={<i className="far fa-edit"></i>}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        controlRole(role.player, "api/Player/RemovePlaylist", "DELETE") &&
                                                        <div className="col icon delete" onClick={() => { this.hasClickRemove(playlist.key) }}>
                                                            <MyTooltip
                                                                title="Elimina"
                                                                position="left"
                                                                content={<i className="far fa-trash-alt"></i>}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className="row">
                                <div className='col-12'>
                                    <h6 style={{ margin: '1rem 0' }}>- VPN Pass Associata al Player -</h6>
                                </div>

                                <div className="col-4">
                                    <FormControl>
                                        <InputLabel htmlFor="id-vpnPass-player">Key VPN Pass</InputLabel>
                                        <Input
                                            id="id-vpnPass-player"
                                            value={this.state.vpnPass === '' ? "VPN Pass Non Associata..." : this.state.vpnPass}
                                            readOnly
                                            endAdornment={
                                                this.state.vpnPass &&
                                                <InputAdornment position="end">
                                                    <SelectorCopy
                                                        item={this.state.vpnPass}
                                                        infoNotify={this.props.infoNotify}
                                                        noLabel
                                                    />
                                                    {
                                                        this.state.vpnPass &&
                                                        <span className="clickForPayment" onClick={this.goToPass}>
                                                            <MyTooltip
                                                                title="Vai a questa VPN Pass"
                                                                position="top"
                                                                content={<i className="fas fa-share-square"></i>}
                                                            />
                                                        </span>
                                                    }
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </Fragment>
                }

                {
                    (this.state.hasClickEdit || this.state.hasClickNewPlaylist || this.state.hasClickRemove) &&
                    <ModalPlayList
                        titleModal={this.state.titleModal}
                        toggle={this.toggleModal}
                        stateModal={this.state.modal}

                        title={this.state.title}
                        url={this.state.url}
                        deviceId={this.state.deviceId}
                        keyPlaylist={this.state.keyPlaylist}
                        type={this.state.type}

                        errorNotify={this.props.errorNotify}
                        clickNew={this.sendNewPlaylist}
                        clickEdit={this.sendEditPlaylist}
                        clickRemove={this.removePlaylist}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        player: state.player,
        role: state.role
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addPassToPlayer: (data) => {
            dispatch(playerActions.addPassToPlayer(data))
        },
        setKeyVpnPassForDetail: (data) => {
            dispatch(vpnActions.setKeyVpnPassForDetail(data))
        },
        sendNewPlaylist: (data) => {
            dispatch(playerActions.sendNewPlaylist(data))
        },
        sendModifyPlaylist: (data) => {
            dispatch(playerActions.sendModifyPlaylist(data))
        },
        removePlaylist: (data) => {
            dispatch(playerActions.removePlaylist(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(tabExpanablePlayer);