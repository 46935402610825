import { connect } from 'react-redux'
import { userActions, notifyActions, paymentActions, roleActions, groupActions, vpnActions, playerActions, preferencesActions, promoActions } from '../store/actions';
import pageUserInDetail from './pages';

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user,
        role: state.role,
        group: state.group,
        preferences: state.preferences,
        promo: state.promo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // tab delle promo
        getPromoList: (data, isReset) => {
            dispatch(promoActions.getPromoList(data, isReset))
        },
        sendNewPromo: (data, isCoupon) => {
            dispatch(promoActions.sendNewPromo(data, isCoupon))
        },
        sendEditPromo: (data, isCoupon) => {
            dispatch(promoActions.sendEditPromo(data, isCoupon))
        },
        deletePromo: (idPromo) => {
            dispatch(promoActions.deletePromo(idPromo))
        },

        // azioni sugli utenti
        getUserList: (data) => {
            dispatch(userActions.getUserList(data))
        },
        getGroupList: () => {
            dispatch(groupActions.getGroupList())
        },
        getProductList: (data) => {
            dispatch(preferencesActions.getProductList(data))
        },
        assignGroup: (data) => {
            dispatch(groupActions.assignGroup(data))
        },
        resetPassword: (user) => {
            dispatch(userActions.resetPassword(user))
        },
        resetTfa: (user) => {
            dispatch(userActions.resetTfa(user))
        },
        deleteUser: (userMail) => {
            dispatch(userActions.deleteUser(userMail))
        },
        sendMail: (data) => {
            dispatch(userActions.sendMail(data))
        },
        updateAddress: (data, id) => {
            dispatch(userActions.updateAddress(data, id))
        },
        updateUser: (data) => {
            dispatch(userActions.updateUser(data))
        },
        getRoleList: () => {
            dispatch(roleActions.getRoleList())
        },
        assignRole: (data) => {
            dispatch(roleActions.assignRole(data))
        },
        removeVpnPass: (id) => {
            dispatch(vpnActions.removeVpnPass(id))
        },

        // funzione per settare 
        setIdPaymentForDetail: (data) => {
            dispatch(paymentActions.setIdPaymentForDetail(data))
        },
        setIdVpnPassForDetail: (data) => {
            dispatch(vpnActions.setIdVpnPassForDetail(data))
        },
        setKeyVpnPassForDetail: (data) => {
            dispatch(vpnActions.setKeyVpnPassForDetail(data))
        },
        setIdPlayerForDetail: (data) => {
            dispatch(playerActions.setIdPlayerForDetail(data))
        },

        //tab payments
        getPaymentList: (data) => {
            dispatch(paymentActions.getPaymentList(data))
        },
        deletePayment: (idPayment) => {
            dispatch(paymentActions.deletePayment(idPayment))
        },
        deactiveSub: (idPayment) => {
            dispatch(paymentActions.deactiveSub(idPayment))
        },
        refundPayment: (idPayment, data) => {
            dispatch(paymentActions.refundPayment(idPayment, data))
        },

        //tab licenze
        getLicenceList: (data) => {
            dispatch(playerActions.getLicenceList(data))
        },

        //notifiche
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(pageUserInDetail);