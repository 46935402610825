import React, { Component, Fragment } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Select, FormControl, MenuItem, InputLabel, TextField } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default class formFiltersBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // parametri per filtri ricerca
            deviceId: this.props.vpn.idVpnPassForDetail === '' ? '' : this.props.vpn.idVpnPassForDetail,
            description: '',
            deviceModel: '',
            deviceVersion: '',
            devicePlatform: '',
            typeOrder: '',
            fieldOrder: '',
            user: '',
            key: '',
            createdStartAt: null,
            createdEndAt: null,
        };

        this.onChange = this.onChange.bind(this);
        this.setDateCreatedStartAt = this.setDateCreatedStartAt.bind(this);
        this.setDateCreatedEndAt = this.setDateCreatedEndAt.bind(this);
    }

    /**
     * Cambia il valore di quello stato.
     * 
     * @param {*} event 
     */
    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === 'typeOrder') {
            setTimeout(() => {
                this.sendParamsForSearch();
            }, 200);
        }
    }

    setHourStart = (newHour) => {
        newHour.setHours(1);
        newHour.setMinutes(0);
        newHour.setSeconds(0);
        newHour.setMilliseconds(0);
        return newHour;
    }

    setHourEnd = (newHour) => {
        newHour.setHours(24);
        newHour.setMinutes(59);
        newHour.setSeconds(59);
        newHour.setMilliseconds(999);
        return newHour;
    }

    // change per le date
    setDateCreatedStartAt = (newDate) => { this.setState({ createdStartAt: newDate }); }
    setDateCreatedEndAt = (newDate) => { this.setState({ createdEndAt: newDate }); }

    // Resetta tutti i filtri
    resetFilter = () => {
        this.setState({
            deviceId: '',
            description: '',
            deviceModel: '',
            deviceVersion: '',
            devicePlatform: '',
            createdStartAt: null,
            createdEndAt: null,
            typeOrder: '',
            fieldOrder: '',
            user: '',
            key: ''
        });

        setTimeout(() => {
            this.sendParamsForSearch('vuoto');
        }, 300);
    }

    /**
     * Se viene premuto il tasto enter (Invio) avvia una ricerca
     */
    clickEnter = (event) => {
        if (event.keyCode === 13) {
            this.sendParamsForSearch();
        }
    }

    /**
     * Invia i parametri filtro per la ricerca.
     * Se il parametro vuoto è presente, invia stringhe vuote per la ricerca.
     * 
     * @param {*} label se è vuoto, manda parametri vuoti
     */
    sendParamsForSearch = (label) => {
        if (label === 'vuoto') {
            this.props.setParamsForSearch({
                deviceId: '',
                description: '',
                deviceModel: '',
                deviceVersion: '',
                devicePlatform: '',
                createdStartAt: '',
                createdEndAt: '',
                typeOrder: '',
                fieldOrder: '',
                user: '',
                key: ''
            });
        } else {
            this.props.setParamsForSearch({
                deviceId: this.state.deviceId,
                description: this.state.description,
                deviceModel: this.state.deviceModel,
                deviceVersion: this.state.deviceVersion,
                devicePlatform: this.state.devicePlatform,
                createdStartAt: this.state.createdStartAt === null ? '' : this.setHourStart(this.state.createdStartAt),
                createdEndAt: this.state.createdEndAt === null ? '' : this.setHourEnd(this.state.createdEndAt),
                typeOrder: this.state.typeOrder,
                fieldOrder: this.state.fieldOrder,
                user: this.state.user,
                sortField: this.state.fieldOrder,
                sortType: this.state.typeOrder
            });
        }
    }

    render() {
        const { numberVpnBox, totalPages, setChange, currentNumberPage } = this.props;
        return (
            <Fragment>
                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="deviceId"
                            value={this.state.deviceId}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="Seriale"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="user"
                            value={this.state.user}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="Utente"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button
                            className={numberVpnBox === '10' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChange({ target: { name: 'numberVpnBox' } }, '10')}
                        > 10 </button>
                        <button
                            className={numberVpnBox === '25' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChange({ target: { name: 'numberVpnBox' } }, '25')}
                        > 25 </button>
                        <button
                            className={numberVpnBox === '50' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChange({ target: { name: 'numberVpnBox' } }, '50')}
                        > 50 </button>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <Pagination
                            boundaryCount={2}
                            count={parseInt(totalPages)}
                            page={parseInt(currentNumberPage)}
                            onChange={setChange}
                            color="primary"
                            showFirstButton
                            showLastButton
                            size="small"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button className="btn btn-primary" style={{ 'margin': '10px' }} onClick={this.sendParamsForSearch}>
                            Cerca
                        </button>
                        <button className="btn btn-secondary" style={{ 'margin': '10px' }} onClick={this.resetFilter}>
                            Reset
                        </button>
                    </div>
                </div>

                <Accordion className="myAccordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    // aria-controls="panel1a-content"
                    // id="panel1a-header"
                    >
                        <Typography>Altri filtri</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div">
                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl>
                                        <InputLabel>Come vuoi ordinare?</InputLabel>
                                        <Select
                                            labelId="fieldOrderLabel"
                                            name="fieldOrder"
                                            value={this.state.fieldOrder}
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value={'DeviceId'}>Vpn Box Serial</MenuItem>
                                            <MenuItem value={'DeviceModel'}>Device Model</MenuItem>
                                            <MenuItem value={'CreatedAt'}>Data Creazione</MenuItem>
                                            <MenuItem value={'DeviceVersion'}>Device Version</MenuItem>
                                            <MenuItem value={'DevicePlatform'}>Device Platform</MenuItem>
                                            <MenuItem value={'User'}>User</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-12 itemForSearch">
                                    <FormControl disabled={this.state.fieldOrder === ''}>
                                        <InputLabel>Tipo di Ordinamento</InputLabel>
                                        <Select
                                            labelId="typeOrderLabel"
                                            name="typeOrder"
                                            value={this.state.typeOrder}
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value={'asc'}>Crescente [A-Z]</MenuItem>
                                            <MenuItem value={'desc'}>Decrescente [Z-A]</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="col-12 itemForSearch">
                                        <KeyboardDatePicker
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="createdStartAt"
                                            label="Creata dal"
                                            value={this.state.createdStartAt}
                                            onChange={this.setDateCreatedStartAt}
                                        />
                                    </div>

                                    <div className="col-12 itemForSearch">
                                        <KeyboardDatePicker
                                            variant="inline"
                                            id="createdEndAt"
                                            label="Fino a"
                                            value={this.state.createdEndAt}
                                            onChange={this.setDateCreatedEndAt}
                                            // se la data di inizio è presente, disabilita tutte le date da quella data (compresa)
                                            minDate={this.state.createdStartAt ? moment(this.state.createdStartAt).add(1, 'days')._d : false}
                                            minDateMessage="Attenzione! Data precedente a quella di inizio"
                                            format="dd/MM/yyyy"
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="description"
                                        value={this.state.description}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Descrizione"
                                    />
                                </div>

                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="deviceModel"
                                        value={this.state.deviceModel}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Device Model"
                                    />
                                </div>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

            </Fragment>
        )
    }
}