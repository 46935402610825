import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Alert from '@material-ui/lab/Alert';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import 'date-fns';

import './style.scss';
import { FormControl, TextField } from '@material-ui/core';

/**
 * Attivazione modale per nuovo/modifica gruppo
 */
export default class modalGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.dataGroup.name || '',
            description: this.props.dataGroup.description || '',
            createdAt: this.props.dataGroup.createdAt ? moment(this.props.dataGroup.createdAt).format("DD/MM/YYYY - HH:mm") : '',
        }
        this.setChange = this.setChange.bind(this);
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    confirmSend = () => {
        const { errorNotify, dataGroup, isNewGroup, isUpdateGroup, isDeleteGroup, confirmSend } = this.props;
        if (isNewGroup) {
            if (this.state.name === "" || this.state.description === "") {
                errorNotify("Il campo nome o descrizione, non possono essere vuoti!")
            } else {
                confirmSend({
                    name: this.state.name,
                    description: this.state.description,
                });
            }
        }
        if (isUpdateGroup) {
            if (this.state.description === "") {
                errorNotify("Il campo descrizione non può essere vuoto!")
            } else {
                confirmSend({
                    name: this.state.name,
                    description: this.state.description,
                    createdAt: dataGroup.createdAt //inserisco una props poiche lo state viene modificato con moment...
                });
            }
        }
        if (isDeleteGroup) {
            confirmSend({
                "groupName": this.state.name
            });
        }
    }

    render() {
        const { stateModal, toggle, titleModal, dataGroup, isNewGroup, isUpdateGroup, isDeleteGroup } = this.props;
        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalGroup">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        {
                            !isDeleteGroup &&
                            <FormControl>
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <TextField
                                            name="name"
                                            label="Nome"
                                            value={this.state.name}
                                            onChange={this.setChange}
                                            InputProps={{ readOnly: isUpdateGroup }}
                                        />
                                    </div>
                                    {
                                        !isNewGroup &&
                                        <div className="col-6">
                                            <TextField
                                                name="createdAt"
                                                label="Data Creazione"
                                                value={this.state.createdAt}
                                            />
                                        </div>
                                    }
                                </div>
                            </FormControl>
                        }
                        {
                            (isUpdateGroup && !isDeleteGroup) &&
                            <Alert severity="warning"> Con la modifica puoi solo cambiare la descrizione.</Alert>
                        }
                        {
                            !isDeleteGroup &&
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <Form>
                                        <Form.Label column sm="2" className="text-right">
                                            Descrizione
                                        </Form.Label>
                                        <Form.Control
                                            name="description"
                                            as="textarea"
                                            rows="5"
                                            value={this.state.description}
                                            onChange={this.setChange}
                                        />
                                    </Form>
                                </div>
                            </div>
                        }
                        {
                            isDeleteGroup &&
                            <div>
                                Sei sicuro di voler cancellare il gruppo {dataGroup.name} ?
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.confirmSend}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}