import { connect } from 'react-redux'
import { userActions, notifyActions } from '../store/actions';
import Login from './pages';

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loginRequest: (data) => {
            dispatch(userActions.loginRequest(data))
        },
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);