import React, { Component, Fragment } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { isNil } from 'lodash';

import { API_GET_PLAYER_LIST_URL } from '../../../config';
import { playerActions } from '../../../store/actions';
import Date from '../../../common/date';
import ExpanablePlayer from './tabExpanablePlayer';
import Loading from '../../../common/loading';
import CSVExport from '../../../common/csvExport';
import ActionMenuPlayerList from './actionMenuPlayerList';
import ModalPlayer from '../../../common/modal/modalPlayer';
import ModalSendMessagePlayer from '../../../common/modal/modalSendMessagePlayer';
import IconLicence from '../../../common/iconLicence';
import { randomString } from '../../../common/actions/randomString';
import SelectorCopy from '../../../common/selectorCopy';

class tabPlayers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            listPlayers: [],
            countPlayers: 0,

            hasClickEditPlayer: false,
            hasClickSendMessagePlayer: false,

            modal: false,
            modalSendMessagePlayer: false,

            // dati del player da editare
            player: {
                licenceKey: '',
                description: '',
                playerId: '',
                user: ''
            },

            // inizializza dati sull'utente in dettaglio
            user: {
                id: '',
                registrationIp: '',
                email: '',
                lastLogOn: '',
                isRegistered: '',
                createdAt: '',
                isReseller: false,
                name: '',
                surname: '',
                country: ''
            },

            // usato per pulire la modale a ogni apertura, cosi da avere sempre i dati aggiornati
            shortId: ''
        }
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleModalSendMessagePlayer = this.toggleModalSendMessagePlayer.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            this.getListPlayer();
        }, 500);
    }

    /**
     * La funzione prende in input l'id dell'utente selezionato (input preso in automatico dallo stato), 
     * e verifica i player associati ad esso
     * 
     */
    getListPlayer = async () => {
        this.setState({ isLoading: true });
        try {
            let params = {
                'elementsByPage': '1000',
                'filters[0][User]': this.props.userId,
            };

            let response = await axios.get(`${API_GET_PLAYER_LIST_URL}`, { params: params });

            let arrayTemp = [];

            Object.values(response.data.value.value).forEach((k) => {
                arrayTemp.push(k);
            })

            this.setState({
                isLoading: false,
                listPlayers: arrayTemp,
                countPlayers: arrayTemp.length,

                // inizializza dati sull'utente in dettaglio
                user: {
                    id: this.props.userInDetail.id,
                    registrationIp: this.props.userInDetail.registrationIp,
                    email: this.props.userInDetail.id,
                    lastLogOn: this.props.userInDetail.lastLogOn,
                    isRegistered: this.props.userInDetail.isRegistered,
                    createdAt: this.props.userInDetail.createdAt,
                    isReseller: this.props.userInDetail.isReseller,
                    name: this.props.userInDetail.name,
                    surname: this.props.userInDetail.surname,
                    country: this.props.userInDetail.country
                },

                shortId: randomString()
            });

        } catch (result) {
            const { errorNotify } = this.props;
            this.setState({ isLoading: false });
            if (result && result.response && result.response.status) {
                switch (result.response.status) {
                    default: errorNotify('Error ' + result.response.status); break;
                }
            }
        }
    }

    /**
     * Al click del menu opzioni, controlla quale opzione sia stata selezionata 
     * e di conseguenza esegue l'azione per quella riga.
     * 
     * @param action è il tipo di azione selezionato
     * @param dataPlayer è l'oggetto contente le informazioni del player selezionato dell'utente per cui è richiesta quell'azione
     */
    actionClick = (action, dataPlayer) => {
        switch (action) {
            case 'editPlayer': case 'extendTrial': this.hasClickEditPlayer(dataPlayer); break;
            case 'sendMessagePlayer': this.hasClickSendMessagePlayer(dataPlayer); break;
            case 'deletePlayer': this.removePlayer(dataPlayer._id); break;
            case 'deactiveLicence': this.deactiveLicence(action, dataPlayer); break;
            case 'refreshPlayer': this.props.refreshPlayer(dataPlayer, action); break;
            default: break;
        }
    }

    /**
     * Rimuove un player
     * 
     * @param id del player
     */
    removePlayer = (playerId) => {
        this.props.removePlayer(playerId);
        setTimeout(() => {
            this.getListPlayer();
        }, 300);
    }

    /**
     * Ricarica la lista dei player dopo una modifica ad essi o alle playlist.
     * Non posso richiamare direttamente getListPlayer poiche
     * è una funzione async, e tramite il passaggio di props non viene letta come una funzione
     */
    refreshList = () => {
        this.getListPlayer();
    }

    toggleModal() {
        this.setState(prevState => ({ modal: !prevState.modal }));
    }

    toggleModalSendMessagePlayer() {
        this.setState(prevState => ({ modalSendMessagePlayer: !prevState.modalSendMessagePlayer }));
    }

    /**
     * Azioni prima di aprire la modale di editazione player
     */
    hasClickEditPlayer = (data) => {
        this.setState({
            hasClickEditPlayer: true,
            hasClickSendMessagePlayer: false,
            shortId: randomString()
        });
        this.associaPlayer(data);
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 100);
    }

    /**
     * Azioni prima di aprire la modale per invio messaggio al player
     */
    hasClickSendMessagePlayer = (data) => {
        this.setState({
            hasClickEditPlayer: false,
            hasClickSendMessagePlayer: true,
            shortId: randomString()
        });
        this.associaPlayer(data);
        setTimeout(() => {
            this.setState(prevState => ({ modalSendMessagePlayer: !prevState.modalSendMessagePlayer }));
        }, 200);
    }

    /**
     * Modifica un player
     */
    sendEditPlayer = (data) => {
        this.props.sendEditPlayer(data);
        setTimeout(() => {
            this.refreshList();
        }, 500);
    }

    /**
     * Manda un messaggio un player
     */
    sendMessagePlayer = (data) => {
        this.props.sendMessagePlayer(data);
        this.toggleModalSendMessagePlayer();
    }

    /**
     * Disattiva la licenza di un player.
     * Viene passata una licenceKey non valida così il player verrà disattivato
     */
    deactiveLicence = (action, data) => {
        let newData = {
            licenceKey: '0000000000000000',
            description: data.description === "" ? "---" : data.description,
            id: data._id,
            user: data.user,
            vpnPass: data.vpnPass
        };

        this.props.sendEditPlayer(newData, action);
    }

    /**
     * Prende i dati passati dall'action del player (in particolar modo quando si seleziona edita player),
     * e li manda alla modale di editazione.
     * Se i dati sono presenti associa al player, altrimenti resetta
     */
    associaPlayer = (data) => {
        if (data) {
            this.setState({
                player: {
                    licenceKey: data.licenceKey,
                    description: data.description,
                    user: data.user,
                    playerId: data._id,
                    vpnPass: data.vpnPass
                },
            });
        }
    }

    render() {
        const { role } = this.props;
        const columns = [
            {
                name: '',
                selector: row => <IconLicence typeIcon={row.licence !== null ? (row.licence.expireAt === null ? 'lifetime' : 'trial') : 'trial'} forDevice />,
                width: '55px'
            },
            {
                name: 'ID Device',
                selector: row => <SelectorCopy item={row._id} infoNotify={this.props.infoNotify} />
            },
            {
                name: 'Licence KEY',
                selector: row => isNil(row.licence) ? '--' : <SelectorCopy item={row.licence.key} infoNotify={this.props.infoNotify} />
            },
            {
                name: 'Descrizione',
                selector: row => row.description
            },
            {
                name: 'Creato il',
                selector: row => <Date format="L" date={row.createdAt} />
            },
            {
                name: 'Modello / Versione',
                selector: row => row.model + '/' + row.version
            },
            {
                name: 'Piattaforma',
                selector: row => row.platform
            },
            {
                selector: row => <ActionMenuPlayerList actionClick={this.actionClick} dataPlayer={row} role={role} />,
                right: true,
                button: true,
                allowOverflow: true,
                wrap: true
            },
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                {(this.props.player.isLoading || this.state.isLoading) && <Loading />}
                {
                    !this.state.isLoading &&
                    <Fragment>
                        <div className="row title">
                            <div className="col-6">
                                <Badge variant="warning">
                                    Numero Device: {this.state.countPlayers}
                                </Badge>
                            </div>

                            <div className="col-6 text-right">
                                {
                                    this.state.listPlayers.length !== 0 &&
                                    <CSVExport
                                        data={this.state.listPlayers}
                                        label="Scarica la lista di tutti i player"
                                        filename="players-list.csv"
                                    />
                                }
                            </div>
                        </div>

                        <IconLicence legend forDevice />

                        <hr />
                        <DataTable
                            columns={columns}
                            data={this.state.listPlayers}
                            customStyles={customStyles}
                            noHeader
                            striped
                            highlightOnHover
                            pointerOnHover
                            pagination
                            paginationPerPage={30}

                            expandableRows
                            expandOnRowClicked
                            expandableRowsComponent={ExpanablePlayer}
                            expandableRowsComponentProps={{
                                "refreshList": this.refreshList,
                                "errorNotify": this.props.errorNotify,
                                "infoNotify": this.props.infoNotify,
                                "history": this.props.history,
                            }}
                        />
                    </Fragment>
                }
                {
                    this.state.hasClickEditPlayer &&
                    <ModalPlayer
                        titleModal={'Modifica Player'}
                        clickEditPlayer={this.sendEditPlayer}
                        toggle={this.toggleModal}
                        stateModal={this.state.modal}
                        licence={this.state.player.licenceKey}
                        idPlayer={this.state.player.playerId}
                        description={this.state.player.description}
                        userId={this.props.userId}
                        vpnPass={this.state.player.vpnPass}
                        errorNotify={this.props.errorNotify}
                        key={this.state.shortId}
                    />
                }
                {
                    this.state.hasClickSendMessagePlayer &&
                    <ModalSendMessagePlayer
                        titleModal="Invia un messaggio al player"
                        deviceId={this.state.player.playerId}
                        click={this.sendMessagePlayer}
                        stateModal={this.state.modalSendMessagePlayer}
                        toggle={this.toggleModalSendMessagePlayer}
                        errorNotify={this.props.errorNotify}
                        key={this.state.shortId}
                    />
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        player: state.player
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removePlayer: (idPlayer) => {
            dispatch(playerActions.removePlayer(idPlayer))
        },
        sendEditPlayer: (data, action) => {
            dispatch(playerActions.sendEditPlayer(data, action))
        },
        refreshPlayer: (data) => {
            dispatch(playerActions.refreshPlayer(data))
        },
        sendMessagePlayer: (data) => {
            dispatch(playerActions.sendMessagePlayer(data))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(tabPlayers);