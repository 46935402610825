import React, { Component, Fragment } from 'react';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { controlRole } from '../../common/actions/controlRole';

export default class actionMenuMachine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setOpen: false,

            // valori che indicano lo status della macchina

            // isFailed: -2
            // isSuspended: -1
            // isCreated: 0
            // isInstalling: 1
            // isStarted: 2
        };
    }

    toggle = () => {
        this.setState({ setOpen: !this.state.setOpen });
    }

    render() {
        const { vpn, actionClick, role } = this.props;

        return (
            <Fragment>
                <ButtonDropdown isOpen={this.state.setOpen} toggle={this.toggle}>
                    <DropdownToggle caret>
                        <i className="fas fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu>
                        {
                            (
                                // quando lo status è IsFailed, isSuspended, isCreated
                                // esce il pulsante Modifica
                                (vpn.status !== 1 && vpn.status !== 2) &&
                                controlRole(role.vpnMachine, "api/VpnMachine/Update", "PUT")
                            ) &&
                            <DropdownItem onClick={() => { actionClick('edit', vpn); }}>
                                <div className="row">
                                    <div className="col-3 icon moreDetail">
                                        <i className="far fa-edit"></i>
                                    </div>
                                    <div className="col-9">
                                        Modifica
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            (
                                // quando lo status è IsFailed, isSuspended, isCreated
                                // esce il pulsante Setup
                                (vpn.status !== 1 && vpn.status !== 2) &&
                                controlRole(role.vpnMachine, "api/VpnMachine/Setup", "POST")
                            ) &&
                            <DropdownItem onClick={() => { actionClick('setup', vpn); }}>
                                <div className="row">
                                    <div className="col-3 icon resetPassword">
                                        <i className="fas fa-play"></i>
                                    </div>
                                    <div className="col-9">
                                        Setup
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            (
                                // quando lo status è IsFailed, isSuspended, isCreated
                                // esce il pulsante Elimina
                                (vpn.status !== 1 && vpn.status !== 2) &&
                                controlRole(role.vpnMachine, "api/VpnMachine/Delete", "DELETE")
                            ) &&
                            <DropdownItem onClick={() => { actionClick('remove', vpn); }}>
                                <div className="row">
                                    <div className="col-3 icon deleteUser">
                                        <i className="far fa-trash-alt"></i>
                                    </div>
                                    <div className="col-9">
                                        Elimina
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            // quando lo status è IsStarted
                            // esce il pulsante Vai ai peer
                            vpn.status === 2 &&
                            <Fragment>
                                {
                                    controlRole(role.vpnPeer, "api/VpnPeer", "GET") &&
                                    <DropdownItem onClick={() => { this.props.history.push(`/vpn/peer/${vpn.key}`); }}>
                                        <div className="row">
                                            <div className="col-3 icon sendMail">
                                                <i className="fas fa-external-link-alt"></i>
                                            </div>
                                            <div className="col-9">
                                                Vai ai peer
                                            </div>
                                        </div>
                                    </DropdownItem>
                                }

                                {
                                    controlRole(role.vpnMachine, "api/VpnMachine/Stop", "GET") &&
                                    <DropdownItem onClick={() => { actionClick('stop', vpn); }}>
                                        <div className="row">
                                            <div className="col-3 icon deleteUser">
                                                <i className="fas fa-stop-circle"></i>
                                            </div>
                                            <div className="col-9">
                                                Stop!
                                            </div>
                                        </div>
                                    </DropdownItem>
                                }
                            </Fragment>
                        }

                        {
                            (
                                // quando lo status è IsSuspended
                                // esce il pulsante Start
                                vpn.status === -1 &&
                                controlRole(role.vpnMachine, "api/VpnMachine/Start", "GET")
                            ) &&
                            <DropdownItem onClick={() => { actionClick('start', vpn); }}>
                                <div className="row">
                                    <div className="col-3 icon resetPassword">
                                        <i className="fas fa-play-circle"></i>
                                    </div>
                                    <div className="col-9">
                                        Start
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                    </DropdownMenu>
                </ButtonDropdown>

            </Fragment>
        )
    }
}