import { workerActionType } from '../actions';

const initialState = {
    isLoading: false,
};

export default function worker(state = initialState, action) {
    switch (action.type) {
        case `${workerActionType.ADD_ACTIVE_JOB}_PENDING`:
        case `${workerActionType.REMOVE_ACTIVE_JOB}_PENDING`:
            return {
                ...state,
                isLoading: true
            }
        case `${workerActionType.ADD_ACTIVE_JOB}_FULFILLED`:
        case `${workerActionType.REMOVE_ACTIVE_JOB}_FULFILLED`:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
}