import React, { Component, Fragment } from 'react';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { controlRole } from '../../common/actions/controlRole';
import { SUPER_ADMIN } from '../../config';

export default class actionMenuBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setOpen: false,
        };
    }

    toggle = () => {
        this.setState({ setOpen: !this.state.setOpen });
    }

    actionClick = (action, data) => {
        this.props.actionClick(action, data);
    }

    render() {
        const { role, vpnBox, userRole } = this.props;

        return (
            <Fragment>
                <ButtonDropdown isOpen={this.state.setOpen} toggle={this.toggle}>
                    <DropdownToggle caret>
                        <i className="fas fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu>
                        {
                            // oltre a controllare il ruolo viene controllato anche se la box è online.
                            // se offline la diagnostica non appare
                            
                            ( controlRole(role.vpnBox, "api/VpnBox/logs", "GET", true) && vpnBox.isOnline ) &&
                            <DropdownItem onClick={() => { this.actionClick('diagnostics', vpnBox); }}>
                                <div className="row">
                                    <div className="col-3 icon sendMail">
                                        <i className="fas fa-wrench"></i>
                                    </div>
                                    <div className="col-9">
                                        Diagnostica
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            controlRole(role.vpnBox, "api/VpnBox", "PUT", true) &&
                            <DropdownItem onClick={() => { this.actionClick('edit', vpnBox); }}>
                                <div className="row">
                                    <div className="col-3 icon moreDetail">
                                        <i className="far fa-edit"></i>
                                    </div>
                                    <div className="col-9">
                                        Modifica
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            controlRole(role.vpnBox, "api/VpnBox/Reboot", "GET", true) &&
                            <DropdownItem onClick={() => { this.actionClick('reboot', vpnBox); }}>
                                <div className="row">
                                    <div className="col-3 icon rebootBox">
                                        <i className="fas fa-sync-alt"></i>
                                    </div>
                                    <div className="col-9">
                                        Riavvia
                                    </div>
                                </div>
                            </DropdownItem>
                        }

                        {
                            userRole.user.role === SUPER_ADMIN &&
                            <DropdownItem onClick={() => { this.actionClick('remove', vpnBox); }}>
                                <div className="row">
                                    <div className="col-3 icon deleteUser">
                                        <i className="far fa-trash-alt"></i>
                                    </div>
                                    <div className="col-9">
                                        Elimina
                                    </div>
                                </div>
                            </DropdownItem>
                        }
                    </DropdownMenu>
                </ButtonDropdown>

            </Fragment>
        )
    }
}