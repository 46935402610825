import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { isNull, isEmpty } from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import './style.scss';
import MyTooltip from '../../common/tooltip';
import Loading from '../../common/loading';
import ModalPricing from '../../common/modal/modalPricing';
import { returnNationality } from '../../common/actions/returnNationality';
import { randomString } from '../../common/actions/randomString';
import { compareString } from '../../common/actions/compareString';
import { returnTypeName } from '../../common/actions/returnTypeName';
import { API_PREFERENCES, COUNTRY_PRICINGS } from '../../config';
import { controlRole } from '../../common/actions/controlRole';

export default class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // contiene la lista dei pricing restituita dopo la chiamata al BE
            getPricingList: [],

            // chiave di riferimento nel caso si volesse editare o rimuovere
            keyReference: '',
            // sottochiave di riferimento nel caso si volesse editare o rimuovere
            subKeyReference: '',
            // tutto il contenuto della chiave selezionata (variabile temporanea di appoggio)
            keyContentReference: '',

            // usato per avere come riferimento l'elemento da sostituire o eliminare durante l'edit
            keyElement: '',

            // elemento di riferimento nel caso si volesse editare o rimuovere
            elementReference: '',

            hasClickNewKey: false,
            hasClickNewElement: false,
            hasClickEdit: false,
            hasClickRemoveKey: false,
            hasClickExclusion: false,
            hasClickInclude: false,

            // variabili di appoggio per salvare nello stato i prezzi e le esclusioni cui voglio apportare modifiche
            allElementPricings: [],
            allElementExclusions: [],

            shortId: randomString(),

            modal: false,
            isLoading: true,

            loadingForRole: true
        }
        this.getPricingList = this.getPricingList.bind(this);
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare il pricing, viene rispedito alla home
        if (!isEmpty(this.props.role.preferences)) {
            if (!controlRole(this.props.role.preferences, "api/Preferences", "GET")) {
                this.props.history.push('/');
                this.props.infoNotify('Non hai nessun permesso per visualizzare il pricing!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    this.getPricingList();
                    this.props.getProductList();
                }
            }
        }
    }

    componentDidUpdate() {
        // se l'utente non ha nessun permesso per visualizzare il pricing, viene rispedito alla home
        if (!isEmpty(this.props.role.preferences)) {
            if (!controlRole(this.props.role.preferences, "api/Preferences", "GET")) {
                this.props.history.push('/');
                this.props.infoNotify('Non hai nessun permesso per visualizzare il pricing!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    this.getPricingList();
                    this.props.getProductList();
                }
            }
        }
    }


    /**
     * Chiama il BE e ritorna la lista degli indirizzi dei pricing
     * 
     * @return type array
     */
    getPricingList = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await axios.get(`${API_PREFERENCES}/${COUNTRY_PRICINGS}`);
            this.setState({
                getPricingList: response.data,
                isLoading: false,
            });
        } catch (result) {
            const { errorNotify } = this.props;
            this.setState({ isLoading: false });
            if (result && result.response && result.response.status) {
                switch (result.response) {
                    default: errorNotify(`Error ${result.response.status} su ${API_PREFERENCES} - GET`); break;
                }
            }
        }
    }

    /**
     * Usato solo per chiudere la modale, poiche resetta tutto lo state
     */
    toggleModal = () => {
        this.setState(prevState => ({ modal: !prevState.modal }));
        this.setState({
            hasClickNewKey: false,
            hasClickEdit: false,
            hasClickNewElement: false,
            hasClickRemoveKey: false,
            hasClickExclusion: false,
            hasClickInclude: false,
            keyReference: '',
            subKeyReference: '',
            keyContentReference: '',
            allElementPricings: [],
            allElementExclusions: [],
            elementReference: '',
            keyElement: ''
        });
    }

    /**
     * Se viene cliccato il pulsante nuova nazione, attiva la modale per inviare una nuova chiave
     */
    hasClickNewKey = () => {
        this.setState({ hasClickNewKey: true });

        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * Se viene cliccato il pulsante nuovo elemento, attiva la modale per inviare un nuovo elemento alla key(country).
     */
    hasClickNewElement = (key, subKey) => {
        this.setState({
            hasClickNewElement: true,
            keyReference: key,
            subKeyReference: subKey,
        });

        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * Se viene cliccato il pulsante rimuovi key, attiva la modale per rimuovere la nazione
     */
    hasClickRemoveKey = (key) => {
        this.setState({
            hasClickRemoveKey: true,
            keyReference: key
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * Se viene cliccato il pulsante edit pricing, attiva la modale per inviare modifiche su quel pricing e quel prodotto.
     * 
     * @param keyName è il nome della chiave, in questo caso il country (es: IT)
     * @param keyContent è il contenuto di tutta la chiave (es: Contenuto di IT)
     * @param subKey è il nome contenuto nella chiave (es: Pricings, Exclusions)
     * @param element è l'elemento che deve essere modificato (es: {"ProductName":"blabla",....}) 
     * @param keyElement è la chiave dell'indice dell'elemento che deve essere sostituito
     * 
     */
    hasClickEdit = (keyName, keyContent, subKey, element, keyElement) => {
        this.setState({
            hasClickEdit: true,
            keyReference: keyName,
            keyContentReference: keyContent,
            subKeyReference: subKey,
            elementReference: element,
            keyElement: keyElement
        });

        setTimeout(() => {
            this.getAllElement();
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * Se viene cliccato il pulsante escludi, attiva la modale di conferma per escludere quel prodotto.
     * 
     * @param keyName è il nome della chiave, in questo caso il country (es: IT)
     * @param keyContent è il contenuto di tutta la chiave (es: Contenuto di IT)
     * @param subKey è il nome contenuto nella chiave (es: Pricings, Exclusions)
     * @param element è l'elemento che deve essere modificato (es: {"ProductName":"blabla",....}) 
     * @param keyElement è la chiave dell'indice dell'elemento che deve essere sostituito
     * 
     */
    hasClickExclusion = (keyName, keyContent, subKey, element, keyElement) => {
        this.setState({
            hasClickExclusion: true,
            keyReference: keyName,
            keyContentReference: keyContent,
            subKeyReference: subKey,
            elementReference: element,
            keyElement: keyElement
        });

        setTimeout(() => {
            this.getAllElement();
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * Se viene cliccato il pulsante escludi, attiva la modale di conferma per escludere quel prodotto.
     * 
     * @param keyName è il nome della chiave, in questo caso il country (es: IT)
     * @param keyContent è il contenuto di tutta la chiave (es: Contenuto di IT)
     * @param subKey è il nome contenuto nella chiave (es: Pricings, Exclusions)
     * @param element è l'elemento che deve essere modificato (es: {"ProductName":"blabla",....}) 
     * @param keyElement è la chiave dell'indice dell'elemento che deve essere sostituito
     * 
     */
    hasClickInclude = (keyName, keyContent, subKey, element, keyElement) => {
        this.setState({
            hasClickInclude: true,
            keyReference: keyName,
            keyContentReference: keyContent,
            subKeyReference: subKey,
            elementReference: element,
            keyElement: keyElement
        });

        setTimeout(() => {
            this.getAllElement();
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * Aggiunge una nuova chiave alla lista.
     * Di default aggiunge tutti i prezzi di che il BE mi torna, e nelle esclusioni nulla.
     * Poi se voglio modificare qualcosa, modifico quello che mi pare.
     * 
     * @param key è la nuova chiave
     */
    addKey = (key) => {
        const { preferences } = this.props;

        let dataPricings = [];

        // eslint-disable-next-line
        preferences.allProducts.map(el => {
            dataPricings.push({
                "ProductName": el.name,
                "ProductType": el.type,
                "Price": el.price.toString()
            })
        });

        let data = {
            [key]: {
                "Pricings": dataPricings,
                "Exclusions": []
            }
        }

        this.props.sendEditPreferences(COUNTRY_PRICINGS, data);
        this.toggleModal();

        setTimeout(() => {
            this.getPricingList();
        }, 1000)
    }

    /**
     * Modifica un prezzo della lista, e rimanda tutto al BE.
     * 
     * @param key è la chiave principale (es. IT)
     * @param subKey è la sottochiave (es. Pricings, Exclusions)
     * @param data è l'oggetto completo (contiene: ProductType, ProductName, e se è un pricing contiene anche Price)
     */
    editKey = (key, subKey, data) => {
        let listTemp = [];
        let listTemp2 = [];
        let dataTemp = {};
        const { preferences } = this.props;

        // è un pricing da modificare?
        if (this.state.hasClickEdit && compareString(subKey, 'pricings')) {
            // eslint-disable-next-line
            this.state.allElementPricings.map(el => {
                listTemp.push(el);
            });

            // sostituisco il vecchio indirizzo elemento pricing con il nuovo
            listTemp.splice(this.state.keyElement, 1, data);

            // la chiave iniziale aveva anche le exclusions, quindi reinserisco quello che avevo salvate nello state in precedenza
            dataTemp = {
                [key]: {
                    [subKey]: listTemp,
                    "Exclusions": this.state.allElementExclusions
                }
            }
        }

        // è un exclusion?
        if (this.state.hasClickExclusion) {
            // eslint-disable-next-line
            this.state.allElementExclusions.map(el => {
                listTemp.push(el);
            });

            // aggiungo il nuovo elemento alle esclusioni
            listTemp.push(data);

            // eslint-disable-next-line
            this.state.allElementPricings.map(el => {
                listTemp2.push(el);
            });

            // rimuovo l'elemento dal pricings
            listTemp2.splice(this.state.keyElement, 1);

            // reimposto la chiave iniziale togliendo l'elemento dal pricings (listTemp2) e aggiungendo alle exclusions (listTemp) il nuovo elemento
            dataTemp = {
                [key]: {
                    "Pricings": listTemp2,
                    "Exclusions": listTemp
                }
            }
        }

        // è un inclusione?
        if (this.state.hasClickInclude) {
            let newData = {};

            // eslint-disable-next-line
            this.state.allElementPricings.map(el => {
                listTemp2.push(el);
            });

            // cerco il prodotto nella lista dei prodotti che ho nello store che lo stesso productName e prendo il prezzo di default
            // eslint-disable-next-line
            preferences.allProducts.map(el => {
                if (compareString(el.name, data.ProductName)) {
                    newData = {
                        "ProductName": data.ProductName,
                        "ProductType": data.ProductType,
                        "Price": el.price.toString()
                    }
                }
            })

            // aggiungo il nuovo elemento ai prodotti prezzati
            listTemp2.push(newData);

            // eslint-disable-next-line
            this.state.allElementExclusions.map(el => {
                listTemp.push(el);
            });

            // rimuovo l'elemento dalle escluzioni
            listTemp.splice(this.state.keyElement, 1);

            // reimposto la chiave iniziale togliendo l'elemento dalle esclusioni (listTemp) e aggiungendo alla lista dei pricings (listTemp2) il nuovo elemento
            dataTemp = {
                [key]: {
                    "Pricings": listTemp2,
                    "Exclusions": listTemp
                }
            }
        }

        this.props.sendEditPreferences(COUNTRY_PRICINGS, dataTemp);
        this.toggleModal();

        setTimeout(() => {
            this.getPricingList();
        }, 1000);
    }

    /**
     * Rimuove una key (country) dalla lista.
     * 
     * @param key è la chiave da rimuovere
     */
    removeKey = (key) => {
        this.props.deleteKeyFromList(COUNTRY_PRICINGS, key);
        this.toggleModal();
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.getPricingList();
        }, 1000);
    }

    /**
     * Ogni volta che l'utente clicca 'modifica un pricing',
     * questa funzione prende tutti i gli elementi contenuti nell'array Pricings e Exclusions riferiti a quella chiave e 
     * li inserisce in delle variabili temporanee in modo da tenerli
     * salvati nello state per una possibile put futura.
     * 
     * Esempio: se voglio modificare un prezzo che si trova all'interno id Pricings di IT,
     * questa funzione in allElementPricings inserisce tutti gli elementi di Pricings e tutte
     * le Exclusions in allElementExclusions
     */
    getAllElement = () => {
        // eslint-disable-next-line
        Object.keys(this.state.keyContentReference).map(item => {
            if (compareString(item, 'Pricings') && !isEmpty(this.state.keyContentReference[item])) {
                this.setState({ allElementPricings: this.state.keyContentReference[item] });
            }
            if (compareString(item, 'Exclusions') && !isEmpty(this.state.keyContentReference[item])) {
                this.setState({ allElementExclusions: this.state.keyContentReference[item] });
            }
        });
    }

    render() {
        const { role } = this.props;
        return (
            <Fragment>
                <div className="row whiteList">
                    <div className="col-sm-4 col-12">
                        <h2>WhiteList</h2>
                    </div>
                    <div className="col-sm-4 col-12 text-center">
                        {
                            controlRole(role.preferences, "api/Preferences/flush", "POST") &&
                            <button style={{ 'marginTop': '5px' }} className="btn btn-warning" onClick={this.props.flush}>
                                Flush
                            </button>
                        }
                        {
                            controlRole(role.configFlush, "api/Config", "POST") &&
                            <button style={{ 'marginTop': '5px', 'marginLeft': '15px' }} className="btn btn-danger" onClick={this.props.flushConfig}>
                                Flush CONFIG
                            </button>
                        }
                    </div>
                    {
                        controlRole(role.preferences, "api/Preferences", "PUT") &&
                        <div className="col-sm-4 col-12 text-right">
                            <button className="btn btn-success" onClick={this.hasClickNewKey} >
                                <i className="fas fa-plus"></i> Aggiungi Nuova Nazione
                            </button>
                        </div>
                    }
                </div>

                {
                    (this.props.preferences.isLoading || this.state.isLoading || this.state.loadingForRole) ? <Loading /> :
                        <Fragment>
                            <div className="contentPricingList">
                                {
                                    !isNull(this.state.getPricingList) &&
                                    Object.keys(this.state.getPricingList).map((item, key) => { // prende tutto l'oggetto con chiave primaria (es. Italia)
                                        return (
                                            <Accordion className="myAccordion">
                                                <AccordionSummary
                                                    // expandIcon={<ExpandMoreIcon />}
                                                    key={key}
                                                    className="keyAccordion"
                                                >
                                                    <div className="row keyRow">
                                                        <div className="col-10">
                                                            {returnNationality(item).toUpperCase()}
                                                        </div>
                                                        <div className="col-2 text-right">
                                                            <span onClick={() => { this.hasClickRemoveKey(item) }}>
                                                                <MyTooltip
                                                                    title={"Elimina la chiave " + item}
                                                                    position="top"
                                                                    content={<i className="fas fa-trash-alt" ></i>}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {
                                                        Object.keys(this.state.getPricingList[item]).map((el, key2) => { // prende tutti gli oggetti con sottochiave primaria (es. le subKey della key, Pricings, Exclusions)
                                                            return (
                                                                <Fragment key={key2}>
                                                                    <div className="row subKeyRow justify-content-center">
                                                                        <div className="col-10">
                                                                            <p className="subKeyTitle">{el}</p>
                                                                            {
                                                                                isEmpty(this.state.getPricingList[item][el]) ?
                                                                                    <div className="row emptyIp justify-content-center">
                                                                                        <div className="col">
                                                                                            - Vuoto -
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    this.state.getPricingList[item][el].map((elem, key3) => { // prende tutto l'array contenente gli indirizzi ip della sottochiave (es. tutti gli indirizzi di Ipv4)
                                                                                        return (
                                                                                            <Fragment key={key3}>
                                                                                                {
                                                                                                    compareString(el, 'pricings') &&
                                                                                                    <div className="row elementPricing justify-content-center">
                                                                                                        <div className="col-8">
                                                                                                            {returnTypeName(elem.ProductType)} - {returnTypeName(elem.ProductName)}: {elem.Price} €
                                                                                                        </div>
                                                                                                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6 styleCol">
                                                                                                            {
                                                                                                                controlRole(role.preferences, "api/Preferences", "PUT") &&
                                                                                                                <button
                                                                                                                    className="btnEdit"
                                                                                                                    onClick={
                                                                                                                        () => {
                                                                                                                            this.hasClickEdit(item, this.state.getPricingList[item], el, elem, key3)
                                                                                                                        }
                                                                                                                    }
                                                                                                                >
                                                                                                                    <i className="far fa-edit"></i> Modifica
                                                                                                                </button>
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6 styleCol">
                                                                                                            {
                                                                                                                controlRole(role.preferences, "api/Preferences", "PUT") &&
                                                                                                                <button
                                                                                                                    className="btnExclude"
                                                                                                                    onClick={
                                                                                                                        () => {
                                                                                                                            this.hasClickExclusion(item, this.state.getPricingList[item], el, elem, key3)
                                                                                                                        }
                                                                                                                    }
                                                                                                                >
                                                                                                                    <i className="fas fa-sign-out-alt"></i> Escludi
                                                                                                                </button>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    compareString(el, 'Exclusions') &&
                                                                                                    <div className="row ipAddress justify-content-center" key={key3}>
                                                                                                        <div className="col-8">
                                                                                                            {returnTypeName(elem.ProductType)} - {returnTypeName(elem.ProductName)}
                                                                                                        </div>
                                                                                                        <div className="col-2" />
                                                                                                        <div className="col-2">
                                                                                                            {
                                                                                                                controlRole(role.preferences, "api/Preferences", "PUT") &&
                                                                                                                <button
                                                                                                                    className="btnInclude"
                                                                                                                    onClick={
                                                                                                                        () => {
                                                                                                                            this.hasClickInclude(item, this.state.getPricingList[item], el, elem, key3)
                                                                                                                        }
                                                                                                                    }
                                                                                                                >
                                                                                                                    <i className="fas fa-sign-in-alt"></i> Includi
                                                                                                                </button>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>

                                                                                                }
                                                                                            </Fragment>
                                                                                        )
                                                                                    })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    })
                                }
                            </div>

                            {
                                (
                                    this.state.hasClickNewKey ||
                                    this.state.hasClickRemoveKey ||
                                    this.state.hasClickEdit ||
                                    this.state.hasClickExclusion ||
                                    this.state.hasClickInclude
                                ) &&
                                <ModalPricing
                                    titleModal={
                                        this.state.hasClickNewKey ? 'Nuovo Country' :
                                            this.state.hasClickRemoveKey ? 'Rimuovi Country' :
                                                this.state.hasClickEdit ? 'Modifica Prodotto' :
                                                    this.state.hasClickExclusion ? 'Escludi Prodotto' :
                                                        this.state.hasClickInclude ? 'Includi Prodotto' : ''
                                    }
                                    type={
                                        this.state.hasClickNewKey ? 'newKey' :
                                            this.state.hasClickRemoveKey ? 'removeKey' :
                                                this.state.hasClickEdit ? 'edit' :
                                                    this.state.hasClickExclusion ? 'exclude' :
                                                        this.state.hasClickInclude ? 'include' : ''
                                    }
                                    toggle={this.toggleModal}
                                    stateModal={this.state.modal}

                                    allElementPricings={this.state.allElementPricings}
                                    allElementExclusions={this.state.allElementExclusions}

                                    clickNewKey={this.addKey}
                                    clickEdit={this.editKey}
                                    clickRemoveKey={this.removeKey}

                                    keyReference={this.state.keyReference}
                                    subKeyReference={this.state.subKeyReference}
                                    elementReference={this.state.elementReference}

                                    preferences={this.props.preferences}
                                    key={this.state.shortId}
                                    errorNotify={this.props.errorNotify}
                                />
                            }
                        </Fragment>
                }
            </Fragment>
        )
    }
}
