import React, { Component } from 'react';
import { CSVLink } from "react-csv";

import icon from './exportCsv.png';
import './style.scss';
import MyTooltip from '../../common/tooltip';

/**
 * Crea il button per il download del csv.
 * Parametri in entrata:
 *  - data (dati che devono essere inseriti nella tabella excel)
 *  - headers (se presente, sono i titoli di colonna)
 *  - filename (nome del file di salvataggio)
 *  - label (se presente, è un testo in tooltip che si aggiunge vicino l'icona)
 */
export default class index extends Component {
    render() {
        const { data, headers, filename, label } = this.props;
        return (
            <CSVLink
                data={data}
                headers={headers}
                filename={filename}
                className="csvLink"
                // con ; come separatore, l'excel viene popolato correttamente in colonna
                separator={";"}
            >
                {
                    label ?
                        <MyTooltip
                            title={label}
                            position="left"
                            content={<img src={icon} alt="iconCsv" />}
                        />
                        :
                        <div className="smallBtn">
                            <img src={icon} alt="iconCsv" />
                        </div>
                }
            </CSVLink>
        )
    }
}