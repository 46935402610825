import React, { Component, Fragment } from 'react';
import { Badge } from 'react-bootstrap';

import IconLicence from '../../common/iconLicence';
import Date from '../../common/date';

export default class setailDevices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDeviceClicked: this.props.isDeviceClicked,
            countDevices: this.props.countDevices,
            listDevices: this.props.listDevices
        }
    }

    render() {
        return (
            <Fragment>
                {
                    (this.state.isDeviceClicked && this.state.countDevices !== 0) &&
                    <Fragment>
                        <div className="row">
                            <div className="col text-center">
                                <Badge variant="secondary"> Device associati: {this.state.countDevices} </Badge>
                            </div>
                        </div>
                        <br />
                        <IconLicence legend forDevice />
                        <br />
                        <div className="row title">
                            <div className="col-1">
                            </div>
                            <div className="col">
                                Tipologia Device
                            </div>
                            <div className="col">
                                Data creazione
                            </div>
                            <div className="col">
                                IP di registrazione
                            </div>
                            <div className="col">
                                Modello TV
                            </div>
                        </div>
                    </Fragment>
                }
                
                <div className="blockHeight">
                    {
                        (this.state.isDeviceClicked && this.state.listDevices !== []) &&
                        this.state.listDevices.map((item, k) => {
                            return (
                                <div className="row subList" key={k}>
                                    <div className="col-1 icon">
                                        {
                                            item.licence !== null ?
                                                item.licence.endAt === null ?
                                                    <span className="iconLifetime">
                                                        <i className="fas fa-check-circle"></i>
                                                    </span>
                                                    :
                                                    <span className="iconTrial">
                                                        <i className="fas fa-clock"></i>
                                                    </span>
                                                :
                                                <span className="iconTrial">
                                                    <i className="fas fa-clock"></i>
                                                </span>
                                        }
                                    </div>
                                    <div className="col top-10">
                                        {item.deviceType === 'VIDEO-PLAYER' &&
                                            <span> Player </span>}
                                        {item.deviceType === 'VPN-NANOBOX' &&
                                            <span> VPN </span>}
                                    </div>
                                    <div className="col">
                                        <Date format="L" date={item.createdAt} />
                                    </div>
                                    <div className="col top-10">
                                        {item.registrationIp}
                                    </div>
                                    <div className="col top-10">
                                        {item.model}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    (this.state.isDeviceClicked && this.state.countDevices === 0) && <div> Nessun device associato... </div>
                }
            </Fragment>
        )
    }
}