import { connect } from 'react-redux'
import { notifyActions } from '../store/actions';
import pageChangeLog from './pages';

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user,
        role: state.role
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(pageChangeLog);