import React, { Component, Fragment } from 'react';
import { Form } from 'react-bootstrap';

import { MAINTENANCE } from '../../config';

export default class tabModalChange extends Component {

    /**
     * Ritorna la stringa corretta per il name dell'input.
     * Se il secondo parametro è presente, allora restituisce lo state (esempio: this.state.logsIt)
     * @param {*} lang 
     * @param {*} state 
     * @returns 
     */
    controlLanguage = (lang, state) => {
        const { logsIt, logsEn, logsEs, logsDe, logsFr } = this.props;

        switch (lang) {
            case 'it-IT':
                if (state) return logsIt;
                else return 'logsIt';
            case 'en-GB':
                if (state) return logsEn;
                else return 'logsEn';
            case 'es-SP':
                if (state) return logsEs;
                else return 'logsEs';
            case 'de-DE':
                if (state) return logsDe;
                else return 'logsDe';
            case 'fr-FR':
                if (state) return logsFr;
                else return 'logsFr';
            default: break;
        }
    }

    render() {
        const { type, onChange, language, typeChangeLog } = this.props;
        return (
            <Fragment>
                {
                    typeChangeLog !== MAINTENANCE &&
                    <div className="row justify-content-center" style={{ 'marginTop': '10px' }}>
                        <div className="col-12">
                            Logs
                        </div>
                    </div>
                }
                <div className="row justify-content-center">
                    <div className="col-12 miniLegend">
                        {
                            typeChangeLog !== MAINTENANCE ?
                                '(per ottenere un elenco puntato, premere \'Invio\' nella textArea)' : ''
                        }
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-12">
                        {
                            type === 'newVersionLog' &&
                            <Form>
                                <Form.Control
                                    as="textarea"
                                    rows="5"
                                    name={this.controlLanguage(language)}
                                    value={this.controlLanguage(language, true)}
                                    onChange={onChange}
                                />
                            </Form>
                        }
                        {
                            type === 'editVersionLog' &&
                            <Form>
                                <Form.Control
                                    as="textarea"
                                    rows="5"
                                    name={this.controlLanguage(language)}
                                    value={this.controlLanguage(language, true)}
                                    onChange={onChange}
                                />
                            </Form>
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}