import { connect } from 'react-redux'
import { roleActions, notifyActions, promoActions } from '../store/actions';
import pageRole from './pages';

const mapStateToProps = state => {
    return {
        ...state,
        user: state.user,
        role: state.role,
        promo: state.promo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        },
        deleteRole: (roleId) => {
            dispatch(roleActions.deleteRole(roleId))
        },
        getRoleList: () => {
            dispatch(roleActions.getRoleList())
        },
        newRole: (data) => {
            dispatch(roleActions.newRole(data))
        },
        updateRole: (data) => {
            dispatch(roleActions.updateRole(data))
        },
        assignRole: (data) => {
            dispatch(roleActions.assignRole(data))
        },
        getListDevice: (email) => {
            dispatch(promoActions.getListDevice(email))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(pageRole);