import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Select, FormControl, MenuItem, InputLabel, TextField } from '@material-ui/core';

import './style.scss';
import { isJson } from '../../common/actions/isJson';

/**
 * Attivazione modale per aggiungere / modificare active job
 */
export default class modalActiveJob extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobId: '',
            taskName: '',
            taskInput: '{"":""}',
            serviceName: '',
            serviceInput: '{"":""}',
            formIsValid: true
        }
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    changeInput = (name, input) => {
        if (name === 'taskName') {
            this.setState({ taskInput: JSON.stringify(input) });
        }
        if (name === 'serviceName') {
            this.setState({ serviceInput: JSON.stringify(input) });
        }
    }

    confirm = () => {
        if (this.formIsValid()) {
            this.props.click({
                jobId: this.state.jobId,
                taskName: this.state.taskName,
                serviceName: this.state.serviceName,
                taskInput: this.state.taskInput === '{"":""}' ? null : JSON.parse(this.state.taskInput),
                serviceInput: this.state.serviceInput === '{"":""}' ? null : JSON.parse(this.state.serviceInput)
            })
        }
    }

    /**
     * Controlla se la form sia stata correttamente compilata
     */
    formIsValid = () => {
        const { errorNotify } = this.props;
        if (
            this.state.jobId === '' ||
            this.state.taskName === '' ||
            this.state.serviceName === '' ||
            !isJson(this.state.taskInput) ||
            !isJson(this.state.serviceInput)
        ) {
            this.setState({ formIsValid: false });
            errorNotify('Compila correttamente tutti i campi!');
            return false;
        } else {
            this.setState({ formIsValid: true });
            return true;
        }
    }

    render() {
        const { stateModal, toggle, titleModal, listTasks, listServices } = this.props;
        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalActiveJob">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        <FormControl className="choose-form">
                            <div className="row">
                                <div className="col-12">
                                    <TextField
                                        name="jobId"
                                        label="JOB ID"
                                        value={this.state.jobId}
                                        onChange={this.setChange}
                                        error={this.state.jobId === '' && !this.state.formIsValid}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <FormControl>
                                        <InputLabel>Task Name</InputLabel>
                                        <Select
                                            name="taskName"
                                            value={this.state.taskName}
                                            onChange={this.setChange}
                                            error={this.state.taskName === '' && !this.state.formIsValid}
                                        >
                                            {
                                                listTasks.map((item, k) => {
                                                    return (
                                                        <MenuItem
                                                            key={k}
                                                            value={item.name}
                                                            onClick={() => { this.changeInput('taskName', item.input) }}
                                                        >
                                                            {item.name}
                                                        </MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-6">
                                    <FormControl>
                                        <InputLabel>Service Name</InputLabel>
                                        <Select
                                            name="serviceName"
                                            value={this.state.serviceName}
                                            onChange={this.setChange}
                                            error={this.state.serviceName === '' && !this.state.formIsValid}
                                        >
                                            {
                                                listServices.map((item, k) => {
                                                    return (
                                                        <MenuItem
                                                            key={k}
                                                            value={item.name}
                                                            onClick={() => { this.changeInput('serviceName', item.input) }}
                                                        >
                                                            {item.name}
                                                        </MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <TextField
                                        label="Task Input"
                                        name="taskInput"
                                        multiline
                                        rows={5}
                                        value={this.state.taskInput}
                                        onChange={this.setChange}
                                    />
                                </div>
                                <div className="col-6">
                                    <TextField
                                        label="Service Input"
                                        multiline
                                        rows={5}
                                        name="serviceInput"
                                        value={this.state.serviceInput}
                                        onChange={this.setChange}
                                    />
                                </div>
                            </div>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.confirm}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}