/**
 * Controlla due stringhe ignorando il case sensitive.
 * 
 * Esempio:
 *   'a' = 'a'? true
 *   'AaA' = 'aAa'? true
 *   'a' = 'á'? false
 *   'a' = 'b'? false
 * 
 * @param a prima stringa
 * @param b seconda stringa
 * 
 */
export var compareString = function (a, b) {
    return typeof a === 'string' && typeof b === 'string'
        ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
        : a === b;
}