import React, { Component, Fragment } from 'react';
import axios from 'axios';

import { API_GET_PLAYER_LIST_URL, API_PAYMENT } from '../../config';
import './style.scss';
import LoadingBar from '../../common/loading/loadingBar';
import DetailDevices from './detailDevices';
import DetailPayment from './detailPayment';
import { controlRole } from '../../common/actions/controlRole';

export default class expanableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.data.id,
            user: {
                id: this.props.data.id,
                registrationIp: this.props.data.registrationIp,
                lastLogOn: this.props.data.lastLogOn,
                isRegistered: this.props.data.isRegistered,
                country: this.props.data.country
            },

            isLoading: false,

            // inizializzo la lista dei player dell'utente
            listDevices: [],

            // inizializzo la lista dei pagamenti dell'utente
            listPayment: [],

            // quanti player ha acquistato l'utente
            countDevices: 0,

            // quanti pagamenti ha fatto l'utente
            countPayment: 0,

            // verifica che sia stato premuto il click del button device
            isDeviceClicked: false,

            // verifica che sia stato premuto il click del button payment
            isPaymentClicked: false,

        }
    }

    /**
     * La funzione prende in input l'id dell'utente selezionato (input preso in automatico dallo stato), 
     * e verifica i player associati ad esso
     * 
     */
    getDeviceList = async () => {
        this.setState({ isLoading: true });
        try {
            let params = {
                'elementsByPage': '100',
                'filters[0][User]': this.state.userId,
            };

            let response = await axios.get(`${API_GET_PLAYER_LIST_URL}`, { params: params });

            let arrayTemp = [];

            Object.values(response.data.value.value).forEach((k) => {
                arrayTemp.push(k);
            })

            this.setState({
                isDeviceClicked: true,
                isPaymentClicked: false,
                isLoading: false,
                listDevices: arrayTemp,
                countDevices: arrayTemp.length
            });

        } catch (result) {
            const { errorNotify } = this.props;
            this.setState({ isLoading: false });
            if (result && result.response && result.response.status) {
                switch (result.response.status) {
                    default: errorNotify(`Error ${result.response.status} su ${API_GET_PLAYER_LIST_URL} - GET`); break;
                }
            }
        }
    }

    /**
     * La funzione prende in input l'id dell'utente selezionato (input preso in automatico dallo stato), 
     * e verifica i pagamenti associati ad esso  
     * 
     */
    getPaymentList = async () => {
        this.setState({ isLoading: true });
        try {
            let params = {
                'elementsByPage': '10000000',
                'userFilters[0][Email]': this.state.userId,
                'filters[0][PaymentStatus]': '1',
            };

            let response = await axios.get(`${API_PAYMENT}`, { params: params });

            let arrayTemp = [];

            Object.values(response.data.value.value).forEach((k) => {
                arrayTemp.push(k);
            })

            this.setState({
                isDeviceClicked: false,
                isPaymentClicked: true,
                isLoading: false,
                listPayment: arrayTemp,
                countPayment: arrayTemp.length
            });


        } catch (result) {
            const { errorNotify } = this.props;
            this.setState({ isLoading: false });
            if (result && result.response && result.response.status) {
                switch (result.response.status) {
                    default: errorNotify(`Error ${result.response.status} su ${API_PAYMENT} - GET`); break;
                }
            }
        }
    }

    /**
     * Al click, rimanda alla pagina con le informazioni dettagliate dell'utente selezionato
     */
    clickMoreDetail = () => {
        this.props.clickMoreDetail('moreDetail', this.state.user);
    }

    render() {
        const { role } = this.props;
        return (
            <div className="row expanableComponentUser">
                <div className="col-12">

                    <div className="row text-center">
                        {
                            controlRole(role.player, "api/Player/GetPlayers", "GET") &&
                            <div className="col">
                                <button className="btn btn-primary" onClick={this.getDeviceList}> Device associati </button>
                            </div>
                        }
                        {
                            controlRole(role.payment, "api/Payment", "GET") &&
                            <div className="col">
                                <button className="btn btn-primary" onClick={this.getPaymentList}> Pagamenti associati </button>
                            </div>
                        }
                        <div className="col">
                            <button className="btn btn-warning" onClick={this.clickMoreDetail} > Maggiori Dettagli </button>
                        </div>
                    </div>
                    {
                        this.state.isLoading ?
                            <LoadingBar />
                            :
                            <Fragment>
                                {
                                    this.state.isDeviceClicked &&
                                    <DetailDevices
                                        isDeviceClicked={this.state.isDeviceClicked}
                                        countDevices={this.state.countDevices}
                                        listDevices={this.state.listDevices}
                                    />
                                }
                                {
                                    this.state.isPaymentClicked &&
                                    <DetailPayment
                                        isPaymentClicked={this.state.isPaymentClicked}
                                        countPayment={this.state.countPayment}
                                        listPayment={this.state.listPayment}
                                    />
                                }
                            </Fragment>

                    }
                </div>
            </div>
        )
    }
}