import { RECAPTCHA_KEY } from '../../config';

const loadReCaptcha = () => {
    const script = document.createElement('script');

    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`;

    document.body.appendChild(script);
}

export default loadReCaptcha
