import React, { Component } from 'react';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { controlRole } from '../../../common/actions/controlRole';
import isNull from 'lodash/isNull';
import './style.scss';

export default class actionMenuPayments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            setOpen: false,
            paymentId: '',
            paymentTemp: {}
        };
    }

    toggle = () => {
        this.setState({ setOpen: !this.state.setOpen });
    }

    /**
     * Invia all'azione del click, il tipo di azione e i dati del player
     */
    actionClick = (action) => {
        this.props.actionClick(action, this.props.promoTemp._id, this.props.promoTemp);
    }

    render() {
        const { role, paymentTemp, hasClickModalDeletePayment, hasClickModalRefundPayment, hasClickModalDeactiveSub } = this.props;
        return (
            <ButtonDropdown isOpen={this.state.setOpen} toggle={this.toggle}>
                <DropdownToggle caret>
                    <i className="fas fa-ellipsis-v"></i>
                </DropdownToggle>

                <DropdownMenu>
                    {
                        !isNull(paymentTemp.invoiceUrl) &&
                        <DropdownItem href={paymentTemp.invoiceUrl} download>
                            <div className="row">
                                <div className="col-3 icon downloadPayment">
                                    <i className="fas fa-download" />
                                </div>
                                <div className="col-9 label">
                                    Download Fattura
                                </div>
                            </div>
                        </DropdownItem>
                    }

                    {
                        !isNull(paymentTemp.deliveryNoteUrl) &&
                        <DropdownItem href={paymentTemp.deliveryNoteUrl} download>
                            <div className="row">
                                <div className="col-3 icon bollaPayment">
                                    <i className="fas fa-download" />
                                </div>
                                <div className="col-9 label">
                                    Download Bolla
                                </div>
                            </div>
                        </DropdownItem>
                    }

                    {
                        // se il prodotto è un'acquisto con status 1 (pagato) o status 6 (rimborsato parzialmente)', allora permette un rimborso sulle quantità disponibili
                        (
                            paymentTemp.paymentProvider !== 'Stark' && (paymentTemp.totalpaid !== paymentTemp.totalRefunded) &&
                            (paymentTemp.paymentStatus === 1 || paymentTemp.paymentStatus === 6 || paymentTemp.paymentStatus === 3) &&
                            controlRole(role.payment, "api/Payment/partialRefund", "POST")
                        ) &&
                        <DropdownItem onClick={() => hasClickModalRefundPayment(paymentTemp)}>
                            <div className="row">
                                <div className="col-3 icon downloadPayment">
                                    <i className="fas fa-sync-alt"></i>
                                </div>
                                <div className="col-9 label">
                                    Rimborsa Pagamento
                                </div>
                            </div>
                        </DropdownItem>
                    }

                    {
                        controlRole(role.payment, "api/Payment/CancelSubscription", "POST") && paymentTemp.paymentStatus === 4 &&
                        <DropdownItem onClick={() => hasClickModalDeactiveSub(paymentTemp._id)}>
                            <div className="row">
                                <div className="col-3 icon deletePayment">
                                    <i className="fas fa-times"></i>
                                </div>
                                <div className="col-9 label">
                                    Disattiva Sottoscrizione
                                </div>
                            </div>
                        </DropdownItem>
                    }

                    {
                        controlRole(role.payment, "api/Payment", "DELETE") &&
                        <DropdownItem onClick={() => hasClickModalDeletePayment(paymentTemp._id)}>
                            <div className="row">
                                <div className="col-3 icon deletePayment">
                                    <i className="far fa-trash-alt"></i>
                                </div>
                                <div className="col-9 label">
                                    Elimina Pagamento
                                </div>
                            </div>
                        </DropdownItem>
                    }
                </DropdownMenu>
            </ButtonDropdown>
        )
    }
}