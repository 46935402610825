import React, { Component } from 'react';
import { NativeSelect, FormControl } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';

import { months } from '../../../common/actions/months';
import Loading from '../../../common/loading/loadingInBlock';

export default class paymentOfDay extends Component {
    render() {
        const { valueMonths, monthUnPaired, onChange, labelsMonths, loadingGraph5 } = this.props;

        const optionsPayments = {
            responsive: true,
            layout: {
                padding: {
                    left: 15,
                    right: 25,
                    top: 10
                }
            },

            legend: {
                display: true,
                position: 'top',
                align: 'end'
            },

            plugins: {
                title: {
                    display: true,
                    text: 'Pagamenti Mensili',
                    font: {
                        size: 20
                    }
                }
            }
        };

        const dataPayments = {
            labels: labelsMonths,
            datasets: [
                {
                    label: "Nazioni",
                    backgroundColor: "rgba(42,110,255, 0.5)",
                    data: valueMonths,
                    hoverBackgroundColor: 'rgba(42,110,255, 0.5)',
                    borderColor: 'Grey',
                    hoverBorderColor: 'Orange',
                    borderWidth: 1,

                },
            ],
        };

        return (
            <div className="containerChart">
                <div className="homeChart">
                    <FormControl className="licenceMonth">
                        <NativeSelect
                            name="monthUnPaired"
                            value={monthUnPaired}
                            onChange={onChange}
                        >
                            {
                                months.map((item, k) => {
                                    return (
                                        <option className="colorMonth" key={k} value={item.value}>{item.label}</option>
                                    );
                                })
                            }
                        </NativeSelect>
                    </FormControl>
                    {
                        loadingGraph5 ? <Loading /> :
                            <Bar options={optionsPayments} data={dataPayments} />
                    }
                </div>
            </div>
        )
    }
}