import { paymentActionType } from '../actions';

const initialState = {
    isLoading: false,

    // numero dei pagamenti
    totalPayments: '',

    // numero di pagine dei pagamenti
    totalPages: '',

    // lista dei pagamenti (con i vari filtri settati)
    listPayments: [],

    // id usato per skippare a un determinato pagamento
    idPaymentForDetail: '',

    // filtri conservati nello store, usato se voglio richiamare la get, dopo aver eseguito CRUD
    paramsForListPaymentsTemp: {}
};

export default function payment(state = initialState, action) {
    switch (action.type) {
        case `${paymentActionType.SET_ID_PAYMENT_FOR_DETAIL}`:
            return {
                ...state,
                idPaymentForDetail: action.idPaymentForDetail
            }
        case `${paymentActionType.GET_PAYMENT}_PENDING`:
        case `${paymentActionType.REFUND_PAYMENT}_PENDING`:
        case `${paymentActionType.UPDATE_DEVICE}_PENDING`:
        case `${paymentActionType.DELETE_PAYMENT}_PENDING`:
        case `${paymentActionType.CANCEL_SUB}_PENDING`:
            return {
                ...state,
                isLoading: true
            }
        case `${paymentActionType.REFUND_PAYMENT}_FULFILLED`:
        case `${paymentActionType.DELETE_PAYMENT}_FULFILLED`:
        case `${paymentActionType.CANCEL_SUB}_FULFILLED`:
        case `${paymentActionType.UPDATE_DEVICE}_FULFILLED`:
            return {
                ...state,
                isLoading: false
            }
        case `${paymentActionType.GET_PAYMENT}_FULFILLED`:
            return {
                ...state,
                isLoading: false,
                listPayments: action.payload.listPayments,
                totalPages: action.payload.totalPages,
                totalPayments: action.payload.totalPayments,
                paramsForListPaymentsTemp: action.payload.paramsForListPaymentsTemp
            }
        default:
            return state;
    }
}