import React, { Component, Fragment } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Select, FormControl, MenuItem, InputLabel, TextField } from '@material-ui/core';
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default class formFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // quanti bundle vuoi visualizzare
            numberBundle: '2',

            // numeri della pagina che si possono visualizzare (si popola in base alla risposta del server)
            numbersPage: [],

            // numerpo di pagina selezionato
            currentNumberPage: '1',

            // quale campo ordinare
            fieldOrder: '',

            // tipo di ordinamento
            typeOrder: '',

            // // inizializzazione dell'array filters
            filterBundleId: '',
            filterBundleName: '',
            filterBundleProductName: '',
            filterBundleDescription: '',
            filterBundleDeviceId: '',
            filterBundleDateCreated: null,
            filterBundleDateEnd: null,

            // elementi totali presenti nel DB (in questo caso quanti bundle ci sono)
            totalElements: 0,

            changeNumberPage: false
        }
    }

    componentDidMount() {
        this.search();
    }

    setChange = (event, page) => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === '' || event.target.name === undefined) {
            this.setState({
                currentNumberPage: page,
                changeNumberPage: true
            });
            setTimeout(() => {
                this.search();
            }, 200);
        }
        if (event.target.name === 'numberBundle' || event.target.name === 'typeOrder') {
            setTimeout(() => {
                this.search();
            }, 200);
        }
    }

    /**
     * Al click, effettua alcuni controlli di inserimento ricerca.
     * In caso di esito positivo, avvia la ricerca.
     * Altrimenti notifica all'utente cosa deve correggere
     */
    search = () => {
        this.props.setLoading(true);
        if (this.state.fieldOrder !== '' && this.state.typeOrder === '') {
            this.props.warningNotify('Devi inserire il tipo di ordinamento!');
            this.props.setLoading(false);
        } else {
            setTimeout(() => {
                this.props.getBundleList({
                    'elementsByPage': this.state.numberBundle,
                    'page': this.state.changeNumberPage ? this.state.currentNumberPage : 1,
                    'sortField': this.state.fieldOrder,
                    'sortType': this.state.typeOrder,
                    'filters[0][Id]': this.state.filterBundleId,
                    'filters[1][Name]': this.state.filterBundleName,
                    'filters[2][Description]': this.state.filterBundleDescription,
                    'filters[3][ProductName]': this.state.filterBundleProductName,
                    'filters[4][DeviceId]': this.state.filterBundleDeviceId,
                    'filters[5][CreatedAtStart]': this.state.filterBundleDateCreated === null ? '' : this.setHourStart(this.state.filterBundleDateCreated),
                    'filters[6][CreatedAtEnd]': this.state.filterBundleDateEnd === null ? '' : this.setHourEnd(this.state.filterBundleDateEnd),
                });

                if (!this.state.changeNumberPage) {
                    this.setState({
                        currentNumberPage: 1,
                    });
                }

                if (this.state.changeNumberPage) {
                    this.setState({
                        changeNumberPage: false,
                    });
                }

                this.props.setLoading(false);
            }, 300);
        }
    }

    /**
     * In base alla risposta del server, 
     * setta il numero delle pagine disponibili per la paginazione
     */
    setNumbersPage = (numbersPage) => {
        let temp = [];
        for (let i = 1; i <= parseInt(numbersPage); i++) {
            temp.push(i);
        }
        this.setState({ numbersPage: temp });
    }

    setHourStart = (newHour) => {
        newHour.setHours(1);
        newHour.setMinutes(0);
        newHour.setSeconds(0);
        newHour.setMilliseconds(0);
        return newHour;
    }

    setHourEnd = (newHour) => {
        newHour.setHours(24);
        newHour.setMinutes(59);
        newHour.setSeconds(59);
        newHour.setMilliseconds(999);
        return newHour;
    }

    setDateCreatedChange = (newDate) => { this.setState({ filterBundleDateCreated: newDate }); }

    setDateEndChange = (newDate) => { this.setState({ filterBundleDateEnd: newDate }); }

    /**
     * Se viene premuto il tasto enter (Invio) avvia una ricerca
     */
    clickEnter = (event) => {
        if (event.keyCode === 13) {
            this.search();
        }
    }

    // Resetta tutti i filtri
    resetFilter = () => {
        this.setState({
            // quanti player vuoi visualizzare
            numberBundle: '25',

            // numeri della pagina che si possono visualizzare (si popola in base alla risposta del server)
            numbersPage: [],

            // numerpo di pagina selezionato
            currentNumberPage: '1',

            // quale campo ordinare
            fieldOrder: '',

            // tipo di ordinamento
            typeOrder: '',

            // // inizializzazione dell'array filters
            filterBundleId: '',
            filterBundleName: '',
            filterBundleDescription: '',
            filterBundleProductName: '',
            filterBundleDeviceId: '',
            filterBundleDateCreated: null,
            filterBundleDateEnd: null,

            // elementi totali presenti nel DB (in questo caso quanti bundle ci sono)
            totalElements: 0,
            changeNumberPage: false
        });

        setTimeout(() => {
            this.search();
        }, 300);
    }

    /**
     * Se decido di cambiare il numero di bundle.
     * 
     * @param {*} number 
     */
    setNumberBundleForSearch = (number) => {
        this.setState({ numberBundle: number });
        setTimeout(() => {
            this.search();
        }, 200);
    }

    render() {
        const { bundle, preferences } = this.props;
        return (
            <Fragment>
                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="filterBundleId"
                            value={this.state.filterBundleId}
                            onChange={this.setChange}
                            onKeyDown={this.clickEnter}
                            label="ID Bundle"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="filterBundleName"
                            value={this.state.filterBundleName}
                            onChange={this.setChange}
                            onKeyDown={this.clickEnter}
                            label="Nome Bundle"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <FormControl>
                            <InputLabel>Nome Prodotto</InputLabel>
                            <Select
                                labelId="filterBundleProductName"
                                name="filterBundleProductName"
                                value={this.state.filterBundleProductName}
                                onChange={this.setChange}
                            >
                                {
                                    preferences.allProducts.map((el, key) => {
                                        return (
                                            <MenuItem key={key} value={el.name}>{el.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button
                            className={this.state.numberBundle === '10' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberBundleForSearch('10')}
                        > 10 </button>
                        <button
                            className={this.state.numberBundle === '25' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberBundleForSearch('25')}
                        > 25 </button>
                        <button
                            className={this.state.numberBundle === '50' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberBundleForSearch('50')}
                        > 50 </button>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <Pagination
                            boundaryCount={2}
                            count={parseInt(bundle.totalPages)}
                            page={parseInt(this.state.currentNumberPage)}
                            onChange={this.setChange}
                            color="primary"
                            showFirstButton
                            showLastButton
                            size="small"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button className="btn btn-primary" style={{ 'margin': '10px' }} onClick={this.search}>
                            Cerca
                        </button>
                        <button className="btn btn-secondary" style={{ 'margin': '10px' }} onClick={this.resetFilter}>
                            Reset
                        </button>
                    </div>
                </div>

                <Accordion className="myAccordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    // aria-controls="panel1a-content"
                    // id="panel1a-header"
                    >
                        <Typography>Altri filtri</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div">
                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl>
                                        <InputLabel>Come vuoi ordinare?</InputLabel>
                                        <Select
                                            labelId="fieldOrderLabel"
                                            name="fieldOrder"
                                            value={this.state.fieldOrder}
                                            onChange={this.setChange}
                                        >
                                            <MenuItem value={'Id'}>ID Bundle</MenuItem>
                                            <MenuItem value={'Name'}>Nome</MenuItem>
                                            <MenuItem value={'ProductName'}>Product Name</MenuItem>
                                            <MenuItem value={'Description'}>Descrizione</MenuItem>
                                            <MenuItem value={'CreatedAt'}>Creazione</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-12 itemForSearch">
                                    <FormControl disabled={this.state.fieldOrder === ''}>
                                        <InputLabel>Tipo di Ordinamento</InputLabel>
                                        <Select
                                            labelId="typeOrderLabel"
                                            name="typeOrder"
                                            value={this.state.typeOrder}
                                            onChange={this.setChange}
                                        >
                                            <MenuItem value={'asc'}>Crescente [A-Z]</MenuItem>
                                            <MenuItem value={'desc'}>Decrescente [Z-A]</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="col-12 itemForSearch">
                                        <KeyboardDatePicker
                                            id="dateCreated"
                                            variant="inline"
                                            label="Creazione da"
                                            value={this.state.filterBundleDateCreated}
                                            onChange={this.setDateCreatedChange}
                                            format="dd/MM/yyyy"
                                        />
                                    </div>

                                    <div className="col-12 itemForSearch">
                                        <KeyboardDatePicker
                                            id="dateEnd"
                                            variant="inline"
                                            label="Fino a"
                                            value={this.state.filterBundleDateEnd}
                                            onChange={this.setDateEndChange}
                                            // se la data di inizio è presente, disabilita tutte le date da quella data (compresa)
                                            minDate={this.state.filterBundleDateCreated ? moment(this.state.filterBundleDateCreated).add(1, 'days')._d : false}
                                            minDateMessage="Attenzione! Data precedente a quella di inizio"
                                            format="dd/MM/yyyy"
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="filterBundleDeviceId"
                                        value={this.state.filterBundleDeviceId}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="Device ID"
                                    />
                                </div>

                                {/* <div className="col-12 itemForSearch">

                                </div> */}
                            </div>

                        </Typography>
                    </AccordionDetails>
                </Accordion>

            </Fragment>
        )
    }
}